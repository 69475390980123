import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { CloseIcon } from '../../assets/icons';
import { checkIfMACAddressIsValid } from '../../shared/validation';
import {
	CommonDeviceConfigurationGroup,
	UserRolesEnum,
	Device,
	ConfigGroupName,
	CommonPropertyNameEnum,
} from '../../types';
import Button from '../buttons/Button';
import AddressField from './fields/AddressField';
import UserRoleNotice from '../UserRoleNotice';
import { userHasRoleSelector } from '../../state/auth/selectors/userHasRole';
import Alert from '../Alert';
import { int32Max } from '../../shared/constants';
import { useConnectDeviceForm } from '../../hooks/useConnectDeviceForm';
import { getConfigGroupPropertyValue } from '../../utils/device';
import NumberField from './fields/NumberField';

interface ConnectDeviceModalProps {
	close: () => void;
	device: Device;
	commonConfiguration?: CommonDeviceConfigurationGroup;
}

export default function ConnectDeviceModal( {
	device,
	close,
	commonConfiguration,
}: ConnectDeviceModalProps ) {
	const { t } = useTranslation();
	const userCanEditSettings = useRecoilValue(
		userHasRoleSelector( UserRolesEnum.MAINTENANCE )
	);

	const portNumberValue = getConfigGroupPropertyValue(
		device.configuration,
		ConfigGroupName.Common,
		CommonPropertyNameEnum.PortNumber
	);

	const addressValue = getConfigGroupPropertyValue(
		device.configuration,
		ConfigGroupName.Common,
		CommonPropertyNameEnum.Address
	);

	const {
		onTestConnection,
		onSave,
		control,
		isDirty,
		errors,
		testConnectionLoading,
		saveConfigurationLoading,
		testConnectionStatus,
		responseMessage,
		responseStatus,
		deviceActivationFailed,
	} = useConnectDeviceForm(
		device,
		commonConfiguration as CommonDeviceConfigurationGroup,
		{
			'address.value': addressValue,
			'port.value': Number( portNumberValue ),
		}
	);

	return (
		<div className="modal modal-open">
			<div className="modal-box h-1/2 flex flex-col animate-fade-in-up-modal-content rounded-lg">
				<div className="mb-2">
					<UserRoleNotice requiredRole={ UserRolesEnum.MAINTENANCE } />
				</div>
				<div>
					<AddressField
						name="address.value"
						label={ t( 'MAC Address' ) }
						placeholder={ t( 'Enter MAC Address' ) }
						control={ control }
						rules={ {
							required: `${ t( 'MAC address is required' ) }`,
							validate: ( address: string ) => {
								const isValidMacAddress = checkIfMACAddressIsValid( address );

								if ( !isValidMacAddress ) {
									return `${ t( 'Invalid MAC Address' ) }`;
								}
								return true;
							},
						} }
					/>

					<NumberField
						name="port"
						control={ control }
						label={ t( 'Port' ) }
						placeHolder={ t( 'Enter port' ) }
						rules={ {
							min: {
								value: 0,
								message: `${ t( 'Invalid port value' ) }`,
							},
							max: {
								value: int32Max,
								message: `${ t( 'Invalid port value' ) }`,
							},
						} }
						invalidChars={ [ '+', '-', 'e' ] }
						required
					/>

					<Button
						text={ t( 'Test connection' ) }
						onClick={ onTestConnection }
						className="bg-primary text-center mt-4"
						loading={ testConnectionLoading }
						disabled={
							!isDirty || !!Object.keys( errors ).length || !userCanEditSettings
						}
					/>

					{responseStatus && responseMessage ? (
						<Alert
							type={
								responseStatus === 'fail' ? 'connectionFail' : responseStatus
							}
							message={ responseMessage }
							className="mt-3"
						/>
					) : null}

					{deviceActivationFailed ? (
						<Alert
							message={ t( 'Device activation failed!' ) }
							type="fail"
							className="mt-2"
						/>
					) : null}
				</div>
				<div className="flex mt-auto">
					<div className="flex flex-row flex-1 justify-end gap-4">
						{testConnectionStatus ? (
							<div>
								<Button
									text={
										testConnectionStatus === 'fail'
											? t( 'Save anyway' )
											: t( 'Save connection' )
									}
									onClick={ onSave }
									className="bg-primary text-center"
									loading={ saveConfigurationLoading }
								/>
							</div>
						) : null}
						<div>
							<Button text={ t( 'Close' ) } onClick={ close } icon={ <CloseIcon /> } />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
