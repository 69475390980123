import React, { useCallback } from 'react';
import { twMerge } from 'tailwind-merge';
import { AnimatedSpinIcon } from '../../assets/icons';

interface ButtonProps
	extends React.DetailedHTMLProps<
	React.ButtonHTMLAttributes<HTMLButtonElement>,
	HTMLButtonElement
	> {
	text: string;
	icon?: React.ReactNode;
	loading?: boolean;
	isFormButton?: boolean;
}

export default function Button( {
	text,
	icon,
	className,
	loading = false,
	disabled = false,
	isFormButton = false,
	type = 'button',
	...rest
}: ButtonProps ) {
	const buttonStyle = twMerge(
		isFormButton
			? 'rounded mx-6 min-w-36 min-h-8 btn-primary'
			: 'flex flex-row justify-between px-3 w-full min-h-8 leading-10 items-center text-sm font-bold rounded bg-base-700 text-primary-content',
		icon ? 'justify-between' : 'justify-center',
		className,
		disabled
			? 'bg-neutral bg-opacity-50 shadow-none btn-disabled text-base-300'
			: 'shadow-bsm'
	);

	return (
		<button { ...rest } disabled={ loading || disabled } className={ buttonStyle }>
			{loading ? (
				<AnimatedSpinIcon />
			) : (
				<>
					<span>{text}</span>
					{icon ? <span className="ml-2">{icon}</span> : null}
				</>
			)}
		</button>
	);
}
