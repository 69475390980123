import React from 'react';

interface PlaceClipperProps {
	path: string;
}

const PlaceClipper = React.forwardRef( ( props: PlaceClipperProps, ref: any ) => {
	const { path } = props;
	return (
		<svg overflow="visible">
			<defs>
				<clipPath id="clippedPlace">
					<path d={ path } fill="none" />
				</clipPath>
				<clipPath id="clip">
					<use xlinkHref="#outlineInside" />
				</clipPath>
			</defs>

			{/* Clipped place outline */}
			<path
				ref={ ref }
				id="outlineInside"
				className="stroke-base-content stroke-8 fill-none"
				d={ path }
				clipPath="url(#clip)"
			/>
		</svg>
	);
} );

export default PlaceClipper;
