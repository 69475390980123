import { url } from 'inspector';
import { DateTime } from 'luxon';
import { DeviceTypeEnum, GraphicsUrls } from '../types';
import { queryBuilder } from './strings';

export type TimeOptionType =
	| 'hour'
	| 'day'
	| 'week'
	| 'month'
	| 'year'
	| 'custom';

export const monthQuery = queryBuilder( { from: 'now-1M', to: 'now' } );

export const weekQuery = queryBuilder( { from: 'now-1w', to: 'now' } );

export const yearQuery = queryBuilder( { from: 'now-1y', to: 'now' } );

export const dayQuery = queryBuilder( { from: 'now-1d', to: 'now' } );

export const hourQuery = queryBuilder( { from: 'now-60m', to: 'now' } );

export const refreshTime = ( refresh: string ) => queryBuilder( { refresh } );

export const customRangeQuery = ( from: string, to: string ) => {
	return queryBuilder( { from, to } );
};
export const getTimeDataQuery = (
	option: TimeOptionType,
	range?: { from?: DateTime; to?: DateTime }
) => {
	switch ( option ) {
		case 'hour':
			return hourQuery;
		case 'day':
			return dayQuery;
		case 'week':
			return weekQuery;
		case 'month':
			return monthQuery;
		case 'year':
			return yearQuery;
		default:
			return customRangeQuery(
				range?.from?.toMillis().toString() || '',
				range?.to?.toMillis().toString() || ''
			);
	}
};

export const getGrafanaPeriodUrl = ( urlOptions: {
	timeOption?: TimeOptionType;
	selectedRange?: { from: DateTime; to: DateTime };
	deviceType?: DeviceTypeEnum;
} ): keyof GraphicsUrls => {
	const hasMoreThanWeekDifference =
		!!urlOptions.selectedRange &&
		urlOptions.selectedRange?.from.plus( { day: 7 } ).toMillis() <
			urlOptions.selectedRange?.to.toMillis();

	if (
		hasMoreThanWeekDifference ||
		urlOptions.timeOption === 'month' ||
		urlOptions.timeOption === 'year'
	) {
		return 'ConcatPerHour';
	}

	const hasUpToSevenDaysDifference =
		!!urlOptions.selectedRange &&
		urlOptions.selectedRange?.from.plus( { day: 1 } ).toMillis() <
			urlOptions.selectedRange?.to.toMillis();

	if (
		hasUpToSevenDaysDifference ||
		urlOptions.timeOption === 'day' ||
		urlOptions.timeOption === 'week'
	) {
		if ( urlOptions.deviceType === DeviceTypeEnum.VRM_SM ) {
			// The Stack Monitor has no accumulated data per minute,
			// because its sample is already per minute.
			// In this case, we should return the Raw format
			return 'RawMeasurementData';
		}

		return 'ConcatPerMinute';
	}

	return 'RawMeasurementData';
};
