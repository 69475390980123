/* eslint-disable react/jsx-indent */
import React, {
	useCallback, useEffect, useMemo, useState
} from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { DateTime } from 'luxon';
import { twMerge } from 'tailwind-merge';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import { AlarmListItem } from './AlarmListItem';
import { NextIcon, PrevIcon } from '../../assets/icons';
import { AppScreen, historicalAlarmsPageSize } from '../../shared/constants';
import { activeAlarmsAtom } from '../../state/alarms/activeAlarms';
import { allDevicesStateAtom } from '../../state/devices/allDevices';
import { Alarm, AlarmSeverityEnum, DeviceTypeEnum } from '../../types';
import { AlarmListItemIcon } from './AlarmListItemIcon';
import {
	alarmSeverityScale,
	getAlarmWithLowerAlertType,
	sortAlarmsByPriority,
} from '../../utils/alarms';
import { isLoggedInState } from '../../state/auth/isLoggedIn';
import api from '../../middleware/api';
import { alarmPaneHistoricalAlarmsAtom } from '../../state/alarms/alarmPaneHistoricalAlarms';

const SHOW_HISTORICAL_ALARMS_IN_ALARM_PANE = false;

const animationClass =
	'transition-spacing ease-out-cubic-bezier-1 duration-300 transform-gpu';
const styles = {
	opened: 'bg-base-200 justify-end ml-0',
	closed: '-ml-72',
	outerWrapper:
		'w-80 flex flex-col relative rounded-tr-md h-full bg-white border-gray-300 border-solid',
	mainContent: 'flex flex-col min-h-full',
};

const AlarmPanel: React.FC<{ canHide: boolean }> = ( { canHide } ) => {
	const { t } = useTranslation();
	const history = useHistory();
	const devices = useRecoilValue( allDevicesStateAtom );
	const activeAlarms = useRecoilValue( activeAlarmsAtom );
	const isLoggedInUser = useRecoilValue( isLoggedInState );
	const [ historicalAlarms, setHistoricalAlarms ] = useRecoilState(
		alarmPaneHistoricalAlarmsAtom
	);

	const [ isAlarmPaneOpen, setIsAlarmPaneOpen ] = useState<boolean>( false );
	const [ isAnimationEnabled, setIsAnimationEnabled ] = useState( false );

	// On route change disable animation if it is enabled
	useEffect( () => {
		const removeRouteChangeListener = history.listen( () => {
			if ( isAnimationEnabled === true ) {
				setIsAnimationEnabled( false );
			}
		} );
		return () => {
			removeRouteChangeListener();
		};
	}, [ history, isAnimationEnabled ] );

	const hasActiveAlarms = activeAlarms.length > 0;

	const handleButtonPress = () => {
		if ( !hasActiveAlarms || canHide ) {
			if ( isAnimationEnabled === false ) {
				setIsAnimationEnabled( true );
			}
			setIsAlarmPaneOpen( !isAlarmPaneOpen );
		}
	};

	useEffect( () => {
		// Opening it only when the user haven't explicitly opened it
		if ( isAlarmPaneOpen === undefined ) {
			if ( hasActiveAlarms ) {
				setIsAlarmPaneOpen( true );
			}
		}
	}, [ hasActiveAlarms, isAlarmPaneOpen ] );

	const alarmTypes = useMemo( () => {
		return new Set<AlarmSeverityEnum>( [
			...activeAlarms.map( ( alarm ) => {
				return alarm.alarmSeverity;
			} ),
		] );
	}, [ activeAlarms ] );

	// Array of second active alarms for the devices with more than one alarm. The array includes the additional alarms with lower alert type.
	const extraDeviceAlarms: Array<Alarm> = [];
	activeAlarms.forEach( ( alarm, index ) => {
		const secondDeviceAlarm = activeAlarms.find(
			( a, i ) => i !== index &&
				a.deviceId === alarm.deviceId &&
				a.alarmSeverity === AlarmSeverityEnum.CONNECTION
		);
		if ( secondDeviceAlarm ) {
			extraDeviceAlarms.push(
				getAlarmWithLowerAlertType( alarm, secondDeviceAlarm )
			);
		}
	} );

	// List of grouped alarms by device
	const listOfDevicesWithAlarms = activeAlarms.reduce( ( list, currentAlarm ) => {
		const listGroupIndex = list.findIndex(
			( alarm ) => alarm.deviceId === currentAlarm.deviceId
		);
		const deviceWithAlarm = devices.find(
			( device ) => device.id === currentAlarm.deviceId
		);

		if ( listGroupIndex === -1 ) {
			list.push( {
				deviceId: currentAlarm.deviceId,
				deviceName: deviceWithAlarm?.name ?? t( 'Unknown device' ),
				deviceType: deviceWithAlarm?.type,
				alarms: [ currentAlarm ],
			} );
		} else {
			const alarmsList = [ ...list[listGroupIndex].alarms, currentAlarm ];
			const sortedAlarmsListByPriority = sortAlarmsByPriority( alarmsList );
			/* eslint-disable no-param-reassign */
			list[listGroupIndex].alarms = sortedAlarmsListByPriority;
		}

		return list;
	}, [] as Array<{ deviceId: string; deviceName: string; deviceType?: DeviceTypeEnum; alarms: Alarm[] }> );

	// Prioritized list of grouped alarms by device
	const prioritizedListOfDevicesWithAlarms = listOfDevicesWithAlarms.sort(
		( device1, device2 ) => {
			let device1HighestAlarm = device1.alarms[0];
			let device2HighestAlarm = device2.alarms[0];

			if (
				alarmSeverityScale[device1HighestAlarm.alarmSeverity] <
				alarmSeverityScale[device2HighestAlarm.alarmSeverity]
			) {
				return -1;
			}

			if (
				alarmSeverityScale[device1HighestAlarm.alarmSeverity] >
				alarmSeverityScale[device2HighestAlarm.alarmSeverity]
			) {
				return 1;
			}

			return 0;
		}
	);

	// Sort - devices with higher alarms should go up

	const getAlarmPanelColorClass = useCallback( () => {
		if ( alarmTypes.has( AlarmSeverityEnum.ERROR_AND_CONNECTION ) ) {
			return `bg-danger-${ AlarmSeverityEnum.ERROR_AND_CONNECTION }`;
		}
		if ( alarmTypes.has( AlarmSeverityEnum.ERROR ) ) {
			return `bg-danger-${ AlarmSeverityEnum.ERROR }`;
		}
		if ( alarmTypes.has( AlarmSeverityEnum.WARNING_AND_CONNECTION ) ) {
			return `bg-danger-${ AlarmSeverityEnum.WARNING_AND_CONNECTION }`;
		}
		if ( alarmTypes.has( AlarmSeverityEnum.WARNING ) ) {
			return `bg-danger-${ AlarmSeverityEnum.WARNING }`;
		}
		if ( alarmTypes.has( AlarmSeverityEnum.CONNECTION ) ) {
			return `bg-danger-${ AlarmSeverityEnum.CONNECTION }`;
		}

		return 'bg-base-700';
	}, [ alarmTypes ] );

	useEffect( () => {
		api.alarms
			.getHistorical( {
				pageSize: historicalAlarmsPageSize,
				periodFrom: DateTime.local().minus( { hours: 24 } ).toISO(),
				periodTo: DateTime.local().toISO(),
			} )
			.then( ( alarmsResponse ) => {
				setHistoricalAlarms( alarmsResponse.alarms );
			} )
			.catch( ( getHistoricalAlarmsErr ) => {
				console.warn( { getHistoricalAlarmsErr } );
			} );
	}, [ setHistoricalAlarms ] );

	const alarmPanelColorClass = getAlarmPanelColorClass().toLowerCase();

	const outerWrapperStyles = twMerge(
		styles.outerWrapper,
		isAlarmPaneOpen || ( hasActiveAlarms && !canHide )
			? `${ styles.opened }`
			: `${ styles.closed } ${ alarmPanelColorClass }`,
		isAnimationEnabled && animationClass
	);

	const mainContentWrapperStyles = twMerge(
		styles.mainContent,
		isAlarmPaneOpen || ( hasActiveAlarms && !canHide )
			? 'opacity-100'
			: 'opacity-0'
	);

	const showToggleButton = !hasActiveAlarms || ( canHide && hasActiveAlarms );
	const showActiveAlarmsIndicators =
		!isAlarmPaneOpen && hasActiveAlarms && canHide;

	return (
		<div className="pt-4 max-h-screen">
			<div className={ outerWrapperStyles }>
				{/* Panel action button */}
				<div className="absolute rounded flex flex-col items-center justify-center top-0 z-10 py-1 right-1">
					{showToggleButton && (
						<button className="cursor-pointer" onClick={ handleButtonPress }>
							{isAlarmPaneOpen ? (
								<div className="flex text-base-700">
									<PrevIcon />
								</div>
							) : (
								<div className="flex text-primary-content">
									<NextIcon />
								</div>
							)}
						</button>
					)}
				</div>
				{/* Panel alarms state summary (appears when closed) */}
				{!hasActiveAlarms ? null : (
					<div
						className={ `absolute -mt-12 top-1/4 text-primary-content right-1.5 ${
							showActiveAlarmsIndicators ? 'animate-fade-in-0.75' : 'hidden'
						}` }
					>
						{Array.from( alarmTypes ).map( ( type ) => (
							<div
								key={ type }
								className="flex items-center justify-center flex-col"
							>
								<AlarmListItemIcon type={ type } />
								<span className="font-bold text-md text-primary-content mb-4">
									{
										activeAlarms.filter( ( alarm ) => alarm.alarmSeverity === type )
											.length
									}
								</span>
							</div>
						) )}
					</div>
				)}

				<div className={ mainContentWrapperStyles }>
					<h3 className="flex text-base-700 pr-2 pl-8 py-2 font-bold">
						{t( 'Alarms' )}
					</h3>
					{/* Active alarms list */}
					{( isAlarmPaneOpen && hasActiveAlarms ) ||
					( hasActiveAlarms && !canHide ) ? (
							<div className="flex flex-1 flex-col overflow-y-auto scrollbar scrollbar-thumb-scrollbar scrollbar-track-scrollbar-default-bg scrollbar-thin">
								{prioritizedListOfDevicesWithAlarms.map( ( deviceWithAlarm ) => {
									const currentDeviceHighestPriorityAlarm =
									deviceWithAlarm.alarms[0];
									const currentDeviceSecondaryAlarm = deviceWithAlarm.alarms.find(
										( alarm ) => alarm.id !== currentDeviceHighestPriorityAlarm.id &&
										alarm.alarmSeverity === AlarmSeverityEnum.CONNECTION
									);

									return (
										<Link
											key={ deviceWithAlarm.deviceId }
											to={ {
												pathname: `${
													deviceWithAlarm.deviceType === DeviceTypeEnum.VRM_SM
														? AppScreen.STACK_MONITOR
														: AppScreen.DEVICE_DETAILS
												}/${ currentDeviceHighestPriorityAlarm.deviceId }`,
											} }
										>
											<AlarmListItem
												type={ currentDeviceHighestPriorityAlarm.alarmSeverity }
												deviceName={ deviceWithAlarm.deviceName }
												peakValue={ currentDeviceHighestPriorityAlarm.peakValue }
												unit={ currentDeviceHighestPriorityAlarm.unit }
												created={ currentDeviceHighestPriorityAlarm.created }
												secondAlarm={ currentDeviceSecondaryAlarm }
											/>
										</Link>
									);
								} )}
								<ReactTooltip id="device-name-tooltip" className="tooltip" />
							</div>
						) : (
							<div className="flex flex-col justify-center h-full">
								<h3 className="text-2xl text-center text-gray-300">
									{t( 'No active alarms' )}
								</h3>
								{isLoggedInUser && (
									<Link
										to="/alarm-log"
										className="text-sm text-center text-blue-400"
									>
										{t( 'View alarm log' )}
									</Link>
								)}
							</div>
						)}
					{/* Solved alarms list */}
					{SHOW_HISTORICAL_ALARMS_IN_ALARM_PANE && historicalAlarms.length ? (
						<div className="mt-auto text-base-700 bg-base-300 p-2 rounded-t-md mx-2">
							<h4 className="text-base font-semibold pl-2">
								{t( 'Recent resolved alarms (24h)' )}
							</h4>
							{( isAlarmPaneOpen || ( hasActiveAlarms && !canHide ) ) && (
								<div>
									{historicalAlarms.map( ( alarm ) => {
										return (
											<AlarmListItem
												key={ alarm.id }
												type={ alarm.alarmSeverity }
												deviceName={ alarm.deviceName }
												peakValue={ alarm.peakValue }
												unit={ alarm.unit }
												created={ alarm.created }
												isResolved={ !!alarm.resolvedBy }
												resolvedAt={ alarm.resolved }
											/>
										);
									} )}
								</div>
							)}
						</div>
					) : null}
				</div>
			</div>
		</div>
	);
};

export default AlarmPanel;
