import React from 'react';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
import { roundMeasurement } from '../../utils/device';
import { getPrettyTimeStamp } from '../../utils/time';

interface AlarmSummaryInfoProps {
	title: string;
	currentValue?: number;
	unit?: string;
	peakValue?: number;
	startTime?: string;
	className?: string;
}

const AlarmSummaryInfo = ( {
	title,
	currentValue,
	peakValue,
	unit,
	startTime,
	className,
}: AlarmSummaryInfoProps ) => {
	const { t } = useTranslation();

	const containerStyle = twMerge(
		'border-l border-base-100 border-opacity-20 mt-4',
		className
	);

	return (
		<div className={ containerStyle }>
			<div className="font-normal px-2">
				{title !== undefined && title.trim().length > 0 ? title : '-'}
			</div>
			<div className="flex flex-row text-sm px-2">
				<div className="min-w-28">{t( 'Current value' )}:</div>
				<div className="font-bold whitespace-nowrap overflow-ellipsis">
					{currentValue !== undefined ? roundMeasurement( currentValue ) : '-'}{' '}
					{unit ?? ''}
				</div>
			</div>

			<div className="flex flex-row text-sm px-2 italic">
				<div className="min-w-28">{t( 'Peak value' )}:</div>
				<div className="font-bold whitespace-nowrap overflow-ellipsis">
					{peakValue !== undefined ? roundMeasurement( peakValue ) : '-'}{' '}
					{unit ?? ''}
				</div>
			</div>

			<div className="flex flex-row text-sm px-2">
				<div className="min-w-28 ">{t( 'Start time' )}:</div>
				<div>{getPrettyTimeStamp( startTime )}</div>
			</div>
		</div>
	);
};

export default AlarmSummaryInfo;
