import React from 'react';
import { LoginIcon } from '@heroicons/react/solid';
import { twMerge } from 'tailwind-merge';
import AlarmPanel from '../alarms/AlarmPanel';
import SideNavigation from '../nav/SideNavigation';
import StackMonitorsBottomIndicator from '../stack-monitor/StackMonitorsBottomIndicator';

export const IS_AUTHENTICATED = true;

interface LayoutProps {
	children: React.ReactNode;
	hideAlarmPanel?: boolean;
	canToggleAlarmPanel?: boolean;
	hideNavigation?: boolean;
	hideStackMonitor?: boolean;
	showScreenBackground?: boolean;
	screenContentClassName?: string;
	screenWrapperClassName?: string;
	expandStackMonitor?: boolean;
}

export const LoginButton = () => (
	<a
		href="/components/modal#my-modal"
		className="btn capitalize text-lg btn-outline ml-5"
	>
		Sign in <LoginIcon className="h-5 w-5 ml-5" />
	</a>
);

export const Layout = ( {
	children,
	hideAlarmPanel,
	canToggleAlarmPanel = true,
	hideNavigation,
	hideStackMonitor,
	showScreenBackground,
	screenContentClassName,
	screenWrapperClassName,
	expandStackMonitor,
}: LayoutProps ) => {
	const isStackActive = false;
	const layoutHeight = isStackActive
		? 'h-stack-layout-active'
		: 'h-stack-layout';

	const screenContentStyle = twMerge(
		'flex mx-auto rounded-xl p-4 h-full overflow-y-hidden w-full',
		!hideStackMonitor ? layoutHeight : 'min-h-full',
		showScreenBackground ? 'bg-base-200 shadow' : '',
		screenContentClassName
	);
	const screenWrapperStyle = twMerge(
		'h-screen px-8 flex-1 relative overflow-y-hidden scrollbar scrollbar-thumb-scrollbar scrollbar-track-scrollbar-default-bg scrollbar-thin',
		showScreenBackground ? 'py-4' : '',
		screenWrapperClassName
	);

	return (
		<div className="flex flex-row bg-base-100">
			{hideAlarmPanel ? null : <AlarmPanel canHide={ canToggleAlarmPanel } />}
			<div className={ screenWrapperStyle }>
				<div className="flex flex-row justify-end items-center">
					{!IS_AUTHENTICATED ? <LoginButton /> : null}
				</div>

				{/* Screen content container */}
				<main className={ screenContentStyle }>{children}</main>
				{hideStackMonitor ? null : (
					<StackMonitorsBottomIndicator expanded={ !!expandStackMonitor } />
				)}
			</div>
			{IS_AUTHENTICATED && !hideNavigation ? <SideNavigation /> : null}
		</div>
	);
};
