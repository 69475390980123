import React from 'react';

interface SpinnerProps {
	containerClass?: string;
	iconClass?: string;
}

const LoadingSpinner: React.FC<SpinnerProps> = ( {
	containerClass,
	iconClass,
} ) => {
	return (
		<div
			className={ `${
				containerClass ? containerClass : 'w-auto h-auto'
			} flex justify-center items-center` }
		>
			<div
				className={ `animate-spin rounded-full border-b-2 border-gray-900 ${
					iconClass ? iconClass : 'w-32 h-32'
				}` }
			/>
		</div>
	);
};

export default LoadingSpinner;
