import { AlarmSeverityEnum } from '../../types';
import { ErrorIcon, WarningIcon, StatusOfflineIcon } from '../../assets/icons';

export const alaramStyles: {
	[key: string]: {
		iconColor: string;
		iconShadow: string;
		background: string;
	};
} = {
	[AlarmSeverityEnum.ERROR]: {
		iconColor: '#C60204',
		iconShadow: '#a30002',
		background: 'rgba(198, 2, 4,.45)',
	},
	[AlarmSeverityEnum.WARNING]: {
		iconColor: '#FBB003',
		iconShadow: '#b07b00',
		background: 'rgba(251, 176, 3, .45)',
	},
	[AlarmSeverityEnum.CONNECTION]: {
		iconColor: '#795658',
		iconShadow: '#593e40',
		background: 'rgba(121, 86, 88, .45)',
	},
};

export const getAlarmIcon = ( alarmType: string ) => {
	switch ( alarmType ) {
		case AlarmSeverityEnum.ERROR:
			return <ErrorIcon color="white" />;
		case AlarmSeverityEnum.WARNING:
			return <WarningIcon color="white" />;
		case AlarmSeverityEnum.CONNECTION:
			return <StatusOfflineIcon color="white" />;
		default:
	}

	return undefined;
};
