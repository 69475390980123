import { HubConnectionBuilder } from '@microsoft/signalr';
import config from '../config-loader';
import {
	Alarm,
	Device,
	IMeasurementMessage,
	ISignallingDeviceStatusMessage,
	Location,
} from '../types';

const realtimeApiUrl = config.realtimeApiUrl;

// Setup the SignalR connection
const connection = new HubConnectionBuilder().withUrl( realtimeApiUrl ).build();

/**
 * Alarms Realtime Messages
 */

// Alarms Message (New Alarm)
type AlarmsMessageHandler = ( alarms: Alarm[] ) => void;

let alarmsMessageHandler: AlarmsMessageHandler | null = null;

connection.on( 'AlarmsMessage', ( alarmMessages: Alarm[] ) => {
	if ( alarmsMessageHandler ) {
		alarmsMessageHandler( alarmMessages );
	}
} );

// AcknowledgeAlarm
type AcknowledgeAlarmMessageHandler = ( alarmId: string ) => void;

let acknowledgeAlarmMessageHandler: AcknowledgeAlarmMessageHandler | null =
	null;

connection.on(
	'AcknowledgeAlarmsMessage',
	( acknowledgeAlarmMessages: { alarmId: string } ) => {
		if ( acknowledgeAlarmMessageHandler ) {
			acknowledgeAlarmMessageHandler( acknowledgeAlarmMessages.alarmId );
		}
	}
);

// ResolveAlarm
type ResolveAlarmMessageHandler = ( alarmId: string ) => void;

let resolvedAlarmMessageHandler: ResolveAlarmMessageHandler | null = null;

connection.on(
	'ResolveAlarmsMessage',
	( resolveAlarmMessages: { alarmId: string } ) => {
		if ( resolvedAlarmMessageHandler ) {
			resolvedAlarmMessageHandler( resolveAlarmMessages.alarmId );
		}
	}
);

/**
 * Measurements messages
 */
type MeasurementsMessageHandler = (
	measurementsMessage: IMeasurementMessage
) => void;

let measurementsMessageHandler: MeasurementsMessageHandler | null = null;

connection.on(
	'MeasurementsMessage',
	( measurementsMessages: IMeasurementMessage ) => {
		if ( measurementsMessageHandler ) {
			measurementsMessageHandler( measurementsMessages );
		}
	}
);

/**
 * Signaling Status Messages
 */
type SignalingStatusMessageHandler = (
	signalingStatusMessage: ISignallingDeviceStatusMessage
) => void;

let signalingStatusMessageHandler: SignalingStatusMessageHandler | null = null;

connection.on(
	'SignallingDeviceStatusMessage',
	( signalingStatusMessage: ISignallingDeviceStatusMessage ) => {
		if ( signalingStatusMessageHandler ) {
			signalingStatusMessageHandler( signalingStatusMessage );
		}
	}
);

/**
 * Realtime Places (and Devices positioning)
 */
connection.on( 'PlaceAddedMessage', ( newPlace: any ) => {
	// CANDO: Show notice that the page will be refreshed
	console.log( { newPlace } );
} );

connection.on( 'PlaceUpdatedMessage', ( updatedPlace: any ) => {
	// CANDO: Show notice that the page will be refreshed
	console.log( { updatedPlace } );
} );

connection.on( 'PlaceDeletedMessage', ( deletedPlace: any ) => {
	// CANDO: Show notice that the page will be refreshed
	console.log( { deletedPlace } );
} );

// ResolveAlarm
export interface DevicePositionMessage {
	deviceId: string;
	position: Location;
	placeId: string;
}
type DevicePlacedMessageHandler = ( message: DevicePositionMessage ) => void;

let devicePlacedMessageHandler: DevicePlacedMessageHandler | null = null;

connection.on(
	'DeviceAddedToPlaceMessage',
	( deviceAddedPayload: DevicePositionMessage ) => {
		if ( devicePlacedMessageHandler ) {
			devicePlacedMessageHandler( deviceAddedPayload );
		}
	}
);

type DeviceDisplacedMessageHandler = ( message: DevicePositionMessage ) => void;

let deviceDisplacedMessageHandler: DeviceDisplacedMessageHandler | null = null;
connection.on(
	'DeviceDeletedFromPlaceMessage',
	( deviceDeletedPayload: DevicePositionMessage ) => {
		if ( deviceDisplacedMessageHandler ) {
			deviceDisplacedMessageHandler( deviceDeletedPayload );
		}
	}
);

type DeviceMovedMessageHandler = ( message: DevicePositionMessage ) => void;

let deviceMovedMessageHandler: DeviceMovedMessageHandler | null = null;
connection.on( 'DevicePositionUpdatedMessage', ( deviceUpdatedPayload: any ) => {
	if ( deviceMovedMessageHandler ) {
		deviceMovedMessageHandler( deviceUpdatedPayload );
	}
} );

type DeviceConfigurationMessageHandler = ( device: Device ) => void;

let deviceAddedMessageHandler: DeviceConfigurationMessageHandler | null = null;
connection.on( 'ConfigurationAddedMessage', ( addedDevice: Device ) => {
	if ( deviceAddedMessageHandler ) {
		deviceAddedMessageHandler( addedDevice );
	}
	console.log( { addedDevice } );
} );

let deviceUpdatedMessageHandler: DeviceConfigurationMessageHandler | null =
	null;
connection.on( 'ConfigurationUpdatedMessage', ( updatedDevice: Device ) => {
	if ( deviceUpdatedMessageHandler ) {
		deviceUpdatedMessageHandler( updatedDevice );
	}
	console.log( { updatedDevice } );
} );

let deviceDeletedMessageHandler: DeviceConfigurationMessageHandler | null =
	null;
connection.on( 'ConfigurationDeletedMessage', ( deletedDevice: Device ) => {
	if ( deviceDeletedMessageHandler ) {
		deviceDeletedMessageHandler( deletedDevice );
	}
	console.log( { deletedDevice } );
} );

export interface ConnectParams {
	onAlarms?: AlarmsMessageHandler;
	onAlarmResolved?: ResolveAlarmMessageHandler;
	onAlarmAcknowledged?: AcknowledgeAlarmMessageHandler;

	onMeasurements?: MeasurementsMessageHandler;
	onSignalingStatus?: SignalingStatusMessageHandler;

	onDevicePlaced?: DevicePlacedMessageHandler;
	onDeviceDisplaced?: DeviceDisplacedMessageHandler;
	onDeviceMoved?: DeviceMovedMessageHandler;
	onDeviceAdded?: DeviceConfigurationMessageHandler;
	onDeviceUpdated?: DeviceConfigurationMessageHandler;
	onDeviceDeleted?: DeviceConfigurationMessageHandler;
}

/**
 * Reconnect to the service after a wait time (in seconds)
 * If the reconnect fails, it tries to reconnect, doubling the timeout seconds.
 *
 * @param waitTime How many seconds to wait before reconnecting
 */
const reconnect = ( waitTime: number = 4 ) => {
	setTimeout( () => {
		try {
			connection.start();
		} catch ( err ) {
			console.log( err );
			reconnect( waitTime * 2 );
		}
	}, waitTime * 1000 );
};

const realtimeApi = {
	connect: ( {
		onAlarms,
		onAlarmAcknowledged,
		onAlarmResolved,
		onMeasurements,
		onSignalingStatus,
		onDevicePlaced,
		onDeviceDisplaced,
		onDeviceMoved,
		onDeviceAdded,
		onDeviceUpdated,
		onDeviceDeleted,
	}: ConnectParams ) => {
		if ( connection.state === 'Disconnected' ) {
			connection.start();
		}

		// Alarms
		alarmsMessageHandler = onAlarms ?? null;
		acknowledgeAlarmMessageHandler = onAlarmAcknowledged ?? null;
		resolvedAlarmMessageHandler = onAlarmResolved ?? null;

		// Measurements and Status
		measurementsMessageHandler = onMeasurements ?? null;
		signalingStatusMessageHandler = onSignalingStatus ?? null;

		// Devices
		deviceAddedMessageHandler = onDeviceAdded ?? null;
		deviceUpdatedMessageHandler = onDeviceUpdated ?? null;
		deviceDeletedMessageHandler = onDeviceDeleted ?? null;

		// Devices Positions
		devicePlacedMessageHandler = onDevicePlaced ?? null;
		deviceDisplacedMessageHandler = onDeviceDisplaced ?? null;
		deviceMovedMessageHandler = onDeviceMoved ?? null;

		console.log( 'Realtime Connection Started' );
	},
	disconnect: () => {
		console.log( 'Realtime Connection Ended' );
	},
};

connection.onclose( () => {
	console.warn( 'Realtime Connection Closed' );
	reconnect();
} );

export default realtimeApi;
