import React from 'react';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { Device } from '../types';
import DraggableDevicesList from './floorPlan/DraggableDevicesList';
import Spinner from './LoadingSpinner';
import UserRoleNotice from './UserRoleNotice';
import { userHasRoleSelector } from '../state/auth/selectors/userHasRole';

export const SettingsDraggableDevicesContainer = ( {
	devices,
	loading,
	isWizardMode,
}: {
	devices: Array<Device>;
	loading: boolean;
	isWizardMode: boolean;
} ) => {
	const userCanEditPositions = useRecoilValue(
		userHasRoleSelector( 'MAINTENANCE' )
	);
	const { t } = useTranslation();

	return (
		<div className={ `${ isWizardMode ? 'mt-6' : 'mt-10' }` }>
			{!isWizardMode ? (
				<h4 className="text-gray-700 text-xl font-medium mb-5 mt-2 border-b border-base-300 pb-3">
					{t( 'Devices to place' )}
				</h4>
			) : null}
			{
				// eslint-disable-next-line no-nested-ternary
				userCanEditPositions ? (
					loading ? (
						<Spinner containerClass="mt-20" iconClass="w-10 h-10" />
					) : (
						<DraggableDevicesList listItems={ devices } />
					)
				) : (
					<div className="mb-2">
						<UserRoleNotice requiredRole="MAINTENANCE" className="text-xs" />
					</div>
				)
			}
		</div>
	);
};
