import { useCallback, useLayoutEffect, useState } from 'react';
import { useResizeDetector } from 'react-resize-detector';
import useWindowSize from './useWindowSize';

export const useCalculateDeviceConnectionLinePosition = () => {
	const screenDimensions = useWindowSize();
	const { ref: containerRef, width: containerWidth } = useResizeDetector();

	const [ offsetLeft, setOffsetLeft ] = useState<number | undefined>();
	const [ offsetRight, setOffsetRight ] = useState<number | undefined>();
	const [ offsetTop, setOffsetTop ] = useState<number | undefined>();
	const [ deviceNode, setDeviceNode ] = useState<HTMLElement | null>( null );
	const [ screenSeparatorNode, setScreenSeparatorNode ] =
		useState<HTMLElement | null>( null );

	const onDeviceNodeChange = useCallback( ( node ) => {
		setDeviceNode( node );
	}, [] );

	const onScreenSeparatorNodeChange = useCallback( ( node ) => {
		setScreenSeparatorNode( node );
	}, [] );

	const calculateLineOffset = useCallback(
		( deviceNodePosition, screenSeparatorNodePosition, screenWidth ) => {
			const lineLeftPosition = deviceNodePosition.right;
			const lineRightPosition = screenWidth - screenSeparatorNodePosition.left;
			const lineTopPosition =
				deviceNodePosition.top + deviceNodePosition.height / 2;

			setOffsetLeft( lineLeftPosition );
			setOffsetRight( lineRightPosition );
			setOffsetTop( lineTopPosition );
		},
		[]
	);

	useLayoutEffect( () => {
		const screenSeparatorNodeRect =
			screenSeparatorNode?.getBoundingClientRect();
		const deviceNodeRect = deviceNode?.getBoundingClientRect();

		if ( screenSeparatorNodeRect && deviceNodeRect && screenDimensions?.width ) {
			calculateLineOffset(
				deviceNodeRect,
				screenSeparatorNodeRect,
				screenDimensions.width
			);
		}
	}, [
		calculateLineOffset,
		deviceNode,
		screenSeparatorNode,
		screenDimensions.width,
		containerWidth,
	] );

	return {
		offsetTop,
		offsetLeft,
		offsetRight,
		onDeviceNodeChange,
		onScreenSeparatorNodeChange,
		containerRef,
	};
};
