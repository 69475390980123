import React from 'react';

export default function ShadowFilter( { color }: { color?: string } ) {
	return (
		<filter id="dropshadow">
			<feDropShadow
				dx="2"
				dy="3"
				stdDeviation="3"
				floodOpacity="0.25"
				floodColor={ color ? color : '#000000' }
			/>
		</filter>
	);
}
