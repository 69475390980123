import { useCallback } from 'react';
import { useSetRecoilState } from 'recoil';
import realtimeApi from '../middleware/realtimeApi';
import { activeAlarmsAtom } from '../state/alarms/activeAlarms';
import { allDevicesStateAtom } from '../state/devices/allDevices';
import { devicesDetailsAtom } from '../state/devices/devicesDetails';
import { placesDefinitionAtom } from '../state/places/placesDefinition';
import { IMeasuringDeviceDetails } from '../types';
import {
	addDeviceToTree,
	findPlaceByDeviceId,
	removeDeviceFromTree,
	updateDevicePositionInTree,
} from '../utils/floorPlan';

const LOGGING = false;

/**
 * Wire the app state (recoil) with the realtime messages from the API (via SignalR)
 */
const useRealtime = () => {
	const setPlacesDefinition = useSetRecoilState( placesDefinitionAtom );
	const setAllDevices = useSetRecoilState( allDevicesStateAtom );
	const setActiveAlarms = useSetRecoilState( activeAlarmsAtom );
	const setDevicesDetails = useSetRecoilState( devicesDetailsAtom );

	const connect = useCallback( () => {
		realtimeApi.connect( {
			onAlarms: ( updatedAlarms ) => {
				setActiveAlarms( updatedAlarms );
			},
			onMeasurements: ( measurementsMessage ) => {
				setDevicesDetails( ( deviceDetails ) => {
					const lastSeen = measurementsMessage.isConnected
						? measurementsMessage.timeStamp
						: '';
					if (
						!deviceDetails.measuringDeviceDetails.find(
							( device ) => device.deviceId === measurementsMessage.deviceIdentifier
						)
					) {
						return {
							signallingDeviceDetails: deviceDetails.signallingDeviceDetails,
							measuringDeviceDetails: [
								...deviceDetails.measuringDeviceDetails,
								{
									deviceId: measurementsMessage.deviceIdentifier,
									lastSeen,
									graphicsUrls: {},
									measurementMessage: measurementsMessage,
									signallingDeviceStatusMessage: undefined,
								},
							],
						};
					}

					const updatedMeasurementDevicesDetails =
						deviceDetails.measuringDeviceDetails.reduce<
						IMeasuringDeviceDetails[]
						>( ( partiallyUpdatedDetails, currentDeviceDetails ) => {
							if (
								currentDeviceDetails.deviceId ===
								measurementsMessage.deviceIdentifier
							) {
								const updatedMeasurementsMessage = {
									...measurementsMessage,
								};

								// The realtime measurement message contains only the updated channels for a device (so we still need to keep the old ones)
								currentDeviceDetails.measurementMessage.measurements.forEach(
									( oldMeasurement ) => {
										const newMessageWithSameName =
											updatedMeasurementsMessage.measurements.find(
												( newMeasurement ) => newMeasurement.name === oldMeasurement.name
											);
										if ( !newMessageWithSameName ) {
											updatedMeasurementsMessage.measurements.push(
												oldMeasurement
											);
										}
									}
								);

								const updatedDeviceDetails: IMeasuringDeviceDetails = {
									...currentDeviceDetails,
									lastSeen: lastSeen ?? currentDeviceDetails.lastSeen,
									measurementMessage: updatedMeasurementsMessage,
								};

								partiallyUpdatedDetails.push( updatedDeviceDetails );
							} else {
								partiallyUpdatedDetails.push( currentDeviceDetails );
							}
							return partiallyUpdatedDetails;
						}, [] );

					return {
						signallingDeviceDetails: deviceDetails.signallingDeviceDetails,
						measuringDeviceDetails: updatedMeasurementDevicesDetails,
					};
				} );
				LOGGING && console.log( { measurementsMessage } );
			},
			onSignalingStatus: ( signalingStatusMessage ) => {
				setDevicesDetails( ( deviceDetails ) => {
					const oldSignalingMessages = deviceDetails.signallingDeviceDetails;
					const lastSeen = signalingStatusMessage.isConnected
						? signalingStatusMessage.timeStamp
						: '';

					if (
						!deviceDetails.signallingDeviceDetails.find(
							( device ) => device.deviceId === signalingStatusMessage.deviceIdentifier
						)
					) {
						return {
							measuringDeviceDetails: deviceDetails.measuringDeviceDetails,
							signallingDeviceDetails: [
								...deviceDetails.signallingDeviceDetails,
								{
									deviceId: signalingStatusMessage.deviceIdentifier,
									lastSeen,
									graphicsUrls: undefined,
									measurementMessage: undefined,
									signallingDeviceStatusMessage: signalingStatusMessage,
								},
							],
						};
					}

					const updatedSignalingMessages = oldSignalingMessages.map( ( item ) => {
						if ( item.deviceId === signalingStatusMessage.deviceIdentifier ) {
							return {
								...item,
								lastSeen: lastSeen ?? item.lastSeen,
								signallingDeviceStatusMessage: signalingStatusMessage,
							};
						}

						return item;
					} );

					return {
						...deviceDetails,
						signallingDeviceDetails: updatedSignalingMessages,
					};
				} );
				LOGGING && console.log( { signalingStatusMessage } );
			},
			onDevicePlaced: ( message ) => {
				setPlacesDefinition( ( currentPlacesDefinition ) => {
					const newDefinition = addDeviceToTree(
						currentPlacesDefinition,
						message.deviceId,
						message.placeId,
						message.position
					);

					LOGGING && console.log( { newDefinition, p: 'placed' } );

					return newDefinition;
				} );
			},
			onDeviceDisplaced: ( message ) => {
				setPlacesDefinition( ( currentPlacesDefinition ) => {
					const newDefinition = removeDeviceFromTree(
						currentPlacesDefinition,
						message.deviceId,
						message.placeId
					);

					LOGGING && console.log( { newDefinition, p: 'displaced' } );

					return newDefinition;
				} );
			},
			onDeviceMoved: ( message ) => {
				setPlacesDefinition( ( currentPlacesDefinition ) => {
					const oldPlace = findPlaceByDeviceId(
						currentPlacesDefinition,
						message.deviceId
					);

					if ( oldPlace ) {
						// Device is moved to another place
						if ( oldPlace.id !== message.placeId ) {
							const removedFromOldPlaceTree = removeDeviceFromTree(
								currentPlacesDefinition,
								message.deviceId,
								oldPlace.id
							);

							const addedToTheNewPlaceTree = addDeviceToTree(
								removedFromOldPlaceTree,
								message.deviceId,
								message.placeId,
								message.position
							);

							return addedToTheNewPlaceTree;
						}

						// Device was moved to new position in the same place
						const newDefinition = updateDevicePositionInTree(
							currentPlacesDefinition,
							message.deviceId,
							message.placeId,
							message.position
						);

						LOGGING && console.log( { newDefinition, p: 'moved: old' } );

						return newDefinition;
					}

					// Device was moved but we have missed the add message.
					// That's why we just add it.
					const newDefinition = addDeviceToTree(
						currentPlacesDefinition,
						message.deviceId,
						message.placeId,
						message.position
					);

					LOGGING && console.log( { newDefinition, p: 'moved: new -> add' } );

					return newDefinition;
				} );
			},
			onDeviceAdded: ( device ) => {
				setAllDevices( ( existingDevices ) => {
					const updatedDevices = [ device, ...existingDevices ];

					LOGGING && console.log( { updatedDevices, p: 'add' } );

					return updatedDevices;
				} );
			},
			onDeviceUpdated: ( device ) => {
				setAllDevices( ( existingDevices ) => {
					const updatedDevices = existingDevices.map( ( existingDevice ) => {
						if ( existingDevice.id === device.id ) {
							return device;
						}

						return existingDevice;
					} );

					LOGGING && console.log( { updatedDevices, p: 'update' } );

					return updatedDevices;
				} );
			},
			onDeviceDeleted: ( device ) => {
				setAllDevices( ( existingDevices ) => {
					const updatedDevices = existingDevices.filter(
						( existingDevice ) => existingDevice.id !== device.id
					);

					LOGGING && console.log( { updatedDevices, p: 'delete' } );

					return updatedDevices;
				} );

				setPlacesDefinition( ( currentPlacesDefinition ) => {
					return removeDeviceFromTree( currentPlacesDefinition, device.id );
				} );
			},
		} );
	}, [ setActiveAlarms, setAllDevices, setDevicesDetails, setPlacesDefinition ] );

	const disconnect = useCallback( () => {
		return realtimeApi.disconnect();
	}, [] );

	return {
		connect,
		disconnect,
	};
};

export default useRealtime;
