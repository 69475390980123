import { useHistory } from 'react-router';

import api from '../middleware/api';
import auth from '../middleware/auth';
import { AppScreen } from '../shared/constants';

export interface LoginRequest {
	username: string;
	password: string;
}

const useAuthActions = () => {
	const history = useHistory();

	const login = async ( loginData: LoginRequest ) => {
		try {
			await api.auth.login( loginData );
			// action is 'POP' on initial load
			history.action !== 'POP'
				? history.goBack()
				: history.push( AppScreen.HOME );
		} catch ( e ) {
			throw new Error( 'Login failed' );
		}
	};

	const logout = () => {
		// remove user from local storage, set auth state to null and redirect to login page
		auth.clearTokens();
		history.push( '/' );
	};

	return {
		login,
		logout,
	};
};

export { useAuthActions };
