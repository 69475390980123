import React, { Suspense, useEffect } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import useRealtime from '../../hooks/useRealtime';
import i18n from '../../i18n';
import api from '../../middleware/api';
import realtimeApi from '../../middleware/realtimeApi';
import { activeAlarmsAtom } from '../../state/alarms/activeAlarms';
import { allDevicesStateAtom } from '../../state/devices/allDevices';
import { deviceDefinitionStateAtom } from '../../state/devices/deviceDefinitions';
import { devicesDetailsAtom } from '../../state/devices/devicesDetails';
import { placesDefinitionAtom } from '../../state/places/placesDefinition';
import { instanceUnitsStateAtom } from '../../state/units/instanceUnits';
import { generalSettingsDefinitionStateAtom } from '../../state/units/unitsDefinitions';
import AppRouter from '../AppRouter';
import LoadingSpinner from '../LoadingSpinner';
import AuthVerifier from './AuthVerifier';

const LoadedApp: React.FC = () => {
	const setSettingsDefinitions = useSetRecoilState(
		generalSettingsDefinitionStateAtom
	);
	const setPlacesDefinition = useSetRecoilState( placesDefinitionAtom );
	const setDeviceDefinitions = useSetRecoilState( deviceDefinitionStateAtom );
	const setAllDevices = useSetRecoilState( allDevicesStateAtom );
	const setActiveAlarms = useSetRecoilState( activeAlarmsAtom );
	const [ instanceUnits, setInstanceUnits ] = useRecoilState(
		instanceUnitsStateAtom
	);
	const setDevicesDetails = useSetRecoilState( devicesDetailsAtom );

	useEffect( () => {
		// Request the instance data:
		api.units.definitions.get().then( ( res ) => setSettingsDefinitions( res ) );
		api.units.selection.get().then( ( res ) => setInstanceUnits( res ) );
		api.devices.definitions.get().then( ( res ) => setDeviceDefinitions( res ) );
		api.devices.get().then( ( res ) => setAllDevices( res ) );

		api.devices.details
			.get()
			.then( ( devicesDetails ) => {
				setDevicesDetails( devicesDetails );
			} )
			.catch( ( deviceDetailsError ) => console.log( { deviceDetailsError } ) );

		api.places.get().then( ( placesTree ) => {
			setPlacesDefinition( placesTree );
		} );

		api.alarms.get().then( ( alarms ) => setActiveAlarms( alarms ) );

		return () => {
			realtimeApi.disconnect();
		};
	}, [
		setInstanceUnits,
		setPlacesDefinition,
		setSettingsDefinitions,
		setDeviceDefinitions,
		setAllDevices,
		setActiveAlarms,
		setDevicesDetails,
	] );
	// Set instance language
	useEffect( () => {
		i18n.changeLanguage( instanceUnits.language );
	}, [ instanceUnits.language ] );

	// Realtime (hide the ugly christmas-tree code in a hook ;) )
	const realtime = useRealtime();
	useEffect( () => {
		realtime.connect();

		return () => realtime.disconnect();
	}, [ realtime ] );

	return (
		<>
			<AuthVerifier />
			<AppRouter />
		</>
	);
};

const SuspendedApp = () => (
	<Suspense fallback={ <LoadingSpinner containerClass="w-screen h-screen" /> }>
		<LoadedApp />
	</Suspense>
);

export default SuspendedApp;
