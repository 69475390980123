import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { findDeviceDetailsByIdSelector } from '../state/devices/selectors/findDeviceDetailsById';
import {
	DeviceConfiguration,
	DeviceConnectionStatus,
	DeviceTypeEnum,
} from '../types';
import {
	getMeasurementPropertyDisplayValue,
	roundMeasurement,
	getDeviceConnectionStatus,
	checkDeviceHasConnectionProperties,
} from '../utils/device';
import { getPrettyTimeStamp } from '../utils/time';
import { DefinitionListItem } from './DefinitionListItem';
import Vrm303Toggle from './Vrm303Toggle';

export interface DeviceMeasurementCardProps {
	deviceId: string | undefined;
	title: string;
	isMeasuringDevice?: boolean;
	isStackMonitorRegion?: boolean;
	configuration?: DeviceConfiguration;
	isActivated?: boolean;
	deviceType?: DeviceTypeEnum;
	isLoggedIn?: boolean;
}

const DeviceMeasurementCard: React.FC<DeviceMeasurementCardProps> = ( {
	deviceId,
	title,
	isMeasuringDevice = true,
	configuration,
	isActivated,
	deviceType,
	isLoggedIn,
} ) => {
	const { t } = useTranslation();
	const deviceDetails = useRecoilValue( findDeviceDetailsByIdSelector( deviceId ) );
	const signalingMessage = deviceDetails?.signallingDeviceStatusMessage;

	// * Device current value (We always get the first measurement, since the normal devices have only 1 channel (for now).)
	const measurement = deviceDetails?.measurementMessage?.measurements[0];

	const measurementDisplayValue = measurement
		? `${ roundMeasurement( measurement.value ) } ${ measurement.unit }`
		: '-';

	// * Device last seen time
	const timeStamp =
		deviceDetails?.measurementMessage?.timeStamp ?? signalingMessage?.timeStamp;
	const lastSeenTime = getPrettyTimeStamp( timeStamp );

	// * VRM SM GP only - Device flow speed
	const flowSpeedMeasurement = getMeasurementPropertyDisplayValue(
		deviceDetails?.measurementMessage?.measurements,
		'FlowRate'
	);

	const flowSpeedMeasurementDisplayValue = `${
		flowSpeedMeasurement?.value
			? `${ roundMeasurement( flowSpeedMeasurement?.value ) } ${
				flowSpeedMeasurement?.unit
			  }`
			: '-'
	}`;

	// * VRM 303 devices only - Power status
	const powerStatus = deviceDetails?.measurementMessage?.isEnabled;

	// * Connection status
	const hasConnectionProperties =
		checkDeviceHasConnectionProperties( configuration );
	const isConnectedMeasurement =
		deviceDetails?.measurementMessage?.isConnected ??
		signalingMessage?.isConnected;

	const connectionStatus = getDeviceConnectionStatus(
		isActivated,
		isConnectedMeasurement,
		hasConnectionProperties
	);

	// * Connection status checks
	const noNetworkConfigured =
		connectionStatus?.status === DeviceConnectionStatus.NoNetworkConfigured;
	const notActivatedNetworkConfigured =
		connectionStatus?.status ===
		DeviceConnectionStatus.NotActivatedNetworkConfigured;

	// * Device type check
	const isSmGp = deviceType === DeviceTypeEnum.VRM_SM_GP;
	const is303 = deviceType === DeviceTypeEnum.VRM_303;

	return (
		<div>
			<h3 className="font-semibold mt-5 mb-2">{title}</h3>
			<div className="flex flex-row">
				<dl className="pt-3 border-l border-base-300 pl-2">
					{isMeasuringDevice && (
						<DefinitionListItem
							term={ `${ t( 'Current readout' ) }:` }
							text={ measurementDisplayValue }
							textStyle="font-bold"
							termStyle="font-light"
						/>
					)}

					{isSmGp && (
						<DefinitionListItem
							term={ `${ t( 'Flow meter' ) }:` }
							text={ flowSpeedMeasurementDisplayValue }
							termStyle="font-light"
						/>
					)}

					<DefinitionListItem
						term={ `${ t( 'Last seen' ) }:` }
						text={ lastSeenTime }
						termStyle="font-light"
					/>
					<DefinitionListItem
						term={ `${ t( 'Connection status' ) }:` }
						text={ connectionStatus?.text ?? '' }
						termStyle="font-light"
						textStyle={
							!noNetworkConfigured && !notActivatedNetworkConfigured
								? `text-${ connectionStatus?.color }`
								: 'text-base-content'
						}
					/>

					{isLoggedIn && is303 && (
						<DefinitionListItem
							textStyle="flex"
							term={ `${ t( 'Power' ) }:` }
							termStyle="font-light"
						>
							<Vrm303Toggle deviceId={ deviceId } />
						</DefinitionListItem>
					)}
				</dl>
			</div>
		</div>
	);
};

export default DeviceMeasurementCard;
