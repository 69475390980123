import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { AppScreen } from '../../shared/constants';

interface SettingsNavLinkProps {
	to: AppScreen;
}

const SettingsNavLink: React.FC<SettingsNavLinkProps> = ( { to, children } ) => {
	return (
		<li className="">
			<NavLink
				className="text-left block bg-base-301 px-2 py-2 my-2 font-medium text-xs rounded"
				activeClassName="bg-secondary-focus text-base-200 "
				exact
				to={ to }
			>
				{children}
			</NavLink>
		</li>
	);
};

const SettingsNavigation: React.FC = () => {
	const { t } = useTranslation();

	return (
		<nav>
			<ul>
				<SettingsNavLink to={ AppScreen.GENERAL_SETTINGS }>
					{t( 'General Settings' )}
				</SettingsNavLink>
				<SettingsNavLink to={ AppScreen.PLACES_CONFIGURATION }>
					{t( 'Places' )}
				</SettingsNavLink>
				<SettingsNavLink to={ AppScreen.MANAGE_DEVICES }>
					{t( 'Manage Devices' )}
				</SettingsNavLink>
				<SettingsNavLink to={ AppScreen.POSITION_DEVICES }>
					{t( 'Position Devices' )}
				</SettingsNavLink>
				<SettingsNavLink to={ AppScreen.CONNECT_DEVICES }>
					{t( 'Connect Devices' )}
				</SettingsNavLink>
				<SettingsNavLink to={ AppScreen.SETTINGS_DEVICES }>
					{t( 'Configure Devices' )}
				</SettingsNavLink>
			</ul>
		</nav>
	);
};

export default SettingsNavigation;
