export enum ErrorCode {
	ApplicationError = 1001,

	// Data
	WrongInputData = 1101,
	WrongInputDeviceConfiguration = 1102,

	// NotFound
	PlaceNotFound = 1201,
	DevicePositionNotFound = 1202,
	DeviceDefinitionNotFound = 1203,
	DeviceMessageNotFound = 1204,
	DeviceConfigurationNotFound = 1205,

	// Already Exists
	PlaceAlreadyExists = 1301,
	DeviceAlreadyExists = 1302,

	// Auth
	InvalidUsernameAndPassword = 1401,
	UserSessionExpired = 1402,
	InvalidSession = 1403,
	AuthNoRights = 1404,
	AuthFailed = 1405,
	InvalidJwtToken = 1411,

	// External
	ExternalError = 100000,
	ExternalRequestHandledUnsuccesfully = 100001,

	// DeviceConfiguration
	DeviceConfigurationNone = 100101,
	DeviceConfigurationConnectionAlreadyInUse = 100102,
	DeviceConfigurationDeviceDoesNotExist = 100103,
	DeviceConfigurationInvalidConfiguration = 100104,
	DeviceConfigurationUnexpectedError = 100104,
	DeviceConfigurationIdentifierAlreadyInUse = 100105,

	// AlarmsMonitoring
	AlarmsMonitoringNone = 100201,
	AlarmsMonitoringAlarmNotFound = 100202,
	AlarmsMonitoringMeasurementsOutOfBounds = 100203,
	AlarmsMonitoringMeasurementsUnexpectedError = 100204,
	AlarmsMonitoringDeviceStillDisconnected = 100205,

	// TryConnectDevice
	ConnectionInUse = 100301,
	DeviceTypeNotSupported = 100302,
	AddressNotResolved = 100303,
	ConnectionFailed = 100304,
	DeviceCheckFailed = 100305,
	WrongAddressOrPort = 100306,
	InternalError = 100307,
}
