import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { CheckIcon, ConnectedIcon, WarningIcon } from '../assets/icons';
import api, {
	SetPowerCommand,
	SET_POWER_OFF_COMMAND,
	SET_POWER_ON_COMMAND,
} from '../middleware/api';
import { findDeviceDetailsByIdSelector } from '../state/devices/selectors/findDeviceDetailsById';
import LoadingSpinner from './LoadingSpinner';

interface Vrm303ToggleProps {
	deviceId?: string;
}

const Vrm303Toggle: React.FC<Vrm303ToggleProps> = ( { deviceId } ) => {
	const { t } = useTranslation();
	const deviceDetails = useRecoilValue( findDeviceDetailsByIdSelector( deviceId ) );
	const powerStatus = deviceDetails?.measurementMessage?.isEnabled;

	// Use the power status initially for the isChecked
	// We duplicate it, because we want to check it immediately (and reset it back in case of an error)
	const [ isChecked, setIsChecked ] = useState( powerStatus );

	// Update the isChecked based on the power status
	// The device can be turned on/off from outside of the web portal.
	useCallback( () => setIsChecked( powerStatus ), [ powerStatus ] );

	// Indication to the user about the command state
	const [ isSendingCommand, setIsSendingCommand ] = useState( false );
	const [ didCommandSucceeded, setDidCommandSucceeded ] = useState( false );
	const [ commandError, setCommandError ] = useState( '' );

	const sendCommand = useCallback(
		async ( command: SetPowerCommand ) => {
			if ( !deviceId ) {
				throw new Error( t( 'No device id' ) );
			}

			try {
				setCommandError( '' );
				setDidCommandSucceeded( false );
				setIsSendingCommand( true );
				await api.devices.command( deviceId, command );

				setIsSendingCommand( false );
				setDidCommandSucceeded( true );
			} catch ( err: any ) {
				setCommandError(
					`${ t( err.message ) } ${ err.code ? `(${ err.code.toString() })` : '' }`
				);
				setIsSendingCommand( false );
				throw err;
			}
		},
		[ deviceId, t ]
	);

	const powerStatusChangeHandler = useCallback( () => {
		if ( isChecked ) {
			console.log( 'Off' );
			setIsChecked( false );
			sendCommand( SET_POWER_OFF_COMMAND ).catch( () => {
				setIsChecked( true );
			} );
		} else {
			setIsChecked( true );
			sendCommand( SET_POWER_ON_COMMAND ).catch( () => setIsChecked( false ) );
		}
	}, [ isChecked, sendCommand ] );

	let switchedOnButtonIcon = <ConnectedIcon className="w-5 h-5" />;

	if ( isSendingCommand ) {
		switchedOnButtonIcon = <LoadingSpinner iconClass="w-5 h-5" />;
	}

	return (
		<>
			<input
				type="checkbox"
				checked={ isChecked }
				onClick={ powerStatusChangeHandler }
				className="toggle toggle-primary toggle-md mr-4"
			/>
			{isSendingCommand && <LoadingSpinner iconClass="w-4 h-4" />}
			{commandError ? (
				<div className="text-warning border border-warning flex px-2">
					<div className="w-6 h-6 mr-2">
						<WarningIcon className="w-full h-full" />
					</div>
					<div title={ commandError } className="">
						{commandError}
					</div>
				</div>
			) : null}
			{didCommandSucceeded ? (
				<div className="w-6 h-6 rounded-full">
					<CheckIcon className="w-full h-full text-success" />
				</div>
			) : null}
		</>
	);
};

export default Vrm303Toggle;
