import { PlaceDefinition, PlaceType, Location } from '../types';
import roundPathCorners from './rounding';

const generateSVGPath = ( pathPoints: Location[], rounded?: boolean ) => {
	const roundRadius = 20;
	let path = pathPoints
		.map( ( point, index ) => {
			if ( index === 0 ) {
				return `M ${ point.x } ${ point.y }`;
			}
			if ( index === pathPoints.length - 1 ) {
				return `L ${ point.x } ${ point.y } Z`;
			}
			return `L ${ point.x } ${ point.y }`;
		} )
		.join( ' ' );

	if ( rounded ) {
		path = roundPathCorners( path, roundRadius, false );
	}

	return path;
};

const screenToSVGPosition = ( svgElement: SVGSVGElement, position: Location ) => {
	let point = svgElement.createSVGPoint();
	point.x = position.x;
	point.y = position.y;
	point = point.matrixTransform( svgElement.getScreenCTM()?.inverse() );
	return point;
};

const getViewBoxFromElement = (
	element: SVGSVGElement | SVGPathElement,
	padding?: number
) => {
	if ( typeof element.getBBox !== 'function' ) {
		return '';
	}

	const bBox = element.getBBox();
	let {
		x, y, width, height
	} = bBox;

	if ( padding !== undefined ) {
		x = bBox.x - padding / 2;
		y = bBox.y - padding / 2;
		width = bBox.width + padding;
		height = bBox.height + padding;
	}
	return `${ x } ${ y } ${ width } ${ height }`;
};

const getElementScreenDimensions = ( id: string ) => {
	const element = (
		document.getElementById( id ) as HTMLElement
	)?.getBoundingClientRect();
	const width = element?.width;
	const height = element?.height;

	return { width, height };
};

const getDimensionAndPositionFromViewBox = ( viewBox: string ) => {
	const [ x, y, width, height ] = viewBox.split( ' ' );
	return {
		x,
		y,
		width,
		height,
	};
};

const isRounded = ( placeType: PlaceType ) => placeType === 'area' || placeType === 'zone';

const getNestedPlacesList = ( placeData: PlaceDefinition ) => {
	if ( Object.keys( placeData ).length === 0 ) {
		return [];
	}
	let allNestedPlacesList: PlaceDefinition[] = [];
	allNestedPlacesList = [ ...allNestedPlacesList, placeData ];

	if ( placeData.children.length > 0 ) {
		for ( let index = 0; index < placeData.children.length; index++ ) {
			const currentPlace = placeData.children[index];
			allNestedPlacesList = allNestedPlacesList.concat(
				getNestedPlacesList( currentPlace )
			);
		}
	}

	return allNestedPlacesList;
};

const getNestedDevicesList = (
	placeData: PlaceDefinition,
	skipDevicesWithoutPosition = true
) => {
	if ( Object.keys( placeData ).length === 0 ) {
		return [];
	}
	let allNestedDevicesList: {
		placeId: string;
		deviceId: string;
		position: Location;
	}[] = [];

	if ( placeData.devices.length > 0 ) {
		for ( let index = 0; index < placeData.devices.length; index++ ) {
			const currentDevice = placeData.devices[index];

			if ( currentDevice.position.x || !skipDevicesWithoutPosition ) {
				// Boolean(currentDevice.position) Change the condition once the backend allows to add device without request body!
				allNestedDevicesList = [
					...allNestedDevicesList,
					{ placeId: placeData.id, ...currentDevice },
				];
			}
		}
	}

	if ( placeData.children.length ) {
		for ( let index = 0; index < placeData.children.length; index++ ) {
			const placeChild = placeData.children[index];
			allNestedDevicesList = allNestedDevicesList.concat(
				getNestedDevicesList( placeChild, skipDevicesWithoutPosition )
			);
		}
	}

	return allNestedDevicesList;
};

const findPlaceByDeviceId = (
	placeTree: PlaceDefinition,
	deviceId: string,
	skipDevicesWithoutPosition = true
): PlaceDefinition | undefined => {
	const isDeviceInProvidedNode = placeTree.devices.find( ( device ) => {
		if ( skipDevicesWithoutPosition ) {
			return device.deviceId === deviceId && !!device.position.x;
		}
		return device.deviceId === deviceId;
	} );

	if ( isDeviceInProvidedNode ) {
		return placeTree;
	}

	if ( placeTree.children.length ) {
		for ( const childNode of placeTree.children ) {
			let foundPlaceNode = findPlaceByDeviceId( childNode, deviceId );
			if ( foundPlaceNode ) {
				return foundPlaceNode;
			}
		}
	}

	return undefined;
};

const searchPlaceNodeInTree = (
	tree: PlaceDefinition,
	key: keyof PlaceDefinition,
	matchingValue: string
): PlaceDefinition | undefined => {
	if ( !key || !tree || !matchingValue ) {
		return undefined;
	}

	if ( tree[key] === matchingValue ) {
		return tree;
	}
	if ( tree.children.length ) {
		let index;
		let result;
		for (
			index = 0;
			result === undefined && index < tree.children.length;
			index++
		) {
			result = searchPlaceNodeInTree( tree.children[index], key, matchingValue );
		}
		return result;
	}
	return undefined;
};

type TreeModifierFunction = (
	tree: PlaceDefinition,
	deviceId: string,
	placeId: string,
	position: Location
) => PlaceDefinition;

type RemoveDeviceFromTreeFunction = (
	tree: PlaceDefinition,
	deviceId: string,
	placeId?: string
) => PlaceDefinition;

const addDeviceToTree: TreeModifierFunction = (
	tree,
	deviceId,
	placeId,
	position
) => {
	if ( tree.id === placeId ) {
		const newTree = {
			...tree,
			devices: [
				...tree.devices,
				{
					deviceId,
					position,
				},
			],
		};

		return newTree;
	}
	if ( tree.children.length ) {
		return {
			...tree,
			children: tree.children.map( ( subTree ) => {
				return addDeviceToTree( subTree, deviceId, placeId, position );
			} ),
		};
	}

	return tree;
};

const removeDeviceFromTree: RemoveDeviceFromTreeFunction = (
	tree,
	deviceId,
	placeId
) => {
	if (
		( !placeId && tree.devices.find( ( device ) => device.deviceId === deviceId ) ) ||
		tree.id === placeId
	) {
		const newTree = {
			...tree,
			devices: tree.devices.filter( ( device ) => device.deviceId !== deviceId ),
		};

		return newTree;
	}
	if ( tree.children.length ) {
		return {
			...tree,
			children: tree.children.map( ( subTree ) => {
				return removeDeviceFromTree( subTree, deviceId, placeId );
			} ),
		};
	}

	return tree;
};

const updateDevicePositionInTree: TreeModifierFunction = (
	tree,
	deviceId,
	placeId,
	position
) => {
	if ( tree.id === placeId ) {
		const newTree = {
			...tree,
			devices: tree.devices.map( ( devicePosition ) => {
				if ( devicePosition.deviceId === deviceId ) {
					return {
						deviceId,
						position,
					};
				}
				return devicePosition;
			} ),
		};

		return newTree;
	}
	if ( tree.children.length ) {
		return {
			...tree,
			children: tree.children.map( ( subTree ) => {
				return updateDevicePositionInTree( subTree, deviceId, placeId, position );
			} ),
		};
	}

	return tree;
};

/**
 *
 * @param list
 * @param elementCurrentPosition
 * @param elementNewPosition
 * @returns list with updated item position inside
 */
const getListWithUpdatedDevicePosition = (
	list: any[],
	elementCurrentPosition: number,
	elementNewPosition: number
): any[] => {
	if ( !list ) {
		return [];
	}

	if (
		elementCurrentPosition === undefined ||
		elementNewPosition === undefined
	) {
		return list;
	}

	const reorderedList = [ ...list ];
	const element = list[elementCurrentPosition];
	reorderedList.splice( elementCurrentPosition, 1 );
	reorderedList.splice( elementNewPosition, 0, element );
	return reorderedList;
};

export {
	generateSVGPath,
	getViewBoxFromElement,
	getDimensionAndPositionFromViewBox,
	isRounded,
	getNestedPlacesList,
	getNestedDevicesList,
	findPlaceByDeviceId,
	searchPlaceNodeInTree,
	screenToSVGPosition,
	addDeviceToTree,
	removeDeviceFromTree,
	updateDevicePositionInTree,
	getListWithUpdatedDevicePosition,
	getElementScreenDimensions,
};
