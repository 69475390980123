import { memo, useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { ConnectedIcon, StatusOfflineIcon } from '../../assets/icons';
import { findDeviceDetailsByIdSelector } from '../../state/devices/selectors/findDeviceDetailsById';
import { findLocatedDeviceByIdSelector } from '../../state/places/findLocatedDeviceById';
import { Device, DeviceConnectionStatus, DeviceTypeEnum } from '../../types';
import {
	checkDeviceHasConnectionProperties,
	getDeviceConnectionStatus,
	roundMeasurement,
} from '../../utils/device';
import { getPrettyTimeStamp } from '../../utils/time';
import TableData from '../table/TableData';
import TableRow from '../table/TableRow';
import StackRegionsMeasurements from './StackRegionsMeasurements';

interface DevicesItemRowProps {
	device: Device;
	onClick: ( id: string ) => void;
}

const DevicesItemRow: React.FC<DevicesItemRowProps> = ( { device, onClick } ) => {
	const clickHandler = useCallback( () => {
		onClick( device.id );
	}, [ onClick, device.id ] );

	// Device details and measurements data could be put in a hook
	const deviceDetails = useRecoilValue(
		findDeviceDetailsByIdSelector( device.id )
	);
	const signallingDeviceStatusMessage =
		deviceDetails?.signallingDeviceStatusMessage;
	const measurementMessage = deviceDetails
		? deviceDetails.measurementMessage
		: null;
	const measurement = measurementMessage?.measurements[0];
	const measurementDisplayValue = measurement
		? `${ roundMeasurement( measurement.value ) } ${ measurement.unit }`
		: '-';

	const deviceLocation = useRecoilValue(
		findLocatedDeviceByIdSelector( device.id )
	);
	const longLocation = deviceLocation?.locationPath;
	const locationParts = longLocation?.split( '/' ).slice( 1 );
	const shortLocation = locationParts?.length
		? locationParts[locationParts.length - 1]
		: '-';
	const prettyLongLocation = locationParts?.join( ' / ' );

	const timeStamp = deviceDetails?.lastSeen;
	const prettyTimeStamp = getPrettyTimeStamp( timeStamp );
	const isStackMonitorDevice = device.type === DeviceTypeEnum.VRM_SM;

	const hasConnectionProperties = checkDeviceHasConnectionProperties(
		device?.configuration
	);

	const isConnectedMeasurement =
		measurementMessage?.isConnected ??
		signallingDeviceStatusMessage?.isConnected;

	const connectionStatus = getDeviceConnectionStatus(
		device.isActivated,
		isConnectedMeasurement,
		hasConnectionProperties
	);
	const noNetworkConfigured =
		connectionStatus?.status === DeviceConnectionStatus.NoNetworkConfigured;
	const connectionTooltipText = connectionStatus?.text;
	const connectionIconColorClass = noNetworkConfigured
		? 'bg-base-100 border-2 border-base-301'
		: `bg-${ connectionStatus?.color }`;

	return (
		<TableRow onClick={ clickHandler }>
			<TableData>{device.name}</TableData>
			<TableData>{device.type}</TableData>
			<TableData>
				{isStackMonitorDevice ? (
					<StackRegionsMeasurements
						measurementMessage={ measurementMessage }
						device={ device }
					/>
				) : (
					measurementDisplayValue
				)}
			</TableData>
			<TableData title={ prettyLongLocation }>{shortLocation}</TableData>
			<TableData>{prettyTimeStamp}</TableData>
			<TableData>
				<div
					className={ `self-end rounded-full p-1 flex justify-end items-center w-5 h-5  ${ connectionIconColorClass } text-primary-content` }
					title={ connectionTooltipText }
				>
					{connectionStatus?.status ===
					DeviceConnectionStatus.ActivatedDisconnected ? (
						<StatusOfflineIcon />
						) : (
							<ConnectedIcon />
						)}
				</div>
			</TableData>
		</TableRow>
	);
};

export default memo( DevicesItemRow );
