import React from 'react';
import { useParams } from 'react-router';
import { useRecoilValue } from 'recoil';
import FloorPlanRenderer, { placeSelector } from './FloorPlanRenderer';
import { placesDefinitionAtom } from '../../state/places/placesDefinition';
import { PlaceDefinition } from '../../types';
import { activeAlarmsAtom } from '../../state/alarms/activeAlarms';

interface ParamTypes {
	id: string | undefined;
}

export default function FloorPlan() {
	const params = useParams<ParamTypes>();
	const placesDefinitionTree: PlaceDefinition =
		useRecoilValue( placesDefinitionAtom );
	const activeAlarms = useRecoilValue( activeAlarmsAtom );
	const areAlarmsActive = activeAlarms.length > 0;

	const selectedPlaceID = params ? params.id : 'root';
	const selectedPlaceFromTree =
		Object.keys( placesDefinitionTree ).length &&
		placeSelector( placesDefinitionTree, selectedPlaceID );

	return Object.keys( placesDefinitionTree ).length && selectedPlaceFromTree ? (
		<div className="w-full flex flex-col items-center justify-center">
			<h1 className="text-base font-medium w-full mb-4">
				{selectedPlaceFromTree.name}
			</h1>
			<FloorPlanRenderer
				placesDefinitionTree={ placesDefinitionTree }
				selectedPlaceData={ selectedPlaceFromTree }
				activeAlarms={ areAlarmsActive ? activeAlarms : undefined }
			/>
		</div>
	) : null;
}
