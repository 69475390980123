import { useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { findDeviceByIdSelector } from '../state/devices/selectors/findDeviceById';
import { findDeviceDetailsByIdSelector } from '../state/devices/selectors/findDeviceDetailsById';
import { ChannelNameEnum, IDeviceMeasurement } from '../types';
import {
	getConfigGroupPropertyValue,
	getMeasurementPropertyDisplayValue,
	getRegionConfigurationPropertyDisplayValue,
	indexToChannelNameMap,
	roundMeasurement,
} from '../utils/device';

export const useMeasurements = ( deviceId: string ) => {
	const deviceDetails = useRecoilValue( findDeviceDetailsByIdSelector( deviceId ) );
	const device = useRecoilValue( findDeviceByIdSelector( deviceId ) );
	const measurementMessage = deviceDetails
		? deviceDetails.measurementMessage
		: null;
	const measurement = measurementMessage?.measurements[0];
	const measurementDisplayValue = measurement
		? `${ roundMeasurement( measurement.value ) } ${ measurement.unit }`
		: '-';

	const region1 = getMeasurementPropertyDisplayValue(
		measurementMessage?.measurements,
		ChannelNameEnum.Region1
	);

	const region2 = getMeasurementPropertyDisplayValue(
		measurementMessage?.measurements,
		ChannelNameEnum.Region2
	);
	const region3 = getMeasurementPropertyDisplayValue(
		measurementMessage?.measurements,
		ChannelNameEnum.Region3
	);

	const getRegionName = useCallback(
		( region: IDeviceMeasurement, index: number ) => {
			// name defined in the Device config
			const regionNameFromConfig = getRegionConfigurationPropertyDisplayValue(
				device?.configuration,
				indexToChannelNameMap[index],
				'Name'
			);

			return regionNameFromConfig.trim() ? regionNameFromConfig : region.name;
		},
		[ device?.configuration ]
	);

	const regionsMeasurements = [ region1, region2, region3 ]
		.filter( ( region ) => !!region )
		.filter( ( region ) => {
			// check if region is enabled
			const isRegionValid = getConfigGroupPropertyValue(
				device?.configuration,
				region?.name as ChannelNameEnum,
				'Valid'
			);

			return isRegionValid;
		} )
		.map( ( region, index ) => ( {
			measurementsData: region,
			displayName: region ? getRegionName( region, index ) : '',
		} ) );

	return {
		regionsMeasurements,
		measurementDisplayValue,
		measurement,
		device,
		deviceDetails,
		measurementMessage,
	};
};
