import { twMerge } from 'tailwind-merge';
import { Alarm, AlarmSeverityEnum, IMeasurementMessage } from '../../types';
import { getMeasurementPropertyDisplayValue } from '../../utils/device';

export const getAlarmCurrentValueText = (
	channel: string | undefined,
	alarm?: Alarm,
	measurementMessage?: IMeasurementMessage
) => {
	if ( !channel ) {
		return undefined;
	}

	const regionMeasurements = getMeasurementPropertyDisplayValue(
		measurementMessage?.measurements,
		channel
	);

	if ( regionMeasurements?.value !== undefined ) {
		return regionMeasurements.value;
	}

	return alarm?.currentValue !== undefined ? alarm.currentValue : undefined;
};

export const getContainerStyleByAlarmSeverity = (
	alarmSeverity: AlarmSeverityEnum | undefined
) => {
	let containerStyle = '';

	switch ( alarmSeverity ) {
		case AlarmSeverityEnum.ERROR: {
			containerStyle = 'shadow-stackError bg-danger-error';
			break;
		}
		case AlarmSeverityEnum.ERROR_AND_CONNECTION: {
			containerStyle = 'shadow-stackError bg-danger-error_and_connection';
			break;
		}
		case AlarmSeverityEnum.WARNING: {
			containerStyle = 'shadow-stackWarning bg-danger-warning';
			break;
		}
		case AlarmSeverityEnum.WARNING_AND_CONNECTION: {
			containerStyle = 'shadow-stackWarning bg-danger-warning_and_connection';
			break;
		}
		case AlarmSeverityEnum.CONNECTION: {
			containerStyle = 'shadow-stackDisconnected bg-danger-disconnected';
			break;
		}

		default: {
			containerStyle = 'shadow-stackNormal bg-base-300';
		}
	}

	return containerStyle;
};
