import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { Device } from '../types';
import ConnectDeviceForm from './forms/ConnectDeviceForm';
import UserRoleNotice from './UserRoleNotice';
import { userHasRoleSelector } from '../state/auth/selectors/userHasRole';

export const SettingsConnectFormContainer = ( {
	device,
}: {
	device?: Device;
} ) => {
	const { t } = useTranslation();
	const userCanEditSettings = useRecoilValue(
		userHasRoleSelector( 'MAINTENANCE' )
	);
	const formEndRef = React.createRef<HTMLDivElement>();

	return (
		<div className={ `p-4 rounded bg-base-100 mt-10 ${ !device ? 'pb-8' : '' }` }>
			<h3 className="text-gray-700 text-xl font-medium mb-5 mt-2 border-b border-base-300 pb-3">
				{t( 'Devices' )}
			</h3>

			<div className="mb-2">
				<UserRoleNotice requiredRole="MAINTENANCE" className="text-xs" />
			</div>

			{device ? (
				// TODO: remove key(resetting the form) and restructure
				<ConnectDeviceForm
					key={ device.id }
					selectedDevice={ device }
					formEndRef={ formEndRef }
				/>
			) : (
				<h4>{userCanEditSettings ? t( 'Select a device to connect' ) : ''}</h4>
			)}
		</div>
	);
};
