import i18n from 'i18next';
import {
	LengthDefinition,
	PropertyTypeEnum,
	RangeDefinition,
} from '../../types';

export const createRangeRule = ( range?: RangeDefinition ) => {
	if ( !range ) {
		return {};
	}

	let rules: {
		min?: { value: number; message: string };
		max?: { value: number; message: string };
	} = {};

	if ( range.start !== undefined ) {
		rules.min = {
			value: range.start,
			message: i18n.t( 'error-min-value', { range: range.start } ),
		};
	}

	if ( range.end !== undefined ) {
		rules.max = {
			value: range.end,
			message: i18n.t( 'error-max-value', { range: range.end } ),
		};
	}

	return rules;
};

export const createLengthRule = ( length?: LengthDefinition ) => {
	if (
		length === undefined ||
		length.start === undefined ||
		length.end === undefined
	) {
		return {};
	}

	const minLength = {
		value: length.start,
		message: i18n.t( 'error-min-length', { length: length.start } ),
	};

	const maxLength = {
		value: length.end,
		message: i18n.t( 'error-max-length', { length: length.end } ),
	};

	return { minLength, maxLength };
};

export const alarmLevelsDependencies = (
	values?: number[],
	fieldType?: PropertyTypeEnum
) => {
	// Follows the rule (no support for Low and LowLow in definitions)
	// `HighHigh` > `High` > `Low` > `LowLow`
	if (
		values &&
		fieldType &&
		( fieldType === PropertyTypeEnum.AlarmHigh ||
			fieldType === PropertyTypeEnum.AlarmHighHigh )
	) {
		const [ highValue, highHighValue ] = values;

		// Field is empty
		if ( typeof highValue !== 'number' || typeof highHighValue !== 'number' ) {
			return true;
		}

		if ( highValue >= highHighValue ) {
			return fieldType === PropertyTypeEnum.AlarmHighHigh
				? i18n.t( 'error-highhigh-value-less-than-high' )
				: i18n.t( 'error-high-value-greater-than-highhigh' );
		}
	}

	return true;
};

export const areOverlapping = (
	firstRange: [number, number],
	secondRange: [number, number]
) => {
	return firstRange[0] <= secondRange[1] && firstRange[1] >= secondRange[0];
};
