import React, { useCallback } from 'react';
import { useHistory } from 'react-router';
import { useRecoilValue } from 'recoil';
import { AppScreen } from '../../shared/constants';
import { allDevicesStateAtom } from '../../state/devices/allDevices';
import {
	Alarm,
	DeviceFamilyType,
	DeviceTypeEnum,
	FloorPlanDevice,
	PlaceDefinition,
} from '../../types';
import {
	getAlarmWithHigherAlertType,
	getHighestPriorityAlarm,
} from '../../utils/alarms';
import { checkDeviceHasConnectionProperties } from '../../utils/device';
import {
	findPlaceByDeviceId,
	generateSVGPath,
	isRounded,
} from '../../utils/floorPlan';
import Device from './Device';
import Place from './Place';
import PlaceWithAlarm from './PlaceWithAlarm';

interface ReadOnlyFloorPlanLayerProps {
	selectedPlaceData: PlaceDefinition;
	devices: Array<FloorPlanDevice>;
	deviceSize: number;
	selectedDeviceRef: any;
	activeAlarms?: Array<Alarm>;
	selectedDeviceId?: string;
	showSignallingDevices?: boolean;
	showDeviceConnectionStatus?: boolean;
	showDeviceName?: boolean;
	onDeviceClickCallback?: ( deviceId: string ) => void;
	onPlaceClickCallback?: ( place: PlaceDefinition ) => void;
}

interface PlacedAlarms {
	[placeId: string]: Alarm;
}

const ReadOnlyFloorPlanLayer: React.FC<ReadOnlyFloorPlanLayerProps> = ( {
	selectedPlaceData,
	onPlaceClickCallback,
	devices,
	selectedDeviceId,
	showSignallingDevices,
	onDeviceClickCallback,
	deviceSize,
	showDeviceConnectionStatus,
	showDeviceName,
	activeAlarms,
	selectedDeviceRef,
} ) => {
	const history = useHistory();
	const listOfAllDevices = useRecoilValue( allDevicesStateAtom );

	const handlePlaceClick = useCallback(
		( place: PlaceDefinition ) => {
			if ( onPlaceClickCallback && typeof onPlaceClickCallback === 'function' ) {
				onPlaceClickCallback( place );
			} else {
				history.push( `${ AppScreen.PLACES }/${ place.id }` );
			}
		},
		[ onPlaceClickCallback, history ]
	);

	const handleDeviceClick = useCallback(
		( deviceId: string ) => {
			if (
				onDeviceClickCallback &&
				typeof onDeviceClickCallback === 'function'
			) {
				onDeviceClickCallback( deviceId );
			} else {
				history.push( `${ AppScreen.DEVICE_DETAILS }/${ deviceId }` );
			}
		},
		[ onDeviceClickCallback, history ]
	);

	const handlePlaceWithAlarmClick = useCallback(
		( deviceId: string ) => history.push( `${ AppScreen.DEVICE_DETAILS }/${ deviceId }` ),
		[ history ]
	);

	const activeAlarmsPerPlace = activeAlarms?.reduce<PlacedAlarms>(
		( placedAlarms, currentAlarm ) => {
			const placeOfCurrentAlarm = findPlaceByDeviceId(
				selectedPlaceData,
				currentAlarm.deviceId
			);

			if ( placeOfCurrentAlarm?.id ) {
				const previousAlarmOfCurrentPlace =
					placedAlarms[placeOfCurrentAlarm?.id];

				// If there is an alarm already in the placedAlarms, we use the one of the two with higher severity
				if ( previousAlarmOfCurrentPlace ) {
					const higherSeverityAlarm = getAlarmWithHigherAlertType(
						previousAlarmOfCurrentPlace,
						currentAlarm
					);

					return {
						...placedAlarms,
						[placeOfCurrentAlarm.id]: higherSeverityAlarm,
					};
				}

				// If there is no previous alarm for this place, we add the current one to the list
				return {
					...placedAlarms,
					[placeOfCurrentAlarm.id]: currentAlarm,
				};
			}

			return placedAlarms;
		},
		{}
	);

	return (
		<>
			{/* Places */}
			{selectedPlaceData.children.map( ( place: PlaceDefinition ) => {
				// If current place has a device in alarm
				// hide default place bg and show Place with error
				if ( activeAlarmsPerPlace ) {
					const currentPlaceHasAlarm = Object.keys( activeAlarmsPerPlace ).find(
						( placeId ) => placeId === place.id
					);
					if ( currentPlaceHasAlarm ) {
						return null;
					}
				}

				return (
					<Place
						placeId={ place.id }
						key={ place.id }
						type={ place.type }
						pathCoords={ generateSVGPath( place.box, isRounded( place.type ) ) }
						onPlaceClick={ () => {
							handlePlaceClick( place );
						} }
					/>
				);
			} )}

			{/* Devices */}
			{devices.map( ( device ) => {
				if ( !device ) {
					return null;
				}

				const foundDevice = listOfAllDevices.find(
					( d ) => d.id === device.deviceId
				);

				//    Handle show/hide signalling device on map
				if ( foundDevice?.family === DeviceFamilyType.SIGNALING ) {
					const deviceHasAlarm = activeAlarms?.some(
						( alarm ) => alarm.deviceId === foundDevice?.id
					);
					const currentDeviceIsSelected = selectedDeviceId === device.deviceId;
					if (
						!currentDeviceIsSelected &&
						!deviceHasAlarm &&
						!showSignallingDevices
					) {
						return null;
					}
				}

				const isDeviceStackMonitor = !!(
					foundDevice?.type === DeviceTypeEnum.VRM_SM
				);

				if ( isDeviceStackMonitor ) {
					return null;
				}

				const isDirectAncestor = !!selectedPlaceData.devices.find(
					( d ) => d.deviceId === device.deviceId
				);

				let highestPriorityAlarmForCurrentDevice;
				let activeAlarmsForCurrentDevice = activeAlarms
					? activeAlarms.filter( ( alarm ) => alarm.deviceId === device.deviceId )
					: undefined;
				if ( activeAlarmsForCurrentDevice ) {
					highestPriorityAlarmForCurrentDevice = getHighestPriorityAlarm(
						activeAlarmsForCurrentDevice
					);
				}

				const hasConnectionProperties = checkDeviceHasConnectionProperties(
					foundDevice?.configuration
				);

				return (
					<Device
						key={ device.deviceId }
						selectedDeviceRef={ selectedDeviceRef }
						device={ device }
						onClick={ () => {
							handleDeviceClick( device.deviceId );
						} }
						size={ deviceSize }
						selected={ selectedDeviceId === device.deviceId }
						activeAlarm={ highestPriorityAlarmForCurrentDevice }
						isDirectAncestor={ isDirectAncestor }
						showDeviceName={ showDeviceName }
						showConnectionStatus={ showDeviceConnectionStatus }
						hasConnectionProperties={ hasConnectionProperties }
						isActivated={ foundDevice?.isActivated }
					/>
				);
			} )}

			{/* Place with Alarm */}
			{activeAlarms &&
				activeAlarmsPerPlace &&
				Object.keys( activeAlarmsPerPlace ).map( ( placeId ) => {
					const { id, deviceId, alarmSeverity } = activeAlarmsPerPlace[placeId];
					const placeWithAlarm = findPlaceByDeviceId(
						selectedPlaceData,
						deviceId
					);

					// Hide place error background if selected device is in the place which we currently zoomed
					if (
						placeWithAlarm?.devices.find(
							( device ) => device.deviceId === selectedDeviceId
						)
					) {
						return null;
					}

					return placeWithAlarm ? (
						<PlaceWithAlarm
							key={ id }
							pathCoords={ generateSVGPath(
								placeWithAlarm.box,
								isRounded( placeWithAlarm.type )
							) }
							onPlaceClick={ () => {
								handlePlaceWithAlarmClick( deviceId );
							} }
							alarmType={ alarmSeverity }
						/>
					) : null;
				} )}
		</>
	);
};

export default ReadOnlyFloorPlanLayer;
