import React from 'react';
import auth from '../../middleware/auth';
import {
	EXPIRED_ACCESS_TOKEN,
	EXPIRED_REFRESH_TOKEN,
} from '../../testing-in-production';

const TestingInProductionSettings: React.FC = () => {
	const setExpiredAccessToken = () => {
		if ( auth.tokens ) {
			auth.updateTokens( { ...auth.tokens, accessToken: EXPIRED_ACCESS_TOKEN } );
		}
	};

	const setExpiredRefreshToken = () => {
		if ( auth.tokens ) {
			auth.updateTokens( {
				...auth.tokens,
				refreshToken: EXPIRED_REFRESH_TOKEN,
			} );
		}
	};

	const logTokens = () => {
		console.log( auth.tokens );
	};

	const setInvalidTokens = () => {
		auth.updateTokens( {
			refreshToken: '___INVALID___',
			accessToken: '___ALSO___INVALID___',
		} );
	};

	return (
		<section className="p-4 bg-room m-auto">
			<div className="p-2">
				<button
					className="btn btn-primary w-full rounded"
					onClick={ setExpiredAccessToken }
				>
					Set expired access token
				</button>
			</div>
			<div className="p-2">
				<button
					className="btn btn-primary w-full rounded"
					onClick={ setExpiredRefreshToken }
				>
					Set expired refresh token
				</button>
			</div>
			<div className="p-2">
				<button
					className="btn btn-primary w-full rounded"
					onClick={ auth.clearTokens }
				>
					Clear tokens
				</button>
			</div>
			<div className="p-2">
				<button
					className="btn btn-primary w-full rounded"
					onClick={ setInvalidTokens }
				>
					Set invalid tokens
				</button>
			</div>
			<div className="p-2">
				<button className="btn btn-info w-full rounded" onClick={ logTokens }>
					Log tokens
				</button>
			</div>
		</section>
	);
};

export default TestingInProductionSettings;
