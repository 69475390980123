import {
	ChannelNameEnum,
	CommonPropertyNameEnum,
	ConfigGroupName,
	DeviceConfiguration,
	DeviceConnectionStatus,
	DeviceRegionsGroup,
	DeviceRegionsGroupPropertyNameEnum,
	IDeviceMeasurement,
	PlaceDefinition,
} from '../types';
import i18n from '../i18n';

export const channelNameToIndexMap: { [key: string]: number } = {
	[ChannelNameEnum.Region1]: 0,
	[ChannelNameEnum.Region2]: 1,
	[ChannelNameEnum.Region3]: 2,
};

export const indexToChannelNameMap: { [key: string]: ChannelNameEnum } = {
	0: ChannelNameEnum.Region1,
	1: ChannelNameEnum.Region2,
	2: ChannelNameEnum.Region3,
};

/**
 *
 * @param config
 * @param propertyName
 * @param configurationGroupName
 * @returns
 */
const getConfigGroupPropertyValue = (
	configuration: DeviceConfiguration | undefined,
	configurationGroupName: ConfigGroupName | ChannelNameEnum,
	propertyName: string
) => {
	if ( !configuration || !propertyName || !configurationGroupName ) {
		return undefined;
	}

	if ( configurationGroupName.startsWith( 'Region' ) ) {
		const regionConfiguration = configuration?.find(
			( configurationItem ) => configurationItem.name === 'Regions' &&
				indexToChannelNameMap[configurationItem.index] ===
					configurationGroupName
		);

		const searchedProperty = regionConfiguration?.properties.find(
			( prop ) => prop.name === propertyName
		);
		return searchedProperty?.value;
	}

	const configGroup = configuration.find(
		( group ) => group.name === configurationGroupName
	);

	const prop = configGroup?.properties.find(
		( property ) => property.name === propertyName
	);

	return prop?.value;
};

const getConfigGroupPropertyDefinition = (
	config: any | undefined,
	configurationGroupName: ConfigGroupName,
	propertyName: string
) => {
	if ( !config || !propertyName || !configurationGroupName ) {
		return undefined;
	}
	const configGroup = config.find(
		( group: any ) => group.name === configurationGroupName
	);

	const prop = configGroup?.properties.find(
		( property: any ) => property.name === propertyName
	);

	return prop;
};

/**
 *
 * @param placeTree
 * @param deviceID
 * @returns  boolean
 */
const checkDeviceAddedInPlaceDefinition = (
	placeTree: PlaceDefinition,
	deviceID: string
): boolean => {
	if ( !deviceID || Object.keys( placeTree ).length === 0 ) {
		return false;
	}

	const isDeviceInCurrentPlaceNode = placeTree.devices.some(
		( device ) => device.deviceId === deviceID
	);

	if ( isDeviceInCurrentPlaceNode ) {
		return true;
	}

	if ( placeTree.children.length ) {
		for ( const childNode of placeTree.children ) {
			let found = checkDeviceAddedInPlaceDefinition( childNode, deviceID );
			if ( found ) {
				return true;
			}
		}
	}

	return false;
};

interface LocatedDevice {
	deviceId: string;
	locationPath: string;
	locationId: string;
}

const getLocatedDevices = (
	place: PlaceDefinition,
	currentLocation?: string
) => {
	const currentPlaceLocatedDevices: LocatedDevice[] = place.devices?.length
		? place.devices.map( ( device ) => ( {
			deviceId: device.deviceId,
			locationPath: currentLocation ?? place.name,
			locationId: place.id,
		  } ) )
		: [];

	if ( place.children?.length ) {
		place.children.forEach( ( childPlace ) => {
			const childLocatedDevices = getLocatedDevices(
				childPlace,
				`${ currentLocation }/${ childPlace.name }`
			);

			currentPlaceLocatedDevices.push( ...childLocatedDevices );
		} );
	}

	return currentPlaceLocatedDevices;
};

const getMeasurementPropertyDisplayValue = (
	measurements: IDeviceMeasurement[] | undefined,
	measurementName: string
) => {
	return measurements?.find(
		( measurement ) => measurement.name === measurementName
	);
};

const getRegionConfigurationPropertyDisplayValue = (
	configuration: DeviceConfiguration | undefined,
	channelName: string,
	propertyName: string
) => {
	const regionConfiguration = configuration?.find(
		( configurationItem ) => configurationItem.name === 'Regions' &&
			configurationItem.index === channelNameToIndexMap[channelName]
	);

	const searchedProperty = regionConfiguration?.properties.find(
		( prop ) => prop.name === propertyName
	);

	const displayValue = searchedProperty
		? `${ searchedProperty.value ?? '-' } ${ searchedProperty.unit ?? '' }`
		: '-';

	return displayValue;
};

const isNumberScientificNotation = ( value: number ) => {
	let stringNumber = value.toString().toLocaleLowerCase();
	return stringNumber.indexOf( 'e' ) !== -1;
};

/**
 * Round measurement to the 3rd digit after the decimal point
 *
 * @param value
 * @returns rounded value (to 0.000)
 */
const roundMeasurement = ( value: number, proximityMultiplier = 1000 ) => {
	// if scientific notation return as it is
	if ( isNumberScientificNotation( value ) ) {
		return value;
	}
	// else round it
	return Math.round( value * proximityMultiplier ) / proximityMultiplier;
};

/**
 * Use scientific notation when numbers get too big (as strings).
 *
 * @param value
 * @returns
	- When the number is greater or eq than 100 000 show scientific notation with 2 precision
	- When the number is between 100 000 and 10 - show the rounded value.
	- When the number is less than 10 and greater than 0.001, show the value, rounded to 3 point precision
	- When the number is less or eq than 0.001 show scientific notation with 1 precision
	// let testCases = [ 1000001, 10.5456456456, 3.67676576567553345, 0.00076867861, undefined ]
 */

const getFormattedMeasurementValueAsString = ( value: number ): string => {
	if ( value === undefined ) {
		return '-';
	}

	if ( value >= 100000 ) {
		// show scientific notation with 2 precision
		const fractionDigits = 2;
		return value.toExponential( fractionDigits );
	}
	if ( value >= 10 && value < 100000 ) {
		// show the rounded value
		return roundMeasurement( value, 1 ).toString();
	}
	if ( value < 10 && value > 0.001 ) {
		// show the value rounded to 3 point precision
		return roundMeasurement( value ).toString();
	}
	if ( value <= 0.001 && value !== 0 ) {
		// show the scientific notation with 1 precision
		const fractionDigits = 1;
		return value.toExponential( fractionDigits );
	}

	return value.toString();
};

const getConfiguredRegions = ( configuration: DeviceConfiguration ) => {
	return configuration.filter( ( configGroup ) => {
		if ( configGroup.name === ConfigGroupName.Regions ) {
			const validProp = configGroup.properties.find(
				( configProp ) => configProp.name === DeviceRegionsGroupPropertyNameEnum.Valid
			);
			return validProp?.value;
		}
		return false;
	} ) as DeviceRegionsGroup[];
};

const checkDeviceHasConnectionProperties = (
	configuration?: DeviceConfiguration
) => {
	if ( !configuration ) {
		return false;
	}

	const portNumberValue = getConfigGroupPropertyValue(
		configuration,
		ConfigGroupName.Common,
		CommonPropertyNameEnum.PortNumber
	);

	const addressValue = getConfigGroupPropertyValue(
		configuration,
		ConfigGroupName.Common,
		CommonPropertyNameEnum.Address
	);

	return portNumberValue !== undefined && addressValue;
};

/**
 *
 * @param isActivated
 * @param isConnectedMeasurement
 * @param hasConnectionProperties
 * @returns { status: DeviceConnectionStatus, text: string }
 */
const connectionStatusColor = {
	activatedConnected: 'success',
	activatedDisconnected: 'danger-warning_and_connection',
	notActivatedWithConnectionProperties: 'secondary',
};
const getDeviceConnectionStatus = (
	isActivated?: boolean,
	isConnectedMeasurement?: boolean,
	hasConnectionProperties?: boolean
) => {
	if (
		isActivated === undefined &&
		isConnectedMeasurement === undefined &&
		hasConnectionProperties === undefined
	) {
		return undefined;
	}

	if ( !hasConnectionProperties ) {
		return {
			status: DeviceConnectionStatus.NoNetworkConfigured,
			text: i18n.t( 'No Network Configured' ),
		};
	}

	if ( isActivated ) {
		if ( isConnectedMeasurement ) {
			return {
				status: DeviceConnectionStatus.ActivatedConnected,
				text: i18n.t( 'Connected' ),
				color: connectionStatusColor.activatedConnected,
			};
		}
		return {
			status: DeviceConnectionStatus.ActivatedDisconnected,
			text: i18n.t( 'Disconnected' ),
			color: connectionStatusColor.activatedDisconnected,
		};
	}

	// Not activated, with connection props
	return {
		status: DeviceConnectionStatus.NotActivatedNetworkConfigured,
		text: i18n.t( 'Network Configured' ),
		color: connectionStatusColor.notActivatedWithConnectionProperties,
	};
};

export {
	checkDeviceAddedInPlaceDefinition,
	getConfigGroupPropertyValue,
	getConfigGroupPropertyDefinition,
	getLocatedDevices,
	getMeasurementPropertyDisplayValue,
	getRegionConfigurationPropertyDisplayValue,
	roundMeasurement,
	getFormattedMeasurementValueAsString,
	getConfiguredRegions,
	getDeviceConnectionStatus,
	checkDeviceHasConnectionProperties,
};
