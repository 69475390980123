import { atom } from 'recoil';
import { Alarm } from '../../types';

/* Alarms */
export const defaultActiveAlarmsState: Alarm[] = [];

export const activeAlarmsAtom = atom<Alarm[]>( {
	key: 'activeAlarms',
	default: defaultActiveAlarmsState,
} );
