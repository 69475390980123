import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { AppScreen } from '../../shared/constants';
import DevicesGrid from '../DevicesGrid';

export default function Devices() {
	const history = useHistory();
	const { t } = useTranslation();

	const navigateToDeviceDetails = useCallback(
		( deviceId: string ) => {
			history.push( `${ AppScreen.DEVICE_DETAILS }/${ deviceId }` );
		},
		[ history ]
	);

	return (
		<div className="flex flex-col w-full content-between ">
			<h1 className="text-gray-700 text-3xl font-medium mx-10 mt-5 mb-10">
				{t( 'Devices' )}
			</h1>
			<div className="mx-10 mb-10 rounded flex flex-1 overflow-hidden">
				<DevicesGrid onItemClick={ navigateToDeviceDetails } />
			</div>
		</div>
	);
}
