import React from 'react';

interface DefinitionListItemProps {
	term: string;
	text?: string;
	termStyle?: string;
	textStyle?: string;
	containerStyle?: string;
}

export const DefinitionListItem: React.FC<DefinitionListItemProps> = ( {
	term,
	text,
	termStyle,
	textStyle,
	containerStyle,
	children,
} ) => (
	<div
		className={ `grid grid-cols-2 gap-5 ${ containerStyle ? containerStyle : '' }` }
	>
		<dt className={ `text-sm text-black ${ termStyle ? termStyle : '' }` }>
			{term}
		</dt>
		<dd className={ `text-sm text-gray-900 ${ textStyle ? textStyle : '' }` }>
			{text ? text : null}
			{children}
		</dd>
	</div>
);
