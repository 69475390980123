import { useCallback } from 'react';
import { useSetRecoilState } from 'recoil';
import api from '../middleware/api';
import { devicesDetailsAtom } from '../state/devices/devicesDetails';

export const useRefreshDeviceDetails = () => {
	const setDevicesDetails = useSetRecoilState( devicesDetailsAtom );

	// Fetch the device details and update the recoil state
	const refreshDeviceDetails = useCallback(
		async ( deviceId: string ) => {
			const updatedDevice = await api.devices.details.getById( deviceId, true );
			// The response has either an item the measurement or in the signaling details array
			if ( updatedDevice.signallingDeviceDetails.length ) {
				setDevicesDetails( ( devicesDetails ) => {
					let updatedDeviceDetails = devicesDetails;
					const updatedDeviceIndex =
						devicesDetails.signallingDeviceDetails.findIndex(
							( deviceDetails ) => deviceDetails.deviceId ===
								updatedDevice.signallingDeviceDetails[0].deviceId
						);

					if ( updatedDeviceIndex !== -1 ) {
						// If the device is already in the details, replace it with the updated response
						updatedDeviceDetails = {
							...devicesDetails,
							signallingDeviceDetails: [
								...devicesDetails.signallingDeviceDetails.slice(
									0,
									updatedDeviceIndex
								),
								updatedDevice.signallingDeviceDetails[0],
								...devicesDetails.signallingDeviceDetails.slice(
									updatedDeviceIndex + 1
								),
							],
						};
					}

					return updatedDeviceDetails;
				} );
			} else if ( updatedDevice.measuringDeviceDetails.length ) {
				setDevicesDetails( ( devicesDetails ) => {
					let updatedDeviceDetails = devicesDetails;
					const updatedDeviceIndex =
						devicesDetails.measuringDeviceDetails.findIndex(
							( deviceDetails ) => deviceDetails.deviceId ===
								updatedDevice.measuringDeviceDetails[0].deviceId
						);

					if ( updatedDeviceIndex !== -1 ) {
						// If the device is already in the details, replace it with the updated response
						updatedDeviceDetails = {
							...devicesDetails,
							measuringDeviceDetails: [
								...devicesDetails.measuringDeviceDetails.slice(
									0,
									updatedDeviceIndex
								),
								updatedDevice.measuringDeviceDetails[0],
								...devicesDetails.measuringDeviceDetails.slice(
									updatedDeviceIndex + 1
								),
							],
						};
					}

					return updatedDeviceDetails;
				} );
			}
		},
		[ setDevicesDetails ]
	);

	return refreshDeviceDetails;
};
