import React, { useEffect, useState } from 'react';
import { useDrag } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { DragIndicatorDotsIcon } from '../../assets/icons';
import { Device } from '../../types';
import { DragItemType } from './dragItemsTypes';

interface DraggableDeviceListItemProps {
	device: Device;
}

const DRAG_INDICATOR_DOTS_SIZE = 16;

const DraggableDeviceListItem = ( { device }: DraggableDeviceListItemProps ) => {
	const [ didDrop, setDidDrop ] = useState( false );
	const [ { isDragging }, drag, preview ] = useDrag( () => ( {
		type: DragItemType.NOT_POSITIONED_DEVICE,
		item: device,
		collect: ( monitor ) => ( {
			isDragging: monitor.isDragging(),
		} ),
		end: ( _, monitor ) => {
			setDidDrop( monitor.didDrop() );
		},
	} ) );

	useEffect( () => {
		preview( getEmptyImage(), { captureDraggingState: true } );
	}, [ preview ] );

	return !didDrop ? (
		<div
			ref={ drag }
			// eslint-disable-next-line
			role="DraggableBox"
			className={ `flex flex-row rounded items-center p-2 shadow-xs mb-2 font-bold bg-base-300 cursor-move opacity-${
				isDragging ? 20 : 100
			}` }
		>
			<div className="text-xs p-1 rounded text-base-100 bg-neutral">
				<span>{device.type}</span>
			</div>
			<h4 className="text-sm text-gray-700 ml-3">{device.name}</h4>
			<DragIndicatorDotsIcon
				className="ml-auto opacity-40"
				size={ DRAG_INDICATOR_DOTS_SIZE }
			/>
		</div>
	) : null;
};

export default React.memo( DraggableDeviceListItem );
