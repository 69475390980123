import React from 'react';

interface TableDataProps {
	title?: string;
}

const TableData: React.FC<TableDataProps> = ( { title, children } ) => {
	return (
		<td title={ title }>
			<div className="break-words max-w-full text-sm font-system">
				{children}
			</div>
		</td>
	);
};

export default TableData;
