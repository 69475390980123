import React, { useRef } from 'react';
import { useDrop } from 'react-dnd';
import { Device, PlaceType, Location } from '../../types';
import { DragItemType } from './dragItemsTypes';
import ShadowFilter from './ShadowFilter';

export interface DroppedDeviceType {
	id: string;
	placeId: string;
	position: Location;
	droppedItemType: string;
}

interface PositionedDeviceType {
	deviceId: string;
	placeId: string;
	position: Location;
}

interface PlaceProps {
	placeId: string;
	type: 'zone' | 'room' | 'area';
	pathCoords: string;
	onPlaceClick: ( element: any | undefined ) => void;
	withOutline?: boolean;
	onDrop?: ( droppedDeviceType: DroppedDeviceType ) => void;
}

const getPlaceStyling = (
	type: PlaceType,
	isOutlined?: boolean,
	isOver?: boolean
) => {
	if ( type === 'area' ) {
		// Area specific styling
		return `${ isOver ? 'fill-drag-over-area' : 'fill-transparent' } stroke-4`;
	}
	if ( isOver ) {
		// Place styling when dragging item over
		if ( type === 'zone' ) {
			return 'fill-drag-over-zone  stroke-outlined-zone stroke-4';
		}
		return 'fill-drag-over-room stroke-outlined-room stroke-4';
	}

	if ( isOutlined ) {
		if ( type === 'zone' ) {
			// Tailwind forces us do stupid stuff... (Dynamic Classes Cannot be determined, so they are being purged during the production build)
			return 'hover:fill-outlined-zone stroke-outlined-zone fill-transparent transition duration-100 ease-in stroke-4';
		}
		return 'hover:fill-outlined-room stroke-outlined-room fill-transparent transition duration-100 ease-in stroke-4';
	}

	if ( type === 'zone' ) {
		// Tailwind forces us do stupid stuff... (Dynamic Classes Cannot be determined, so they are being purged during the production build)
		return 'fill-zone opacity-50 mix-blend-multiply';
	}

	return 'fill-room opacity-50 mix-blend-multiply';
};

const Place = ( props: PlaceProps ) => {
	const {
		placeId,
		type,
		pathCoords,
		onPlaceClick,
		withOutline: isOutlined,
		onDrop,
	} = props;

	const pathRef = useRef<SVGPathElement | null>( null );

	const [ { isOver }, drop ] = useDrop(
		() => ( {
			accept: [
				DragItemType.NOT_POSITIONED_DEVICE,
				DragItemType.POSITIONED_DEVICE,
			],
			drop: ( droppedDevice: Device & PositionedDeviceType, monitor ) => {
				let mousePosition: Location | null = monitor.getClientOffset();
				if ( !onDrop || typeof onDrop !== 'function' || mousePosition === null ) {
					return;
				}
				const id = droppedDevice.id ?? droppedDevice.deviceId; // from list of devices | when dragged
				const droppedItemType = monitor.getItemType()?.toString() ?? '';

				onDrop( {
					id,
					placeId,
					position: {
						x: mousePosition.x,
						y: mousePosition.y,
					},
					droppedItemType,
				} );
			},
			collect: ( monitor ) => ( { isOver: monitor.isOver() } ),
		} ),
		[]
	);

	const handleOnClick = () => {
		onPlaceClick( pathRef.current );
	};

	let styling = getPlaceStyling( type, isOutlined, isOver );

	return (
		<svg overflow="visible" ref={ drop }>
			{!isOutlined && <ShadowFilter />}
			<path
				ref={ pathRef }
				className={ `cursor-pointer ${ styling }` }
				onClick={ handleOnClick }
				d={ pathCoords }
				filter={ !isOutlined ? 'url(#dropshadow)' : '' }
			/>
		</svg>
	);
};

export default Place;
