import React from 'react';
import { AlarmListItemIcon } from '../alarms/AlarmListItemIcon';
import { AlarmSeverityEnum } from '../../types';
import { RadiationIcon } from '../../assets/icons';

interface TileIconProps {
	alarmSeverityType: AlarmSeverityEnum;
	showRadiationIcon: boolean;
}

export const TileIcon = ( {
	alarmSeverityType,
	showRadiationIcon,
}: TileIconProps ) => {
	return (
		<div
			className={ `absolute top-4 right-4 w-8 h-8 bg-base-100 rounded-full justify-center items-center flex text-danger-${ alarmSeverityType.toLowerCase() }` }
		>
			{showRadiationIcon ? (
				<RadiationIcon />
			) : (
				<AlarmListItemIcon type={ alarmSeverityType } />
			)}
		</div>
	);
};
