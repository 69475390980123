import { useCallback } from 'react';
import { useSetRecoilState } from 'recoil';
import api from '../middleware/api';
import { allDevicesStateAtom } from '../state/devices/allDevices';

export const useRefreshDevices = () => {
	const setAllDevices = useSetRecoilState( allDevicesStateAtom );

	// Fetch updated devices and update recoil state
	const refreshDevices = useCallback( async () => {
		api.devices
			.get()
			.then( setAllDevices )
			.catch( ( error ) => {
				console.warn( {
					msg: 'Devices refresh failed',
					error,
				} );
			} );
	}, [ setAllDevices ] );

	return refreshDevices;
};
