import { useRecoilValue } from 'recoil';
import i18n from '../i18n';
import { instanceUnitsStateAtom } from '../state/units/instanceUnits';

const useLanguage = () => {
	const instanceUnits = useRecoilValue(
		instanceUnitsStateAtom
	);

	return instanceUnits.language || i18n.language
};

export default useLanguage;

