import React, { EventHandler } from 'react';
import {
	Control,
	FieldValues,
	RegisterOptions,
	useController,
} from 'react-hook-form';

type Option = { id: string; name: string };

export interface CheckBoxesFieldProps {
	name: string;
	control: Control<FieldValues, object>;
	selectedOptions: string[];
	options: Array<Option>;
	label?: string;
	errorText?: string;
	rules?: Exclude<
	RegisterOptions,
	'valueAsNumber' | 'valueAsDate' | 'setValueAs'
	>;
}

const CheckBoxesField = ( {
	name,
	control,
	options,
	selectedOptions,
	label,
	errorText,
	rules,
}: CheckBoxesFieldProps ) => {
	const { field } = useController( {
		control,
		name: `${ name }.value`,
		defaultValue: selectedOptions,
		rules,
	} );

	const [ value, setValue ] = React.useState( field.value || [] );

	const handleChange = (
		event: React.ChangeEvent<HTMLInputElement>,
		index: number
	) => {
		let valueCopy = [ ...value ];
		const itemIndex = valueCopy.indexOf( event.target.value );

		// update the checkbox value
		if ( event.target.checked && itemIndex === -1 ) {
			valueCopy = [ ...valueCopy, event.target.value ];
		} else if ( itemIndex > -1 ) {
			valueCopy = valueCopy.filter( ( v ) => v !== event.target.value );
		}

		// send data to react hook form
		field.onChange( valueCopy );

		// update local state
		setValue( valueCopy );
	};

	return (
		<>
			<h4 className="text-base-700 text-sm mb-1 mt-2 block">{label}</h4>
			<div className="p-2 max-h-20 overflow-y-auto">
				{options.map( ( option, index ) => {
					const isChecked = selectedOptions.includes( option.id );
					return (
						<label
							key={ option.id }
							className="cursor-pointer flex flex-row items-center mb-1"
						>
							<input
								onChange={ ( event ) => handleChange( event, index ) }
								value={ option.id }
								type="checkbox"
								defaultChecked={ isChecked }
								className="checkbox checkbox-xs checkbox-primary mr-2"
							/>
							<span className="label-text flex-1">{option.name}</span>
						</label>
					);
				} )}
			</div>
			{!!errorText && (
				<span className="text-danger pt-1 text-xs">{errorText}</span>
			)}
		</>
	);
};

export default CheckBoxesField;
