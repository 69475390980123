import { DateTime } from 'luxon';
import DayPicker, { LocaleUtils, } from 'react-day-picker';
import { useTranslation } from 'react-i18next';
import { FixedLengthArray } from '../utils/types';
import useLanguage from './useLanguage';

const useCalendarLocalization = () => {
	const language = useLanguage();
	const { t } = useTranslation();

	// Strict order!
	const WEEKDAYS_SHORT = [
		t( 'calendar-short-Sunday' ),
		t( 'calendar-short-Monday' ),
		t( 'calendar-short-Tuesday' ),
		t( 'calendar-short-Wednesday' ),
		t( 'calendar-short-Thursday' ),
		t( 'calendar-short-Friday' ),
		t( 'calendar-short-Saturday' ),
	];

	// Strict order!
	const WEEKDAYS_LONG = [
		t( 'calendar-Sunday' ),
		t( 'calendar-Monday' ),
		t( 'calendar-Tuesday' ),
		t( 'calendar-Wednesday' ),
		t( 'calendar-Thursday' ),
		t( 'calendar-Friday' ),
		t( 'calendar-Saturday' ),
	];

	const MONTHS: FixedLengthArray<string, 12> = [
		t( 'calendar-january' ),
		t( 'calendar-february' ),
		t( 'calendar-march' ),
		t( 'calendar-april' ),
		t( 'calendar-may' ),
		t( 'calendar-june' ),
		t( 'calendar-july' ),
		t( 'calendar-august' ),
		t( 'calendar-september' ),
		t( 'calendar-october' ),
		t( 'calendar-november' ),
		t( 'calendar-december' ),
	];

	const formatWeekdayShort = ( i: number ) => {
		return WEEKDAYS_SHORT[i];
	};

	const formatWeekdayLong = ( i: number ) => {
		return WEEKDAYS_LONG[i];
	};

	const formatDate = ( date: Date ) => {
		return DateTime.fromJSDate( date ).setLocale( language ).toLocaleString();
	};

	const getMonths = () => MONTHS;

	const localeUtils: LocaleUtils = {
		...DayPicker.LocaleUtils,
		formatWeekdayShort,
		formatWeekdayLong,
		formatDate,
		getMonths,
	};

	return { localeUtils };
};

export default useCalendarLocalization;
