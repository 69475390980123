import { useCallback, useEffect } from 'react';
import { ArrowLeftIcon } from '@heroicons/react/solid';
import { useParams, useHistory } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import Button from '../buttons/Button';
import FloorPlanRenderer, {
	placeSelector,
} from '../floorPlan/FloorPlanRenderer';
import { placesDefinitionAtom } from '../../state/places/placesDefinition';
import { PathOfLinksToSelectedPlace } from '../floorPlan/PathOfLinksToSelectedPlace';
import { AlarmIcon, DataAnalysisIcon, PencilIcon } from '../../assets/icons';
import { AppScreen } from '../../shared/constants';
import { activeAlarmsAtom } from '../../state/alarms/activeAlarms';
import { DeviceFamilyType, DeviceTypeEnum, GraphicsUrls } from '../../types';
import { findDeviceByIdSelector } from '../../state/devices/selectors/findDeviceById';
import {
	getAlarmWithHigherAlertType,
	getAlarmWithLowerAlertType,
} from '../../utils/alarms';
import DeviceDetailsAlarmCard from '../alarms/DeviceDetailsAlarmCard';
import DeviceMeasurementCard from '../DeviceMeasurementCard';
import { isLoggedInState } from '../../state/auth/isLoggedIn';
import { useCalculateDeviceConnectionLinePosition } from '../../hooks/useCalculateDeviceConnectionLinePosition';
import GrafanaChart from '../GrafanaChart';
import { getGrafanaPeriodUrl, getTimeDataQuery } from '../../utils/grafana';
import { DefinitionListItem } from '../DefinitionListItem';
import Vrm303Toggle from '../Vrm303Toggle';

interface ParamTypes {
	id: string;
}

export default function DeviceDetails() {
	const history = useHistory();
	const { t } = useTranslation();
	let params = useParams<ParamTypes>();
	const deviceId = params.id;

	const placesDefinitionTree = useRecoilValue( placesDefinitionAtom );
	const isLoggedInUser = useRecoilValue( isLoggedInState );
	const activeAlarms = useRecoilValue( activeAlarmsAtom );
	// The device holds the device configuration
	const device = useRecoilValue( findDeviceByIdSelector( deviceId ) );

	const {
		offsetTop,
		offsetLeft,
		offsetRight,
		onDeviceNodeChange,
		onScreenSeparatorNodeChange,
		containerRef,
	} = useCalculateDeviceConnectionLinePosition();

	const deviceName = device ? device.name : '-';

	const placeFromTreeWithSelectedDevice =
		Object.keys( placesDefinitionTree ).length &&
		placeSelector( placesDefinitionTree, undefined, deviceId );

	const backButtonClickHandler = useCallback( () => {
		history.goBack();
	}, [ history ] );

	const selectedDeviceAlarms = activeAlarms.filter(
		( activeAlarm ) => deviceId && activeAlarm.deviceId === deviceId
	);

	const viewDeviceDataClickHandler = useCallback( () => {
		history.push( `${ AppScreen.DATA_ANALYSIS }/devices/${ deviceId }` );
	}, [ history, deviceId ] );

	const onRedirectToAlarmLog = useCallback( () => {
		history.push( `${ AppScreen.ALARM_LOG }/devices/${ deviceId }` );
	}, [ history, deviceId ] );

	useEffect( () => {
		if ( device?.type === DeviceTypeEnum.VRM_SM ) {
			history.replace( `${ AppScreen.STACK_MONITOR }/${ device.id }` );
		}
	}, [ device, history ] );

	const isMeasuringDevice =
		!device || device.family === DeviceFamilyType.MEASURING;

	// A device has maximum two alarms for now.
	const higherDeviceAlarm =
		selectedDeviceAlarms.length > 1
			? getAlarmWithHigherAlertType(
				selectedDeviceAlarms[0],
				selectedDeviceAlarms[1]
			  )
			: selectedDeviceAlarms[0];
	const lowerDeviceAlarm =
		selectedDeviceAlarms.length > 1
			? getAlarmWithLowerAlertType(
				selectedDeviceAlarms[0],
				selectedDeviceAlarms[1]
			  )
			: undefined;

	const chartUrlType: keyof GraphicsUrls = higherDeviceAlarm?.created
		? getGrafanaPeriodUrl( {
			selectedRange: {
				from: DateTime.fromJSDate( new Date( higherDeviceAlarm.created ) ),
				to: DateTime.fromMillis( Date.now() ),
			},
		  } )
		: 'RawMeasurementData';

	const chartPeriod = higherDeviceAlarm?.created
		? getTimeDataQuery( 'custom', {
			from: DateTime.fromJSDate( new Date( higherDeviceAlarm.created ) ),
		  } )
		: undefined;

	// Due to units miss-match, we are going to always allow resolving alarm (and rely on server errors and validations)
	const resolveDisabled = false;

	const displayFloorPlanRenderer =
		Object.keys( placesDefinitionTree ).length && placeFromTreeWithSelectedDevice;

	const connectionLineColorClass = higherDeviceAlarm
		? `border-danger-${ higherDeviceAlarm.alarmSeverity.toLowerCase() } border-opacity-70`
		: 'border-primary-focus';

	const is303 = device?.type === DeviceTypeEnum.VRM_303;

	return (
		<div className="flex flex-1 relative">
			{/* Left side */}
			<div
				ref={ containerRef }
				className="flex flex-col w-1/2 items-center p-8 gap-10 relative"
			>
				<div className="flex self-start gap-5 justify-center items-center">
					<button className="h-6 w-6" onClick={ backButtonClickHandler }>
						<ArrowLeftIcon className="h-full w-full" />
					</button>
					<h2 className="text-2xl">
						<b className="uppercase">{deviceName}</b> - {t( 'Device details' )}
					</h2>
				</div>
				<div className="flex flex-1 flex-col w-full">
					{displayFloorPlanRenderer ? (
						<>
							<FloorPlanRenderer
								placesDefinitionTree={ placesDefinitionTree }
								selectedPlaceData={ placeFromTreeWithSelectedDevice }
								selectedDeviceId={ deviceId }
								activeAlarms={ activeAlarms }
								height="calc(100vh - 280px)" // TODO: do proper calculations
								selectedDeviceRef={ onDeviceNodeChange }
							/>

							{/* connection line */}
							{offsetLeft !== undefined &&
								offsetRight !== undefined &&
								offsetTop !== undefined && (
								<div
									id="connecting-line"
									className={ `border-t-2 border-dashed fixed ${ connectionLineColorClass }` }
									style={ {
										left: `${ offsetLeft }px`,
										top: `${ offsetTop }px`,
										right: `${ offsetRight }px`,
									} }
								/>
							)}
						</>
					) : (
						<div className="flex bg-base-301 rounded-2xl p-10 mx-auto min-h-full w-full items-center">
							<p className="text-center mx-auto">
								{t( 'Device is not positioned on the floor plan' )}.
							</p>
						</div>
					)}
					{placeFromTreeWithSelectedDevice ? (
						<div className="mt-6 text-sm italic">
							<PathOfLinksToSelectedPlace
								currentPlaceID={ placeFromTreeWithSelectedDevice.id }
							/>
						</div>
					) : null}
				</div>
			</div>
			{/* Vertical screen separator */}
			<div
				ref={ onScreenSeparatorNodeChange }
				className={ `border-l-2 border-dashed absolute left-1/2 top-0 bottom-0 ${ connectionLineColorClass }` }
			/>

			{/* Right side */}
			<div className="flex flex-col w-1/2 p-8 gap-5 scrollbar scrollbar-thumb-scrollbar-connect-fg scrollbar-track-scrollbar-connect-bg scrollbar-thumb-rounded-xl scrollbar-thin">
				{higherDeviceAlarm && (
					<DeviceDetailsAlarmCard
						deviceId={ deviceId }
						selectedDeviceAlarm={ higherDeviceAlarm }
						deviceName={ deviceName }
						secondDeviceAlarm={ lowerDeviceAlarm }
						resolveDisabled={ resolveDisabled }
					/>
				)}
				{lowerDeviceAlarm && (
					<DeviceDetailsAlarmCard
						deviceId={ deviceId }
						selectedDeviceAlarm={ lowerDeviceAlarm }
						deviceName={ deviceName }
						resolveDisabled={ resolveDisabled }
					/>
				)}
				<div className="justify-center flex-1">
					<GrafanaChart
						deviceId={ deviceId }
						deviceType={ device?.type }
						chartUrlType={ chartUrlType }
						selectedPeriod={ chartPeriod }
						totalAmountAlarmsForDevice={ selectedDeviceAlarms.length }
						isMeasuringDevice={ isMeasuringDevice }
					/>
				</div>
				{selectedDeviceAlarms.length === 0 ? (
					<div>
						<DeviceMeasurementCard
							deviceId={ device?.id }
							title={ deviceName }
							isMeasuringDevice={ isMeasuringDevice }
							configuration={ device?.configuration }
							isActivated={ device?.isActivated }
							deviceType={ device?.type }
							isLoggedIn={ isLoggedInUser }
						/>
						{/* Buttons */}
						{isLoggedInUser && (
							<>
								<div className="mt-10 ml-auto xl:w-3/4 w-full">
									{/* [Measuring] devices buttons group */}
									{isMeasuringDevice ? (
										<div className="flex justify-end flex-col">
											<div className="flex flex-row justify-end mb-2">
												<Button
													text={ t( 'View device data' ) }
													onClick={ viewDeviceDataClickHandler }
													icon={ <DataAnalysisIcon /> }
													className="w-1/2 mr-4 text-primary-content"
												/>
												<Button
													text={ t( 'View alarm log' ) }
													onClick={ onRedirectToAlarmLog }
													icon={ <AlarmIcon /> }
													className="w-1/2 text-primary-content"
												/>
											</div>
											<Button
												text={ t( 'Edit configuration' ) }
												onClick={ () => {
													history.push(
														`${ AppScreen.DEVICE_CONFIGURATION }/${ deviceId }`
													);
												} }
												icon={ <PencilIcon /> }
												className="w-full text-primary-content"
											/>
										</div>
									) : (
										/* [Signalling] devices buttons group */
										<div className="flex flex-row mb-2 justify-end">
											<Button
												text={ t( 'View alarm log' ) }
												onClick={ onRedirectToAlarmLog }
												icon={ <AlarmIcon /> }
												className="w-1/2 mr-4 text-primary-content"
											/>
											<Button
												text={ t( 'Edit configuration' ) }
												onClick={ () => {
													history.push(
														`${ AppScreen.DEVICE_CONFIGURATION }/${ deviceId }`
													);
												} }
												icon={ <PencilIcon /> }
												className="w-1/2 text-primary-content"
											/>
										</div>
									)}
								</div>
							</>
						)}
					</div>
				) : (
					<div className="border-l border-base-302 pl-4">
						{isLoggedInUser && is303 && (
							<DefinitionListItem
								textStyle="flex"
								term={ `${ t( 'Power' ) }:` }
								termStyle="font-light"
							>
								<Vrm303Toggle deviceId={ deviceId } />
							</DefinitionListItem>
						)}
					</div>
				)}
			</div>
		</div>
	);
}
