import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { AppScreen } from '../../shared/constants';
import { userHasRoleSelector } from '../../state/auth/selectors/userHasRole';
import { UserRole } from '../../middleware/auth';
import UserRoleNotice from '../UserRoleNotice';
import Button from '../buttons/Button';

export interface ConfirmationDialogProps {
	title: string;
	isOpen: boolean;
	onChange: () => void;
	toggleModal: () => void;
	requiredRoleForConfirmation: UserRole;
	onConfirm: () => void;
	isLoading:boolean
}

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ( {
	title,
	isOpen,
	onChange,
	toggleModal,
	requiredRoleForConfirmation,
	onConfirm,
	isLoading,
} ) => {
	const history = useHistory();
	const { t } = useTranslation();

	const loginWithDifferentUserRoleClickHandler = useCallback( () => {
		history.push( AppScreen.LOGIN );
	}, [ history ] );

	const userCanCOnfirm = useRecoilValue(
		userHasRoleSelector( requiredRoleForConfirmation )
	);

	return (
		<>
			<input
				type="checkbox"
				className="modal-toggle resolve-alarm-modal"
				checked={ isOpen }
				onChange={ onChange }
			/>
			<div className="modal">
				<div className="modal-box rounded-xl relative">
					<div className="mb-2">
						<UserRoleNotice requiredRole={ requiredRoleForConfirmation } />
					</div>
					<div className="flex gap-2">
						<h2 className="font-semibold text-base-700 text-xl flex-1">
							{title}
						</h2>
					</div>
					<div className="flex-1 w-full " />
					<div className="modal-action">
						{userCanCOnfirm ? (
							<Button
								className="m-0"
								onClick={ onConfirm }
								text={ t( 'Confirm' ) }
								isFormButton
								disabled={ isLoading }
								loading={ isLoading }
							/>
						) : (
							<Button
								onClick={ loginWithDifferentUserRoleClickHandler }
								text={ t( 'change-user-role', { userRole: requiredRoleForConfirmation.toLowerCase() } ) }
								isFormButton
							/>
						)}
						<Button
							className=" bg-base-800 hover:bg-base-700 m-0"
							onClick={ toggleModal }
							text={ t( 'Cancel' ) }
							isFormButton
							disabled={ isLoading }
						/>
					</div>
				</div>
			</div>
		</>
	);
};

export default ConfirmationDialog;
