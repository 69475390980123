import React from 'react';
import { ChannelNameEnum, Device, IMeasurementMessage } from '../../types';
import {
	getConfiguredRegions,
	getMeasurementPropertyDisplayValue,
	getRegionConfigurationPropertyDisplayValue,
	indexToChannelNameMap,
} from '../../utils/device';
import RegionMeasurement from './RegionMeasurement';

export interface StackRegionsMeasurementsProps {
	measurementMessage?: IMeasurementMessage | null;
	device: Device;
}

const StackRegionsMeasurements: React.FC<StackRegionsMeasurementsProps> = ( {
	measurementMessage,
	device,
} ) => {
	let region1 = getMeasurementPropertyDisplayValue(
		measurementMessage?.measurements,
		ChannelNameEnum.Region1
	);
	let region2 = getMeasurementPropertyDisplayValue(
		measurementMessage?.measurements,
		ChannelNameEnum.Region2
	);
	let region3 = getMeasurementPropertyDisplayValue(
		measurementMessage?.measurements,
		ChannelNameEnum.Region3
	);

	const regionsMeasurements = [ region1, region2, region3 ];

	const configuredRegions = getConfiguredRegions( device.configuration );

	return (
		<div className="flex flex-col flex-1 gap-0.5">
			{configuredRegions.length === 0 ? '-' : null}
			{configuredRegions.map( ( region ) => {
				const regionDefinitionName = indexToChannelNameMap[region.index];
				const regionNameFromConfig = getRegionConfigurationPropertyDisplayValue(
					device.configuration,
					indexToChannelNameMap[region.index],
					'Name'
				);
				const regionMeasurementData = regionsMeasurements.find(
					( r ) => r?.name === indexToChannelNameMap[region.index]
				);
				return (
					<RegionMeasurement
						key={ region.index }
						name={ regionNameFromConfig ?? regionDefinitionName }
						value={ regionMeasurementData?.value }
						unit={ regionMeasurementData?.unit }
					/>
				);
			} )}
		</div>
	);
};

export default StackRegionsMeasurements;
