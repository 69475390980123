import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';
import SettingsNavigation from './nav/SettingsNavigation';

export interface SettingsLayoutProps {
	isWizard: boolean;
	title: string;
	leftContainerSlot?: ReactNode;
	customContentClass?: string;
	customSideBarClass?: string;
}

const getLayoutClasses = (
	isWizard: boolean,
	hasLeftSlot: boolean,
	customSideBarClass?: string,
	customContentClass?: string
) => {
	const scrollBarClass =
		'scrollbar scrollbar-thumb-scrollbar scrollbar-track-scrollbar-default-bg scrollbar-thin overflow-auto';

	const containerWrapper = `flex flex-1 overflow-hidden gap-4 ${
		isWizard && !hasLeftSlot ? 'flex-col' : 'flex-row'
	}`;
	const leftSideBar = twMerge(
		'ml-10 mt-10',
		scrollBarClass,
		!isWizard ? 'w-72 rounded p-4 my-10 self-stretch' : 'py-1',
		isWizard && hasLeftSlot ? 'w-72' : '',
		customSideBarClass
	);
	const screenContent = twMerge(
		'flex flex-1 flex-col h-full pr-10 pb-10 overflow-hidden',
		!isWizard && 'pt-20',
		customContentClass
	);

	return { containerWrapper, leftSideBar, screenContent };
};

const SettingsLayout: React.FC<SettingsLayoutProps> = ( {
	isWizard,
	title,
	children,
	leftContainerSlot,
	customContentClass,
	customSideBarClass,
} ) => {
	const layoutClasses = getLayoutClasses(
		isWizard,
		!!leftContainerSlot,
		customSideBarClass,
		customContentClass
	);

	return (
		<div className="flex flex-col flex-1 overflow-hidden">
			<div className={ layoutClasses.containerWrapper }>
				<div className="flex">
					<div className={ layoutClasses.leftSideBar }>
						{isWizard ? (
							<h1 className="text-gray-700 text-2xl font-medium">{title}</h1>
						) : (
							<h2 className="text-gray-700 text-xl font-medium mb-5 mt-2 border-b border-base-300 pb-3">
								{title}
							</h2>
						)}

						{/* Navigation */}
						{isWizard ? null : <SettingsNavigation />}

						{/* Left Slot */}
						{leftContainerSlot ? <div>{leftContainerSlot}</div> : null}
					</div>
				</div>
				{!isWizard && <div className="w-px bg-base-301 shadow-left-sided" />}
				<div className="flex flex-1 overflow-hidden">
					<div className={ layoutClasses.screenContent }>{children}</div>
				</div>
			</div>
		</div>
	);
};

export default SettingsLayout;
