import React, {
	createRef,
	useCallback,
	useEffect,
	useLayoutEffect,
	useMemo,
	useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { useHistory, useParams } from 'react-router';
import { ResetIcon } from '../../assets/icons';
import { allDevicesStateAtom } from '../../state/devices/allDevices';
import { placesDefinitionAtom } from '../../state/places/placesDefinition';
import { DeviceFamilyType, PlaceDefinition } from '../../types';
import DateRangeInput from '../calendar/DateRangeInput';
import { getNestedDevicesList } from '../../utils/floorPlan';
import {
	AppScreen,
	deviceFiltersContainerMaxHeight,
} from '../../shared/constants';
import FiltersCalendar from '../calendar/FiltersCalendar';
import Button from '../buttons/Button';
import Select from '../controls/Select/Select';
// import useAutoClose from '../../hooks/useAutoClose';

interface DeviceFiltersParams {
	id: string;
}
export interface IFiltersData {
	devices: {
		placeId: string;
		deviceId: string;
	}[];
	selectedArea?: PlaceDefinition;
	selectedZone?: PlaceDefinition;
	selectedRoom?: PlaceDefinition;
	location?: string;
}
export interface DeviceFiltersProps {
	title: string;
	pickDates?: boolean;
	onChangeFilters?: ( data?: IFiltersData ) => void;
	routePath: AppScreen;
	allDevicesAvailable?: boolean;
}

const DeviceFilters: React.FC<DeviceFiltersProps> = ( {
	title,
	pickDates,
	onChangeFilters,
	routePath,
	allDevicesAvailable = false,
} ) => {
	const { id } = useParams<DeviceFiltersParams>();
	const history = useHistory();

	const componentContainerRef = createRef<HTMLDivElement>();

	const placesDefinition = useRecoilValue( placesDefinitionAtom );
	const allDevices = useRecoilValue( allDevicesStateAtom );

	const [ selectedArea, setSelectedArea ] = useState<PlaceDefinition>();
	const [ selectedZone, setSelectedZone ] = useState<PlaceDefinition>();
	const [ selectedRoom, setSelectedRoom ] = useState<PlaceDefinition>();
	const [ isCalendarOpen, setIsCalendarOpen ] = useState<boolean>( false );
	const [ isStartDateActive, setIsStartDateActive ] = useState<boolean>( false );
	const [ containerHeight, setContainerHeight ] = useState<number>();

	// useAutoClose( { setIsOpen: setIsCalendarOpen, element: calendarRef } );
	const calendarWithBottomOffset =
		containerHeight && containerHeight < deviceFiltersContainerMaxHeight;

	useLayoutEffect( () => {
		setContainerHeight( componentContainerRef.current?.scrollHeight );
	}, [ setContainerHeight, componentContainerRef ] );

	// Filters Data
	const singleArea =
		placesDefinition?.children?.length === 1
			? placesDefinition.children[0]
			: null;
	const areas = placesDefinition.children;
	const currentArea = singleArea || selectedArea;
	const zones = currentArea?.children.filter( ( item ) => item.type === 'zone' );
	const rooms = selectedZone
		? selectedZone.children
		: currentArea?.children.filter( ( item ) => item.type === 'room' );

	const devices = useMemo( () => {
		return (
			( selectedRoom && getNestedDevicesList( selectedRoom, false ) ) ||
			( selectedZone && getNestedDevicesList( selectedZone, false ) ) ||
			( currentArea && getNestedDevicesList( currentArea, false ) ) ||
			[]
		);
	}, [ selectedRoom, currentArea, selectedZone ] );

	const measuringDevices = allDevices.filter(
		( device ) => device.family === DeviceFamilyType.MEASURING && device.isActivated
	);

	const devicesToVisualize = allDevicesAvailable
		? allDevices
		: measuringDevices;

	// Manage filters
	const resetUrl = useCallback( () => {
		history.push( routePath );
	}, [ history, routePath ] );

	const onResetFilters = useCallback( () => {
		setSelectedZone( undefined );
		setSelectedRoom( undefined );
		setSelectedArea( undefined );
		resetUrl();
	}, [ resetUrl ] );

	const onSelectArea = useCallback(
		( value: string ) => {
			setSelectedArea( placesDefinition?.children.find( ( a ) => a.id === value ) );
			setSelectedRoom( undefined );
			setSelectedZone( undefined );
		},
		[ setSelectedArea, placesDefinition ]
	);

	const onSelectZone = useCallback(
		( value: string ) => {
			setSelectedZone( zones?.find( ( z ) => z.id === value ) );
			setSelectedRoom( undefined );
		},
		[ zones ]
	);

	const onSelectRoom = useCallback(
		( value: string ) => {
			const roomPlace = selectedZone || singleArea;
			setSelectedRoom( roomPlace?.children.find( ( r ) => r.id === value ) );
		},
		[ selectedZone, singleArea ]
	);

	const onSelectDevice = useCallback(
		( e ) => {
			const selectedDevice =
				devicesToVisualize.find( ( d ) => d.id === e.target.value ) || null;
			if ( selectedDevice ) {
				history.push( `${ routePath }/devices/${ selectedDevice?.id }` );
			} else {
				resetUrl();
			}
		},
		[ history, devicesToVisualize, routePath, resetUrl ]
	);

	const onOpenCalendarWithStartDate = useCallback( () => {
		setIsCalendarOpen( false );
		setTimeout( () => {
			setIsCalendarOpen( true );
		}, 0 );
		setIsStartDateActive( true );
	}, [] );

	const onOpenCalendarWithEndDate = useCallback( () => {
		setIsCalendarOpen( false );
		setTimeout( () => {
			setIsCalendarOpen( true );
		}, 0 );

		setIsStartDateActive( false );
	}, [] );

	// Effects
	useEffect( () => {
		if (
			id &&
			devices.length &&
			!devices.find( ( device ) => device.deviceId === id )
		) {
			resetUrl();
		}
	}, [ resetUrl, devices, id ] );

	useEffect( () => {
		onChangeFilters &&
			onChangeFilters( {
				selectedArea: currentArea,
				selectedRoom,
				selectedZone,
				devices,
				// The backend does not return the nested historical alarms so we need to use the selected area data instead if current area, when there is only one area
				location: selectedRoom?.id || selectedZone?.id || selectedArea?.id,
			} );
	}, [
		onChangeFilters,
		devices,
		currentArea,
		selectedRoom,
		selectedZone,
		selectedArea,
	] );

	const { t } = useTranslation();

	// We cache the devices options
	// (useful when there is a long list of devices)
	const selectDeviceOptions = useMemo(
		() => devicesToVisualize
			.filter( ( d ) => devices.map( ( deviceItem ) => deviceItem.deviceId ).includes( d.id ) )
			.map( ( device ) => (
				<option value={ device.id } key={ device.id }>
					{device.name}
				</option>
			) ),
		[ devices, devicesToVisualize ]
	);

	return areas?.length ? (
		<div
			className="min-w-80 p-4 pl-10 pr-6 flex flex-col gap-3"
			ref={ componentContainerRef }
		>
			<h1 className="text-gray-700 text-3xl font-medium font-system mb-5">
				{title}
			</h1>
			<div className="text-md font-medium">{t( 'Select place' )}</div>
			<div className="border-b border-base-302" />
			<Select
				key="devices-filters-select-area"
				className="w-full max-w-xs select select-sm select-bordered rounded-md font-normal italic shadow-bsm"
				placeholder={
					singleArea?.name || selectedArea?.name || t( 'Select area' ).toString()
				}
				disabled={ !!singleArea }
				onChange={ onSelectArea }
				options={ [
					{ prettyName: t( 'Select area' ), value: t( 'Select area' ) },
				].concat(
					areas?.map( ( a ) => ( { prettyName: a.name, value: a.id } ) ) || []
				) }
			/>
			<Select
				key="devices-filters-select-zone"
				className="w-full max-w-xs select select-sm select-bordered rounded-md font-normal italic shadow-bsm"
				placeholder={ selectedZone?.name || t( 'Select zone' ) }
				onChange={ onSelectZone }
				disabled={ !currentArea }
				options={ [
					{ prettyName: t( 'Select zone' ), value: t( 'Select zone' ) },
				].concat(
					zones?.map( ( z ) => ( { prettyName: z.name, value: z.id } ) ) || []
				) }
			/>
			<Select
				key="devices-filters-select-room"
				className="w-full max-w-xs select select-sm select-bordered rounded-md font-normal italic shadow-bsm"
				placeholder={ selectedRoom?.name || t( 'Select room' ).toString() }
				onChange={ onSelectRoom }
				disabled={ !currentArea }
				options={ [
					{ prettyName: t( 'Select room' ), value: t( 'Select room' ) },
				].concat(
					rooms?.map( ( r ) => ( { prettyName: r.name, value: r.id } ) ) || []
				) }
			/>
			<div className="text-md font-medium mt-4">{t( 'Select device' )}</div>
			<div className="border-b border-base-302" />
			<select
				key="devices-filters-select-device"
				className="w-full max-w-xs flex-1 max-h-52 p-2 focus:outline-input scrollbar scrollbar-thumb-scrollbar scrollbar-track-scrollbar-default-bg scrollbar-thin"
				multiple
				onChange={ onSelectDevice }
				value={ [ id ] }
			>
				{selectDeviceOptions}
			</select>
			{calendarWithBottomOffset && (
				<FiltersCalendar
					className="absolute top-filters-calendar z-30 bg-base-100 shadow-dark"
					isOpen={ isCalendarOpen }
					setIsOpen={ setIsCalendarOpen }
					firstDayInputIsActive={ isStartDateActive }
					routePath={ routePath }
				/>
			)}
			{/* </div> */}
			{pickDates ? (
				<>
					{!calendarWithBottomOffset && (
						<div className="relative h-0 p-0">
							<FiltersCalendar
								className="absolute top-28 z-30 bg-base-100 shadow-dark w-calendar overflow-visible"
								isOpen={ isCalendarOpen }
								setIsOpen={ setIsCalendarOpen }
								firstDayInputIsActive={ isStartDateActive }
								routePath={ routePath }
							/>
						</div>
					)}
					<div className="text-md font-medium mt-4">{t( 'Select period' )}</div>
					<div className="border-b border-base-302" />
					<DateRangeInput
						className="text-base-600"
						iconColor="text-base-600"
						dateRangeType="from"
						onControlClick={ onOpenCalendarWithStartDate }
					/>
					<DateRangeInput
						className="text-base-600"
						iconColor="text-base-600"
						dateRangeType="to"
						onControlClick={ onOpenCalendarWithEndDate }
					/>
				</>
			) : null}
			<Button
				text={ t( 'Reset filters' ) }
				onClick={ onResetFilters }
				icon={ <ResetIcon /> }
				className="bg-base-300 w-full border-base-700 text-sm text-base-700 font-bold my-2"
			/>
		</div>
	) : null;
};

export default React.memo( DeviceFilters );
