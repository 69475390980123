const ERROR_TEXT = {
	invalid_mac_address: 'The MAC address is invalid.',
	common: 'Something went wrong.',
};

const MAC_ADDRESS_LENGTH = 12;

const checkIfMACAddressIsValid = ( value: string ) => {
	const valueWithoutFormatting = value.replace( / /g, '' ).split( ':' ).join( '' ); // Api does not accept mac address with dots
	if (
		value.length === 0 ||
		valueWithoutFormatting.length < MAC_ADDRESS_LENGTH ||
		valueWithoutFormatting.length > MAC_ADDRESS_LENGTH
	) {
		return false;
	}
	return true;
};

export { checkIfMACAddressIsValid, ERROR_TEXT };
