import React, { ReactNode } from 'react';
import * as floorPlanConfig from './floorplanConfig';

type Measurement = {
	value: number;
	unit: string;
};

interface DeviceBoxProps {
	id: string;
	selectedDeviceRef: any;
	positionX: number;
	positionY: number;
	size: number;
	className: string;
	measurement?: Measurement;
	children?: ReactNode;
}

export const DeviceBox = ( {
	id,
	selectedDeviceRef,
	positionX,
	positionY,
	size,
	className,
	children,
}: DeviceBoxProps ) => {
	return (
		<svg overflow="visible">
			<rect
				ref={ selectedDeviceRef }
				id={ id }
				className={ className }
				x={ positionX - size / 2 }
				y={ positionY - size / 2 }
				width={ size }
				height={ size }
				rx={ floorPlanConfig.DEVICE_RADIUS }
				ry={ floorPlanConfig.DEVICE_RADIUS }
			/>

			{children}
		</svg>
	);
};
