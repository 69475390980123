import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router';
import { parse, stringify } from 'svgson';
import SVG from 'react-inlinesvg';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import api from '../../middleware/api';
import { placesDefinitionAtom } from '../../state/places/placesDefinition';
import SettingsLayout from '../SettingsLayout';
import UserRoleNotice from '../UserRoleNotice';
import { userHasRoleSelector } from '../../state/auth/selectors/userHasRole';
import { AppScreen } from '../../shared/constants';
import Button from '../buttons/Button';

interface PlacesConfigurationProps {}

const PlacesConfiguration: React.FC<PlacesConfigurationProps> = () => {
	const { t } = useTranslation();

	const [ , setPlacesDefinition ] = useRecoilState( placesDefinitionAtom );
	const [ jsonText, setJsonText ] = useState( '' );
	const [ svgData, setSvgData ] = useState( '' );
	const [ error, setError ] = useState( { json: '', svg: '' } );
	const history = useHistory();

	const settingsPath = history.location.pathname
		.split( '/' )
		.filter( ( location ) => location !== 'places' )
		.join( '/' );

	const isWizardMode = settingsPath.includes( 'wizard' );

	const onPrevStateHandler = useCallback( () => {
		history.push( `${ settingsPath }` );
	}, [ history, settingsPath ] );

	const onChangeSvg = useCallback( ( e ) => {
		setSvgData( '' );
		setError( ( err ) => ( { ...err, svg: '' } ) );
		parse( e.target.value )
			.then( ( json ) => {
				setSvgData( stringify( json ) );
			} )
			.catch( () => {
				// eslint-disable-next-line no-extra-boolean-cast
				if ( !!e.target.value ) {
					setError( ( err ) => ( {
						...err,
						svg: 'You need to add valid svg data!',
					} ) );
				} else {
					setError( ( err ) => ( {
						...err,
						svg: '',
					} ) );
				}
			} );
	}, [] );

	const submitDisabled = !!error.svg || !!error.json || !svgData || !jsonText;

	const onSaveConfigurationData = useCallback( async () => {
		try {
			const jsonData = JSON.parse( jsonText );
			const jsonRequestData = { ...jsonData, background: svgData };
			await api.places.forceUpdate( {
				...jsonRequestData,
				background: svgData,
			} );

			const placesDefinition = await api.places.get();
			setPlacesDefinition( placesDefinition );
			history.push( `${ settingsPath }/manage-devices` );
		} catch ( e ) {
			// silence
		}
	}, [ jsonText, svgData, history, settingsPath, setPlacesDefinition ] );

	const onChangeJsonText = useCallback( ( e ) => {
		setJsonText( e.target.value );
		setError( ( err ) => ( { ...err, json: '' } ) );
		try {
			JSON.parse( e.target.value );
		} catch ( _ ) {
			setError( ( err ) => ( { ...err, json: 'Please enter a valid JSON!' } ) );
		}
	}, [] );

	const userCanEditSettings = useRecoilValue(
		userHasRoleSelector( 'MAINTENANCE' )
	);
	const loginAsMaintenanceClickHandler = useCallback( () => {
		history.push( AppScreen.LOGIN );
	}, [ history ] );

	return (
		<SettingsLayout isWizard={ isWizardMode } title={ t( 'Floor plan definition' ) }>
			<div className="flex flex-1 flex-col w-full content-between">
				<div className="flex-1">
					<div className="form-control mx-10">
						<UserRoleNotice requiredRole="MAINTENANCE" />
						<div className="flex">
							<div className="flex-1 w-full">
								{/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
								<label className="label">
									<span className="label-text">{t( 'SVG' )}</span>
								</label>
								{!!error.svg && (
									<div className="text-error font-bold text-xs mb-4">
										{error.svg}
									</div>
								)}
								<textarea
									className="textarea h-40 rounded w-full"
									placeholder={ t( 'Paste SVG code here' ) }
									onChange={ onChangeSvg }
								/>
							</div>
							{!error.svg && !!svgData && (
								<SVG src={ svgData } className="h-40 mt-9" width="300px" />
							)}
						</div>
					</div>
					<div className="form-control m-10">
						{/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
						<label className="label">
							<span className="label-text">{t( 'JSON' )}</span>
						</label>
						{!!error.json && (
							<div className="text-error font-bold text-xs mb-4">
								{error.json}
							</div>
						)}
						<textarea
							className="textarea h-40 rounded"
							placeholder={ t( 'Paste JSON code here' ) }
							value={ jsonText }
							onChange={ onChangeJsonText }
						/>
					</div>
				</div>

				<div
					className={ `flex w-full ${
						isWizardMode ? 'justify-between' : 'justify-end'
					}` }
				>
					{isWizardMode && (
						<Button
							text={ `< ${ t( 'Previous page' ) }` }
							onClick={ onPrevStateHandler }
							isFormButton
							className="px-2"
						/>
					)}
					{userCanEditSettings ? (
						<Button
							text={ !isWizardMode ? t( 'Save' ) : `${ t( 'Add devices' ) } >` }
							onClick={ onSaveConfigurationData }
							disabled={ submitDisabled }
							isFormButton
							className="px-2"
						/>
					) : (
						<Button
							onClick={ loginAsMaintenanceClickHandler }
							text={ `${ t( 'Login as maintenance' ) } >` }
							isFormButton
							className="px-2"
						/>
					)}
				</div>
			</div>
		</SettingsLayout>
	);
};

export default PlacesConfiguration;
