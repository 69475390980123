import React from 'react';

const TableHeadItem: React.FC = ( { children } ) => {
	return (
		<th className="bg-base-200 sticky top-0 z-10 text-base text-left font-normal p-0">
			<div className="flex border-b border-base-700 border-opacity-25 pb-2 pr-2 mb-3 min-h-14 items-baseline overflow-ellipsis">
				<span className="self-end">{children}</span>
			</div>
		</th>
	);
};

export default TableHeadItem;
