import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import i18n from '../../i18n';
import { generalSettingsDefinitionStateAtom } from '../../state/units/unitsDefinitions';
import { instanceUnitsStateAtom } from '../../state/units/instanceUnits';
import api from '../../middleware/api';
import SettingsLayout from '../SettingsLayout';
import { isLoggedInState } from '../../state/auth/isLoggedIn';
import {
	AppScreen,
	GeneralSettingsUnitsDefinitions,
} from '../../shared/constants';
import UserRoleNotice from '../UserRoleNotice';
import { userHasRoleSelector } from '../../state/auth/selectors/userHasRole';
import GeneralSettingsSelectInput from '../settings/fields/GeneralSettingsSelectInput';
import GeneralSettingsTextInput from '../settings/fields/GeneralSettingsTextInput';
import Button from '../buttons/Button';

interface GeneralSettingsProps {}

const filterObject = (
	object: Object,
	callback: ( value: string[] ) => Boolean
) => {
	return Object.fromEntries(
		Object.entries( object ).filter( ( [ key, value ] ) => callback( value ) )
	);
};

const GeneralSettings: React.FC<GeneralSettingsProps> = () => {
	const unitsDefinitions = useRecoilValue( generalSettingsDefinitionStateAtom );
	const [ instanceUnits, setInstanceUnits ] = useRecoilState(
		instanceUnitsStateAtom
	);

	const isLoggedIn = useRecoilValue( isLoggedInState );

	const {
		register,
		handleSubmit,
		reset,
		formState: { isSubmitting },
	} = useForm();

	const history = useHistory();
	const { pathname } = history.location;
	const { t } = useTranslation();

	const isWizard = pathname !== '/settings';

	const [ errorMessage, setErrorMessage ] = useState( '' );

	const onFormSubmit = handleSubmit( async ( data ) => {
		try {
			await api.units.selection.update( data );
			// TO DO create a helper function for catching the errors

			const updatedUnits = await api.units.selection.get();
			setInstanceUnits( updatedUnits );
			setErrorMessage( '' );

			if ( isWizard ) {
				history.push( `${ pathname }/places` );
			}
		} catch ( e: unknown ) {
			setErrorMessage( ( e as Error ).message );
		}
	} );

	const userCanEditSettings = useRecoilValue(
		userHasRoleSelector( 'MAINTENANCE' )
	);
	const loginAsMaintenanceClickHandler = useCallback( () => {
		history.push( AppScreen.LOGIN );
	}, [ history ] );

	const multipleOptionsUnitDefinitions = filterObject(
		unitsDefinitions,
		( units: string[] ) => units.length > 1
	);

	const singleOptionUnitDefinitions = filterObject(
		unitsDefinitions,
		( units: string[] ) => units.length <= 1
	);

	const languages = multipleOptionsUnitDefinitions.language || [];

	useEffect( () => {
		// Refresh the token each time the user opens the settings page to verify
		// that they are still logged in.
		api
			.refreshToken()
			.then( () => {} )
			.catch( () => {} );
	}, [] );

	useEffect( () => {
		if ( !isLoggedIn ) {
			history.replace( AppScreen.LOGIN );
		}
	}, [ isLoggedIn, history ] );

	// defaultValues are cached on the first render (undefined on first render)
	// resetting the form with the fetched data will prevent sending empty fields and crash
	useEffect( () => {
		if ( instanceUnits ) {
			reset( instanceUnits );
		}
	}, [ reset, instanceUnits ] );

	const getLanguageOption = useCallback(
		( option: string ) => {
			switch ( option ) {
				case 'en':
					return t( 'en' );
				case 'nl':
					return t( 'nl' );
				case 'it':
					return t( 'it' );
				default:
					return t( 'en' );
			}
		},
		[ t ]
	);

	const getDefinitionsPrettyNames = useCallback(
		( definition: GeneralSettingsUnitsDefinitions ) => {
			switch ( definition ) {
				case GeneralSettingsUnitsDefinitions.EFFICIENCY:
					return t( 'efficiency' );
				case GeneralSettingsUnitsDefinitions.EMISSIONS:
					return t( 'emissions' );
				case GeneralSettingsUnitsDefinitions.ENERGY:
					return t( 'energy' );
				case GeneralSettingsUnitsDefinitions.FLOW:
					return t( 'flow' );
				case GeneralSettingsUnitsDefinitions.RADIATION:
					return t( 'radiation' );
				case GeneralSettingsUnitsDefinitions.RADIO_ACTIVITY_CONCENTRATION:
					return t( 'radioactivityConcentration' );
				case GeneralSettingsUnitsDefinitions.RADIO_ACTIVITY_INPUT:
					return t( 'radioactivityInput' );
				case GeneralSettingsUnitsDefinitions.SURFACE:
					return t( 'surface' );
				case GeneralSettingsUnitsDefinitions.VOLUME:
					return t( 'volume' );
				default:
					return t( 'efficiency' );
			}
		},
		[ t ]
	);

	return (
		<SettingsLayout isWizard={ isWizard } title={ t( 'Settings' ) }>
			<form
				className={ `flex ${ isWizard ? 'flex-1' : '' } flex-col w-full` }
				onSubmit={ onFormSubmit }
				id="general-settings-form"
			>
				<div className="flex flex-col flex-1 h-full flex-wrap px-10">
					<UserRoleNotice requiredRole="MAINTENANCE" />
					{/* Language input */}
					<div className="flex form-control w-1/4 mb-2">
						<label className="label" htmlFor="language-selector">
							<span className="label-select">{t( 'Language' )}</span>
						</label>
						<select
							id="language"
							defaultValue={ instanceUnits.language || i18n.language }
							className="select select-sm rounded-md w-full font-normal"
							{ ...register( 'language', { required: false } ) }
						>
							{languages.map( ( language: string ) => (
								<option key={ language } value={ language }>
									{getLanguageOption( language )}
								</option>
							) )}
						</select>
					</div>
					{/* Data inputs */}
					<div className="flex w-full flex-wrap gap-x-10 gap-y">
						{(
							Object.keys(
								multipleOptionsUnitDefinitions
							) as Array<GeneralSettingsUnitsDefinitions>
						)
							.filter( ( k ) => k !== 'language' )
							.map( ( propKey ) => (
								<GeneralSettingsSelectInput
									key={ propKey }
									name={ propKey }
									options={ unitsDefinitions[propKey] || [] }
									optionsLabel={ getDefinitionsPrettyNames( propKey ) }
									defaultValue={ instanceUnits[propKey] }
									register={ {
										...register( propKey, { required: false } ),
									} }
								/>
							) )}

						{Object.keys( singleOptionUnitDefinitions ).map( ( propKey: string ) => (
							<GeneralSettingsTextInput
								key={ propKey }
								name={ propKey }
								register={ register }
								label={ getDefinitionsPrettyNames(
									propKey as GeneralSettingsUnitsDefinitions
								) }
								defaultValue={ instanceUnits[propKey] }
								disabled
							/>
						) )}
					</div>
				</div>
				{!isWizard && (
					<div className="ml-4 mt-8 flex">
						<div
							data-tip={ errorMessage }
							className={
								errorMessage
									? 'tooltip tooltip-open tooltip-right tooltip-error'
									: ''
							}
						>
							{userCanEditSettings ? (
								<Button
									form="general-settings-form"
									text={ t( 'Save' ) }
									loading={ isSubmitting }
									isFormButton
								/>
							) : (
								<Button
									onClick={ loginAsMaintenanceClickHandler }
									text={ `${ t( 'Login as maintenance' ) } >` }
									isFormButton
									className="px-2"
								/>
							)}
						</div>
					</div>
				)}

				<div
					className={ `flex w-full ml-4 mt-8 ${ isWizard ? 'justify-end' : '' }` }
				>
					{isWizard && (
						<Button
							form="general-settings-form"
							text={ `${ t( 'Define Areas' ) } >` }
							isFormButton
							loading={ isSubmitting }
							className="px-2"
						/>
					)}
				</div>
			</form>
		</SettingsLayout>
	);
};

export default GeneralSettings;
