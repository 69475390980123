import { DateTime } from 'luxon';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useIsMounted } from '../../hooks/useIsMounted';
import api from '../../middleware/api';
import { AppScreen, historicalAlarmsPageSize } from '../../shared/constants';
import { alarmPaneHistoricalAlarmsAtom } from '../../state/alarms/alarmPaneHistoricalAlarms';
import { userHasRoleSelector } from '../../state/auth/selectors/userHasRole';
import {
	Alarm,
	AlarmSeverityEnum,
	DeviceConfiguration,
	UserRolesEnum,
} from '../../types';
import { getRegionConfigurationPropertyDisplayValue } from '../../utils/device';
import { getApiErrors, ErrorType } from '../../utils/errors';
import { AlarmListItemIcon } from '../alarms/AlarmListItemIcon';
import Button from '../buttons/Button';
import UserRoleNotice from '../UserRoleNotice';

interface ResolveAlarmProps {
	alarms: Array<Alarm>;
	configuration?: DeviceConfiguration;
	resolveDisabled?: boolean;
	closeModal: () => void;
}

const ResolveAlarm: React.FC<ResolveAlarmProps> = ( {
	alarms,
	configuration,
	resolveDisabled = false,
	closeModal,
} ) => {
	const setHistoricalAlarms = useSetRecoilState( alarmPaneHistoricalAlarmsAtom );
	const userCanResolve = useRecoilValue(
		userHasRoleSelector( UserRolesEnum.SUPERVISOR )
	);
	const { t } = useTranslation();
	const history = useHistory();
	const isMounted = useIsMounted();

	// Local State
	const [ reason, setReason ] = useState( '' );
	const [ error, setError ] = useState( '' );
	const [ selectedAlarm, setSelectedAlarm ] = useState<Alarm | undefined>();
	const [ loading, setLoading ] = useState( false );

	useEffect( () => {
		// Refresh the token each time the user opens the settings page to verify
		// that they are still logged in.

		api
			.refreshToken()
			.then( () => {} )
			.catch( () => {} );
	}, [] );

	useEffect( () => {
		if ( !selectedAlarm ) {
			setSelectedAlarm( alarms[0] );
		}
	}, [ alarms, selectedAlarm ] );

	const onChangeReason = useCallback( ( e ) => {
		setError( '' );
		setReason( e.target.value );
	}, [] );

	const onChangeSelectedAlarm = useCallback(
		( e ) => {
			setError( '' );
			const index = e.target.selectedIndex;
			const optionElement = e.target.childNodes[index];
			const optionElementId = optionElement.getAttribute( 'value' );
			const selectedAlarm = alarms.find(
				( alarm ) => alarm.id === optionElementId
			);
			setSelectedAlarm( selectedAlarm );
		},
		[ alarms ]
	);

	const getHeaderTitleText = useCallback( () => {
		if ( selectedAlarm ) {
			if ( selectedAlarm.alarmSeverity === AlarmSeverityEnum.CONNECTION ) {
				return t( 'Device is disconnected' );
			}
			if (
				selectedAlarm.alarmSeverity === AlarmSeverityEnum.WARNING ||
				selectedAlarm.alarmSeverity === AlarmSeverityEnum.ERROR
			) {
				return t( 'High Measurement Detected' );
			}
			return '-';
		}
		return '-';
	}, [ selectedAlarm, t ] );

	const onResolve = useCallback( async () => {
		if ( selectedAlarm ) {
			if ( isMounted() ) {
				setLoading( true );
			}
			try {
				await api.alarms.resolve( {
					id: selectedAlarm.id,
					reason,
				} );
				const historicalAlarmsResponse = await api.alarms.getHistorical( {
					pageSize: historicalAlarmsPageSize,
					periodFrom: DateTime.local().minus( { hours: 24 } ).toISO(),
					periodTo: DateTime.local().toISO(),
				} );

				setHistoricalAlarms( historicalAlarmsResponse.alarms );
			} catch ( error ) {
				const apiErrorMessage = getApiErrors( error as ErrorType );
				const errorMessage =
					apiErrorMessage.customMessage || t( 'Unable to resolve the alarm' );
				if ( isMounted() ) {
					setError( errorMessage );
				}
			}
			if ( isMounted() ) {
				setLoading( false );
			}
		}
	}, [ reason, selectedAlarm, t, setHistoricalAlarms, isMounted ] );

	const handleCloseClick = useCallback( () => {
		if ( resolveDisabled ) {
			return;
		}
		closeModal();
	}, [ resolveDisabled, closeModal ] );

	const loginAsASupervisorClickHandler = useCallback( () => {
		history.push( AppScreen.LOGIN );
	}, [ history ] );

	const getAlarmTitleFromConfig = useCallback(
		( channelName: string ) => {
			const alarmChannelNameFromConfig =
				getRegionConfigurationPropertyDisplayValue(
					configuration,
					channelName,
					'Name'
				);
			const alarmTitle = `${ alarmChannelNameFromConfig } (${ channelName })`;
			return alarmTitle;
		},
		[ configuration ]
	);

	const disabled = reason.length === 0 || !selectedAlarm;
	const showAlarmsList = alarms.length > 1;

	return (
		<>
			<div className="modal modal-open">
				<div className="modal-box rounded-xl relative  animate-fade-in-up-modal-content">
					<div className={ `${ !userCanResolve ? 'mb-4' : '' }` }>
						<UserRoleNotice
							requiredRole={ UserRolesEnum.SUPERVISOR }
							message={ t( "You don't have the rights to resolve an alarm." ) }
						/>
					</div>
					<div className="flex gap-2">
						<h2 className="font-semibold text-base-700 text-xl flex-1">
							{getHeaderTitleText()}
						</h2>

						{selectedAlarm ? (
							<div
								className={ `bg-danger-${ selectedAlarm?.alarmSeverity.toLocaleLowerCase() } flex items-center w-7 h-7 rounded-full justify-center` }
							>
								<AlarmListItemIcon type={ selectedAlarm?.alarmSeverity } />
							</div>
						) : null}
					</div>

					{/* Choose an alarm to resolve */}
					{showAlarmsList ? (
						<div className="flex-1 w-full mt-2 mb-2">
							<label className="label" htmlFor="resolve-alarm">
								<span className="label-text text-base-700 font-bold">
									{t( 'Select an alarm to resolve' )}
								</span>
							</label>
							<div className="flex flex-row">
								{selectedAlarm ? (
									<div
										className={ `bg-danger-${ selectedAlarm.alarmSeverity.toLocaleLowerCase() } rounded-l flex items-center w-8 h-8 justify-center` }
									>
										<AlarmListItemIcon type={ selectedAlarm.alarmSeverity } />
									</div>
								) : null}

								<select
									key={ selectedAlarm?.id }
									onChange={ onChangeSelectedAlarm }
									id="resolve-alarm"
									className="select select-sm select-bordered w-auto flex-1 text-base-700  rounded-r"
									defaultValue={ selectedAlarm ? selectedAlarm?.id : 'DEFAULT' }
								>
									<option
										className="bg-base-300"
										value="DEFAULT"
										disabled={ !selectedAlarm }
									>
										{t( 'Select an alarm' )}
									</option>
									{alarms.map( ( alarm ) => {
										return (
											<option key={ alarm.id } value={ alarm.id }>
												Channel:{' '}
												{alarm?.channelName
													? getAlarmTitleFromConfig( alarm.channelName )
													: '-'}{' '}
												- ({alarm.alarmSeverity.toLocaleLowerCase()})
											</option>
										);
									} )}
								</select>
							</div>
						</div>
					) : null}

					{/* Resolve reason */}
					<div className="flex-1 w-full">
						<label className="label" htmlFor="resolve-reason">
							<span className="label-text text-base-700 font-bold">
								{t( 'Resolve reason' )}
							</span>
						</label>
						<textarea
							id="resolve-reason"
							className="textarea h-40 textarea-bordered w-full rounded-md text-base-800"
							placeholder={ `${ t( 'Add a reason to resolve the alarm' ) }` }
							onChange={ onChangeReason }
							value={ reason }
						/>
						{Boolean( error ) && (
							<div className="text-danger">
								<span className="text-xs">{error}</span>
							</div>
						)}
					</div>
					<div className="modal-action">
						{userCanResolve ? (
							<Button
								loading={ loading }
								disabled={ disabled }
								text={ t( 'Resolve' ) }
								onClick={ onResolve }
								className={ `bg-primary min-w-24 w-auto btn-sm ${
									disabled ? 'opacity-40 cursor-default' : ''
								}` }
							/>
						) : (
							<Button
								text={ `${ t( 'Login as a supervisor' ) } >` }
								onClick={ loginAsASupervisorClickHandler }
								className="bg-primary w-auto"
							/>
						)}

						<Button
							text={ t( 'Close' ) }
							onClick={ handleCloseClick }
							className="bg-base-800 min-w-24 w-auto"
						/>
					</div>
				</div>
			</div>
		</>
	);
};

export default ResolveAlarm;
