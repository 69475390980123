import React, { useState } from 'react';
import { Control, FieldValues, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
import { EyeIcon } from '../../../assets/icons';
import { PropertyDefinition } from '../../../types';
import * as validation from '../validation';

export interface PasswordFieldProps {
	control: Control<FieldValues, object>;
	name: string;
	label: string;
	placeHolder: string;
	readOnly?: boolean;
	errorText?: string;
	maxLength?: number;
	defaultValue?: string;
	required?: boolean;
	fieldDefinition?: PropertyDefinition;
}

const PasswordField: React.FC<PasswordFieldProps> = ( {
	name,
	control,
	label,
	placeHolder,
	readOnly,
	maxLength,
	defaultValue,
	required,
	fieldDefinition,
} ) => {
	const { t } = useTranslation();
	const {
		field,
		fieldState: { error },
	} = useController( {
		control,
		name: `${ name }.value`,
		defaultValue: defaultValue ?? '',
		rules: {
			...( required && {
				required: label
					? t( 'error-field-required', { name: label } ).toString()
					: t( 'Field is required' ).toString(),
			} ),
			...( fieldDefinition?.range &&
				fieldDefinition.range.length > 0 &&
				validation.createRangeRule( fieldDefinition.range[0] ) ),
			...( fieldDefinition?.length &&
				validation.createLengthRule( fieldDefinition.length ) ),
		},
	} );

	const styles = twMerge(
		'input input-md input-bordered rounded-md w-full h-9 leading-9',
		error && 'border border-danger bg-danger bg-opacity-10',
		readOnly && 'bg-base-300'
	);

	const [ passwordShown, setPasswordShown ] = useState( false );

	const togglePassVisibility = () => {
		setPasswordShown( !passwordShown );
	};

	return (
		<label htmlFor={ `configure-${ label }` } className="block mt-2 text-sm">
			{label ? label : label}
			<div className="flex items-center mt-1 relative">
				<input
					{ ...field }
					id={ `configure-${ label }` }
					type="text"
					placeholder={ placeHolder }
					autoComplete="off"
					className={ `${ styles } ${
						passwordShown ||
						field.value === undefined ||
						( typeof field.value === 'string' && field.value.length === 0 )
							? ''
							: 'password-field'
					}` }
					maxLength={ maxLength }
				/>
				<button
					onClick={ togglePassVisibility }
					type="button"
					className="ml-1 bg-neutral bg-opacity-10 w-8 h-8 px-2 absolute right-0 rounded-l justify-self-end text-primary-content"
				>
					<EyeIcon
						className={ `w-4 ${
							passwordShown ? 'fill-base-content' : 'fill-base-302'
						}` }
					/>
				</button>
			</div>
			{!!error && (
				<span className="text-danger pt-1 text-xs">{error.message}</span>
			)}
		</label>
	);
};

export default PasswordField;
