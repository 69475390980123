import { useCallback } from 'react';
import { useHistory } from 'react-router';
import { useRecoilValue } from 'recoil';
import { AppScreen } from '../../shared/constants';
import { findLocatedDeviceByIdSelector } from '../../state/places/findLocatedDeviceById';
import { Alarm } from '../../types';
import { roundMeasurement } from '../../utils/device';
import { getAuditTimeStamp } from '../../utils/time';
import { AlarmListItemIcon } from '../alarms/AlarmListItemIcon';
import TableData from '../table/TableData';
import TableRow from '../table/TableRow';

interface ActiveAlarmRowProps {
	alarm: Alarm;
}

const ActiveAlarmRow: React.FC<ActiveAlarmRowProps> = ( { alarm } ) => {
	const deviceLocation = useRecoilValue(
		findLocatedDeviceByIdSelector( alarm.deviceId )
	);

	const longLocation = deviceLocation?.locationPath;
	const locationParts = longLocation?.split( '/' ).slice( 1 );
	const shortLocation = locationParts?.length
		? locationParts[locationParts.length - 1]
		: '-';

	const peakValue = alarm.peakValue
		? roundMeasurement( alarm.peakValue ) + alarm.unit
		: '-';
	const created = getAuditTimeStamp( alarm.created );

	const history = useHistory();

	const alarmRowClickHandler = useCallback(
		( deviceId ) => {
			history.push( `${ AppScreen.DEVICE_DETAILS }/${ deviceId }` );
		},
		[ history ]
	);

	return (
		<TableRow onClick={ () => alarmRowClickHandler( alarm.deviceId ) }>
			<TableData title={ alarm.alarmSeverity } key={ alarm.id }>
				<span
					className={ `ml-0.5 mr-6 bg-danger-${ alarm.alarmSeverity.toLowerCase() } rounded-full z-10 flex h-7 items-center w-7 justify-center text-base-100` }
				>
					<AlarmListItemIcon type={ alarm.alarmSeverity } />
				</span>
			</TableData>
			<TableData>{alarm.deviceName}</TableData>
			<TableData>{peakValue}</TableData>
			<TableData>{created}</TableData>
			<TableData title={ locationParts?.join( ' / ' ) }>{shortLocation}</TableData>
		</TableRow>
	);
};

export default ActiveAlarmRow;
