import React, { useLayoutEffect, useRef } from 'react';
import ShadowFilter from './ShadowFilter';
import { AlarmSeverityEnum } from '../../types';
import { getAlarmIcon } from './alarm_styles';

interface PlaceProps {
	pathCoords: string;
	onPlaceClick: ( element: SVGPathElement | null ) => void;
	alarmType: AlarmSeverityEnum;
}

const ALARM_ICON_CIRCLE_SIZE = 55;
const ALARM_ICON_SIZE = ALARM_ICON_CIRCLE_SIZE * 0.55;

const alertIconShadow = {
	darkRed: '#a30002',
	darkYellow: '#b07b00',
	darkBrown: '#593e40',
};

const PlaceWithAlarm = ( props: PlaceProps ) => {
	const { pathCoords, onPlaceClick, alarmType } = props;
	const pathRef = useRef<SVGPathElement | null>( null );
	const [ centerOfPlace, setCenterOfPlace ] = React.useState<any>( { x: 0, y: 0 } );

	useLayoutEffect( () => {
		if ( pathRef?.current ) {
			const {
				x, y, width, height
			} = pathRef.current.getBBox();
			let centerX = width / 2 + x;
			let centerY = height / 2 + y;
			setCenterOfPlace( { x: centerX, y: centerY } );
		}
	}, [ pathRef ] );

	const handleOnClick = () => {
		onPlaceClick( pathRef.current );
	};

	let placeBackground = 'fill-transparent';
	let placeIconColor = 'fill-transparent';
	let placeIconShadow = 'drop-shadow( 2px 3px 6px transparent)';

	if ( alarmType === AlarmSeverityEnum.ERROR ) {
		placeBackground = 'fill-error opacity-50  mix-blend-multiply';
		placeIconColor = 'fill-error';
		placeIconShadow = `drop-shadow( 2px 3px 6px ${ alertIconShadow.darkRed })`;
	}
	if ( alarmType === AlarmSeverityEnum.WARNING ) {
		placeBackground = 'fill-warning opacity-50 mix-blend-multiply';
		placeIconColor = 'fill-warning';
		placeIconShadow = `drop-shadow( 2px 3px 6px ${ alertIconShadow.darkYellow })`;
	}
	if ( alarmType === AlarmSeverityEnum.CONNECTION ) {
		placeBackground = 'fill-info opacity-50  mix-blend-multiply';
		placeIconColor = 'fill-info';
		placeIconShadow = `drop-shadow( 2px 3px 6px ${ alertIconShadow.darkBrown })`;
	}

	return (
		<svg overflow="visible">
			<ShadowFilter />
			{/* Place path */}
			<path
				ref={ pathRef }
				className={ `cursor-pointer ${ placeBackground }` }
				onClick={ handleOnClick }
				d={ pathCoords }
				filter="url(#dropshadow)"
			/>

			{/* Icon */}
			<svg
				onClick={ handleOnClick }
				className="cursor-pointer"
				overflow="visible"
			>
				<rect
					className={ placeIconColor }
					x={ centerOfPlace.x - ALARM_ICON_CIRCLE_SIZE / 2 }
					y={ centerOfPlace.y - ALARM_ICON_CIRCLE_SIZE / 2 }
					width={ ALARM_ICON_CIRCLE_SIZE }
					height={ ALARM_ICON_CIRCLE_SIZE }
					rx={ ALARM_ICON_CIRCLE_SIZE }
					filter={ placeIconShadow }
				/>
				<svg
					width={ ALARM_ICON_SIZE }
					height={ ALARM_ICON_SIZE }
					fill="white"
					x={ centerOfPlace.x - ALARM_ICON_SIZE / 2 }
					y={ centerOfPlace.y - ALARM_ICON_SIZE / 2 }
				>
					{getAlarmIcon( alarmType )}
				</svg>
			</svg>
		</svg>
	);
};

export default PlaceWithAlarm;
