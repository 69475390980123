import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { twMerge } from 'tailwind-merge';
import { ReactComponent as LogoImage } from '../assets/logo.svg';
import { AppScreen } from '../shared/constants';

interface LogoProps {
	className?: string;
}

const hasClass = ( searchTarget: string, text?: string ) => {
	return text ? text.search( searchTarget ) > -1 : false;
};

const Logo: React.FC<LogoProps> = ( { className } ) => {
	const history = useHistory();

	const logoClickHandler = useCallback( () => {
		history.push( AppScreen.HOME );
	}, [ history ] );

	const classNameContainsWidth = hasClass( 'w-', className );
	const classNameContainsHeight = hasClass( 'h-', className );

	const logoStyle = twMerge(
		!classNameContainsWidth ? 'w-logo-sm' : undefined,
		!classNameContainsHeight ? 'h-logo-sm' : undefined,
		className,
		'cursor-pointer'
	);

	return <LogoImage onClick={ logoClickHandler } className={ logoStyle } />;
};

export default Logo;
