// Enums
export enum AppScreen {
	LOGIN = '/login',
	HOME = '/',
	DEVICE_DETAILS = '/device-details',
	DEVICE_CONFIGURATION = '/device/configuration',
	ALARM_LOG = '/alarm-log',
	DEVICES = '/devices',
	PLACES = '/places',
	POSITION_DEVICES = '/settings/position-devices',
	WIZARD_POSITION_DEVICES = '/wizard/settings/position-devices',
	CONNECT_DEVICES = '/settings/connect-devices',
	WIZARD_CONNECT_DEVICES = '/wizard/settings/connect-devices',
	DATA_ANALYSIS = '/data-analysis',
	AUDIT_TRAIL = '/audit-trail',
	SUPPORT = '/support',
	GENERAL_SETTINGS = '/settings',
	WIZARD_GENERAL_SETTINGS = '/wizard/settings',
	STACK_MONITOR = '/stack-monitor',
	PLACES_CONFIGURATION = '/settings/places',
	WIZARD_PLACES_CONFIGURATION = '/wizard/settings/places',
	MANAGE_DEVICES = '/settings/manage-devices',
	WIZARD_MANAGE_DEVICES = '/wizard/settings/manage-devices',
	WIZARD_DEVICES = '/wizard/settings/devices',
	SETTINGS_DEVICES = '/settings/devices',
	TESTING_IN_PRODUCTION_SETTINGS = '/settings/testing-in-production',
}

export enum GeneralSettingsUnitsDefinitions {
	EFFICIENCY = 'efficiency',
	EMISSIONS = 'emissions',
	ENERGY = 'energy',
	FLOW = 'flow',
	VOLUME = 'volume',
	TIME = 'time',
	RADIATION = 'radiation',
	RADIO_ACTIVITY_CONCENTRATION = 'radioactivityConcentration',
	RADIO_ACTIVITY_INPUT = 'radioactivityInput',
	SURFACE = 'surface',
	LANGUAGE = 'language',
}

export const historicalAlarmsPageSize = 3;
// Represents the largest possible value of a 32-bit signed integer.
export const int32Max = 2147483647;

// Represents the max height of the filters container for using the calendar with bottom offset
export const deviceFiltersContainerMaxHeight = 950;
export const auditFiltersContainerMaxHeight = 700;
