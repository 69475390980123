export interface Config {
	apiUrl: string;
	realtimeApiUrl: string;
	supportPhone: string;
	supportEmail: string;
	deviceSize: number;
}

const defaultConfig = {
	apiUrl: 'http://vmstest.up2technology.com:80/api/v1',
	realtimeApiUrl: 'http://vmstest.up2technology.com:80/hub/notificationhub',
	supportPhone: '+31 513 481 160',
	supportEmail: 'helpdesknl@comecer.com',
	deviceSize: 36,
};

const windowConfig = window.config ? window.config : {};

const config: Config = {
	apiUrl: windowConfig.apiUrl ?? defaultConfig.apiUrl,
	realtimeApiUrl: windowConfig.realtimeApiUrl ?? defaultConfig.realtimeApiUrl,
	supportPhone: windowConfig.supportPhone ?? defaultConfig.supportPhone,
	supportEmail: windowConfig.supportEmail ?? defaultConfig.supportEmail,
	deviceSize: windowConfig.deviceSize ?? defaultConfig.deviceSize,
};

export default config;
