import React from 'react';
import { twMerge } from 'tailwind-merge';

interface TableRowProps {
	onClick?: () => void;
}

const TableRow: React.FC<TableRowProps> = ( { onClick, children } ) => {
	const styles = twMerge(
		'relative border-b-2 border-transparent bg-primary-content hover:bg-base-300 ',
		onClick && 'cursor-pointer'
	);

	return (
		<tr
			className={ styles }
			onClick={ onClick }
		>
			{children}
		</tr>
	);
};

export default TableRow;
