import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useRecoilValue } from 'recoil';
import { AppScreen } from '../../shared/constants';
import { placesDefinitionAtom } from '../../state/places/placesDefinition';
import FloorPlan from '../floorPlan/FloorPlan';

const Home: React.FC = () => {
	const placesDefinition = useRecoilValue( placesDefinitionAtom );
	const history = useHistory();

	useEffect( () => {
		if (
			placesDefinition &&
			placesDefinition.children &&
			placesDefinition.children.length === 0
		) {
			history.replace( AppScreen.WIZARD_GENERAL_SETTINGS );
		}
	}, [ history, placesDefinition ] );

	return (
		<>
			<FloorPlan />
		</>
	);
};

export default Home;
