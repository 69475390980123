export const testingInProduction = {
	isEnabled: false,
};

export const ENABLE_TESTING_IN_PRODUCTION_TAP_COUNT = 20;

export const EXPIRED_ACCESS_TOKEN =
	'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy5taWNyb3NvZnQuY29tL3dzLzIwMDgvMDYvaWRlbnRpdHkvY2xhaW1zL3JvbGUiOiJNQUlOVEVOQU5DRSIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL25hbWUiOiJtYWludGVuYW5jZTEiLCJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1laWRlbnRpZmllciI6ImQyNTc4Y2NhLTUxZTUtNGNkYi05OTU2LTg5ZDVmMTY1MzA2ZCIsInNlc3Npb25fZXhwIjoiMTY0MTMxMDgyOCIsIm5vbmNlIjoiN2JhZTczMDgtNWFlNC00NjA2LThkOTAtNWVjYzVkOGYxNWRjIiwiZXhwIjoxNjQxMzkwMDE2LCJpc3MiOiJjb21jZXIiLCJhdWQiOiJjb21jZXIifQ.MJt0UsDXvPIq4ZMHQcgo8bhLYbwTGoDbiV5f1p1Jgvw';

export const EXPIRED_REFRESH_TOKEN =
	'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1laWRlbnRpZmllciI6ImQyNTc4Y2NhLTUxZTUtNGNkYi05OTU2LTg5ZDVmMTY1MzA2ZCIsInNlc3Npb25fZXhwIjoiMTY0MTMxMDgyOCIsIm5vbmNlIjoiMzgwY2ZkODAtZmRhZS00YjJkLTkxOGQtNmVlOGIxMzY2OTU2IiwiZXhwIjoxNjQxNDc2NDE2LCJpc3MiOiJjb21jZXIiLCJhdWQiOiJjb21jZXIifQ.OCCs4qeoLiox9jcTNN2i1fAHpMccnF7PL_bIIy-2CcE';
