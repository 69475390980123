import React, { useCallback, useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { StatusOfflineIcon } from '@heroicons/react/solid';
import { useRecoilValue } from 'recoil';
import { RouterIcon, ConnectedIcon } from '../../assets/icons';
import {
	CommonDeviceConfigurationGroup,
	ConfigGroupName,
	Device,
	DeviceConnectionStatus,
} from '../../types';
import { checkIfMACAddressIsValid } from '../../shared/validation';
import Button from '../buttons/Button';
import Alert from '../Alert';
import AddressField from '../configuration/fields/AddressField';
import NumberField from '../configuration/fields/NumberField';
import { useConnectDeviceForm } from '../../hooks/useConnectDeviceForm';
import { int32Max } from '../../shared/constants';
import { getDeviceConnectionStatus } from '../../utils/device';
import { findDeviceDetailsByIdSelector } from '../../state/devices/selectors/findDeviceDetailsById';

interface ConnectDeviceFormProps {
	selectedDevice: Device;
	formEndRef: React.RefObject<HTMLDivElement>;
}

const ConnectDeviceForm = ( {
	selectedDevice,
	formEndRef,
}: ConnectDeviceFormProps ) => {
	const { t } = useTranslation();

	const commonConfiguration = selectedDevice.configuration.find(
		( config ) => config.name === ConfigGroupName.Common
	);
	const macAddressProperty = commonConfiguration?.properties.find(
		( property ) => property.name === 'Connection.Address'
	);

	const portProperty = commonConfiguration?.properties.find(
		( property ) => property.name === 'Connection.PortNumber'
	);

	const {
		onTestConnection,
		onSave,
		control,
		isDirty,
		errors,
		testConnectionLoading,
		saveConfigurationLoading,
		testConnectionStatus,
		testConnectionMessage,
		saveConfigurationStatus,
		saveConfigurationMessage,
		deviceActivationFailed,
	} = useConnectDeviceForm(
		selectedDevice,
		commonConfiguration as CommonDeviceConfigurationGroup,
		{
			'address.value': macAddressProperty?.value,
			'port.value': portProperty?.value,
		}
	);

	const scrollToBottom = useCallback( () => {
		formEndRef?.current?.scrollIntoView( { behavior: 'smooth' } );
	}, [ formEndRef ] );

	useLayoutEffect( () => {
		scrollToBottom();
	}, [ saveConfigurationLoading, testConnectionLoading, scrollToBottom ] );

	const onTestConnectionClick = async () => {
		await onTestConnection();
	};

	const onSaveClick = async () => {
		await onSave();
	};

	const deviceDetails = useRecoilValue(
		findDeviceDetailsByIdSelector( selectedDevice.id )
	);

	const hasConnectionProperties = Boolean(
		macAddressProperty?.value !== undefined && portProperty?.value !== undefined
	);

	const isConnectedMeasurement =
		deviceDetails?.measurementMessage?.isConnected ??
		deviceDetails?.signallingDeviceStatusMessage?.isConnected;

	const connectionStatus = getDeviceConnectionStatus(
		selectedDevice.isActivated,
		isConnectedMeasurement,
		hasConnectionProperties
	);

	const isConnected =
		connectionStatus?.status === DeviceConnectionStatus.ActivatedConnected;
	const noNetworkConfigured =
		connectionStatus?.status === DeviceConnectionStatus.NoNetworkConfigured;

	return (
		<div>
			<h3 className="flex mb-2 font-bold items-center justify-center">
				{selectedDevice.name}
			</h3>
			<div className="flex items-center justify-center">
				{!noNetworkConfigured ? (
					<div
						className={ `flex items-center justify-center text-xs text-base-100 min-h-6 rounded px-2 bg-${ connectionStatus?.color } ml-2` }
					>
						<div className="mx-1 font-bold">{connectionStatus?.text}</div>
						{isConnected && (
							<div className="inline-block">
								<div className="w-5 h-5 opacity-70 justify-center flex items-center">
									<ConnectedIcon className="w-5 h-5" />
								</div>
							</div>
						)}
					</div>
				) : null}
			</div>

			<div className="mb-8">
				<div className="divider mb-6 ">
					<div className="w-10 h-10 opacity-70 justify-center flex items-center">
						<RouterIcon />
					</div>
				</div>

				<div className="flex flex-col">
					<h4 className="text-center">{t( 'Enter connection info' )}</h4>
					<div className="flex flex-col form-control">
						<AddressField
							name="address.value"
							placeholder={ t( 'Enter MAC Address' ) }
							control={ control }
							rules={ {
								required: `${ t( 'MAC address is required' ) }`,
								validate: ( address: string ) => {
									const isValidMacAddress = checkIfMACAddressIsValid( address );

									if ( !isValidMacAddress ) {
										return `${ t( 'Invalid MAC Address' ) }`;
									}
									return true;
								},
							} }
						/>
						<NumberField
							name="port"
							control={ control }
							placeHolder={ t( 'Enter port' ) }
							rules={ {
								min: {
									value: 0,
									message: `${ t( 'Invalid port value' ) }`,
								},
								max: {
									value: int32Max,
									message: `${ t( 'Invalid port value' ) }`,
								},
							} }
							invalidChars={ [ '+', '-', 'e' ] }
							required
						/>
						<Button
							text={ t( 'Test connection' ) }
							className="mx-0 mt-4 w-full px-0 bg-primary"
							onClick={ onTestConnectionClick }
							disabled={ !isDirty || !!Object.keys( errors ).length }
							loading={ testConnectionLoading }
						/>
						{testConnectionStatus && testConnectionMessage ? (
							<Alert
								type={
									testConnectionStatus === 'fail'
										? 'connectionFail'
										: testConnectionStatus
								}
								message={ testConnectionMessage }
								className="mt-4"
							/>
						) : null}
					</div>
				</div>
			</div>

			{testConnectionStatus ? (
				<div className="mb-5 animate-fade-in-down">
					<div className="divider mb-6">
						<div className="w-6 h-6 opacity-70 justify-center flex items-center">
							{testConnectionStatus === 'fail' ? (
								<StatusOfflineIcon />
							) : (
								<ConnectedIcon />
							)}
						</div>
					</div>

					<Button
						text={
							testConnectionStatus === 'fail'
								? t( 'Save anyway' )
								: t( 'Save connection' )
						}
						className="mx-0 w-full bg-primary"
						onClick={ onSaveClick }
						loading={ saveConfigurationLoading }
					/>
					{saveConfigurationStatus && saveConfigurationMessage ? (
						<Alert
							type={ saveConfigurationStatus }
							message={ saveConfigurationMessage }
							className="mt-4"
						/>
					) : null}
					{deviceActivationFailed ? (
						<Alert
							message={ t( 'Device activation failed!' ) }
							type="fail"
							className="mt-2"
						/>
					) : null}
				</div>
			) : null}

			<div ref={ formEndRef } />
		</div>
	);
};

export default ConnectDeviceForm;
