import React, { useCallback } from 'react';
import { NavbarElementProps } from 'react-day-picker';
import { CloseIcon, CloseIconCircle } from '../../assets/icons';
import { getNextMonthStartDate } from '../../utils/time';

export interface CalendarToolbarProps extends Partial<NavbarElementProps> {
	onClose?: () => void;
}

const CalendarToolbar: React.FC<CalendarToolbarProps> = ( {
	localeUtils,
	month,
	onNextClick,
	onPreviousClick,
	onClose,
} ) => {
	const onNextMonthClick = useCallback( () => {
		if ( onNextClick ) {
			onNextClick();
		}
	}, [ onNextClick ] );
	const onPreviousMonthClick = useCallback( () => {
		if ( onPreviousClick ) {
			onPreviousClick();
		}
	}, [ onPreviousClick ] );

	const months = localeUtils?.getMonths();

	const firstMonth = month && months && months[month.getMonth()];
	const secondMonth =
		month && months && months[getNextMonthStartDate( month ).getMonth()];
	const firstYear = month?.getFullYear();
	const secondYear = month && getNextMonthStartDate( month ).getFullYear();

	return (
		<>
			<div className="flex w-full text-selected relative">
				<button
					className="absolute right-2 top-1/4 border-3 border-base-700 h-5 w-5 flex justify-center items-center cursor-pointer"
					onClick={ onClose }
				>
					<div className="font-bold text-xs text-base-600">
						<CloseIconCircle size={ 24 } />
					</div>
				</button>
				<div className="w-1/2 flex justify-center items-center gap-4 border-b border-selected mx-2 py-2">
					<button
						onClick={ onPreviousMonthClick }
						className="font-bold text-md absolute left-12"
					>
						{'<'}
					</button>
					<div className="font-bold">{`${ firstMonth } - ${ firstYear }`}</div>
				</div>
				<div className="w-1/2  flex justify-center items-center gap-4 border-b border-selected mx-2 py-2">
					<div className="font-bold">{`${ secondMonth } - ${ secondYear }`}</div>
					<button
						onClick={ onNextMonthClick }
						className="font-bold text-md absolute right-12"
					>
						{'>'}
					</button>
				</div>
			</div>
			<div className="w-divider bg-base-302 h-full absolute top-0 left-1/2 right-auto" />
		</>
	);
};

export default CalendarToolbar;
