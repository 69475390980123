import React from 'react';
import { useRecoilValue } from 'recoil';
import { findDeviceDetailsByIdSelector } from '../../state/devices/selectors/findDeviceDetailsById';
import { roundMeasurement } from '../../utils/device';
import * as floorplanConfig from './floorplanConfig';

interface FloorPlanDeviceMeasurementProps {
	deviceId: string;
	positionX: number;
	positionY: number;
}

const FloorPlanDeviceMeasurement = ( {
	deviceId,
	positionX,
	positionY,
}: FloorPlanDeviceMeasurementProps ) => {
	const deviceDetails = useRecoilValue( findDeviceDetailsByIdSelector( deviceId ) );
	const measurement = deviceDetails?.measurementMessage?.measurements[0];

	return (
		<text
			x={ positionX }
			y={ positionY }
			fontSize={ floorplanConfig.DEVICE_TEXT_SIZE }
			className="font-bold fill-white"
			dominantBaseline="middle"
			textAnchor="middle"
		>
			{measurement ? (
				<>
					<tspan x={ positionX } dy="-0.5em">
						{measurement.value !== undefined
							? roundMeasurement( measurement.value )
							: '-'}
					</tspan>
					<tspan x={ positionX } dy="1.2em">
						{measurement.unit ?? ''}
					</tspan>
				</>
			) : null}
		</text>
	);
};

export default FloorPlanDeviceMeasurement;
