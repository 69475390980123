import React from 'react';
import { Link } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { AppScreen } from '../../../shared/constants';

import { AlarmListItemIcon } from '../../alarms/AlarmListItemIcon';
import { Alarm, AlarmSeverityEnum } from '../../../types';
import { getHighestPriorityAlarm } from '../../../utils/alarms';
import { getContainerStyleByAlarmSeverity } from '../helpers';

export interface SmallTileProps {
	id: string;
	name: string;
	alarms: Array<Alarm>;
}

const SmallTile: React.FC<SmallTileProps> = ( { id, name, alarms } ) => {
	const highestPriorityAlarm = getHighestPriorityAlarm( alarms );
	const alarmSeverity = highestPriorityAlarm?.alarmSeverity;
	const containerStyles = twMerge(
		'relative flex border-base-700 rounded mb-2 px-2 min-w-stack-m h-stack-m',
		getContainerStyleByAlarmSeverity( alarmSeverity ),
		highestPriorityAlarm && 'text-base-100'
	);

	return (
		<div className={ containerStyles }>
			<Link
				className="font-semibold text-xl cursor-pointer text-left"
				to={ `${ AppScreen.STACK_MONITOR }/${ id }` }
			>
				<h3 className="font-light text-base leading-7">
					<div className="flex items-center gap-3">
						{name}
						{highestPriorityAlarm && (
							<AlarmListItemIcon type={ highestPriorityAlarm.alarmSeverity } />
						)}
					</div>
				</h3>
			</Link>
		</div>
	);
};

export default SmallTile;
