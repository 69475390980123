import i18n from '../i18n';

export const getTranslatedConfigurationProperty = ( property: string ) => {
	if ( !property ) {
		return '';
	}

	// translatedPropertiesMap should be inside the function body otherwise not working
	const translatedPropertiesMap: Record<string, string> = {
		'Connection.Type': i18n.t( 'Connection.Type' ),
		'Connection.Address': i18n.t( 'Connection.Address' ),
		'Connection.PortNumber': i18n.t( 'Connection.PortNumber' ),
		PollingInterval: i18n.t( 'PollingInterval' ),
		SignallingDevices: i18n.t( 'SignallingDevices' ),
		High: i18n.t( 'High' ),
		HighHigh: i18n.t( 'HighHigh' ),
		FailTimeout: i18n.t( 'FailTimeout' ),
		Identifier: i18n.t( 'Identifier' ),
		Password: i18n.t( 'Password' ),
		CrossSectionDuct: i18n.t( 'CrossSectionDuct' ),
		UseCustomFlowRate: i18n.t( 'UseCustomFlowRate' ),
		CustomFlowRate: i18n.t( 'CustomFlowRate' ),
		Valid: i18n.t( 'Valid' ),
		Name: i18n.t( 'Name' ),
		BeginEnergy: i18n.t( 'BeginEnergy' ),
		EndEnergy: i18n.t( 'EndEnergy' ),
		SampleDuration: i18n.t( 'SampleDuration' ),
		UseCustomEfficiency: i18n.t( 'UseCustomEfficiency' ),
		CustomEfficiency: i18n.t( 'CustomEfficiency' ),
		HighAlarmLevels: i18n.t( 'HighAlarmLevels' ),
		HighHighAlarmLevels: i18n.t( 'HighHighAlarmLevels' ),
	};

	const translatedProperty = translatedPropertiesMap[property];

	if ( translatedProperty ) {
		return translatedProperty;
	}
	return '';
};
