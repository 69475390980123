import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { AuditTrailItem } from '../../types';
import { getPrettyTimeStamp } from '../../utils/time';
import Button from '../buttons/Button';

interface AuditTrailDetailsProps {
	auditItem: AuditTrailItem | null;
	setAuditTrailId: ( value: React.SetStateAction<AuditTrailItem | null> ) => void;
}

const AuditTrailDetails: React.FC<AuditTrailDetailsProps> = ( {
	auditItem,
	setAuditTrailId,
} ) => {
	const { t } = useTranslation();
	const isOpen = !!auditItem;

	const toggleModal = useCallback( () => {
		setAuditTrailId( ( open ) => ( isOpen ? null : open ) );
	}, [ setAuditTrailId, isOpen ] );

	const auditDeviceDetailsOptions: Array<string> = auditItem
		? Object.keys( auditItem?.deviceAuditDetails.objectNew )
		: [];

	const hasDeviceData = auditDeviceDetailsOptions.length > 0;

	return (
		<>
			<input
				type="checkbox"
				className="modal-toggle audit-trail-modal"
				checked={ isOpen }
				onChange={ toggleModal }
			/>
			<div className="modal p-72 max-h-full py-6">
				<div className="modal-box rounded-xl relative flex flex-col max-h-full max-w-full px-2 pt-4 pb-2">
					<div className="flex">
						<h2 className="font-semibold text-base-700 text-xl flex-1 px-6">
							{t( 'Audit trail details' )}
						</h2>
					</div>
					<div className="flex-1 px-8 scrollbar-thumb-scrollbar scrollbar-track-scrollbar-default-bg scrollbar-thin">
						<div>
							<div className="flex flex-col gap-1">
								<div className="divider" />
								<h3 className="font-semibold text-base-700 text-base flex-1">
									{t( 'General details' )}
								</h3>
								<div className="flex flex-1 w-full px-1 text-base gap-2">
									<div className="text-base-700 font-bold max-w-10">ID:</div>
									<div className="text-base-600 flex-1">
										{auditItem?.id ?? '-'}
									</div>
								</div>
								<div className="flex flex-1 w-full px-1 text-base gap-2">
									<div className="text-base-700 font-bold max-w-10">
										{t( 'Message' )}
									</div>
									<div className="text-base-600 flex-1">
										{auditItem?.message ?? '-'}
									</div>
								</div>
								<div className="flex flex-1 w-full px-1 text-base gap-2">
									<div className="text-base-700 font-bold max-w-10">
										{t( 'Operator' )}
									</div>
									<div className="text-base-600 flex-1">
										{auditItem?.operator ?? '-'}
									</div>
								</div>
								<div className="flex flex-1 w-full px-1 text-base gap-2">
									<div className="text-base-700 font-bold max-w-10">
										{t( 'Timestamp' )}
									</div>
									<div className="text-base-600 flex-1">
										{getPrettyTimeStamp( auditItem?.timestamp )}
									</div>
								</div>
							</div>
							{hasDeviceData && (
								<div className="flex flex-col gap-2">
									<div className="divider" />
									<h3 className="font-semibold text-base-700 text-base flex-1">
										{`${ t( 'General device details' ) }`}
									</h3>
									<div className="flex flex-1 w-full px-1 text-base gap-2">
										<div className="text-base-700 font-bold max-w-10">
											{t( 'Type' )}
										</div>
										<div className="text-base-600 flex-1">
											{auditItem?.deviceAuditDetails.type
												? auditItem?.deviceAuditDetails.type
												: '-'}
										</div>
									</div>
									<div className="flex flex-1 w-full px-1 text-base gap-2">
										<div className="text-base-700 font-bold max-w-10">
											{t( 'Action' )}
										</div>
										<div className="text-base-600 flex-1">
											{auditItem?.deviceAuditDetails.action ?? '-'}
										</div>
									</div>
									<div className="flex flex-1 w-full px-1 text-base gap-2">
										<div className="text-base-700 font-bold max-w-10">
											{t( 'Sequence' )}
										</div>
										<div className="text-base-600 flex-1">
											{auditItem?.deviceAuditDetails.sequence ?? '-'}
										</div>
									</div>

									<div className="divider" />
									<h3 className="font-semibold text-base-700 text-base flex-1 w-1/3">
										{`${ t( 'Device changes data' ) }`}
									</h3>
									<div className="scrollbar scrollbar-thumb-scrollbar scrollbar-track-scrollbar-default-bg scrollbar-thin">
										<table className="table w-full">
											<thead>
												<tr>
													<th>{t( 'Device option' )}</th>
													<th>{t( 'Current value' )}</th>
													<th>{t( 'Old Value' )}</th>
												</tr>
											</thead>
											<tbody>
												{auditDeviceDetailsOptions.map( ( key ) => {
													return (
														<tr key={ key }>
															<td>{key}</td>
															<td>
																{auditItem?.deviceAuditDetails.objectNew[key] ??
																	'-'}
															</td>
															<td>
																{auditItem?.deviceAuditDetails.objectOld[key] ??
																	'-'}
															</td>
														</tr>
													);
												} )}
											</tbody>
										</table>
									</div>
								</div>
							)}
						</div>
					</div>
					<div className="modal-action mx-8">
						<Button
							text={ t( 'Close' ) }
							onClick={ toggleModal }
							className="bg-base-800 w-24"
						/>
					</div>
				</div>
			</div>
		</>
	);
};

export default AuditTrailDetails;
