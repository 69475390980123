import { ErrorCode } from './utils/errorCodes';

export type PlaceType = 'room' | 'zone' | 'area';
export enum PlaceTypeEnum {
	AREA = 'AREA',
	ZONE = 'ZONE',
	ROOM = 'ROOM',
}
export type BackgroundTypeEnum = 'svg' | 'png' | 'color';

export interface DevicePosition {
	deviceId: string;
	position: Location;
}

export interface PlaceDefinition {
	id: string;
	name: string;
	type: PlaceType;
	description: string;
	box: Location[];
	background: string;
	backgroundType: BackgroundTypeEnum;
	backgroundLocation: Location; // absolute location in a plane
	isRoot: boolean; // default false {could introduce parent Id ?}
	devices: DevicePosition[];
	children: PlaceDefinition[]; // children place Ids
	childrenIds: string[];
}

export enum AlarmSeverityEnum {
	CONNECTION = 'Disconnected',
	WARNING = 'Warning',
	ERROR = 'Error',
	ERROR_AND_CONNECTION = 'Error_Disconnected',
	WARNING_AND_CONNECTION = 'Warning_Disconnected',
}

export interface Location {
	x: number;
	y: number;
}

export interface Measurement {
	value: number;
	timestamp: string;
}

export interface Alarm {
	id: string;
	alarmSeverity: AlarmSeverityEnum;
	alarmMessage: string;
	details: string;
	created: string;
	lastUpdated: string;
	deviceId: string;
	deviceName: string;
	currentValue: number;
	peakValue: number;
	unit: string;
	remark: string;
	acknowledged?: string;
	acknowledgedBy?: string | null;
	resolved?: string;
	resolvedBy: any;
	channel?: number; // no longer exists ?
	channelName?: string;
}

// - DEVICE TYPES

export interface Device {
	id: string;
	name: string;
	family: DeviceFamilyType;
	type: DeviceTypeEnum; // this is new field based on the Devices screen. It is not present in the Comecer API Specs (REST and SignalR)

	configuration: DeviceConfiguration; // Could be changed

	isActivated: boolean;
	isConnectionVerified: boolean;

	// NB: These items will likely change (because they're nested in the device configuration)
	measurement?: Measurement;
	lowThreshold?: number;
	lowLowThreshold?: number;
	hiThreshold?: number;
	hiHiThreshold?: number;
	unit?: string;
	location?: string;
	placeId?: string;
}

export enum DeviceFamilyType {
	MEASURING = 'MeasuringDeviceType',
	SIGNALING = 'SignallingDeviceType',
}

export enum DeviceTypeEnum {
	GENERIC = 'Generic',
	VRM_NP = 'VRM-NP',
	VRM_SM = 'VRM-SM',
	VRM_AM = 'VRM-AM',
	VRM_303 = 'VRM-303',
	VRM_SM_GP = 'VRM-SM-GP',
	VMS_LA = 'VMS-LA',
	VMS_RB = 'VMS-RB',
}

// -- DEVICE CONFIGURATION DEFINITIONS

export enum PropertyTypeEnum {
	OnOff = 'OnOff',
	Uint = 'Uint', // unsigned integer
	AlarmHigh = 'AlarmHigh',
	AlarmHighHigh = 'AlarmHighHigh',
	AlarmLow = 'AlarmLow',
	AlarmLowLow = 'AlarmLowLow',
	Number = 'Number',
	Password = 'Password',
	Port = 'Port',
	TimeSpan = 'TimeSpan',
	ArrayOfDevices = 'ArrayOfDevices',
	SignallingDevices = 'SignallingDevices',
	String = 'String',
	Flag = 'Flag',
	NumberArray = 'NumberArray',
	Float = 'Float',
	Double = 'Double',
	Address = 'Address',
	ConnectionType = 'ConnectionType',
	High = 'High',
	HighHigh = 'HighHigh',
}

export interface RangeDefinition {
	unit: string;
	start: number;
	end: number;
}

export interface LengthDefinition {
	unit: string;
	start: number;
	end: number;
}

export interface PropertyDefinition {
	name: string;
	type: PropertyTypeEnum;
	order: number;
	range: RangeDefinition[];
	options: string[];
	length?: LengthDefinition;
	unit?: string;
}

export interface ConfigurationSection {
	name: string;
	order: number;
	minOcc: number;
	maxOcc: number;
	properties: PropertyDefinition[];
}

export interface DeviceDefinition {
	type: string;
	configuration: ConfigurationSection[];
}

// -- DEVICE CONFIGURATION GROUPS
export enum ConfigGroupName {
	Common = 'Common',
	DeviceConfiguration = 'Device Configuration',
	Regions = 'Regions',
}

// -- Common Group
export enum CommonPropertyNameEnum {
	Address = 'Connection.Address',
	PortNumber = 'Connection.PortNumber',
	Type = 'Connection.Type',
	PollingInterval = 'PollingInterval',
}

export enum ConnectionTypeEnum {
	IpAddress = 'IpAddress',
	MacAddress = 'MacAddress',
}

export interface AddressProperty {
	name: CommonPropertyNameEnum.Address;
	value?: string;
	unit?: string;
}

export interface PortNumberProperty {
	name: CommonPropertyNameEnum.PortNumber;
	value?: number;
	unit?: string;
}

export interface PollingIntervalProperty {
	name: CommonPropertyNameEnum.PollingInterval;
	value?: number;
	unit?: string;
}

export interface ConnectionTypeProperty {
	name: CommonPropertyNameEnum.Type;
	value?: ConnectionTypeEnum;
	unit?: string;
}

export type CommonProperty =
	| AddressProperty
	| PortNumberProperty
	| PollingIntervalProperty
	| ConnectionTypeProperty;

export interface CommonDeviceConfigurationGroup {
	name: ConfigGroupName.Common;
	properties: CommonProperty[];
}

// -- Device Configuration Group
export type DeviceConfigurationGroupProperty = {
	name: string;
	value?: any;
	unit?: string;
};

export interface DeviceConfigurationGroup {
	name: ConfigGroupName.DeviceConfiguration;
	properties: Array<DeviceConfigurationGroupProperty>;
}

// -- Device Regions Group
export enum DeviceRegionsGroupPropertyNameEnum {
	Valid = 'Valid',
	Name = 'Name',
	BeginEnergy = 'BeginEnergy',
	EndEnergy = 'EndEnergy',
	SampleDuration = 'SampleDuration',
	UseCustomEfficiency = 'UseCustomEfficiency',
	CustomEfficiency = 'CustomEfficiency',
	HighAlarmLevels = 'HighAlarmLevels',
	HighHighAlarmLevels = 'HighHighAlarmLevels',
}

export type DeviceRegionsGroupProperty = {
	name: DeviceRegionsGroupPropertyNameEnum;
	value?: any;
	unit?: string;
};

export interface DeviceRegionsGroup {
	index: number;
	name: ConfigGroupName.Regions;
	properties: Array<DeviceRegionsGroupProperty>;
}

export type DeviceConfiguration = Array<
CommonDeviceConfigurationGroup | DeviceConfigurationGroup | DeviceRegionsGroup
>;
export interface GraphicsUrls {
	RawMeasurementData?: string;
	ConcatPerMinute?: string;
	ConcatPerDay?: string;
	ConcatPerHour?: string;
	Spectrum?: string;
	CalibrationSpectrum?: string;
	CalibrationCloud?: string;
}
export interface IDeviceMeasurement {
	name: string;
	unitType: string;
	unit: string;
	value: number;
}
export interface IMeasurementMessage {
	deviceType: string;
	measurements: IDeviceMeasurement[];
	timeStamp: string;
	deviceIdentifier: string;
	isConnected: boolean;
	isEnabled: boolean;
}
export interface IMeasuringDeviceDetails {
	deviceId: string;
	lastSeen: string;
	graphicsUrls: GraphicsUrls;
	measurementMessage: IMeasurementMessage;
	signallingDeviceStatusMessage: undefined;
}

export interface ISignallingDeviceStatusMessage {
	deviceType: string;
	timeStamp: string;
	deviceIdentifier: string;
	isConnected: boolean;
	isEnabled: boolean;
}

export interface ISignallingDeviceDetails {
	deviceId: string;
	lastSeen: string;
	graphicsUrls: undefined;
	measurementMessage: undefined;
	signallingDeviceStatusMessage: ISignallingDeviceStatusMessage;
}

export interface ReportAlarmsRequest {
	periodFrom?: string;
	periodTo?: string;
	message?: string;
	location?: string;
	deviceId: string;
}

export interface ReportAuditRequest {
	applicationType?: string;
	operator?: string;
	from?: string;
	to?: string;
	deviceAuditData?: string;
}

export interface ReportDeviceDataRequest {
	from?: string;
	to?: string;
	deviceId?: string;
}

export interface AuditTrailRequest {
	applicationType?: string;
	message?: string;
	operator?: string;
	from?: string;
	to?: string;
	deviceAuditData?: string;
	page?: number;
	pageSize?: number;
}
export interface DeviceAuditDetails {
	action: string;
	type: string;
	sequence: number;
	objectNew: {
		[key: string]: string;
	};
	objectOld: {
		[key: string]: string;
	};
}

export interface AuditTrailItem {
	id: number;
	uniqueMessageId: string;
	source: string;
	message: string;
	operator: string;
	timestamp: string;
	deviceAuditDetails: DeviceAuditDetails;
}

export interface IPaging {
	pageSize: number;
	totalCount: number;
	hasNextPage: boolean;
	hasPrevPage: boolean;
	totalPages: number;
	currentPage: number;
}

export enum ChannelNameEnum {
	Region1 = 'Region1',
	Region2 = 'Region2',
	Region3 = 'Region3',
	AlarmLevel1 = 'AlarmLevel1', // Disconnected
}

export enum UserRolesEnum {
	SUPERVISOR = 'SUPERVISOR',
	MAINTENANCE = 'MAINTENANCE',
}

export interface ApiErrorResponse {
	type: string;
	title: string;
	status: ErrorCode;
	detail: string;
	instance: string;
	errors: {
		[key: string]: [string];
	};
}

export interface DeviceAggregatedData {
	deviceId: string;
	year: number;
	volume: number;

	averageFlow: number;
	averageRegion1Unit: string;
	averageRegion1Value: number;
	averageRegion2Unit: string;
	averageRegion2Value: number;
	averageRegion3Unit: string;
	averageRegion3Value: number;
}

export interface LicenseData {
	licenseFound: boolean;
	isExpired: boolean;
	expirationDate: string;
}

export interface FloorPlanDevice {
	placeId: string;
	deviceId: string;
	position: Location;
}

export enum DeviceConnectionStatus {
	ActivatedConnected = 'activated-connected',
	ActivatedDisconnected = 'activated-disconnected',
	NotActivatedNetworkConfigured = 'not-activated-network-configured',
	NoNetworkConfigured = 'no-network-configured',
}
