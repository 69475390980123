import { selectorFamily } from 'recoil';
import { DeviceTypeEnum } from '../../../types';
import { deviceDefinitionStateAtom } from '../deviceDefinitions';

export const findDeviceDefinition = selectorFamily( {
	key: 'findDeviceDefinition',
	get:
		( type: DeviceTypeEnum | undefined ) => ( { get } ) => {
			const definitions = get( deviceDefinitionStateAtom );
			return definitions.find( ( definition ) => definition.type === type );
		},
	cachePolicy_UNSTABLE: {
		// Only store the most recent set of dependencies and their values
		eviction: 'lru',
		maxSize: 16,
	},
} );
