import React, {
	useCallback,
	useEffect,
	useLayoutEffect,
	useState,
} from 'react';
import { FieldValues, useForm, FormProvider } from 'react-hook-form';
import { useRecoilValue } from 'recoil';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import {
	ConfigurationSection,
	DeviceConfiguration,
	Device,
	CommonDeviceConfigurationGroup,
	ConfigGroupName,
	DeviceConfigurationGroup,
	DeviceRegionsGroup,
	DeviceRegionsGroupPropertyNameEnum,
	PropertyTypeEnum,
} from '../../types';
import Button from '../buttons/Button';
import ConnectDeviceModal from './ConnectDeviceModal';
import { CheckIcon, CloseIcon, SettingsIcon } from '../../assets/icons';
import {
	getConfigGroupPropertyValue,
	getConfigGroupPropertyDefinition,
} from '../../utils/device';
import api from '../../middleware/api';
import Alert from '../Alert';
import CommonGroup from './groups/CommonGroup';
import DeviceConfigurationGroupComponent from './groups/DeviceConfigurationGroup';
import RegionsGroup from './groups/RegionsGroup';
import CheckBoxesField from './fields/CheckBoxesField';
import { AppScreen } from '../../shared/constants';
import { userHasRoleSelector } from '../../state/auth/selectors/userHasRole';
import { useRefreshDeviceDetails } from '../../hooks/useRefreshDeviceDetails';
import { useRefreshDevices } from '../../hooks/useRefreshDevices';

export interface DeviceConfigurationFormProps {
	device: Device;
	configurationDefinitions: ConfigurationSection[];
	signallingDevices?: Device[];
	threeCols?: boolean;
	formEndRef: React.RefObject<HTMLDivElement>;
}

const DeviceConfigurationForm: React.FC<DeviceConfigurationFormProps> = ( {
	device,
	configurationDefinitions,
	signallingDevices,
	threeCols,
	formEndRef,
} ) => {
	const history = useHistory();
	const { t } = useTranslation();
	const refreshDevices = useRefreshDevices();
	const methods = useForm( {
		mode: 'onBlur',
		reValidateMode: 'onBlur',
	} );

	const {
		handleSubmit,
		trigger,
		resetField,
		watch,
		formState: { errors, dirtyFields, isSubmitting },
	} = methods;
	// We don't use the built-in isDirty field from the formState,
	// since it is actually isTouched (set to true on focus->blur)
	// https://github.com/react-hook-form/react-hook-form/issues/3213
	const isDirty = !!Object.keys( dirtyFields ).length;

	// state
	const [ isConnectModalOpen, setIsConnectModalOpen ] = useState( false );
	const [ updateSuccess, setUpdateSuccess ] = useState<boolean>();
	const [ updateFail, setUpdateFail ] = useState<boolean>();
	const [ activationFailed, setActivationFailed ] = useState<boolean>();

	const {
		id: deviceId,
		name: deviceName,
		type: deviceType,
		configuration,
	} = device;

	// will trigger revalidation on related fields (cross-field validation)
	useEffect( () => {
		const subscription = watch( ( value, { name } ) => {
			const group = name?.split( '.' )[0]; // i.e. Region-2.EndEnergy.value, Common.PollingInterval.unit
			const propName = name?.split( '.' )[1];

			if (
				name?.includes( PropertyTypeEnum.High ) ||
				( name?.includes( PropertyTypeEnum.HighHigh ) && group )
			) {
				trigger( [
					`${ group }.${ PropertyTypeEnum.High }`,
					`${ group }.${ PropertyTypeEnum.HighHigh }`,
				] );
			}
			if (
				name?.includes( DeviceRegionsGroupPropertyNameEnum.BeginEnergy ) ||
				( name?.includes( DeviceRegionsGroupPropertyNameEnum.EndEnergy ) && group )
			) {
				trigger( [
					`${ group }.${ DeviceRegionsGroupPropertyNameEnum.BeginEnergy }`,
					`${ group }.${ DeviceRegionsGroupPropertyNameEnum.EndEnergy }`,
				] );
			}
			if (
				name?.includes( DeviceRegionsGroupPropertyNameEnum.HighAlarmLevels ) ||
				( name?.includes(
					DeviceRegionsGroupPropertyNameEnum.HighHighAlarmLevels
				) &&
					group )
			) {
				trigger( [
					`${ group }.${ DeviceRegionsGroupPropertyNameEnum.HighAlarmLevels }`,
					`${ group }.${ DeviceRegionsGroupPropertyNameEnum.HighHighAlarmLevels }`,
				] );
			}
			// revalidate related field on unit change
			if ( name?.includes( 'unit' ) && group ) {
				const configPropName = name.split( '.' )[1];
				trigger( `${ group }.${ configPropName }` );
			}

			if (
				name?.includes( 'UseCustomEfficiency' ) &&
				!!group &&
				!!propName &&
				value[group][propName].value === false
			) {
				resetField( `${ group }.CustomEfficiency.value` );
			}
			if (
				name?.includes( 'UseCustomFlowRate' ) &&
				!!group &&
				!!propName &&
				value[group][propName].value === false
			) {
				resetField( `${ group }.CustomFlowRate.value` );
			}
		} );

		return () => subscription.unsubscribe();
	}, [ watch, trigger, resetField ] );

	// Common group
	const commonGroupConfigValues = configuration?.find(
		( group ) => group.name === ConfigGroupName.Common
	);
	const commonDefinitions = configurationDefinitions.find(
		( group ) => group.name === ConfigGroupName.Common
	);

	// Device Configuration group
	const deviceConfigurationGroupValues = configuration?.find(
		( group ) => group.name === ConfigGroupName.DeviceConfiguration
	);
	const deviceConfigurationGroupDefinitions = configurationDefinitions.find(
		( group ) => group.name === ConfigGroupName.DeviceConfiguration
	);

	// Regions group
	const regionsGroupValues =
		configuration?.filter( ( group ) => group.name === ConfigGroupName.Regions ) ||
		[];
	const regionsGroupDefinitions = configurationDefinitions.find(
		( group ) => group.name === ConfigGroupName.Regions
	);

	// Methods
	const updateDeviceConfiguration = useCallback(
		async ( configuration, deviceName ) => {
			return api.devices.update( deviceId, {
				type: deviceType,
				name: deviceName,
				configuration,
			} );
		},
		[ deviceId, deviceType ]
	);

	const refreshCurrentDeviceDetails = useRefreshDeviceDetails();

	const onSubmit = async ( fields: FieldValues ) => {
		if ( updateFail ) {
			setUpdateFail( false );
		}
		if ( updateSuccess ) {
			setUpdateSuccess( false );
		}
		if ( activationFailed ) {
			setActivationFailed( false );
		}

		if (
			Object.keys( errors ).length > 0 ||
			!configuration ||
			!commonGroupConfigValues
		) {
			return;
		}

		// Form state data by groups
		const commonConfigurationGroupState = fields.Common;
		const deviceConfigurationGroupState = fields.DeviceConfiguration;
		// Regions in state - Region-0: {}, Region-1: {}, Region-2: {}
		const regionsConfigurationGroupState = Object.keys( fields )
			.filter( ( group ) => {
				return group.startsWith( 'Region' );
			} )
			.map( ( filteredGroupKey ) => fields[filteredGroupKey] );

		const updatedCommonConfiguration = {
			...commonGroupConfigValues,
			properties: [
				...(
					commonGroupConfigValues as CommonDeviceConfigurationGroup
				).properties.map( ( configProp ) => {
					const propertyDefinition = commonDefinitions?.properties.find(
						( p ) => p.name === configProp.name
					);

					const configPropertyFromState = commonConfigurationGroupState
						? commonConfigurationGroupState[configProp.name]
						: undefined;

					return {
						...configProp,
						value: configPropertyFromState?.value ?? configProp.value,
						unit:
							configPropertyFromState?.unit ??
							configProp?.unit ??
							propertyDefinition?.unit,
					};
				} ),
			],
		};

		// We consider that each device has at least "Common" group in its configuration,
		// others might be missing( "DeviceConfiguration", "Regions" )
		const hasDeviceConfigurationGroup = !!deviceConfigurationGroupDefinitions;
		const hasRegionsGroup = !!regionsGroupDefinitions;
		let updatedConfig: DeviceConfiguration = [
			updatedCommonConfiguration as CommonDeviceConfigurationGroup,
		];

		if ( hasDeviceConfigurationGroup ) {
			const updatedDeviceConfigurationGroup = {
				...deviceConfigurationGroupValues,
				properties: [
					...(
						deviceConfigurationGroupValues as DeviceConfigurationGroup
					).properties.map( ( configProp ) => {
						const propertyDefinition =
							deviceConfigurationGroupDefinitions?.properties.find(
								( p ) => p.name === configProp.name
							);

						const configPropertyFromState = deviceConfigurationGroupState
							? deviceConfigurationGroupState[configProp.name]
							: undefined;

						return {
							...configProp,
							value: configPropertyFromState?.value ?? configProp.value,
							unit:
								configPropertyFromState?.unit ??
								configProp.unit ??
								propertyDefinition?.unit,
						};
					} ),
				],
			};

			updatedConfig = [
				...updatedConfig,
				updatedDeviceConfigurationGroup as DeviceConfigurationGroup,
			];
		}

		if ( hasRegionsGroup ) {
			const updatedRegionsConfigurationGroup =
				regionsConfigurationGroupState.map( ( region, index ) => {
					return {
						name: ConfigGroupName.Regions,
						index,
						properties: Object.keys( region ).map( ( propertyName ) => {
							const existingNuclideRegion = regionsGroupValues[
								index
							]?.properties.find( ( property ) => property.name === propertyName );

							const propertyDefinition =
								regionsGroupDefinitions?.properties.find(
									( p ) => p.name === propertyName
								);

							return {
								name: propertyName,
								value:
									region[propertyName].value ?? existingNuclideRegion?.value,
								unit:
									region[propertyName].unit ??
									existingNuclideRegion?.unit ??
									propertyDefinition?.unit,
							};
						} ),
					};
				} );

			updatedConfig = [
				...updatedConfig,
				...( updatedRegionsConfigurationGroup as Array<DeviceRegionsGroup> ),
			];
		}

		// Submit the updated fields
		const updatedDeviceName = fields.deviceName ?? deviceName;

		try {
			const updateDeviceValidationErrors = await updateDeviceConfiguration(
				updatedConfig,
				updatedDeviceName
			);

			if ( !updateDeviceValidationErrors ) {
				setUpdateSuccess( true );
				try {
					await api.devices.activate( deviceId );
				} catch ( error ) {
					console.warn( {
						msg: 'Device activation failed',
						error,
					} );
					setActivationFailed( true );
				}
				// The Grafana chart urls are not directly available, so we need to refresh the details after activation
				// We need to wait a bit, since it needs to go through all the micro-services
				const waitTime = 5;
				setTimeout( () => {
					refreshCurrentDeviceDetails( deviceId ).catch( ( error ) => {
						console.warn( {
							msg: 'Device details refresh failed',
							error,
						} );
					} );
				}, waitTime * 1000 );

				refreshDevices();
			} else {
				setUpdateFail( true );
			}
		} catch ( error ) {
			setUpdateFail( true );
		}
	};

	const handleSave = handleSubmit( onSubmit );

	const handleOpenModal = () => {
		setIsConnectModalOpen( true );
	};

	const handleCloseModal = () => {
		setIsConnectModalOpen( false );
	};

	// Signalling Devices
	const signallingDevicesDefinition = getConfigGroupPropertyDefinition(
		configurationDefinitions,
		ConfigGroupName.DeviceConfiguration,
		PropertyTypeEnum.SignallingDevices
	);

	const connectedSignallingDevices =
		getConfigGroupPropertyValue(
			configuration,
			ConfigGroupName.DeviceConfiguration,
			PropertyTypeEnum.SignallingDevices
		) || [];

	const userCanEditSettings = useRecoilValue(
		userHasRoleSelector( 'MAINTENANCE' )
	);

	const handleNavigateToLogin = () => history.push( AppScreen.LOGIN );

	const scrollToBottom = useCallback( () => {
		formEndRef?.current?.scrollIntoView( { behavior: 'smooth' } );
	}, [ formEndRef ] );
	useLayoutEffect( () => {
		scrollToBottom();
	}, [ isSubmitting, scrollToBottom ] );

	return (
		<div className="flex flex-col flex-1">
			<div className="flex flex-col flex-1">
				{!threeCols && (
					<h3 className="text-xl font-semibold mt-5 mb-2">
						{t( 'Configuration' )}
					</h3>
				)}
				<FormProvider { ...methods }>
					<div className="flex flex-row">
						<dl className={ `pt-3 pr-6 ${ threeCols ? 'w-1/5' : 'flex-1' }` }>
							<CommonGroup
								deviceName={ deviceName }
								deviceType={ deviceType }
								groupConfiguration={
									commonGroupConfigValues as CommonDeviceConfigurationGroup
								}
								groupDefinitions={ commonDefinitions as ConfigurationSection }
							/>
							{signallingDevicesDefinition ? (
								<div className="mt-4">
									{/* Signaling devices */}
									<h3 className="font-bold">{t( 'Link Signaling Devices' )}</h3>
									{signallingDevices && signallingDevices.length > 0 ? (
										<CheckBoxesField
											control={ methods.control }
											name={ `DeviceConfiguration.${ PropertyTypeEnum.SignallingDevices }` }
											label={ t( 'Link devices' ) }
											selectedOptions={ connectedSignallingDevices }
											options={ signallingDevices }
											errorText={
												errors.DeviceConfiguration?.[
													PropertyTypeEnum.SignallingDevices
												]?.value
													? errors.DeviceConfiguration?.[
														PropertyTypeEnum.SignallingDevices
													  ]?.value.message
													: ''
											}
										/>
									) : (
										<p className="text-xs mt-1 opacity-60">
											{t( 'No signaling devices connected.' )}
										</p>
									)}
								</div>
							) : null}
							<div className="mt-4">
								<Button
									text={ t( 'Configure network' ) }
									onClick={ handleOpenModal }
									icon={ <SettingsIcon /> }
									className="w-full bg-base-100 shadow-none border border-base-302 text-base-content"
								/>
							</div>
						</dl>
						{deviceConfigurationGroupValues ? (
							<dl
								className={ `pt-3 border-l border-base-301 pl-6 ${
									threeCols ? 'w-1/5 pr-6' : 'flex-1'
								}` }
							>
								<DeviceConfigurationGroupComponent
									groupConfiguration={
										deviceConfigurationGroupValues as DeviceConfigurationGroup
									}
									groupDefinitions={
										deviceConfigurationGroupDefinitions as ConfigurationSection
									}
								/>
							</dl>
						) : null}

						{regionsGroupDefinitions ? (
							<dl className="pt-3 border-l border-base-301 pl-6 flex-1">
								<RegionsGroup
									groupDefinitions={ regionsGroupDefinitions }
									groupConfiguration={
										regionsGroupValues as Array<DeviceRegionsGroup>
									}
								/>
							</dl>
						) : null}
					</div>

					{/* Form submit result message */}
					{updateSuccess && (
						<Alert
							message={ t( 'The configuration was successfully updated!' ) }
							type="success"
							className="mt-4"
						/>
					)}
					{updateFail && (
						<Alert
							message={ t( 'Failed to update the configuration!' ) }
							type="fail"
							className="mt-4"
						/>
					)}
					{activationFailed && (
						<Alert
							message={ t( 'Device activation failed!' ) }
							type="fail"
							className="mt-2"
						/>
					)}

					{/* end Form submit result message */}

					<div
						className={ `flex flex-row flex-1 float-right items-end pt-4 ${
							threeCols ? 'w-2/5 self-end pl-5' : ''
						}` }
					>
						<div className={ `flex-1 ${ threeCols ? 'mr-2' : 'mr-3' }` }>
							<Button
								text={
									userCanEditSettings
										? t( 'Save' )
										: `${ t( 'Login as maintenance' ) } >`
								}
								onClick={
									userCanEditSettings ? handleSave : handleNavigateToLogin
								}
								icon={ userCanEditSettings && <CheckIcon /> }
								className="bg-primary"
								loading={ isSubmitting }
								disabled={ !!Object.keys( errors ).length || !isDirty }
							/>
						</div>
						<div className={ `flex-1 ${ threeCols ? 'ml-2' : 'ml-3' }` }>
							<Button
								text={ t( 'Cancel' ) }
								onClick={ () => history.goBack() }
								icon={ <CloseIcon /> }
							/>
						</div>
					</div>
				</FormProvider>
			</div>

			{/* Connect device modal Form */}
			{isConnectModalOpen && (
				<ConnectDeviceModal
					device={ device }
					close={ handleCloseModal }
					commonConfiguration={
						commonGroupConfigValues as CommonDeviceConfigurationGroup
					}
				/>
			)}
			<div ref={ formEndRef } />
		</div>
	);
};

export default DeviceConfigurationForm;
