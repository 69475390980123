import React from 'react';
import { useRecoilValue } from 'recoil';
import { twMerge } from 'tailwind-merge';
import { ConnectedIcon, StatusOfflineIcon } from '../assets/icons';
import { findDeviceDetailsByIdSelector } from '../state/devices/selectors/findDeviceDetailsById';
import { DeviceConnectionStatus } from '../types';
import { getDeviceConnectionStatus } from '../utils/device';

interface StackMonitorDeviceProps {
	id: string;
	name: string;
	isSelected: boolean;
	hasConnectionProperties: boolean;
	isActivated: boolean;
	onClick: ( id: string ) => void;
}

const StackMonitorDeviceItem = ( {
	id,
	name,
	isSelected,
	hasConnectionProperties,
	isActivated,
	onClick,
}: StackMonitorDeviceProps ) => {
	const deviceDetails = useRecoilValue( findDeviceDetailsByIdSelector( id ) );
	const handleOnClick = () => {
		onClick( id );
	};

	const isConnectedMeasurement = deviceDetails?.measurementMessage?.isConnected;

	const connectionStatus = getDeviceConnectionStatus(
		isActivated,
		isConnectedMeasurement,
		hasConnectionProperties
	);

	const hasNetworkConfigured =
		connectionStatus?.status !== DeviceConnectionStatus.NoNetworkConfigured;
	const buttonStyle = twMerge(
		'border border-base-600 rounded px-2.5 py-0.5 border-opacity-50 cursor-pointer relative flex flex-row items-center bg-opacity-10',
		hasNetworkConfigured
			? `border-${ connectionStatus?.color }  border-opacity-100 bg-${ connectionStatus?.color } `
			: 'bg-base-302',
		isSelected ? 'border-opacity-100 border-base-600' : ''
	);
	return (
		<button onClick={ handleOnClick } className={ buttonStyle }>
			<h3
				className={ `font-bold text-xs leading-7 mr-3 ${
					hasNetworkConfigured
						? 'text-base-content'
						: 'text-neutral text-opacity-70'
				}` }
			>
				{name}
			</h3>
			{hasNetworkConfigured ? (
				<div
					className={ `bg-${ connectionStatus?.color }   rounded-full text-xs text-base-100 items-center flex justify-center p-1` }
				>
					<div className="w-3 h-3 opacity-70 flex justify-center items-center">
						{connectionStatus?.status ===
						DeviceConnectionStatus.ActivatedDisconnected ? (
							<StatusOfflineIcon />
							) : (
								<ConnectedIcon />
							)}
					</div>
				</div>
			) : (
				<div className="bg-base-302 rounded-full text-xs text-base-600 items-center flex justify-center p-1">
					<div className="w-3 h-3 opacity-70 flex justify-center items-center">
						<StatusOfflineIcon />
					</div>
				</div>
			)}
		</button>
	);
};

export default StackMonitorDeviceItem;
