import 'react-day-picker/lib/style.css';
import React, { useCallback, useState } from 'react';
import DayPicker, { DateUtils } from 'react-day-picker';
import { DateTime } from 'luxon';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useDetectClickOutside } from 'react-detect-click-outside';
import CalendarToolbar from './CalendarToolbar';
import { CalendarProps, IRange } from './Calendar';
import { AppScreen } from '../../shared/constants';
import { queryBuilder } from '../../utils/strings';
import useCalendarLocalization from '../../hooks/useCalendarLocalization';

const modifiersStyles = {};

export interface FiltersCalendarProps extends CalendarProps {
	firstDayInputIsActive?: boolean;
	routePath?: AppScreen;
}

function addDays( date: Date, days: number ) {
	const result = new Date( date );
	result.setDate( result.getDate() + days );
	return result;
}

function getDates( startDate: Date, stopDate: Date ) {
	let dateArray = [];
	let currentDate: Date = startDate;
	while ( currentDate <= stopDate ) {
		dateArray.push( new Date( currentDate ) );
		currentDate = addDays( currentDate, 1 );
	}
	return dateArray;
}

const FiltersCalendar: React.FC<FiltersCalendarProps> = ( {
	isOpen,
	setIsOpen,
	routePath,
	firstDayInputIsActive = false,
	...rest
} ) => {
	const [ rangeState, setRangeState ] = useState<IRange>( {
		from: undefined,
		to: undefined,
		enteredTo: undefined,
	} );
	const [ currentDate, setCurrentDate ] = useState(
		DateTime.fromJSDate( new Date() ).startOf( 'day' ).toJSDate()
	);

	const history = useHistory();
	const { t } = useTranslation();
	const { localeUtils } = useCalendarLocalization();

	const { from, to, enteredTo } = rangeState;
	const modifiers = firstDayInputIsActive
		? {
			start: from,
			end: enteredTo,
			selection:
					from && enteredTo
						? getDates( addDays( from, 1 ), addDays( enteredTo, -1 ) )
						: [],
		  }
		: {
			start: enteredTo,
			end: to,
			selection:
					to && enteredTo
						? getDates( addDays( enteredTo, 1 ), addDays( to, -1 ) )
						: [],
		  };
	const selectedDays = firstDayInputIsActive
		? [ from, { from, to: enteredTo } ]
		: [ from, { from: enteredTo, to } ];

	const handleResetClick = () => {
		setRangeState( {
			from: undefined,
			to: undefined,
			enteredTo: undefined,
		} );
	};

	const isSelectingFirstDay = ( from?: Date, to?: Date, day?: Date ) => {
		const isBeforeFirstDay = from && day && DateUtils.isDayBefore( day, from );
		const isRangeSelected = from && to;
		return !from || isBeforeFirstDay || isRangeSelected;
	};

	const isSelectingLastDayFirst = ( to?: Date, from?: Date, day?: Date ) => {
		const isAfterLastDay = to && day && DateUtils.isDayAfter( day, to );
		const isRangeSelected = from && to;
		return !to || isAfterLastDay || isRangeSelected;
	};

	const isSelectingFirstNeededInput = firstDayInputIsActive
		? isSelectingFirstDay
		: isSelectingLastDayFirst;

	const checkSelection = useCallback(
		( day: Date ): boolean => {
			const dayToMills = DateTime.fromJSDate( day ).startOf( 'day' ).toMillis();
			const todayToMills = DateTime.fromJSDate( currentDate )
				.startOf( 'day' )
				.toMillis();
			return dayToMills > todayToMills;
		},
		[ currentDate ]
	);

	const firstSelectionInput = firstDayInputIsActive ? from : to;
	const secondSelectionInput = firstDayInputIsActive ? to : from;

	const handleDayClick = ( day: Date ) => {
		if ( ( from && to && day >= from && day <= to ) || checkSelection( day ) ) {
			handleResetClick();
			return;
		}
		if (
			isSelectingFirstNeededInput(
				firstSelectionInput,
				secondSelectionInput,
				day
			)
		) {
			setRangeState( {
				from: firstDayInputIsActive ? day : undefined,
				to: firstDayInputIsActive ? undefined : day,
				enteredTo: undefined,
			} );
		} else {
			setRangeState( ( state ) => ( {
				...state,
				to: firstDayInputIsActive ? day : state.to,
				from: firstDayInputIsActive ? state.from : day,
				enteredTo: day,
			} ) );
		}
	};

	const handleDayMouseEnter = ( day: Date ) => {
		if (
			DateTime.fromJSDate( currentDate ).startOf( 'day' ).toMillis() <
			DateTime.fromMillis( Date.now() ).startOf( 'day' ).toMillis()
		) {
			setCurrentDate(
				DateTime.fromJSDate( currentDate ).plus( { day: 1 } ).toJSDate()
			);
		}
		if (
			!isSelectingFirstNeededInput(
				firstSelectionInput,
				secondSelectionInput,
				day
			) &&
			!checkSelection( day )
		) {
			setRangeState( ( state ) => ( { ...state, enteredTo: day } ) );
		}
	};

	const onClose = useCallback( () => {
		setIsOpen( false );
		setRangeState( { from: undefined, to: undefined, enteredTo: undefined } );
	}, [ setIsOpen ] );

	const ref = useDetectClickOutside( { onTriggered: onClose } );

	const onSelectDate = () => {
		if ( from || to ) {
			const query = queryBuilder( {
				from: from ? DateTime.fromJSDate( from ).toISODate() : '',
				to: to ? DateTime.fromJSDate( to ).toISODate() : '',
			} );
			const location = history.location;
			history.push( `${ location.pathname }?${ query }` );
			onClose();
		}
	};

	const onRenderDay = ( day: Date ) => {
		if ( day.getTime() === from?.getTime() ) {
			return (
				<div className="start-selection text-sm h-8 w-8 text-base-100 flex justify-center items-center bg-selected rounded-full">
					{day.getDate()}
				</div>
			);
		}

		if (
			day.getTime() === enteredTo?.getTime() ||
			day.getTime() === to?.getTime()
		) {
			return (
				<div className="end-selection text-sm h-8 w-8 text-base-100 flex justify-center items-center bg-selected rounded-full">
					{day.getDate()}
				</div>
			);
		}
		return (
			<div className="text-sm h-8 w-8 flex justify-center items-center">
				{day.getDate()}
			</div>
		);
	};

	// eslint-disable-next-line no-nested-ternary
	const selectRangeText = firstDayInputIsActive
		? !to
			? t( 'Select start date only' )
			: t( 'Select date range' )
		: !from
			? t( 'Select end date only' )
			: t( 'Select date range' );

	return isOpen ? (
		<div { ...rest } ref={ ref }>
			<DayPicker
				className="Range"
				numberOfMonths={ 2 }
				fromMonth={ from }
				selectedDays={ selectedDays }
				modifiers={ modifiers }
				onDayClick={ handleDayClick }
				onDayMouseEnter={ handleDayMouseEnter }
				modifiersStyles={ modifiersStyles }
				renderDay={ onRenderDay }
				firstDayOfWeek={ 1 }
				navbarElement={ <CalendarToolbar onClose={ onClose } /> }
				disabledDays={ { after: currentDate } }
				localeUtils={ localeUtils }
			/>
			<div className="w-full flex justify-end  items-start">
				<button
					className={ `bg-base-700 text-base-100 flex p-1 px-2 mb-2 mx-4 text-xs font-bold rounded-md ${
						!to && !from ? 'opacity-50 cursor-default' : ''
					}` }
					onClick={ onSelectDate }
				>
					{!!from || to ? selectRangeText : t( 'Select date' )}
				</button>
			</div>
		</div>
	) : null;
};

export default FiltersCalendar;
