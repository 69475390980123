import { useCallback, useEffect, useState } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import api from '../../middleware/api';
import auth from '../../middleware/auth';
import { isLoggedInState } from '../../state/auth/isLoggedIn';
import { userRolesAtom } from '../../state/auth/userRoles';
import { LoggedOutTooltip } from './LoggedOutTooltip';

const AuthVerifier = () => {
	const [ isLoggedIn, setIsLoggedIn ] = useRecoilState( isLoggedInState );
	const [ showLoggedOutPopup, setShowLoggedOutPopup ] = useState( false );
	const setUserRoles = useSetRecoilState( userRolesAtom );

	useEffect( () => {
		api
			.refreshToken()
			.then( () => {} )
			.catch( () => {} );
	}, [] );

	const dismissPopup = useCallback( () => {
		setShowLoggedOutPopup( false );
	}, [] );

	useEffect( () => {
		if ( isLoggedIn ) {
			setShowLoggedOutPopup( false );
		}
	}, [ isLoggedIn ] );

	useEffect( () => {
		if ( showLoggedOutPopup ) {
			setTimeout( () => {
				setShowLoggedOutPopup( false );
			}, 5 * 1000 );
		}
	}, [ showLoggedOutPopup ] );

	useEffect( () => {
		auth.onIsLoggedInChange( ( isLoggedIn, roles ) => {
			setIsLoggedIn( isLoggedIn );
			setUserRoles( roles );

			if ( isLoggedIn ) {
				setShowLoggedOutPopup( true );
			}
		} );
	}, [ setIsLoggedIn, setUserRoles ] );

	// Show the tooltip when a user was logged in and was logged out afterwards.
	return showLoggedOutPopup && !isLoggedIn ? (
		<LoggedOutTooltip onDismiss={ dismissPopup } />
	) : null;
};

export default AuthVerifier;
