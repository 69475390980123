import { Link, useHistory, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ArrowLeftIcon } from '@heroicons/react/solid';
import { AppScreen } from '../../shared/constants';
import { AlarmIcon, DataAnalysisIcon, PencilIcon } from '../../assets/icons';
import Button from '../buttons/Button';
import { activeAlarmsAtom } from '../../state/alarms/activeAlarms';
import { findDeviceDetailsByIdSelector } from '../../state/devices/selectors/findDeviceDetailsById';
import { findDeviceByIdSelector } from '../../state/devices/selectors/findDeviceById';
import {
	checkDeviceHasConnectionProperties,
	getDeviceConnectionStatus,
	getMeasurementPropertyDisplayValue,
	roundMeasurement,
} from '../../utils/device';
import {
	Alarm,
	AlarmSeverityEnum,
	ChannelNameEnum,
	DeviceAggregatedData,
	DeviceConnectionStatus,
	DeviceFamilyType,
} from '../../types';
import {
	getHighestPriorityAlarm,
	sortAlarmsByPriority,
} from '../../utils/alarms';
import StackMonitorAlarmWrapper from '../stack-monitor/StackMonitorAlarmWrapper';
import StackMonitorRegions from '../stack-monitor/StackMonitorRegions';
import DeviceDetailsAlarmCard from '../alarms/DeviceDetailsAlarmCard';
import api from '../../middleware/api';
import { getPrettyTimeStamp } from '../../utils/time';
import { isLoggedInState } from '../../state/auth/isLoggedIn';
import GrafanaChart from '../GrafanaChart';

interface ParamTypes {
	id: string;
}

const StackMonitor: React.FC = () => {
	let params = useParams<ParamTypes>();
	const activeAlarms = useRecoilValue( activeAlarmsAtom );
	// const activeAlarms:Array<Alarm> = []
	const history = useHistory();
	const { t } = useTranslation();
	const deviceId = params.id;
	const [ deviceAggregatedData, setDeviceAggregatedData ] =
		useState<DeviceAggregatedData>( {} as DeviceAggregatedData );

	const isLoggedInUser = useRecoilValue( isLoggedInState );

	// The device holds the device configuration
	const device = useRecoilValue( findDeviceByIdSelector( deviceId ) );
	const configuration = device?.configuration;

	// The deviceDetails holds the device measurements and Grafana charts
	const deviceDetails = useRecoilValue( findDeviceDetailsByIdSelector( deviceId ) );

	const backButtonClickHandler = useCallback( () => {
		history.goBack();
	}, [ history ] );

	useEffect( () => {
		const currentYear = new Date().getFullYear();
		api.measurements
			.getAggregatedData( deviceId, currentYear )
			.then( ( aggregatedData ) => {
				if ( aggregatedData ) {
					setDeviceAggregatedData( aggregatedData );
				}
			} );
	}, [ deviceId ] );

	const measurementMessage = deviceDetails
		? deviceDetails.measurementMessage
		: null;

	const isConnected = measurementMessage
		? measurementMessage.isConnected
		: false;
	const hasConnectionProperties =
		checkDeviceHasConnectionProperties( configuration );

	const connectionStatus = getDeviceConnectionStatus(
		device?.isActivated,
		isConnected,
		hasConnectionProperties
	);

	const noNetworkConfigured =
		connectionStatus?.status === DeviceConnectionStatus.NoNetworkConfigured;
	const notActivatedNetworkConfigured =
		connectionStatus?.status ===
		DeviceConnectionStatus.NotActivatedNetworkConfigured;

	const timeStamp = measurementMessage?.timeStamp;
	const prettyTimeStamp = getPrettyTimeStamp( timeStamp );

	const measurements = measurementMessage?.measurements;

	const region1 = getMeasurementPropertyDisplayValue(
		measurements,
		ChannelNameEnum.Region1
	);
	const region2 = getMeasurementPropertyDisplayValue(
		measurements,
		ChannelNameEnum.Region2
	);
	const region3 = getMeasurementPropertyDisplayValue(
		measurements,
		ChannelNameEnum.Region3
	);

	const flowSpeed = getMeasurementPropertyDisplayValue(
		measurements,
		'FlowRate'
	);

	const activeAlarmsForCurrentDevice = activeAlarms.filter(
		( alarm ) => alarm.deviceId === deviceId
	);

	const numberOfSelectedDeviceAlarms = activeAlarmsForCurrentDevice.length;

	const disconnectedAlarm = activeAlarmsForCurrentDevice.find(
		( a ) => a.alarmSeverity === AlarmSeverityEnum.CONNECTION
	);

	const highestPriorityAlarm = getHighestPriorityAlarm(
		activeAlarmsForCurrentDevice
	);

	const onEditConfigurationClick = useCallback( () => {
		history.push( `${ AppScreen.DEVICE_CONFIGURATION }/${ deviceId }` );
	}, [ history, deviceId ] );

	const viewDeviceDataClickHandler = useCallback( () => {
		history.push( `${ AppScreen.DATA_ANALYSIS }/devices/${ deviceId }` );
	}, [ history, deviceId ] );

	const onRedirectToAlarmLog = useCallback( () => {
		history.push( `${ AppScreen.ALARM_LOG }/devices/${ deviceId }` );
	}, [ history, deviceId ] );

	const activeAlarmForRegion1 = activeAlarmsForCurrentDevice.find(
		( alarm ) => alarm.channelName === ChannelNameEnum.Region1
	);
	const activeAlarmForRegion2 = activeAlarmsForCurrentDevice.find(
		( alarm ) => alarm.channelName === ChannelNameEnum.Region2
	);
	const activeAlarmForRegion3 = activeAlarmsForCurrentDevice.find(
		( alarm ) => alarm.channelName === ChannelNameEnum.Region3
	);

	const resolveDisabled = false;

	const title = `${ device?.name ?? t( 'Stack monitor' ) } ${
		highestPriorityAlarm ? t( 'alarm details' ) : t( 'details' )
	}`;

	// If we have a single alarm which is Disconnected
	// we visualize it in the StackMonitorAlarmWrapper
	// otherwise filter disconnected alarm from the list and show only alarms for regions
	const shouldIncludeDisconnected = activeAlarmsForCurrentDevice.length === 1;
	let prioritizedRegionsAlarms = sortAlarmsByPriority(
		activeAlarmsForCurrentDevice
	);

	if ( !shouldIncludeDisconnected ) {
		prioritizedRegionsAlarms = prioritizedRegionsAlarms.filter(
			( alarm ) => alarm.alarmSeverity !== AlarmSeverityEnum.CONNECTION
		);
	}

	const isMeasuringDevice =
		!device || device.family === DeviceFamilyType.MEASURING;
	const chartUrlType = 'ConcatPerHour';

	return (
		<div className="w-full relative">
			{/* Content */}
			<div className="flex flex-row h-full ">
				{/* LEFT SIDE */}
				<div className="flex flex-col flex-1 border-r-2 border-base-302 p-6 h-full overflow-auto scrollbar scrollbar-thumb-scrollbar scrollbar-track-scrollbar-default-bg scrollbar-thin">
					<div className="flex items-center text-gray-700 text-2xl font-medium gap-5 mb-10 mt-1">
						<button className="h-6 w-6" onClick={ backButtonClickHandler }>
							<ArrowLeftIcon className="h-full w-full" />
						</button>
						<h1 className="text-gray-700 text-2xl font-medium">{title}</h1>
					</div>
					<div className="flex-1 ">
						<StackMonitorAlarmWrapper
							resolveDisabled={ resolveDisabled }
							alarms={ prioritizedRegionsAlarms }
							configuration={ configuration }
						>
							<StackMonitorRegions
								region1={ region1 }
								region2={ region2 }
								region3={ region3 }
								configuration={ configuration }
								highestPriorityAlarm={ highestPriorityAlarm }
								alarmRegion1={ activeAlarmForRegion1 }
								alarmRegion2={ activeAlarmForRegion2 }
								alarmRegion3={ activeAlarmForRegion3 }
							/>
						</StackMonitorAlarmWrapper>
						{disconnectedAlarm &&
							activeAlarmsForCurrentDevice.length > 1 &&
							device && (
							<div className="mt-2">
								<DeviceDetailsAlarmCard
									deviceId={ device.id }
									selectedDeviceAlarm={ disconnectedAlarm }
									deviceName={ device.name }
									resolveDisabled={ resolveDisabled }
								/>
							</div>
						)}
						{/* 2-nd */}
						<div className="mt-10 ml-6">
							<h2 className="font-bold mb-4">{t( 'Release statistics' )}</h2>
							<div className="flex flex-row justify-between">
								<div className="flex-1">
									<h4>{t( 'This year' )}</h4>
								</div>
								<div className="flex-1 border-l border-base-302 pl-2">
									{deviceAggregatedData?.averageRegion1Value !== undefined
										? roundMeasurement(
											deviceAggregatedData?.averageRegion1Value
										  )
										: '-'}{' '}
									{deviceAggregatedData.averageRegion1Unit}
								</div>
								<div className="flex-1 border-l border-base-302 pl-2">
									{deviceAggregatedData?.averageRegion2Value !== undefined
										? roundMeasurement(
											deviceAggregatedData?.averageRegion2Value
										  )
										: '-'}{' '}
									{deviceAggregatedData.averageRegion2Unit}
								</div>
								<div className="flex-1 border-l border-base-302 pl-2">
									{deviceAggregatedData?.averageRegion3Value !== undefined
										? roundMeasurement(
											deviceAggregatedData?.averageRegion3Value
										  )
										: '-'}{' '}
									{deviceAggregatedData.averageRegion3Unit}
								</div>
							</div>
						</div>

						{/* 3-rd */}
						<div className="flex flex-row justify-between mt-10 ml-6">
							<div className="flex-1">
								<h2 className="font-bold mb-4">{t( 'General' )}</h2>

								<div className="flex flex-row justify-between">
									<div className="flex-1">{t( 'Flow speed' )}</div>
									<div className="flex-1 font-bold border-l border-base-302 pl-2">
										{flowSpeed
											? `${ roundMeasurement( flowSpeed.value ) } ${ flowSpeed.unit }`
											: '-'}
									</div>
								</div>
								<div className="flex flex-row justify-between">
									<div className="flex-1">{t( 'Connection status' )}</div>
									<div className="flex-1 font-bold border-l border-base-302 pl-2">
										<span
											className={ `text-${
												!noNetworkConfigured && !notActivatedNetworkConfigured
													? connectionStatus?.color
													: 'base-content'
											}` }
										>
											{connectionStatus?.text ?? '-'}
										</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				{/* RIGHT SIDE */}
				<div className="flex-1 min-h-full p-6">
					<div className="flex flex-col align-middle h-full">
						<div className="flex-1 overflow-auto scrollbar scrollbar-thumb-scrollbar scrollbar-track-scrollbar-default-bg scrollbar-thin mt-6">
							<div className="h-full flex flex-col align-middle rounded-2.5xl bg-primary-content">
								<GrafanaChart
									deviceId={ deviceId }
									deviceType={ device?.type }
									chartUrlType={ chartUrlType }
									totalAmountAlarmsForDevice={ numberOfSelectedDeviceAlarms }
									isMeasuringDevice={ isMeasuringDevice }
									showSpectrumGraph
									chartStyle="w-full flex-2 rounded-xl"
								/>
							</div>
						</div>
						{/* Buttons */}
						{isLoggedInUser && (
							<div className="mt-10 mr-12 ml-auto w-3/4">
								<div className="flex flex-row mb-2">
									<Button
										text={ t( 'View device data' ) }
										onClick={ viewDeviceDataClickHandler }
										icon={ <DataAnalysisIcon /> }
										className="w-2/4 mr-4 text-primary-content"
									/>
									<Button
										text={ t( 'View alarm log' ) }
										onClick={ onRedirectToAlarmLog }
										icon={ <AlarmIcon /> }
										className="w-2/4  text-primary-content"
									/>
								</div>

								<Button
									text={ t( 'Edit configuration' ) }
									onClick={ onEditConfigurationClick }
									icon={ <PencilIcon /> }
									className="w-full text-primary-content"
								/>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default StackMonitor;
