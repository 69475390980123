import React from 'react';
import { useDragLayer } from 'react-dnd';
import { Location } from '../../types';
import { getElementScreenDimensions } from '../../utils/floorPlan';
import * as floorPlanConfig from './floorplanConfig';
import config from '../../config-loader';

const DRAG_PREVIEW_BOX_COLOR = 'rgba(131, 131, 203,.8)';

export const getDragTargetBoxOffset = (
	mousePositionOffset: Location,
	width: number,
	height: number
) => {
	const xPos = mousePositionOffset.x - width / 2; // center the element
	const yPos = mousePositionOffset.y - height / 2; // position the element above the mouse
	return { x: xPos, y: yPos };
};

const getDragBoxPositionStyles = (
	initialOffset: Location | null,
	clientOffset: Location | null,
	width: number,
	height: number
) => {
	if ( !initialOffset || !clientOffset ) {
		return {
			display: 'none',
		};
	}

	const { x, y } = getDragTargetBoxOffset( clientOffset, width, height );

	const transform = `translate(${ x }px, ${ y }px)`;
	return {
		transform,
		WebkitTransform: transform,
	};
};

const CustomDeviceListItemDragPreview = () => {
	const { isDragging, initialOffset, clientOffset } = useDragLayer(
		( monitor ) => ( {
			isDragging: monitor.isDragging(),
			initialOffset: monitor.getInitialSourceClientOffset(),
			clientOffset: monitor.getClientOffset(),
		} )
	);

	if ( !isDragging ) {
		return null;
	}

	// Uses the hidden element in the floor plan to the get the scaled size of the device rectangle
	const scaledDeviceDimensions = getElementScreenDimensions(
		'floor-plan-device-hidden'
	);

	const deviceSize = config.deviceSize;

	return (
		<div className="fixed left-0 top-0 z-50 pointer-events-none">
			<div
				style={ getDragBoxPositionStyles(
					initialOffset,
					clientOffset,
					scaledDeviceDimensions.width ?? deviceSize,
					scaledDeviceDimensions.height ?? deviceSize
				) }
			>
				<svg
					width={ deviceSize }
					height={ deviceSize }
					overflow="visible"
					id="dragged-device-svg"
				>
					<rect
						x={ 0 }
						y={ 0 }
						width={ scaledDeviceDimensions.height ?? deviceSize }
						height={ scaledDeviceDimensions.width ?? deviceSize }
						fill={ DRAG_PREVIEW_BOX_COLOR }
						rx={ floorPlanConfig.DEVICE_RADIUS }
						ry={ floorPlanConfig.DEVICE_RADIUS }
					/>
				</svg>
			</div>
		</div>
	);
};

export default React.memo( CustomDeviceListItemDragPreview );
