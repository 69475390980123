import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { useHistory, useParams } from 'react-router';
import { saveAs } from 'file-saver';
import { DateTime } from 'luxon';
import DeviceFilters from '../filters/DeviceFilters';
import { AlarmIcon, DataAnalysisIcon } from '../../assets/icons';
import Calendar from '../calendar/Calendar';
import DateRangeInput from '../calendar/DateRangeInput';
import api from '../../middleware/api';
import {
	getGrafanaPeriodUrl,
	getTimeDataQuery,
	TimeOptionType,
} from '../../utils/grafana';
import LoadingSpinner from '../LoadingSpinner';
import { AppScreen } from '../../shared/constants';
import DeviceMeasurementCard from '../DeviceMeasurementCard';
import Button from '../buttons/Button';
import { userHasRoleSelector } from '../../state/auth/selectors/userHasRole';
import { DeviceFamilyType, DeviceTypeEnum } from '../../types';
import { isLoggedInState } from '../../state/auth/isLoggedIn';
import { getSelectedRangeData } from '../../utils/time';
import { activeAlarmsAtom } from '../../state/alarms/activeAlarms';
import useDateRangeFromQuery from '../../hooks/useDateRangeFromQuery';
import GrafanaChart from '../GrafanaChart';
import DataAnalysisRegionsMeasurements from '../DataAnalysisRegionsMeasurements';
import { findDeviceByIdSelector } from '../../state/devices/selectors/findDeviceById';

const timeOptions: Array<TimeOptionType> = [
	'hour',
	'day',
	'week',
	'month',
	'year',
	'custom',
];

interface DataAnalysisParams {
	id: string;
}
export interface FacetButtonProps {
	text: string;
	onClick?: () => void;
}
const FacetButton: React.FC<FacetButtonProps> = ( { text, onClick } ) => {
	return (
		<button
			className="btn rounded btn-sm mr-1 bg-base-301 text-base-800 border-0 hover:text-base-301 capitalize shadow-bsm"
			onClick={ onClick }
		>
			{text}
		</button>
	);
};

const ActiveFacetButton: React.FC<FacetButtonProps> = ( { text, onClick } ) => {
	return (
		<button
			className="btn rounded btn-sm mr-1 bg-primary-focus text-primary-content border-0 hover:text-base-301 capitalize"
			onClick={ onClick }
		>
			{text}
		</button>
	);
};

const DataAnalysis: React.FC = () => {
	const { t } = useTranslation();
	const { id: selectedDeviceId } = useParams<DataAnalysisParams>();
	const history = useHistory();

	const { periodFrom, periodTo } = useDateRangeFromQuery();

	const isLoggedIn = useRecoilValue( isLoggedInState );
	const activeAlarms = useRecoilValue( activeAlarmsAtom );
	const device = useRecoilValue( findDeviceByIdSelector( selectedDeviceId ) );

	const [ selectedTimeOption, setSelectedTimeOption ] =
		useState<TimeOptionType>( 'hour' );
	const [ isCustomRange, setIsCustomRange ] = useState<boolean>( false );
	const [ isLoadingCharts, setIsLoadingCharts ] = useState<boolean>( false );
	const [ isLoadingReportData, setIsLoadingReportData ] =
		useState<boolean>( false );
	const [ isCalendarVisible, setIsCalendarVisible ] = useState( false );

	const selectedDeviceAlarms = activeAlarms.filter(
		( activeAlarm ) => selectedDeviceId && activeAlarm.deviceId === selectedDeviceId
	);
	const numberOfSelectedDeviceAlarms = selectedDeviceAlarms.length;

	const toggleCalendar = useCallback( () => {
		setIsCalendarVisible( ( isVisible ) => !isVisible );
	}, [] );

	const resetUrl = useCallback( () => {
		history.push( history.location.pathname );
	}, [ history ] );

	const onFacetButtonClick = useCallback(
		( option: TimeOptionType ) => {
			setSelectedTimeOption( option );
			setIsLoadingCharts( true );
			setIsCustomRange( option === 'custom' );
			if ( option !== 'custom' ) {
				resetUrl();
			}
		},
		[ resetUrl ]
	);

	const onRedirectToAlarmLog = useCallback( () => {
		if ( selectedDeviceId ) {
			history.push( `${ AppScreen.ALARM_LOG }/devices/${ selectedDeviceId }` );
		}
	}, [ history, selectedDeviceId ] );

	const onDownloadReport = useCallback( async () => {
		setIsLoadingReportData( true );
		try {
			const blob = await api.reporting.device( {
				...getSelectedRangeData( selectedTimeOption, {
					from: periodFrom,
					to: periodTo,
				} ),
				deviceId: selectedDeviceId,
			} );
			/*
				const fileURL = URL.createObjectURL( blob );
				Open the URL on new Window
				window.open( fileURL );
			*/
			saveAs( blob, 'device-report.pdf' );
			setIsLoadingReportData( false );
		} catch ( e ) {
			setIsLoadingReportData( false );
		}
	}, [ selectedDeviceId, selectedTimeOption, periodFrom, periodTo ] );

	const deviceName = device ? device.name : '-';

	const selectedRange =
		periodFrom && periodTo
			? {
				from: DateTime.fromISO( periodFrom ),
				to: DateTime.fromISO( periodTo ),
			  }
			: undefined;

	const chartUrlType = getGrafanaPeriodUrl( {
		timeOption: selectedTimeOption,
		selectedRange,
		deviceType: device?.type,
	} );

	const chartPeriod = getTimeDataQuery( selectedTimeOption, selectedRange );
	const isMeasuringDevice =
		!device || device.family === DeviceFamilyType.MEASURING;

	useEffect( () => {
		if ( periodFrom && periodTo && !isCustomRange ) {
			setSelectedTimeOption( 'custom' );
			setIsCustomRange( true );
		}
	}, [ periodFrom, periodTo, isCustomRange ] );

	const canDownloadReport = !isLoadingReportData;

	const userCanDownloadReports = useRecoilValue(
		userHasRoleSelector( 'SUPERVISOR' )
	);

	const isStackMonitorDevice = device?.type === DeviceTypeEnum.VRM_SM;

	const getFacetButtonOptionText = ( option: TimeOptionType ) => {
		switch ( option ) {
			case 'custom':
				return t( 'custom' );
			case 'hour':
				return t( 'hour' );
			case 'day':
				return t( 'day' );
			case 'week':
				return t( 'week' );
			case 'month':
				return t( 'month' );
			case 'year':
				return t( 'year' );

			default:
				return t( 'custom' );
		}
	};

	const handleOnGrafanaLoad = () => {
		setIsLoadingCharts( false );
	};

	return (
		<div className="w-full flex flex-col">
			<div className="flex h-full">
				<DeviceFilters
					title={ t( 'Data analysis' ) }
					routePath={ AppScreen.DATA_ANALYSIS }
				/>
				<div className="w-px bg-base-301 shadow-left-sided" />
				{selectedDeviceId ? (
					<div className="flex flex-col flex-1 p-12">
						<div className="flex justify-end relative">
							{timeOptions.map( ( option ) => ( selectedTimeOption !== option ? (
								<FacetButton
									text={ getFacetButtonOptionText( option ) }
									onClick={ () => {
										onFacetButtonClick( option );
									} }
									key={ option }
								/>
							) : (
								<ActiveFacetButton
									text={ getFacetButtonOptionText( option ) }
									key={ `active-${ option }` }
								/>
							) ) )}
							{isCalendarVisible ? (
								<div className="fixed top-24 bg-primary-content shadow-dark z-30 pt-2 w-calendar">
									<Calendar
										isOpen={ isCalendarVisible }
										setIsOpen={ setIsCalendarVisible }
									/>
								</div>
							) : null}
						</div>
						{isCustomRange && (
							<div className="flex justify-end gap-2 mt-2 mx-1">
								<div className="min-w-52">
									<DateRangeInput
										className="text-selected"
										dateRangeType="from"
										onControlClick={ toggleCalendar }
									/>
								</div>
								<div className="min-w-52">
									<DateRangeInput
										className="text-selected"
										dateRangeType="to"
										onControlClick={ toggleCalendar }
									/>
								</div>
							</div>
						)}
						<div
							className={ `flex flex-col h-full ${
								isCalendarVisible ? 'opacity-50' : ''
							}` }
						>
							<div className="py-4 flex-1 rounded-xl">
								<div className="relative flex justify-center h-full items-center">
									{isLoadingCharts && (
										<LoadingSpinner containerClass="absolute bg-base-100 h-full w-full rounded-xl" />
									)}

									{device ? (
										<GrafanaChart
											key={ selectedDeviceId }
											deviceId={ device.id }
											deviceType={ device.type }
											chartUrlType={ chartUrlType }
											selectedPeriod={ chartPeriod }
											totalAmountAlarmsForDevice={ numberOfSelectedDeviceAlarms }
											isMeasuringDevice={ isMeasuringDevice }
											onLoad={ handleOnGrafanaLoad }
										/>
									) : null}
								</div>
							</div>
							<div className="flex flex-row">
								<DeviceMeasurementCard
									deviceId={ device?.id }
									title={
										isStackMonitorDevice
											? `${ deviceName } - ${ t( 'General data' ) }`
											: deviceName
									}
									deviceType={ device?.type }
									isActivated={ device?.isActivated }
									configuration={ device?.configuration }
								/>
								<div className="flex-1 flex flex-col justify-end items-end pr-6 gap-2">
									{userCanDownloadReports && (
										<Button
											text={ t( 'Export device data' ) }
											onClick={ onDownloadReport }
											icon={ <DataAnalysisIcon /> }
											className="w-56 mt-1 text-primary-content self-end"
											loading={ isLoadingReportData }
											disabled={ !canDownloadReport }
										/>
									)}
									{isLoggedIn && (
										<Button
											text={ t( 'View alarm log' ) }
											onClick={ onRedirectToAlarmLog }
											icon={ <AlarmIcon /> }
											className=" text-primary-content self-end w-56"
										/>
									)}
								</div>
							</div>

							{isStackMonitorDevice && device ? (
								<DataAnalysisRegionsMeasurements
									deviceId={ device.id }
									configuration={ device.configuration }
								/>
							) : null}
						</div>
					</div>
				) : (
					<div className="flex justify-center items-center w-full rounded-2xl bg-base-301 m-10">
						<p>{t( 'Please select a device!' )}</p>
					</div>
				)}
			</div>
		</div>
	);
};

export default DataAnalysis;
