import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import Button from '../buttons/Button';

const NotFound: React.FC = () => {
	const { t } = useTranslation();
	const history = useHistory();

	const redirectToHome = useCallback( () => {
		history.push( '/' );
	}, [ history ] );

	return (
		<div className="flex h-screen justify-center items-center bg-primary">
			<div className="flex flex-col items-center text-secondary gap-4">
				<div className=" text-8xl font-bold">404</div>
				<div className="w-1/2">{t( 'page-not-found' )}</div>
				<div className="flex w-full gap-2 justify-center">
					<div className="flex w-1/5">
						{' '}
						<Button
							text={ t( 'Home page' ) }
							className="bg-secondary text-primary rounded-sm shadow-none"
							onClick={ redirectToHome }
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default NotFound;
