import { parallelMap } from '../utils/promises';
import {
	Device,
	DeviceDefinition,
	PlaceDefinition,
	Location,
	Alarm,
	IMeasuringDeviceDetails,
	ReportAlarmsRequest,
	ReportAuditRequest,
	AuditTrailRequest,
	DeviceAggregatedData,
	ISignallingDeviceDetails,
	DeviceTypeEnum,
	DeviceFamilyType,
	ReportDeviceDataRequest,
	LicenseData,
} from '../types';
import auth, { UserRole, UserTokens } from './auth';
import config from '../config-loader';
import { queryBuilder } from '../utils/strings';

export interface LoginResponseData {
	accessToken: string;
	refreshToken: string;
	roles: UserRole[];
	username: string;
}

export interface GetHistoricalAlarmsRequestParams {
	page?: number;
	pageSize?: number;
	deviceId?: string;
	location?: string;
	periodFrom?: string;
	periodTo?: string;
}

export interface DeviceDetailsResponse {
	measuringDeviceDetails: IMeasuringDeviceDetails[];
	signallingDeviceDetails: ISignallingDeviceDetails[];
}

export interface SetPowerCommand {
	command: 'SetPower';
	parameterValue: '0' | '1';
}

export const SET_POWER_ON_COMMAND: SetPowerCommand = {
	command: 'SetPower',
	parameterValue: '1',
};

export const SET_POWER_OFF_COMMAND: SetPowerCommand = {
	command: 'SetPower',
	parameterValue: '0',
};

export type Command = SetPowerCommand;

export interface CommandResponse {
	commandResult: boolean;
	deviceConnected: boolean;
	values: any[];
}

type Method = 'GET' | 'POST' | 'DELETE' | 'PUT';

const apiUrl = config.apiUrl;

const endpointsWithPaging = [ 'audittrail', 'alarms/historical' ];

const api = {
	refreshToken: async () => {
		if ( !auth.tokens ) {
			throw new Error( 'AUTH_0001: User not logged in' );
		}

		const response = await fetch( `${ apiUrl }/auth/refresh`, {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json; charset=utf-8',
			},
			body: JSON.stringify( {
				refreshToken: auth?.tokens?.refreshToken,
			} ),
		} );

		// When the user cannot refresh their token we need to log them out.
		if ( !response.ok ) {
			auth.clearTokens();
			throw new Error( `Error refreshing token: ${ response.statusText }` );
		}

		const refreshResponseData = await response.json();

		if ( refreshResponseData.accessToken && refreshResponseData.refreshToken ) {
			auth.updateTokens( {
				accessToken: refreshResponseData.accessToken,
				refreshToken: refreshResponseData.refreshToken,
			} );
		}

		return refreshResponseData;
	},
	authorizedFetch: async ( endpoint: string, method: Method, body?: any ) => {
		if ( !auth.tokens ) {
			throw new Error( 'AUTH_0001: User not logged in' );
		}

		const hasPaging = !!endpointsWithPaging.find(
			( ep ) => ep === endpoint.split( '?' )[0]
		);

		try {
			const requestBody = body ? JSON.stringify( body ) : undefined;

			const response = await fetch( `${ apiUrl }/${ endpoint }`, {
				method,
				headers: {
					'Content-Type': 'application/json; charset=utf-8',
					Accept: 'application/json',
					authorization: `Bearer ${ auth.tokens.accessToken }`,
				},
				body: requestBody,
			} );

			// When the access token expires, the API returns 401
			// We use this to refresh the token and retry the request
			if ( response.status === 401 ) {
				await api.refreshToken();

				const retriedResponse: any = await api.authorizedFetch(
					endpoint,
					method,
					body
				);

				return retriedResponse;
			}

			let data;

			// Parse to JSON only when the response is application/json
			const contentType = response.headers.get( 'content-type' );
			if ( contentType && contentType.indexOf( 'json' ) !== -1 ) {
				data = await response.json();
			}

			if ( contentType && contentType.indexOf( 'pdf' ) !== -1 ) {
				const blob = await response.blob();
				data = new Blob( [ blob ], { type: 'application/pdf' } );
			}

			if ( !response.ok ) {
				if ( data?.status || data?.errors ) {
					const apiError: any = new Error( 'API Error' );
					apiError.code = data?.status;
					apiError.errors = data?.errors;
					throw apiError;
				}
				throw new Error( `Error fetching: ${ endpoint }` );
			}

			const pagingData = hasPaging
				? {
					pageSize: 0,
					totalCount: 0,
					hasNextPage: true,
					hasPrevPage: false,
					totalPages: 1,
					currentPage: 1,
				  }
				: null;

			if ( pagingData ) {
				for ( let pair of ( response as any ).headers.entries() ) {
					if ( pair[0] === 'x-paging-pagesize' ) {
						pagingData.pageSize = Number( pair[1] );
					}
					if ( pair[0] === 'x-paging-totalcount' ) {
						pagingData.totalCount = Number( pair[1] );
					}
					if ( pair[0] === 'x-paging-totalpages' ) {
						pagingData.totalPages = Number( pair[1] );
					}
					if ( pair[0] === 'x-paging-currentpage' ) {
						pagingData.currentPage = Number( pair[1] );
					}
					if ( pair[0] === 'x-paging-hasnextpage' ) {
						pagingData.hasNextPage = pair[1] === 'True';
					}
					if ( pair[0] === 'x-paging-haspreviouspage' ) {
						pagingData.hasPrevPage = pair[1] === 'True';
					}
				}
			}

			return pagingData ? { data, pagingData } : data;
		} catch ( err ) {
			console.log( {
				err,
				msg: 'network error',
			} );
			throw err;
		}
	},
	units: {
		/**
		 * The units definitions (to be used to fill the dropdown options)
		 */
		definitions: {
			get: async () => {
				const response = await fetch( `${ apiUrl }/definitions/units` );
				return response.json();
			},
		},
		/**
		 * The selected units for the instance
		 */
		selection: {
			get: async () => {
				const response = await fetch( `${ apiUrl }/units` );
				return response.json();
			},
			update: async ( unitsData: { [key: string]: string } ) => {
				return api.authorizedFetch( 'units', 'PUT', unitsData );
			},
		},
	},
	places: {
		/**
		 * Get the places definition tree. This
		 */
		get: async ( id: string = 'root', depth: number = 3 ) => {
			const response = await fetch( `${ apiUrl }/places/${ id }?depth=${ depth }` );
			const responseJson: PlaceDefinition = await response.json();

			return responseJson;
		},
		/**
		 * Delete a node (the back-end handles the recursion here)
		 */
		delete: async ( id: string ) => api.authorizedFetch( `places/${ id }`, 'DELETE' ),
		/**
		 * Create a child to a parent node
		 */
		create: ( parentId: string, place: PlaceDefinition ) => api.authorizedFetch( `places/parentId/${ parentId }`, 'POST', place ),
		/**
		 * Update a place by id
		 */
		update: ( id: string, place: PlaceDefinition ) => api.authorizedFetch( `places/${ id }`, 'POST', place ),
		/**
		 * Remove all children from the root and create the provided tree structure
		 */
		forceUpdate: async ( placesDefinition: PlaceDefinition ) => {
			const root = await api.places.get( 'root' );
			if ( root.children.length ) {
				await parallelMap(
					root.children.map( ( child ) => child.id ),
					api.places.delete
				);
			}

			return api.places.createTree( placesDefinition );
		},
		/**
		 * Create the provided tree structure (ids must be set)
		 */
		createTree: async ( tree: PlaceDefinition, parentId: string = 'root' ) => {
			const { children, ...placeNode } = tree;

			await api.places.create( parentId, {
				...placeNode,
				children: [],
			} );

			return parallelMap( children, async ( placeChildNode: PlaceDefinition ) => {
				await api.places.createTree( placeChildNode, placeNode.id );
			} );
		},
		addDevice: async (
			placeId: string,
			deviceId: string,
			devicePositionData?: { position: Location }
		) => api.authorizedFetch(
			`places/${ placeId }/device/${ deviceId }`,
			'POST',
			devicePositionData
		),
		updateDevice: async (
			placeId: string,
			deviceId: string,
			devicePositionData?: { deviceId: string; position: Location }
		) => {
			return api.authorizedFetch(
				`places/${ placeId }/device/${ deviceId }`,
				'PUT',
				devicePositionData
			);
		},
		removeDevice: async ( deviceId: string ) => api.authorizedFetch( `places/device/${ deviceId }`, 'DELETE' ),
	},
	devices: {
		definitions: {
			get: async () => {
				const response = await fetch( `${ apiUrl }/definitions/devices` );
				const responseJson: DeviceDefinition[] = await response.json();

				return responseJson;
			},
		},
		get: async () => {
			const response = await fetch( `${ apiUrl }/devices` );
			const responseJson: Device[] = await response.json();

			return responseJson;
		},
		create: async (
			device: Omit<
			Device,
			| 'id'
			| 'configuration'
			| 'isActivated'
			| 'isConnectionVerified'
			| 'family'
			>
		) => {
			const newDevice: Device = await api.authorizedFetch(
				'devices',
				'POST',
				device
			);

			return newDevice;
		},
		update: (
			deviceId: string,
			device: Omit<
			Device,
			'id' | 'isActivated' | 'isConnectionVerified' | 'family'
			>
		) => api.authorizedFetch( `devices/${ deviceId }`, 'PUT', device ),
		delete: ( deviceId: string ) => api.authorizedFetch( `devices/${ deviceId }`, 'DELETE' ),
		// checkConnection: ( deviceId: string ) => api.authorizedFetch( `devices/${ deviceId }/checkConnection`, 'POST' ),
		checkConnection: async (
			type: DeviceTypeEnum,
			family: DeviceFamilyType,
			address: string,
			protNumber: number,
			connectionType: 'MacAddress' | 'IpAddress'
		) => {
			const params = `${ type }&Family=${ family }&Address=${ address }&PortNumber=${ protNumber }&ConnectionType=${ connectionType }`;
			return api.authorizedFetch(
				`devices/checkConnection?Type=${ params }`,
				'GET'
			);
		},
		command: async ( deviceId: string, command: Command ) => {
			const commandResponse = await api.authorizedFetch(
				`devices/${ deviceId }/command`,
				'POST',
				command
			);

			return commandResponse as CommandResponse;
		},
		activate: ( deviceId: string ) => api.authorizedFetch( `devices/${ deviceId }/activate`, 'POST' ),
		details: {
			get: async () => {
				const response = await fetch( `${ apiUrl }/devices/details` );

				if ( !response.ok ) {
					throw new Error( response.statusText );
				}

				const responseJson: DeviceDetailsResponse = await response.json();

				return responseJson;
			},
			getById: async ( id: string, force: boolean = false ) => {
				try {
					const response = await fetch(
						`${ apiUrl }/devices/${ id }/details?${ force ? 'refresh=true' : '' }`,
						{
							method: 'GET',
							headers: {
								'Content-Type': 'application/json; charset=utf-8',
								Accept: 'application/json',
							},
						}
					);

					if ( !response.ok ) {
						throw new Error( 'Oops..' );
					}

					const data: DeviceDetailsResponse = await response.json();

					return data;
				} catch ( e ) {
					throw new Error( 'Failed' );
				}
			},
		},
	},
	alarms: {
		get: async () => {
			const response = await fetch( `${ apiUrl }/alarms` );
			const responseJson: Alarm[] = await response.json();

			return responseJson;
		},
		getHistorical: async ( requestData?: GetHistoricalAlarmsRequestParams ) => {
			const requestQuery = requestData ? queryBuilder( requestData ) : {};
			const response = await fetch(
				`${ apiUrl }/alarms/historical?${ requestQuery }`
			);
			const responseJson: Alarm[] = await response.json();

			const pagingData = {
				pageSize: 0,
				totalCount: 0,
				hasNextPage: true,
				hasPrevPage: false,
				totalPages: 1,
				currentPage: 1,
			};
			for ( let pair of ( response as any ).headers.entries() ) {
				if ( pair[0] === 'x-paging-pagesize' ) {
					pagingData.pageSize = Number( pair[1] );
				}
				if ( pair[0] === 'x-paging-totalcount' ) {
					pagingData.totalCount = Number( pair[1] );
				}
				if ( pair[0] === 'x-paging-totalpages' ) {
					pagingData.totalPages = Number( pair[1] );
				}
				if ( pair[0] === 'x-paging-currentpage' ) {
					pagingData.currentPage = Number( pair[1] );
				}
				if ( pair[0] === 'x-paging-hasnextpage' ) {
					pagingData.hasNextPage = pair[1] === 'True';
				}
				if ( pair[0] === 'x-paging-haspreviouspage' ) {
					pagingData.hasPrevPage = pair[1] === 'True';
				}
			}

			return { alarms: responseJson, pagingData };
		},
		acknowledge: async ( id: string ) => api.authorizedFetch( `alarms/${ id }/acknowledge`, 'POST' ),
		resolve: async ( { id, reason }: { id: string; reason: string } ) => api.authorizedFetch( `alarms/${ id }/resolve?reason=${ reason }`, 'POST' ),
	},
	auditTrail: {
		get: async ( filters: AuditTrailRequest ) => {
			const queryOptions = {
				...filters,
				page: filters.page || 1,
				pageSize: filters.pageSize || 25,
			};

			const data = await api.authorizedFetch(
				`audittrail?${ queryBuilder<AuditTrailRequest>( queryOptions ) }`,
				'GET'
			);

			return data;
		},
	},
	reporting: {
		alarms: async ( requestData: ReportAlarmsRequest ) => api.authorizedFetch(
			`reporting/alarms/?${ queryBuilder<ReportAlarmsRequest>( requestData ) }`,
			'GET'
		),
		audit: async ( requestData: ReportAuditRequest ) => api.authorizedFetch(
			`reporting/audit/?${ queryBuilder<ReportAuditRequest>( requestData ) }`,
			'GET'
		),
		device: async ( requestData: ReportDeviceDataRequest ) => {
			return api.authorizedFetch(
				`reporting/device/?${ queryBuilder<ReportDeviceDataRequest>(
					requestData
				) }`,
				'GET'
			);
		},
	},
	measurements: {
		getAggregatedData: async (
			deviceId: string,
			year: number
		): Promise<DeviceAggregatedData | undefined> => {
			try {
				const response = await fetch(
					`${ apiUrl }/measurements/aggregateddata?deviceId=${ deviceId }&year=${ year }`
				);

				if ( response.ok ) {
					const responseJson = await response.json();
					return responseJson;
				}

				return undefined;
			} catch ( error ) {
				return undefined;
			}
		},
	},
	license: {
		get: async (): Promise<LicenseData | undefined> => {
			try {
				const response = await fetch( `${ apiUrl }/license` );

				if ( response.ok ) {
					const responseJson = await response.json();
					return responseJson;
				}

				return undefined;
			} catch ( error ) {
				return undefined;
			}
		},
	},
	auth: {
		login: async ( loginData: { username: string; password: string } ) => {
			const response = await fetch( `${ apiUrl }/auth/login`, {
				method: 'POST',
				body: JSON.stringify( loginData ),
				headers: {
					'Content-Type': 'application/json; charset=utf-8',
					Accept: 'application/json',
				},
			} );

			if ( !response.ok ) {
				throw new Error( 'Login failed' );
			}

			const responseJson: LoginResponseData = await response.json();
			const { accessToken, refreshToken, roles } = responseJson;

			auth.updateTokens( { accessToken, refreshToken }, responseJson.roles );

			return responseJson;
		},
		mockedLogin: ( loginData: { username: string; password: string } ) => new Promise<UserTokens>( ( res, rej ) => {
			setTimeout( () => {
				if (
					loginData.username === loginData.password &&
						loginData.username === 'comecer'
				) {
					const tokens = {
						accessToken: 'my_token',
						refreshToken: 'my_refresh_token',
					};

					auth.updateTokens( tokens );
					res( tokens );
				} else {
					rej( new Error( 'Login failed' ) );
				}
			}, 300 );
		} ),
	},
};

export default api;
