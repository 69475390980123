import React from 'react';

const REMOVE_ICON_SIZE = 13;
const REMOVE_ICON_TEXT_SIZE = REMOVE_ICON_SIZE * 0.9;
const REMOVE_ICON_RADIUS = 2;

export const RemoveDeviceButton = ( {
	onClick,
	x,
	y,
}: {
	onClick: () => void;
	x: number;
	y: number;
} ) => (
	<svg
		overflow="visible"
		x={ x }
		y={ y }
		onClick={ onClick }
		width={ REMOVE_ICON_SIZE }
		height={ REMOVE_ICON_SIZE }
	>
		<rect
			x={ 0 }
			y={ 0 }
			className="fill-danger"
			width={ REMOVE_ICON_SIZE }
			height={ REMOVE_ICON_SIZE }
			rx={ REMOVE_ICON_RADIUS }
			ry={ REMOVE_ICON_RADIUS }
		/>
		<text
			x="50%"
			y="58%"
			dominantBaseline="middle"
			textAnchor="middle"
			fill="#fff"
			fontWeight="bold"
			fontSize={ REMOVE_ICON_TEXT_SIZE }
		>
			&#x2715;
		</text>
	</svg>
);
