export const queryBuilder: <T>( queries: {
	[key in keyof T]: string | number;
} ) => string = ( queries ) => Object.entries( queries )
	.filter( ( entry ) => !!entry[1] )
	.map( ( x ) => x.map( ( q: any ) => encodeURIComponent( q ) ).join( '=' ) )
	.join( '&' );

export const truncateText = (
	text?: string,
	max: number = 10,
	after: string = '...'
) => {
	if ( !text ) {
		return '-';
	}

	if ( text.length <= max ) {
		return text;
	}

	return `${ text.slice( 0, max ) }${ after }`;
};
