import { Alarm, AlarmSeverityEnum } from '../types';

export const alarmSeverityScale = {
	[AlarmSeverityEnum.ERROR_AND_CONNECTION]: 1,
	[AlarmSeverityEnum.ERROR]: 2,
	[AlarmSeverityEnum.WARNING_AND_CONNECTION]: 3,
	[AlarmSeverityEnum.WARNING]: 4,
	[AlarmSeverityEnum.CONNECTION]: 5,
};

export const getAlarmWithHigherAlertType = (
	firstAlarm: Alarm,
	secondAlarm: Alarm
) => {
	if (
		alarmSeverityScale[firstAlarm.alarmSeverity] >
		alarmSeverityScale[secondAlarm.alarmSeverity]
	) {
		return secondAlarm;
	}

	return firstAlarm;
};

export const getAlarmWithLowerAlertType = (
	firstAlarm: Alarm,
	secondAlarm: Alarm
) => {
	if (
		alarmSeverityScale[firstAlarm.alarmSeverity] <
		alarmSeverityScale[secondAlarm.alarmSeverity]
	) {
		return secondAlarm;
	}

	return firstAlarm;
};

export const sortAlarmsByPriority = ( alarms: Array<Alarm> ): Array<Alarm> => {
	const sortedAlarms = [ ...alarms ];
	sortedAlarms.sort( ( firstAlarm, secondAlarm ) => {
		const higherAlarm = getAlarmWithHigherAlertType( firstAlarm, secondAlarm );
		if ( higherAlarm.id === firstAlarm.id ) {
			return -1;
		}
		return 1;
	} );
	return sortedAlarms;
};

export const getHighestPriorityAlarm = ( alarms: Array<Alarm> ) => {
	return alarms.length
		? alarms.reduce( ( maxSeverityAlarm, currAlarm ) => {
			return alarmSeverityScale[maxSeverityAlarm.alarmSeverity] <
					alarmSeverityScale[currAlarm.alarmSeverity]
				? maxSeverityAlarm
				: currAlarm;
		  } )
		: undefined;
};
