import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Layout } from './layout/Layout';
import DeviceDetails from './screens/DeviceDetails';
import Devices from './screens/Devices';
import StackMonitor from './screens/StackMonitor';
import { AppScreen } from '../shared/constants';
import Home from './screens/Home';
import AlarmLog from './screens/AlarmLog';
import PositionDevices from './screens/PositionDevices';
import PlacesConfiguration from './screens/PlacesConfiguration';
import FloorPlan from './floorPlan/FloorPlan';
import GeneralSettings from './screens/GeneralSettings';
import ManageDevices from './screens/ManageDevices';
import ConnectDevices from './screens/ConnectDevices';
import DeviceConfiguration from './screens/DeviceConfiguration';
import ConfigureDevices from './screens/ConfigureDevices';
import Login from './screens/Login';
import AuditTrail from './screens/AuditTrail';
import DataAnalysis from './screens/DataAnalysis';
import TestingInProductionSettings from './screens/TestingInProduction';
import Support from './screens/Support';
import NotFound from './screens/NotFound';
import PrivateRoute from './PrivateRoute';

const AppRouter: React.FC = () => {
	return (
		<Router>
			<Switch>
				<Route path={ AppScreen.LOGIN }>
					<Layout hideStackMonitor>
						<Login />
					</Layout>
				</Route>
				{/* Side nav */}
				<Route exact path={ AppScreen.HOME }>
					<Layout
						expandStackMonitor
						canToggleAlarmPanel={ false }
						screenWrapperClassName="flex flex-col "
					>
						<Home />
					</Layout>
				</Route>

				<PrivateRoute exact path={ AppScreen.ALARM_LOG }>
					<Layout
						hideStackMonitor
						showScreenBackground
						screenContentClassName="p-0"
					>
						<AlarmLog />
					</Layout>
				</PrivateRoute>
				<PrivateRoute path={ `${ AppScreen.ALARM_LOG }/devices/:id` }>
					<Layout
						hideStackMonitor
						showScreenBackground
						screenContentClassName="p-0"
					>
						<AlarmLog />
					</Layout>
				</PrivateRoute>

				<PrivateRoute path={ AppScreen.DEVICES }>
					<Layout hideStackMonitor showScreenBackground>
						<Devices />
					</Layout>
				</PrivateRoute>

				<PrivateRoute exact path={ AppScreen.DATA_ANALYSIS }>
					<Layout
						hideStackMonitor
						showScreenBackground
						screenContentClassName="p-0"
					>
						<DataAnalysis />
					</Layout>
				</PrivateRoute>

				<PrivateRoute path={ `${ AppScreen.DATA_ANALYSIS }/devices/:id` }>
					<Layout
						hideStackMonitor
						showScreenBackground
						screenContentClassName="p-0"
					>
						<DataAnalysis />
					</Layout>
				</PrivateRoute>

				<PrivateRoute path={ AppScreen.AUDIT_TRAIL }>
					<Layout
						hideStackMonitor
						showScreenBackground
						screenContentClassName="p-0"
					>
						<AuditTrail />
					</Layout>
				</PrivateRoute>

				<Route path={ AppScreen.SUPPORT }>
					<Layout hideStackMonitor>
						<Support />
					</Layout>
				</Route>
				{/* End side nav */}

				{/* Screens */}
				<Route path={ `${ AppScreen.DEVICE_DETAILS }/:id` }>
					<Layout showScreenBackground screenContentClassName="p-0">
						<DeviceDetails />
					</Layout>
				</Route>
				<Route path={ `${ AppScreen.DEVICE_CONFIGURATION }/:id` }>
					<Layout showScreenBackground screenContentClassName="p-0">
						<DeviceConfiguration />
					</Layout>
				</Route>
				<Route path={ `${ AppScreen.STACK_MONITOR }/:id` }>
					<Layout
						hideStackMonitor
						showScreenBackground
						screenWrapperClassName="pb-0"
						screenContentClassName="p-0 rounded-b-none"
					>
						<StackMonitor />
					</Layout>
				</Route>
				<PrivateRoute exact path={ AppScreen.GENERAL_SETTINGS }>
					<Layout
						hideStackMonitor
						showScreenBackground
						screenContentClassName="py-0"
					>
						<GeneralSettings />
					</Layout>
				</PrivateRoute>
				<PrivateRoute exact path={ AppScreen.WIZARD_GENERAL_SETTINGS }>
					<Layout
						hideStackMonitor
						hideAlarmPanel
						showScreenBackground
						hideNavigation
					>
						<GeneralSettings />
					</Layout>
				</PrivateRoute>
				<Route path={ AppScreen.PLACES_CONFIGURATION }>
					<Layout
						hideStackMonitor
						showScreenBackground
						screenContentClassName="py-0"
					>
						<PlacesConfiguration />
					</Layout>
				</Route>
				<PrivateRoute exact path={ AppScreen.WIZARD_PLACES_CONFIGURATION }>
					<Layout
						hideStackMonitor
						hideAlarmPanel
						showScreenBackground
						hideNavigation
					>
						<PlacesConfiguration />
					</Layout>
				</PrivateRoute>
				<PrivateRoute path={ AppScreen.MANAGE_DEVICES }>
					<Layout
						hideStackMonitor
						showScreenBackground
						screenContentClassName="py-0"
					>
						<ManageDevices />
					</Layout>
				</PrivateRoute>
				<PrivateRoute exact path={ AppScreen.WIZARD_MANAGE_DEVICES }>
					<Layout
						hideStackMonitor
						hideAlarmPanel
						showScreenBackground
						hideNavigation
					>
						<ManageDevices />
					</Layout>
				</PrivateRoute>
				<Route path={ `${ AppScreen.PLACES }/:id` }>
					<Layout canToggleAlarmPanel={ false }>
						<FloorPlan />
					</Layout>
				</Route>
				<PrivateRoute path={ AppScreen.POSITION_DEVICES }>
					<Layout
						hideStackMonitor
						showScreenBackground
						screenContentClassName="py-0"
					>
						<PositionDevices />
					</Layout>
				</PrivateRoute>
				<PrivateRoute path={ AppScreen.WIZARD_POSITION_DEVICES }>
					<Layout
						hideAlarmPanel
						hideStackMonitor
						showScreenBackground
						hideNavigation
					>
						<PositionDevices />
					</Layout>
				</PrivateRoute>
				<PrivateRoute path={ AppScreen.CONNECT_DEVICES }>
					<Layout
						hideStackMonitor
						showScreenBackground
						screenContentClassName="py-0"
					>
						<ConnectDevices />
					</Layout>
				</PrivateRoute>
				<PrivateRoute path={ AppScreen.WIZARD_CONNECT_DEVICES }>
					<Layout
						hideAlarmPanel
						hideStackMonitor
						showScreenBackground
						hideNavigation
					>
						<ConnectDevices />
					</Layout>
				</PrivateRoute>
				<PrivateRoute path={ AppScreen.WIZARD_DEVICES }>
					<Layout
						hideAlarmPanel
						hideStackMonitor
						showScreenBackground
						hideNavigation
					>
						<ConfigureDevices />
					</Layout>
				</PrivateRoute>
				<PrivateRoute path={ AppScreen.SETTINGS_DEVICES }>
					<Layout
						hideStackMonitor
						showScreenBackground
						screenContentClassName="py-0"
					>
						<ConfigureDevices />
					</Layout>
				</PrivateRoute>
				<PrivateRoute path={ AppScreen.TESTING_IN_PRODUCTION_SETTINGS }>
					<Layout hideAlarmPanel hideStackMonitor showScreenBackground>
						<TestingInProductionSettings />
					</Layout>
				</PrivateRoute>
				<Route component={ NotFound } />
			</Switch>
		</Router>
	);
};

export default AppRouter;
