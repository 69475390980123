import React from 'react';
import { useTranslation } from 'react-i18next';

interface PagingProps {
	onPreviousPage: () => void;
	onNextPage: () => void;
	currentPage: number;
	totalPages: number;
}

const Paging: React.FC<PagingProps> = ( {
	onPreviousPage,
	onNextPage,
	currentPage,
	totalPages,
} ) => {
	const { t } = useTranslation();

	return (
		<div className="btn-group shadow-bsm rounded-md">
			<button
				className="btn btn-sm rounded-tl-md rounded-bl-md bg-base-700 border-0"
				onClick={ onPreviousPage }
			>
				{t( 'Prev' )}
			</button>
			<button className="btn btn-sm btn-disabled text-base-301 bg-base-700 w-20 border-0">
				<span>{currentPage}</span>
				<span className="px-1">/</span>
				{totalPages}
			</button>
			<button
				className="btn btn-sm rounded-tr-md rounded-br-md bg-base-700 border-0"
				onClick={ onNextPage }
			>
				{t( 'Next' )}
			</button>
		</div>
	);
};

export default Paging;
