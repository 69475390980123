import React from 'react';
import { useTranslation } from 'react-i18next';
import { CloseIcon } from '../../assets/icons';

export interface LoggedOutTooltipProps {
	onDismiss?: () => void;
}

export const LoggedOutTooltip: React.FC<LoggedOutTooltipProps> = ( {
	onDismiss,
} ) => {
	const { t } = useTranslation();

	return (
		<div className="absolute top-6 right-20 w-64 bg-room p-4 pr-8 pt-4 rounded text-primary shadow z-pop-over">
			<button className="absolute right-2 top-2" onClick={ onDismiss }>
				<CloseIcon className="w-5 h-5" />
			</button>
			<div>
				{t( 'You have been logged out.' )} <br />
			</div>
			{/* <div className="rotate-90 transform absolute right-0 top-6">
				<div className="w-11 overflow-hidden inline-block rotate-45 transform">
					<div className="h-12 bg-danger-disconnected rotate-45 transform origin-top-right" />
				</div>
			</div> */}
		</div>
	);
};
