import React from 'react';

const ScrollableTable: React.FC = ( { children } ) => {
	return (
		<div className="flex-1 overflow-auto scrollbar-thumb-rounded scrollbar-thumb-scrollbar scrollbar-track-scrollbar-transparent-bg scrollbar-thin pr-1">
			<table className="comecer-table relative w-full text-base-800">
				{children}
			</table>
		</div>
	);
};

export default ScrollableTable;
