import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, NavLink } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import {
	AlarmIcon,
	AuditTrailIcon,
	DataAnalysisIcon,
	DeviceIcon,
	SettingsIcon,
	SupportIcon,
	HomeIcon,
	AccountIcon,
} from '../../assets/icons';
import { useAuthActions } from '../../hooks/useAuth';
import { AppScreen } from '../../shared/constants';
import { isLoggedInState } from '../../state/auth/isLoggedIn';
import Logo from '../Logo';

type NavigationItemProps = {
	icon: React.ReactNode;
	linkTo: AppScreen;
	title: string;
	className?: string;
	exact?: boolean;
};

const NavigationItem: React.FC<NavigationItemProps> = ( {
	icon,
	title,
	className = 'text-primary-content active:bg-primary',
	linkTo,
	exact = true,
} ) => (
	<li className={ className }>
		<NavLink exact={ exact } to={ linkTo } className="flex flex-col">
			{icon}
			<span className="text-xs mt-2 block text-center">{title}</span>
		</NavLink>
	</li>
);

export default function SideNavigation() {
	const { t } = useTranslation();
	const isLoggedIn = useRecoilValue( isLoggedInState );
	const { logout } = useAuthActions();

	return (
		<div className="h-screen pt-4 box-border flex relative">
			<ul className="menu overflow-auto pt-3 rounded-tl-lg bg-primary box-border flex flex-col w-19.5 scrollbar  scrollbar-track-scrollbar-transparent-bg scrollbar-thin">
				<li className="text-center text-secondary">
					<div className="flex justify-center pt-1 pb-3">
						<Logo />
					</div>
					{!isLoggedIn ? (
						<Link
							to={ `${ AppScreen.LOGIN }` }
							className="flex flex-col pointer items-center cursor-pointer"
						>
							<AccountIcon />
							<div className="text-xs lowercase mb-2">{t( 'login' )}</div>
						</Link>
					) : (
						<button
							onClick={ () => logout() }
							className="flex flex-col pointer items-center cursor-pointer py-3"
						>
							<AccountIcon />
							<div className="text-xs lowercase mb-2">{t( 'logout' )}</div>
						</button>
					)}
				</li>
				{isLoggedIn && (
					<>
						<div className="flex flex-1 flex-col justify-center">
							<NavigationItem
								title={ t( 'Home' ) }
								icon={ <HomeIcon /> }
								linkTo={ AppScreen.HOME }
							/>
							<NavigationItem
								title={ t( 'Alarm log' ) }
								icon={ <AlarmIcon /> }
								linkTo={ AppScreen.ALARM_LOG }
							/>
							<NavigationItem
								title={ t( 'Devices' ) }
								icon={ <DeviceIcon /> }
								linkTo={ AppScreen.DEVICES }
							/>
							<NavigationItem
								title={ t( 'Data analysis' ) }
								icon={ <DataAnalysisIcon /> }
								linkTo={ AppScreen.DATA_ANALYSIS }
							/>
							<NavigationItem
								title={ t( 'Audit trail' ) }
								icon={ <AuditTrailIcon /> }
								linkTo={ AppScreen.AUDIT_TRAIL }
							/>
							<NavigationItem
								title={ t( 'Support' ) }
								icon={ <SupportIcon /> }
								linkTo={ AppScreen.SUPPORT }
							/>
						</div>

						<NavigationItem
							title={ t( 'Settings' ) }
							icon={ <SettingsIcon /> }
							className="text-secondary font-semibold pb-5"
							linkTo={ AppScreen.GENERAL_SETTINGS }
							exact={ false }
						/>
					</>
				)}
			</ul>
		</div>
	);
}
