import React from 'react';
import {
	Control,
	FieldValues,
	RegisterOptions,
	useController,
} from 'react-hook-form';
import { twMerge } from 'tailwind-merge';

export interface TextFieldProps {
	control: Control<FieldValues, object>;
	name: string;
	label: string;
	placeHolder: string;
	rules?: Exclude<
	RegisterOptions,
	'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'
	>;
	readOnly?: boolean;
	maxLength?: number;
	disabled?: boolean;
	defaultValue?: string;
	index?: number;
}

const TextField: React.FC<TextFieldProps> = ( {
	control,
	name,
	rules,
	label,
	placeHolder,
	readOnly,
	maxLength,
	disabled,
	defaultValue,
	index,
} ) => {
	const {
		field,
		fieldState: { error },
	} = useController( {
		control,
		name,
		defaultValue,
		rules: {
			...rules,
		},
	} );
	const styles = twMerge(
		'input input-md input-bordered disabled:bg-base-300 rounded-md w-full h-9 leading-9',
		error && 'border-danger bg-danger bg-opacity-10',
		readOnly && 'bg-base-300 cursor-not-allowed'
	);

	let id = `configure-${ label }${ index ?? '' }`;

	return (
		<label htmlFor={ id } className="block mt-2 text-sm">
			<span className={ `${ disabled ? 'opacity-40' : '' }` }>{label}</span>
			<div className="flex items-center mt-1 relative">
				<input
					id={ id }
					{ ...field }
					placeholder={ placeHolder }
					type="text"
					className={ styles }
					maxLength={ maxLength }
					disabled={ disabled }
				/>
			</div>
			{!!error && (
				<span className="text-danger text-xs pt-1 block">{error.message}</span>
			)}
		</label>
	);
};

export default TextField;
