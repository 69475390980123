import { useTranslation } from 'react-i18next';
import {
	getConfigGroupPropertyValue,
	getRegionConfigurationPropertyDisplayValue,
	roundMeasurement,
} from '../../utils/device';
import { Alarm, ChannelNameEnum, DeviceConfiguration } from '../../types';
import { AlarmListItemIcon } from '../alarms/AlarmListItemIcon';
import { getPrettyTimeStamp } from '../../utils/time';

interface StackMonitorRegionsProps {
	region1: any;
	region2: any;
	region3: any;
	configuration?: DeviceConfiguration;
	highestPriorityAlarm?: Alarm;
	alarmRegion1?: Alarm;
	alarmRegion2?: Alarm;
	alarmRegion3?: Alarm;
}

const StackMonitorRegions: React.FC<StackMonitorRegionsProps> = ( {
	region1,
	region2,
	region3,
	configuration,
	highestPriorityAlarm,
	alarmRegion1,
	alarmRegion2,
	alarmRegion3,
} ) => {
	const { t } = useTranslation();
	const region1WarningLevel = getRegionConfigurationPropertyDisplayValue(
		configuration,
		ChannelNameEnum.Region1,
		'HighAlarmLevels'
	);
	const region2WarningLevel = getRegionConfigurationPropertyDisplayValue(
		configuration,
		ChannelNameEnum.Region2,
		'HighAlarmLevels'
	);
	const region3WarningLevel = getRegionConfigurationPropertyDisplayValue(
		configuration,
		ChannelNameEnum.Region3,
		'HighAlarmLevels'
	);

	const region1AlarmLevel = getRegionConfigurationPropertyDisplayValue(
		configuration,
		ChannelNameEnum.Region1,
		'HighHighAlarmLevels'
	);
	const region2AlarmLevel = getRegionConfigurationPropertyDisplayValue(
		configuration,
		ChannelNameEnum.Region2,
		'HighHighAlarmLevels'
	);
	const region3AlarmLevel = getRegionConfigurationPropertyDisplayValue(
		configuration,
		ChannelNameEnum.Region3,
		'HighHighAlarmLevels'
	);

	const region1ConfiguredName = getRegionConfigurationPropertyDisplayValue(
		configuration,
		ChannelNameEnum.Region1,
		'Name'
	);
	const region2ConfiguredName = getRegionConfigurationPropertyDisplayValue(
		configuration,
		ChannelNameEnum.Region2,
		'Name'
	);
	const region3ConfiguredName = getRegionConfigurationPropertyDisplayValue(
		configuration,
		ChannelNameEnum.Region3,
		'Name'
	);

	const isRegion1Configured = getConfigGroupPropertyValue(
		configuration,
		ChannelNameEnum.Region1,
		'Valid'
	);
	const isRegion2Configured = getConfigGroupPropertyValue(
		configuration,
		ChannelNameEnum.Region2,
		'Valid'
	);
	const isRegion3Configured = getConfigGroupPropertyValue(
		configuration,
		ChannelNameEnum.Region3,
		'Valid'
	);

	const region1Name = region1ConfiguredName ?? ChannelNameEnum.Region1;
	const region2Name = region2ConfiguredName ?? ChannelNameEnum.Region2;
	const region3Name = region3ConfiguredName ?? ChannelNameEnum.Region3;

	const warningLevelCssClass = highestPriorityAlarm
		? 'text-primary-content opacity-95'
		: 'text-danger-warning';
	const errorLevelCssClass = highestPriorityAlarm
		? 'text-primary-content opacity-95'
		: 'text-danger-error';

	return (
		<div className={ highestPriorityAlarm ? 'font-light' : '' }>
			<div className="flex flex-row justify-between">
				<div className="flex-1 font-bold mb-4">
					<h3>{t( 'Data summary' )}</h3>
				</div>
				<div className="flex-1 font-bold">
					{isRegion1Configured ? region1Name : '-'}
				</div>
				{
					// To maintain a pleasant visual experience we always show
					// the first region, even when there is no data for it.
					// This is the reason we only check for region2 and region3
					// and not for region1 to be defined
					isRegion2Configured ? (
						<div className="flex-1 font-bold">{region2Name}</div>
					) : null
				}
				{isRegion3Configured ? (
					<div className="flex-1 font-bold">{region3Name}</div>
				) : null}
			</div>

			<div className="flex flex-row justify-between">
				<div className="flex-1">
					<h4>{t( 'Current readout' )}</h4>
				</div>
				<div className="flex-1 border-l border-base-302 pl-2 font-bold">
					{region1 ? `${ roundMeasurement( region1.value ) } ${ region1.unit }` : '-'}
				</div>
				{isRegion2Configured ? (
					<div className="flex-1 border-l border-base-302 pl-2 font-bold">
						{region2 ? roundMeasurement( region2.value ) : '-'}{' '}
						{region2?.unit ?? ''}
					</div>
				) : null}
				{isRegion3Configured ? (
					<div className="flex-1 border-l border-base-302 pl-2 font-bold">
						{region3 ? roundMeasurement( region3.value ) : '-'}{' '}
						{region3?.unit ?? ''}
					</div>
				) : null}
			</div>
			<div className="flex flex-row justify-between">
				<div className="flex-1">
					<h4>{t( 'Warning level' )}</h4>
				</div>
				<div
					className={ `flex-1 border-l border-base-302 border-opacity-50 pl-2 ${ warningLevelCssClass }` }
				>
					{region1WarningLevel ?? '-'}
				</div>
				{isRegion2Configured ? (
					<div
						className={ `flex-1 border-l border-base-302 border-opacity-50 pl-2 ${ warningLevelCssClass }` }
					>
						{region2WarningLevel ?? '-'}
					</div>
				) : null}
				{isRegion3Configured ? (
					<div
						className={ `flex-1 border-l border-base-302 border-opacity-50 pl-2 ${ warningLevelCssClass }` }
					>
						{region3WarningLevel ?? '-'}
					</div>
				) : null}
			</div>
			<div className="flex flex-row justify-between">
				<div className="flex-1">
					<h4>{t( 'Alarm level' )}</h4>
				</div>
				<div
					className={ `flex-1 border-l border-base-302 pl-2 border-opacity-50 ${ errorLevelCssClass }` }
				>
					{region1AlarmLevel}
				</div>
				{isRegion2Configured ? (
					<div
						className={ `flex-1 border-l border-base-302 border-opacity-50 pl-2 ${ errorLevelCssClass }` }
					>
						{region2AlarmLevel}
					</div>
				) : null}
				{isRegion3Configured ? (
					<div
						className={ `flex-1 border-l border-base-302 border-opacity-50 pl-2 ${ errorLevelCssClass }` }
					>
						{region3AlarmLevel}
					</div>
				) : null}
			</div>
			<div className="flex flex-row justify-between">
				<div className="flex-1">
					<h4>{t( 'Alarm time' )}</h4>
				</div>
				<div className="flex-1 border-l border-base-302 border-opacity-50  pl-2">
					{getPrettyTimeStamp( alarmRegion1?.created )}
				</div>
				{isRegion2Configured ? (
					<div className="flex-1 border-l border-base-302 border-opacity-50 pl-2">
						{getPrettyTimeStamp( alarmRegion2?.created )}
					</div>
				) : null}
				{isRegion3Configured ? (
					<div className="flex-1 border-l border-base-302 border-opacity-50 pl-2">
						{getPrettyTimeStamp( alarmRegion3?.created )}
					</div>
				) : null}
			</div>
			<div className="flex flex-row justify-between">
				<div className="flex-1">
					<h4>{t( 'Alarm pending' )}</h4>
				</div>
				<div className="flex-1 border-l border-base-302 border-opacity-50 pl-2">
					{alarmRegion1 ? (
						<span
							className={ `w-5 h-5 p-1 bg-base-100 rounded-full text-danger-${ alarmRegion1.alarmSeverity.toLowerCase() } flex justify-center items-center shadow` }
						>
							<AlarmListItemIcon type={ alarmRegion1.alarmSeverity } />
						</span>
					) : null}
				</div>
				{isRegion2Configured ? (
					<div className="flex-1 border-l border-base-302 border-opacity-50 pl-2">
						{alarmRegion2 ? (
							<span
								className={ `w-5 h-5 p-1 bg-base-100 rounded-full text-danger-${ alarmRegion2.alarmSeverity.toLowerCase() } flex justify-center items-center shadow` }
							>
								<AlarmListItemIcon type={ alarmRegion2.alarmSeverity } />
							</span>
						) : null}
					</div>
				) : null}
				{isRegion3Configured ? (
					<div className="flex-1 border-l border-base-302 border-opacity-50 pl-2">
						{alarmRegion3 ? (
							<span
								className={ `w-5 h-5 p-1 bg-base-100 rounded-full text-danger-${ alarmRegion3.alarmSeverity.toLowerCase() } flex justify-center items-center shadow` }
							>
								<AlarmListItemIcon type={ alarmRegion3.alarmSeverity } />
							</span>
						) : null}
					</div>
				) : null}
			</div>
		</div>
	);
};

export default StackMonitorRegions;
