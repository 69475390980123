import React from 'react';
import { useRecoilValue } from 'recoil';
import { Link, useHistory } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { AppScreen } from '../../../shared/constants';
import { findDeviceDetailsByIdSelector } from '../../../state/devices/selectors/findDeviceDetailsById';
import { getRegionConfigurationPropertyDisplayValue } from '../../../utils/device';
import { Alarm, AlarmSeverityEnum } from '../../../types';
import AlarmSummaryInfo from '../AlarmSummaryInfo';
import { getHighestPriorityAlarm } from '../../../utils/alarms';
import { findDeviceByIdSelector } from '../../../state/devices/selectors/findDeviceById';
import { TileIcon } from '../TileIcon';
import {
	getAlarmCurrentValueText,
	getContainerStyleByAlarmSeverity,
} from '../helpers';

export interface LargeTileProps {
	id: string;
	name: string;
	alarms: Array<Alarm>;
}

const LargeTile: React.FC<LargeTileProps> = ( { id, name, alarms } ) => {
	const history = useHistory();
	const monitorDetails = useRecoilValue( findDeviceDetailsByIdSelector( id ) );
	const device = useRecoilValue( findDeviceByIdSelector( id ) );

	const highestPriorityAlarm = getHighestPriorityAlarm( alarms );
	const regionsAlarms = alarms.filter( ( alarm ) => alarm.channelName?.startsWith( 'Region' ) );
	const alarmSeverity = highestPriorityAlarm?.alarmSeverity;

	const containerStyles = twMerge(
		'relative flex border-base-700 px-2 rounded-t-2.5xl text-primary-content bottom-0 -mb-0.5',
		'h-stack-m-large min-w-stack-m-large',
		getContainerStyleByAlarmSeverity( alarmSeverity )
	);

	const shouldUseRadiationIcon = alarmSeverity === AlarmSeverityEnum.ERROR;

	return (
		<Link to={ `${ AppScreen.STACK_MONITOR }/${ id }` }>
			<div className={ containerStyles }>
				<div className="flex flex-col flex-1">
					{highestPriorityAlarm ? (
						<TileIcon
							alarmSeverityType={ highestPriorityAlarm?.alarmSeverity }
							showRadiationIcon={ shouldUseRadiationIcon }
						/>
					) : null}
					<div className="px-2 pt-4">
						<h3 className="font-semibold text-xl text-left">{name}</h3>
						<div className="flex flex-row flex-1">
							{regionsAlarms.map( ( regionAlarm, index ) => {
								const currentValue = getAlarmCurrentValueText(
									regionAlarm.channelName,
									regionAlarm,
									monitorDetails?.measurementMessage
								);

								const regionName = regionAlarm?.channelName
									? getRegionConfigurationPropertyDisplayValue(
										device?.configuration,
										regionAlarm?.channelName,
										'Name'
									  )
									: '-';

								return (
									<AlarmSummaryInfo
										key={ regionAlarm.id }
										title={ regionName }
										currentValue={ currentValue }
										peakValue={ regionAlarm.peakValue }
										unit={ regionAlarm.unit }
										startTime={ regionAlarm.created }
										className={ index > 0 ? 'ml-6' : '' }
									/>
								);
							} )}
						</div>
					</div>
				</div>
			</div>
		</Link>
	);
};

export default LargeTile;
