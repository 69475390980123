import React from 'react';

const defaultIconSize = 'h-6 w-6';

const HomeIcon = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		className={ defaultIconSize }
		fill="currentColor"
		viewBox="0 0 24 24"
	>
		<path d="M0 0h24v24H0V0z" fill="none" />
		<path d="M12 5.69l5 4.5V18h-2v-6H9v6H7v-7.81l5-4.5M12 3L2 12h3v8h6v-6h2v6h6v-8h3L12 3z" />
	</svg>
);

const AlarmIcon = ( { color }: { color?: string } ) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		className={ defaultIconSize }
		fill={ color ? color : 'currentColor' }
		viewBox="0 0 24 24"
	>
		<path d="M12 22c1.1 0 2-.9 2-2h-4c0 1.1.9 2 2 2zm6-6v-5c0-3.07-1.63-5.64-4.5-6.32V4c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v.68C7.64 5.36 6 7.92 6 11v5l-2 2v1h16v-1l-2-2zm-2 1H8v-6c0-2.48 1.51-4.5 4-4.5s4 2.02 4 4.5v6zM7.58 4.08L6.15 2.65C3.75 4.48 2.17 7.3 2.03 10.5h2c.15-2.65 1.51-4.97 3.55-6.42zm12.39 6.42h2c-.15-3.2-1.73-6.02-4.12-7.85l-1.42 1.43c2.02 1.45 3.39 3.77 3.54 6.42z" />
	</svg>
);

const PencilIcon = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			height="24px"
			viewBox="0 0 24 24"
			width="24px"
			fill="currentColor"
		>
			<path d="M0 0h24v24H0z" fill="none" />
			<path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z" />
		</svg>
	);
};

const DeviceIcon = ( { size }: { size?: number } ) => {
	const iconSize = size !== undefined ? `w-${ size } h-${ size }` : defaultIconSize;
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			className={ iconSize }
			fill="currentColor"
			viewBox="0 0 24 24"
			stroke="none"
		>
			<path d="M0 0h24v24H0V0z" fill="none" />
			<path d="M19 15v4H5v-4h14m1-2H4c-.55 0-1 .45-1 1v6c0 .55.45 1 1 1h16c.55 0 1-.45 1-1v-6c0-.55-.45-1-1-1zM7 18.5c-.82 0-1.5-.67-1.5-1.5s.68-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zM19 5v4H5V5h14m1-2H4c-.55 0-1 .45-1 1v6c0 .55.45 1 1 1h16c.55 0 1-.45 1-1V4c0-.55-.45-1-1-1zM7 8.5c-.82 0-1.5-.67-1.5-1.5S6.18 5.5 7 5.5s1.5.68 1.5 1.5S7.83 8.5 7 8.5z" />
		</svg>
	);
};

const AuditTrailIcon = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		className={ defaultIconSize }
		fill="currentColor"
		viewBox="0 0 24 24"
		stroke="none"
	>
		<rect fill="none" height="24" width="24" />
		<path d="M7,3H4v3H2V1h5V3z M22,6V1h-5v2h3v3H22z M7,21H4v-3H2v5h5V21z M20,18v3h-3v2h5v-5H20z M17,6H7v12h10V6z M19,18 c0,1.1-0.9,2-2,2H7c-1.1,0-2-0.9-2-2V6c0-1.1,0.9-2,2-2h10c1.1,0,2,0.9,2,2V18z M15,8H9v2h6V8z M15,11H9v2h6V11z M15,14H9v2h6V14z" />
	</svg>
);

const DataAnalysisIcon = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		className={ defaultIconSize }
		fill="currentColor"
		viewBox="0 0 24 24"
		stroke="none"
	>
		<g>
			<rect fill="none" height="24" width="24" />
			<g>
				<path d="M19,3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2V5C21,3.9,20.1,3,19,3z M19,19H5V5h14V19z" />
				<rect height="5" width="2" x="7" y="12" />
				<rect height="10" width="2" x="15" y="7" />
				<rect height="3" width="2" x="11" y="14" />
				<rect height="2" width="2" x="11" y="10" />
			</g>
		</g>
	</svg>
);

const SupportIcon = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		className={ defaultIconSize }
		fill="currentColor"
		viewBox="0 0 24 24"
		stroke="none"
	>
		<g>
			<rect fill="none" height="24" width="24" />
		</g>
		<g>
			<g>
				<path d="M21,12.22C21,6.73,16.74,3,12,3c-4.69,0-9,3.65-9,9.28C2.4,12.62,2,13.26,2,14v2c0,1.1,0.9,2,2,2h1v-6.1 c0-3.87,3.13-7,7-7s7,3.13,7,7V19h-8v2h8c1.1,0,2-0.9,2-2v-1.22c0.59-0.31,1-0.92,1-1.64v-2.3C22,13.14,21.59,12.53,21,12.22z" />
				<circle cx="9" cy="13" r="1" />
				<circle cx="15" cy="13" r="1" />
				<path d="M18,11.03C17.52,8.18,15.04,6,12.05,6c-3.03,0-6.29,2.51-6.03,6.45c2.47-1.01,4.33-3.21,4.86-5.89 C12.19,9.19,14.88,11,18,11.03z" />
			</g>
		</g>
	</svg>
);

const SettingsIcon = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		className={ defaultIconSize }
		fill="currentColor"
		viewBox="0 0 24 24"
		stroke="none"
	>
		<path d="M0 0h24v24H0V0z" fill="none" />
		<path d="M19.43 12.98c.04-.32.07-.64.07-.98 0-.34-.03-.66-.07-.98l2.11-1.65c.19-.15.24-.42.12-.64l-2-3.46c-.09-.16-.26-.25-.44-.25-.06 0-.12.01-.17.03l-2.49 1c-.52-.4-1.08-.73-1.69-.98l-.38-2.65C14.46 2.18 14.25 2 14 2h-4c-.25 0-.46.18-.49.42l-.38 2.65c-.61.25-1.17.59-1.69.98l-2.49-1c-.06-.02-.12-.03-.18-.03-.17 0-.34.09-.43.25l-2 3.46c-.13.22-.07.49.12.64l2.11 1.65c-.04.32-.07.65-.07.98 0 .33.03.66.07.98l-2.11 1.65c-.19.15-.24.42-.12.64l2 3.46c.09.16.26.25.44.25.06 0 .12-.01.17-.03l2.49-1c.52.4 1.08.73 1.69.98l.38 2.65c.03.24.24.42.49.42h4c.25 0 .46-.18.49-.42l.38-2.65c.61-.25 1.17-.59 1.69-.98l2.49 1c.06.02.12.03.18.03.17 0 .34-.09.43-.25l2-3.46c.12-.22.07-.49-.12-.64l-2.11-1.65zm-1.98-1.71c.04.31.05.52.05.73 0 .21-.02.43-.05.73l-.14 1.13.89.7 1.08.84-.7 1.21-1.27-.51-1.04-.42-.9.68c-.43.32-.84.56-1.25.73l-1.06.43-.16 1.13-.2 1.35h-1.4l-.19-1.35-.16-1.13-1.06-.43c-.43-.18-.83-.41-1.23-.71l-.91-.7-1.06.43-1.27.51-.7-1.21 1.08-.84.89-.7-.14-1.13c-.03-.31-.05-.54-.05-.74s.02-.43.05-.73l.14-1.13-.89-.7-1.08-.84.7-1.21 1.27.51 1.04.42.9-.68c.43-.32.84-.56 1.25-.73l1.06-.43.16-1.13.2-1.35h1.39l.19 1.35.16 1.13 1.06.43c.43.18.83.41 1.23.71l.91.7 1.06-.43 1.27-.51.7 1.21-1.07.85-.89.7.14 1.13zM12 8c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm0 6c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z" />
	</svg>
);

const AccountIcon = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		className={ defaultIconSize }
		fill="currentColor"
		viewBox="0 0 24 24"
		stroke="none"
	>
		<path d="M0 0h24v24H0V0z" fill="none" />
		<path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM7.07 18.28c.43-.9 3.05-1.78 4.93-1.78s4.51.88 4.93 1.78C15.57 19.36 13.86 20 12 20s-3.57-.64-4.93-1.72zm11.29-1.45c-1.43-1.74-4.9-2.33-6.36-2.33s-4.93.59-6.36 2.33C4.62 15.49 4 13.82 4 12c0-4.41 3.59-8 8-8s8 3.59 8 8c0 1.82-.62 3.49-1.64 4.83zM12 6c-1.94 0-3.5 1.56-3.5 3.5S10.06 13 12 13s3.5-1.56 3.5-3.5S13.94 6 12 6zm0 5c-.83 0-1.5-.67-1.5-1.5S11.17 8 12 8s1.5.67 1.5 1.5S12.83 11 12 11z" />
	</svg>
);

const NextIcon = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		className={ defaultIconSize }
		fill="currentColor"
		viewBox="0 0 24 24"
		stroke="none"
	>
		<g>
			<rect fill="none" height="24" width="24" />
		</g>
		<g>
			<g>
				<polygon points="6.41,6 5,7.41 9.58,12 5,16.59 6.41,18 12.41,12" />
				<polygon points="13,6 11.59,7.41 16.17,12 11.59,16.59 13,18 19,12" />
			</g>
		</g>
	</svg>
);

const PrevIcon = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		className={ defaultIconSize }
		fill="currentColor"
		viewBox="0 0 24 24"
		stroke="none"
	>
		<g>
			<rect fill="none" height="24" width="24" />
		</g>
		<g>
			<g>
				<polygon points="17.59,18 19,16.59 14.42,12 19,7.41 17.59,6 11.59,12" />
				<polygon points="11,18 12.41,16.59 7.83,12 12.41,7.41 11,6 5,12" />
			</g>
		</g>
	</svg>
);

const GeneralSettingsIcon = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		className={ defaultIconSize }
		fill="currentColor"
		viewBox="0 0 24 24"
		stroke="none"
	>
		<path d="M0 0h24v24H0V0z" fill="none" />
		<path d="M3 17v2h6v-2H3zM3 5v2h10V5H3zm10 16v-2h8v-2h-8v-2h-2v6h2zM7 9v2H3v2h4v2h2V9H7zm14 4v-2H11v2h10zm-6-4h2V7h4V5h-4V3h-2v6z" />
	</svg>
);

const CloseIcon = ( { className }: { className?: string } ) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		className={ className || defaultIconSize }
		fill="currentColor"
		viewBox="0 0 24 24"
		stroke="none"
	>
		<path d="M0 0h24v24H0V0z" fill="none" />
		<path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z" />
	</svg>
);

/* Alarm pane icons */
const alarmIconSize = 'w-5 h-5';

const ErrorIcon = ( {
	className,
	color,
}: {
	className?: string;
	color?: string;
} ) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		className={ `${ alarmIconSize } ${ className ? className : '' }` }
		fill={ color ? color : 'currentColor' }
		viewBox="0 0 24 24"
		stroke="none"
	>
		<path d="M0 0h24v24H0V0z" fill="none" />
		<path d="M18 16v-5c0-3.07-1.64-5.64-4.5-6.32V4c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v.68C7.63 5.36 6 7.92 6 11v5l-2 2v1h16v-1l-2-2zm-5 0h-2v-2h2v2zm0-4h-2V8h2v4zm-1 10c1.1 0 2-.9 2-2h-4c0 1.1.89 2 2 2z" />
	</svg>
);

const ErrorConnectionIcon = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		className={ alarmIconSize }
		fill="currentColor"
		viewBox="0 0 24 24"
		stroke="none"
	>
		<rect fill="none" height="24" width="24" />
		<g>
			<path d="M17.58,6.25l1.77,1.77L14.37,13H12.6v-1.77L17.58,6.25z M20.85,5.81l-1.06-1.06c-0.2-0.2-0.51-0.2-0.71,0l-0.85,0.85 l1.77,1.77l0.85-0.85C21.05,6.32,21.05,6,20.85,5.81z M18,12.2V17h2v2H4v-2h2v-7c0-2.79,1.91-5.14,4.5-5.8V3.5 C10.5,2.67,11.17,2,12,2s1.5,0.67,1.5,1.5v0.7c0.82,0.21,1.57,0.59,2.21,1.09L10.6,10.4V15h4.6L18,12.2z M10,20h4c0,1.1-0.9,2-2,2 S10,21.1,10,20z" />
		</g>
	</svg>
);

const StatusOfflineIcon = ( {
	className,
	color,
}: {
	className?: string;
	color?: string;
} ) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		className={ `${ alarmIconSize } ${ className ? className : '' }` }
		viewBox="0 0 24 24"
		fill={ color ? color : 'currentColor' }
	>
		<rect fill="none" height="24" width="24" />
		<path d="M8.14,10.96C8.05,11.29,8,11.64,8,12c0,1.1,0.45,2.1,1.17,2.83l-1.42,1.42C6.67,15.16,6,13.66,6,12 c0-0.93,0.21-1.8,0.58-2.59L5.11,7.94C4.4,9.13,4,10.52,4,12c0,2.21,0.9,4.21,2.35,5.65l-1.42,1.42C3.12,17.26,2,14.76,2,12 c0-2.04,0.61-3.93,1.66-5.51L1.39,4.22l1.41-1.41l18.38,18.38l-1.41,1.41L8.14,10.96z M17.42,14.59C17.79,13.8,18,12.93,18,12 c0-1.66-0.67-3.16-1.76-4.24l-1.42,1.42C15.55,9.9,16,10.9,16,12c0,0.36-0.05,0.71-0.14,1.04L17.42,14.59z M20,12 c0,1.48-0.4,2.87-1.11,4.06l1.45,1.45C21.39,15.93,22,14.04,22,12c0-2.76-1.12-5.26-2.93-7.07l-1.42,1.42C19.1,7.79,20,9.79,20,12z" />
	</svg>
);

const WarningIcon = ( {
	className,
	color,
}: {
	className?: string;
	color?: string;
} ) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		className={ `${ alarmIconSize } ${ className ? className : '' }` }
		fill={ color ? color : 'currentColor' }
		viewBox="-2 -2 28 28"
		stroke="none"
	>
		<path d="M0 0h24v24H0z" fill="none" />
		<path d="M20 2H4c-1.1 0-1.99.9-1.99 2L2 22l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-7 9h-2V5h2v6zm0 4h-2v-2h2v2z" />
	</svg>
);

const WarningConnectionIcon = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		className={ alarmIconSize }
		fill="currentColor"
		viewBox="0 0 24 24"
		stroke="none"
	>
		<g>
			<path d="M14.8,11H20c0.5,0,1-0.5,1-1V4c0-0.5-0.5-1-1-1H6.8L14.8,11z" />
			<path d="M21,17.2V14c0-0.5-0.5-1-1-1h-3.2L21,17.2z" />
			<path
				d="M1.8,0.8L0.4,2.2L3,4.8V10c0,0.5,0.5,1,1,1h5.2l2,2H4c-0.5,0-1,0.5-1,1v6c0,0.5,0.5,1,1,1h15.2l1.6,1.6l1.4-1.4L1.8,0.8z
		 M7,18.5c-0.8,0-1.5-0.7-1.5-1.5c0-0.8,0.7-1.5,1.5-1.5s1.5,0.7,1.5,1.5C8.5,17.8,7.8,18.5,7,18.5z"
			/>
		</g>
	</svg>
);

const DragIndicatorDotsIcon = ( {
	className,
	size,
	color,
}: {
	className?: string;
	size?: number;
	color?: string;
} ) => {
	const iconSize = size !== undefined ? size : 24;
	const fillColor = color ? color : '#000000';
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			height={ iconSize }
			width={ iconSize }
			viewBox="0 0 24 24"
			fill={ fillColor }
			className={ className }
		>
			<path d="M0 0h24v24H0V0z" fill="none" />
			<path d="M11 18c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2zm-2-8c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0-6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm6 4c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" />
		</svg>
	);
};

const DragIcon = ( {
	className,
	size,
}: {
	className?: string;
	size: number;
} ) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			height={ `${ size }px` }
			width={ `${ size }px` }
			viewBox="0 0 24 24"
			fill="currentColor"
			className={ className }
		>
			<path d="M0 0h24v24H0V0z" fill="none" />
			<path d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z" />
		</svg>
	);
};

const TargetIcon = ( {
	className,
	size,
}: {
	className?: string;
	size: number;
} ) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			height={ `${ size }px` }
			width={ `${ size }px` }
			viewBox="0 0 24 24"
			fill="currentColor"
			className={ className }
		>
			<path d="M0 0h24v24H0V0z" fill="none" />
			<path d="M12 8c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm8.94 3c-.46-4.17-3.77-7.48-7.94-7.94V1h-2v2.06C6.83 3.52 3.52 6.83 3.06 11H1v2h2.06c.46 4.17 3.77 7.48 7.94 7.94V23h2v-2.06c4.17-.46 7.48-3.77 7.94-7.94H23v-2h-2.06zM12 19c-3.87 0-7-3.13-7-7s3.13-7 7-7 7 3.13 7 7-3.13 7-7 7z" />
		</svg>
	);
};

const AssignmentIcon = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		fill="#FFFFFF"
		className="w-5 h-5"
	>
		<path d="M0 0h24v24H0z" fill="none" />
		<path d="M19 3h-4.18C14.4 1.84 13.3 1 12 1c-1.3 0-2.4.84-2.82 2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-7 0c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm-2 14l-4-4 1.41-1.41L10 14.17l6.59-6.59L18 9l-8 8z" />
	</svg>
);

const ArrowDownIcon = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		height="24px"
		viewBox="0 0 24 24"
		width="24px"
		fill="#000000"
	>
		<path d="M0 0h24v24H0V0z" fill="none" />
		<path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z" />
	</svg>
);

const CheckIcon = ( { className }: { className?: string } ) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			height="24px"
			viewBox="0 0 24 24"
			width="24px"
			fill="currentColor"
			className={ className }
		>
			<path d="M0 0h24v24H0V0z" fill="none" />
			<path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z" />
		</svg>
	);
};

const CheckIconCircle = ( { className }: { className?: string } ) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			height="24px"
			width="24px"
			viewBox="0 0 24 24"
			fill="currentColor"
			className={ className }
		>
			<path d="M0 0h24v24H0z" fill="none" />
			<path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" />
		</svg>
	);
};

const CheckListIcon = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			height="24px"
			viewBox="0 0 24 24"
			width="24px"
			fill="currentColor"
		>
			<path d="M0 0h24v24H0V0z" fill="none" />
			<path d="M18 9l-1.41-1.42L10 14.17l-2.59-2.58L6 13l4 4zm1-6h-4.18C14.4 1.84 13.3 1 12 1c-1.3 0-2.4.84-2.82 2H5c-.14 0-.27.01-.4.04-.39.08-.74.28-1.01.55-.18.18-.33.4-.43.64-.1.23-.16.49-.16.77v14c0 .27.06.54.16.78s.25.45.43.64c.27.27.62.47 1.01.55.13.02.26.03.4.03h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-7-.25c.41 0 .75.34.75.75s-.34.75-.75.75-.75-.34-.75-.75.34-.75.75-.75zM19 19H5V5h14v14z" />
		</svg>
	);
};

const RouterIcon = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		height="24px"
		viewBox="0 0 24 24"
		width="24px"
		fill="currentColor"
	>
		<path d="M16 4.2c1.5 0 3 .6 4.2 1.7l.8-.8C19.6 3.7 17.8 3 16 3s-3.6.7-5 2.1l.8.8C13 4.8 14.5 4.2 16 4.2zm-3.3 2.5l.8.8c.7-.7 1.6-1 2.5-1s1.8.3 2.5 1l.8-.8c-.9-.9-2.1-1.4-3.3-1.4s-2.4.5-3.3 1.4zM19 13h-2V9h-2v4H5c-1.1 0-2 .9-2 2v4c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-4c0-1.1-.9-2-2-2zm0 6H5v-4h14v4zM6 16h2v2H6zm3.5 0h2v2h-2zm3.5 0h2v2h-2z" />
	</svg>
);
const NetworkIcon = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		height="24px"
		viewBox="0 0 24 24"
		width="24px"
		fill="#000000"
	>
		<g>
			<path d="M0,0h24v24H0V0z" fill="none" />
		</g>
		<g>
			<path d="M12,4C7.31,4,3.07,5.9,0,8.98L12,21L24,8.98C20.93,5.9,16.69,4,12,4z M2.92,9.07C5.51,7.08,8.67,6,12,6 s6.49,1.08,9.08,3.07L12,18.17L2.92,9.07z" />
		</g>
	</svg>
);
const NetworkCheckIcon = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="43.767"
		height="37.09"
		viewBox="0 0 43.767 37.09"
	>
		<defs>
			<clipPath id="clip-path">
				<rect
					id="Rectangle_362"
					data-name="Rectangle 362"
					width="43.767"
					height="37.09"
					fill="#646464"
				/>
			</clipPath>
		</defs>
		<g
			id="Group_1402"
			data-name="Group 1402"
			transform="translate(-1002 -369.955)"
		>
			<g
				id="Group_1398"
				data-name="Group 1398"
				transform="translate(1002 369.955)"
			>
				<g id="Group_1397" data-name="Group 1397" clipPath="url(#clip-path)">
					<path
						id="Path_443"
						data-name="Path 443"
						d="M48.472,81.453a13.1,13.1,0,0,1-.029-18.529l.029-.029,3.112,3.11A8.645,8.645,0,0,0,49,72.176a8.808,8.808,0,0,0,2.561,6.189Z"
						transform="translate(-35.867 -50.557)"
						fill="#646464"
					/>
					<path
						id="Path_444"
						data-name="Path 444"
						d="M9.52,34.434,6.411,31.327A21.874,21.874,0,0,0,6.4,62.261l.01.01,3.107-3.107a17.435,17.435,0,0,1-.072-24.657l.072-.072Z"
						transform="translate(0 -25.182)"
						fill="#646464"
					/>
					<path
						id="Path_445"
						data-name="Path 445"
						d="M104.139,0a.785.785,0,0,0-.661.371l-.113.242L95.016,19.4a3.176,3.176,0,0,0-.42,1.548,3.237,3.237,0,0,0,6.4.677l.016-.048,3.93-20.77A.8.8,0,0,0,104.153,0h-.014"
						transform="translate(-76.039)"
						fill="#646464"
					/>
					<path
						id="Path_446"
						data-name="Path 446"
						d="M179.84,44.02l-3.188,3.09a17.436,17.436,0,0,1-2.064,22.258l3.107,3.107A21.878,21.878,0,0,0,179.84,44.02"
						transform="translate(-140.339 -35.385)"
						fill="#646464"
					/>
					<path
						id="Path_447"
						data-name="Path 447"
						d="M145.686,82.362a8.809,8.809,0,0,1-2.561,6.189l3.087,3.088a13.105,13.105,0,0,0,1.953-16.072l-3.273,3.172a8.643,8.643,0,0,1,.794,3.623"
						transform="translate(-115.048 -60.743)"
						fill="#646464"
					/>
				</g>
			</g>
		</g>
	</svg>
);

const AnimatedSpinIcon = ( { className }: { className?: string } ) => {
	const iconClass = className
		? `animate-spin ${ className }`
		: 'animate-spin h-5 w-5 text-white mx-auto';

	return (
		<svg
			className={ iconClass }
			xmlns="http://www.w3.org/2000/svg"
			fill="none"
			viewBox="0 0 24 24"
		>
			<circle
				className="opacity-25"
				cx="12"
				cy="12"
				r="10"
				stroke="currentColor"
				strokeWidth="4"
			/>
			<path
				className="opacity-75"
				fill="currentColor"
				d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
			/>
		</svg>
	);
};
const RadiationIcon = () => (
	<svg
		id="Group_600"
		data-name="Group 600"
		xmlns="http://www.w3.org/2000/svg"
		width="32"
		height="32"
		viewBox="0 0 32 32"
	>
		<g id="Group_548" data-name="Group 548">
			<circle
				id="Ellipse_24"
				data-name="Ellipse 24"
				cx="16"
				cy="16"
				r="16"
				fill="#fff"
			/>
		</g>
		<g id="Group_549" data-name="Group 549" transform="translate(5.386 7)">
			<path
				id="Path_20"
				data-name="Path 20"
				d="M313.431,124.764a10.608,10.608,0,0,1,4.642,8.046.7.7,0,0,1-.7.739h-5.756a.7.7,0,0,1-.688-.557,3.465,3.465,0,0,0-1.231-2.1.7.7,0,0,1-.15-.887L312.44,125A.7.7,0,0,1,313.431,124.764Z"
				transform="translate(-296.846 -124.649)"
				fill="#c60204"
			/>
			<path
				id="Path_21"
				data-name="Path 21"
				d="M286.951,132.993a10.608,10.608,0,0,1,4.609-8.065.7.7,0,0,1,.99.231l2.9,4.971a.7.7,0,0,1-.134.875,3.462,3.462,0,0,0-1.189,2.119.7.7,0,0,1-.691.577l-5.784.029A.7.7,0,0,1,286.951,132.993Z"
				transform="translate(-286.95 -124.72)"
				fill="#c60204"
			/>
			<path
				id="Path_22"
				data-name="Path 22"
				d="M306.623,152.769a10.607,10.607,0,0,1-9.289-.043.7.7,0,0,1-.286-.975l2.9-4.972a.7.7,0,0,1,.828-.314,3.465,3.465,0,0,0,2.43-.007.7.7,0,0,1,.842.317l2.871,5.02A.7.7,0,0,1,306.623,152.769Z"
				transform="translate(-291.348 -134.22)"
				fill="#c60204"
			/>
			<path
				id="Path_23"
				data-name="Path 23"
				d="M301.488,138.647a2.505,2.505,0,1,1,5.009.113,2.505,2.505,0,0,1-5.009-.113Z"
				transform="translate(-293.343 -129.707)"
				fill="#c60204"
			/>
		</g>
	</svg>
);

const ResetIcon = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="16"
		height="18.43"
		viewBox="0 0 16 18.43"
	>
		<g id="Group_742" data-name="Group 742" transform="translate(-4 -2.5)">
			<g id="Group_741" data-name="Group 741">
				<path
					id="Path_300"
					data-name="Path 300"
					d="M6,13A5.979,5.979,0,0,1,7.76,8.76L6.34,7.34A8,8,0,0,0,11,20.93V18.91A6.007,6.007,0,0,1,6,13Zm14,0a8,8,0,0,0-8-8c-.06,0-.12.01-.18.01l1.09-1.09L11.5,2.5,8,6l3.5,3.5,1.41-1.41L11.83,7.01c.06,0,.12-.01.17-.01a6,6,0,0,1,1,11.91v2.02A7.988,7.988,0,0,0,20,13Z"
					fill="#707070"
				/>
			</g>
		</g>
	</svg>
);

const CloseIconCircle = ( { size }: { size?: number } ) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			height={ `${ size !== undefined ? size : 24 }px` }
			viewBox="0 0 24 24"
			width={ `${ size !== undefined ? size : 24 }px` }
			fill="currentColor"
		>
			<path d="M0 0h24v24H0V0z" fill="none" />
			<path d="M14.59 8L12 10.59 9.41 8 8 9.41 10.59 12 8 14.59 9.41 16 12 13.41 14.59 16 16 14.59 13.41 12 16 9.41 14.59 8zM12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" />
		</svg>
	);
};

const CaretDown = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			height="24px"
			viewBox="0 0 24 24"
			width="24px"
			fill="currentColor"
		>
			<path d="M24 24H0V0h24v24z" fill="none" opacity=".87" />
			<path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6-1.41-1.41z" />
		</svg>
	);
};

const CalendarInputIcon = () => {
	return (
		<svg
			id="S-today_black_24dp"
			xmlns="http://www.w3.org/2000/svg"
			width="20"
			height="20"
			viewBox="0 0 20 20"
		>
			<path id="Path_303" data-name="Path 303" d="M0,0H20V20H0Z" fill="none" />
			<path
				id="Path_304"
				data-name="Path 304"
				d="M15.444,2.6h-.778V1H13.111V2.6H6.889V1H5.333V2.6H4.556A1.578,1.578,0,0,0,3,4.2V15.4A1.578,1.578,0,0,0,4.556,17H15.444A1.583,1.583,0,0,0,17,15.4V4.2A1.583,1.583,0,0,0,15.444,2.6Zm0,12.8H4.556v-8H15.444Zm0-9.6H4.556V4.2H15.444ZM6.111,9H10v4H6.111Z"
				transform="translate(0)"
				fill="currentColor"
			/>
		</svg>
	);
};
const DisabledIcon = ( { className }: { className?: string } ) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			height="24px"
			viewBox="0 0 24 24"
			width="24px"
			fill="currentColor"
			className={ className }
		>
			<g>
				<path d="M0,0h24v24H0V0z" fill="none" />
			</g>
			<g>
				<path d="M2.81,2.81L1.39,4.22l2.27,2.27C2.61,8.07,2,9.96,2,12c0,5.52,4.48,10,10,10c2.04,0,3.93-0.61,5.51-1.66l2.27,2.27 l1.41-1.41L2.81,2.81z M12,20c-4.41,0-8-3.59-8-8c0-1.48,0.41-2.86,1.12-4.06l10.94,10.94C14.86,19.59,13.48,20,12,20z M7.94,5.12 L6.49,3.66C8.07,2.61,9.96,2,12,2c5.52,0,10,4.48,10,10c0,2.04-0.61,3.93-1.66,5.51l-1.46-1.46C19.59,14.86,20,13.48,20,12 c0-4.41-3.59-8-8-8C10.52,4,9.14,4.41,7.94,5.12z" />
			</g>
		</svg>
	);
};

const EyeIcon = ( { className }: { className?: string } ) => {
	return (
		<svg
			className={ className }
			xmlns="http://www.w3.org/2000/svg"
			height="24px"
			viewBox="0 0 24 24"
			width="24px"
			fill="fillCurrent"
		>
			<path d="M0 0h24v24H0z" fill="none" />
			<path d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z" />
		</svg>
	);
};

const ExportDataIcon = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="18"
			height="18"
			viewBox="0 0 18 18"
		>
			<path
				id="Path_275"
				data-name="Path 275"
				d="M19,3H5A2.006,2.006,0,0,0,3,5V19a2.006,2.006,0,0,0,2,2H19a2.006,2.006,0,0,0,2-2V5A2.006,2.006,0,0,0,19,3Zm0,16H5V5H19ZM7,10H9v7H7Zm4-3h2V17H11Zm4,6h2v4H15Z"
				transform="translate(-3 -3)"
				fill="#fff"
			/>
		</svg>
	);
};

const ResolveIcon = () => {
	return (
		<svg
			id="Group_628"
			data-name="Group 628"
			xmlns="http://www.w3.org/2000/svg"
			// viewBox="0 0 24 24"
			className={ `${ defaultIconSize } font-bold` }
		>
			<path id="Path_270" data-name="Path 270" d="M0,0H24V24H0Z" fill="none" />
			<path
				id="Path_271"
				data-name="Path 271"
				d="M18,9,16.59,7.58,10,14.17,7.41,11.59,6,13l4,4Zm1-6H14.82A2.988,2.988,0,0,0,9.18,3H5a1.752,1.752,0,0,0-.4.04A2.021,2.021,0,0,0,3.16,4.23,1.926,1.926,0,0,0,3,5V19a2.052,2.052,0,0,0,.16.78,2.119,2.119,0,0,0,.43.64,2.008,2.008,0,0,0,1.01.55A2.6,2.6,0,0,0,5,21H19a2.006,2.006,0,0,0,2-2V5A2.006,2.006,0,0,0,19,3Zm-7-.25a.75.75,0,1,1-.75.75A.755.755,0,0,1,12,2.75ZM19,19H5V5H19Z"
				fill="#fff"
			/>
		</svg>
	);
};

const RepairIcon = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
		>
			<g id="Group_625" data-name="Group 625" transform="translate(0 0)">
				<g id="Group_621" data-name="Group 621">
					<rect
						id="Rectangle_218"
						data-name="Rectangle 218"
						width="24"
						height="24"
						transform="translate(0 0)"
						fill="none"
					/>
				</g>
				<g id="Group_623" data-name="Group 623">
					<g id="Group_622" data-name="Group 622">
						<path
							id="Path_265"
							data-name="Path 265"
							d="M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8.011,8.011,0,0,1,12,20Z"
							fill="#fff"
							fillRule="evenodd"
						/>
						<path
							id="Path_266"
							data-name="Path 266"
							d="M13.49,11.38A3.459,3.459,0,0,0,8.58,7.17l2.35,2.35L9.52,10.93,7.17,8.58a3.459,3.459,0,0,0,4.21,4.91l3.41,3.41a.5.5,0,0,0,.71,0l1.4-1.4a.5.5,0,0,0,0-.71Z"
							fill="#fff"
							fillRule="evenodd"
						/>
					</g>
				</g>
			</g>
		</svg>
	);
};

const ConfiguredIcon = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="24.234"
		height="24.914"
		viewBox="0 0 24.234 24.914"
	>
		<path
			id="Path_438"
			data-name="Path 438"
			d="M24.044,16.077a9.18,9.18,0,0,0,.078-1.22,7.5,7.5,0,0,0-.091-1.22l2.634-2.05a.637.637,0,0,0,.156-.792L24.329,6.487a.634.634,0,0,0-.766-.285l-3.1,1.246a9.166,9.166,0,0,0-2.1-1.22l-.467-3.3A.628.628,0,0,0,17.27,2.4H12.287a.615.615,0,0,0-.61.532l-.467,3.3a9.369,9.369,0,0,0-2.1,1.22L6.007,6.2a.619.619,0,0,0-.766.285L2.763,10.8a.59.59,0,0,0,.156.792l2.634,2.05a7.219,7.219,0,0,0-.026,2.439l-2.634,2.05a.637.637,0,0,0-.156.792l2.491,4.308a.634.634,0,0,0,.766.285l3.1-1.246a9.166,9.166,0,0,0,2.1,1.22l.467,3.3a.638.638,0,0,0,.623.532H17.27a.605.605,0,0,0,.61-.532l.467-3.3a8.9,8.9,0,0,0,2.1-1.22l3.1,1.246a.619.619,0,0,0,.766-.285l2.491-4.308a.6.6,0,0,0-.156-.792Zm-9.265,3.452a4.671,4.671,0,1,1,4.671-4.671A4.685,4.685,0,0,1,14.779,19.528Z"
			transform="translate(-2.662 -2.4)"
			fill="#fff"
		/>
	</svg>
);

const ConnectedIcon = ( { className }: { className?: string } ) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		// width="33.959"
		// height="24.009"
		viewBox="0 0 33.959 24.009"
		className={ className }
	>
		<path
			id="Path_434"
			data-name="Path 434"
			d="M11.78,24.134a10.166,10.166,0,0,1,0-14.4l2.411,2.411a6.709,6.709,0,0,0-2,4.788,6.834,6.834,0,0,0,1.987,4.805Zm14.4,0a10.166,10.166,0,0,0,0-14.4l-2.411,2.411a6.709,6.709,0,0,1,2,4.788,6.834,6.834,0,0,1-1.987,4.805Zm-7.2-10.6a3.4,3.4,0,1,0,3.4,3.4A3.406,3.406,0,0,0,18.979,13.539Zm13.584,3.4a13.482,13.482,0,0,1-3.99,9.593l2.411,2.411a16.971,16.971,0,0,0,0-24.009L28.573,7.341A13.482,13.482,0,0,1,32.563,16.934ZM9.386,7.341,6.975,4.93a16.971,16.971,0,0,0,0,24.009l2.411-2.411a13.528,13.528,0,0,1,0-19.187Z"
			transform="translate(-2 -4.93)"
			fill="currentColor"
		/>
	</svg>
);

const SupportLogo = ( { className }: { className?: string } ) => (
	<svg
		id="Logo.01.01"
		xmlns="http://www.w3.org/2000/svg"
		width="239.669"
		height="32.119"
		viewBox="0 0 239.669 32.119"
	>
		<g id="wordmark" transform="translate(43.824)">
			<path
				id="Path_159"
				data-name="Path 159"
				d="M212.193,141.654a17.913,17.913,0,0,0-1-5.933,14.467,14.467,0,0,0-2.88-4.946,13.37,13.37,0,0,0-4.852-3.361,17.17,17.17,0,0,0-6.714-1.241,15.9,15.9,0,0,0-8.536,2.167,13.419,13.419,0,0,0-5.183,5.7,17.957,17.957,0,0,0,0,15.212,13.5,13.5,0,0,0,5.18,5.725,15.8,15.8,0,0,0,8.525,2.177,16.077,16.077,0,0,0,8.446-2.091,13.406,13.406,0,0,0,5.224-5.641,17.273,17.273,0,0,0,1.755-7.776m-8.349,0a9.362,9.362,0,0,1-.823,4.019,6.3,6.3,0,0,1-2.409,2.745,7.128,7.128,0,0,1-3.842,1,6.529,6.529,0,0,1-5.164-2.144,9.269,9.269,0,0,1,0-11.2,6.545,6.545,0,0,1,5.174-2.134,7.532,7.532,0,0,1,3.04.578,6.353,6.353,0,0,1,2.226,1.613,6.768,6.768,0,0,1,1.361,2.439,10.138,10.138,0,0,1,.452,3.1Z"
				transform="translate(-155.725 -125.668)"
				fill="#252a7a"
			/>
			<path
				id="Path_160"
				data-name="Path 160"
				d="M256.072,127.492a1,1,0,0,0-1.076-.786h-5.641a1.534,1.534,0,0,0-1.141.574l-7.941,10.733-.445-.561-7.5-10.172a1.529,1.529,0,0,0-1.141-.574h-5.641a1.052,1.052,0,0,0-1.075,1.028v28.079a1.053,1.053,0,0,0,1.029,1.076h5.855a1.062,1.062,0,0,0,1.075-1.049V140.116s0-.716.425-.139l6.8,8.961.617.663,7.184-9.624s.422-.577.422.139v15.7s.067,1.076,1.075,1.076h6.043a1.052,1.052,0,0,0,1.075-1.029.459.459,0,0,0,0-.047V127.774"
				transform="translate(-164.672 -125.779)"
				fill="#252a7a"
			/>
			<path
				id="Path_161"
				data-name="Path 161"
				d="M291.24,156.89V150.8l-.714.017H278.961v-6.445h9.717v-6.073h-9.717V132.77h11.947v-6.063H271.481a.7.7,0,0,0-.713.686.171.171,0,0,0,0,.03v28.752a.7.7,0,0,0,.683.717h.03Z"
				transform="translate(-174.277 -125.78)"
				fill="#252a7a"
			/>
			<path
				id="Path_162"
				data-name="Path 162"
				d="M382.707,143.884s-.435-.282-.07-.5a15.761,15.761,0,0,0,1.613-1.159,6.881,6.881,0,0,0,1.659-2.3,8.516,8.516,0,0,0,.793-3.866,10.373,10.373,0,0,0-.949-4.135,8.875,8.875,0,0,0-3.107-3.7,9.372,9.372,0,0,0-5.526-1.523H363.9a.7.7,0,0,0-.716.684.233.233,0,0,0,0,.033v28.752a.7.7,0,0,0,.683.717h6.8a.7.7,0,0,0,.716-.684v-11.1h.923a5.942,5.942,0,0,1,2.3.378,4.734,4.734,0,0,1,1.832,1.743,22.941,22.941,0,0,1,2.121,4.314l1.868,4.693a1.1,1.1,0,0,0,1,.663h7.371s.713,0,.438-.663l-2.472-5.894a26.18,26.18,0,0,0-2.323-4.713,7.611,7.611,0,0,0-1.728-1.732Zm-4.338-7.633a3.119,3.119,0,0,1-1.1,2.8,8.086,8.086,0,0,1-4.105.731H372.11a.7.7,0,0,1-.713-.687v-6.6h1.726a7.461,7.461,0,0,1,4.019.827,3.26,3.26,0,0,1,1.234,2.917Z"
				transform="translate(-193.449 -125.777)"
				fill="#252a7a"
			/>
			<path
				id="Path_163"
				data-name="Path 163"
				d="M173.251,126.62a22.571,22.571,0,0,0-9.555-.926,21.322,21.322,0,0,0-6.219,1.447,13.242,13.242,0,0,0-4.772,3.212,12.32,12.32,0,0,0-3.01,4.606,20.872,20.872,0,0,0-.634,6.588,20.869,20.869,0,0,0,.634,6.587,12.348,12.348,0,0,0,3.01,4.606,13.254,13.254,0,0,0,4.772,3.213A21.322,21.322,0,0,0,163.7,157.4a22.569,22.569,0,0,0,9.555-.926v-7.181a21.26,21.26,0,0,1-7.537.856,12.323,12.323,0,0,1-4.822-1.267,6.246,6.246,0,0,1-2.608-2.655,10.105,10.105,0,0,1-.71-4.67,10.1,10.1,0,0,1,.71-4.669,6.219,6.219,0,0,1,2.608-2.655,12.309,12.309,0,0,1,4.822-1.268,21.263,21.263,0,0,1,7.537.857Z"
				transform="translate(-149.019 -125.538)"
				fill="#252a7a"
			/>
			<path
				id="Path_164"
				data-name="Path 164"
				d="M322.422,126.62a22.571,22.571,0,0,0-9.555-.926,21.32,21.32,0,0,0-6.219,1.447,13.234,13.234,0,0,0-4.772,3.212,12.309,12.309,0,0,0-3.013,4.606,20.842,20.842,0,0,0-.63,6.588,20.837,20.837,0,0,0,.63,6.587,12.329,12.329,0,0,0,3.013,4.606,13.237,13.237,0,0,0,4.772,3.213,21.32,21.32,0,0,0,6.219,1.446,22.57,22.57,0,0,0,9.555-.926v-7.181a21.244,21.244,0,0,1-7.537.856,12.31,12.31,0,0,1-4.822-1.267,6.237,6.237,0,0,1-2.608-2.655,10.105,10.105,0,0,1-.71-4.67,10.1,10.1,0,0,1,.71-4.669,6.214,6.214,0,0,1,2.608-2.655,12.309,12.309,0,0,1,4.822-1.268,21.243,21.243,0,0,1,7.537.857Z"
				transform="translate(-179.966 -125.538)"
				fill="#252a7a"
			/>
			<path
				id="Path_165"
				data-name="Path 165"
				d="M354.016,156.89V150.8l-.716.017H341.727v-6.445h9.721v-6.073h-9.721V132.77h11.947v-6.063H334.247a.7.7,0,0,0-.713.686v28.782a.7.7,0,0,0,.683.717h.03Z"
				transform="translate(-187.298 -125.78)"
				fill="#252a7a"
			/>
		</g>
		<g id="icon">
			<g
				id="Group_1317"
				data-name="Group 1317"
				transform="translate(3.516 3.516)"
			>
				<path
					id="Path_423"
					data-name="Path 423"
					d="M120.182,133.569l-1.715,1.714v6.038h-1.959v-5.464h-5.463V133.9h6.037l1.71-1.71a12.537,12.537,0,0,0-20.63,9.582c0,.4.024.789.06,1.178l4.721-4.7v-6.036h1.96v5.463h5.463v1.959h-6.038l-5.656,5.656a12.531,12.531,0,0,0,2.349,4.438l1.922-1.922v-6.037h1.96v5.463h5.463v1.959h-6.038l-1.95,1.95a12.526,12.526,0,0,0,4.093,2.425l5.76-5.761v-6.037h1.959v5.463h5.464v1.959h-6.038l-4.945,4.945a12.527,12.527,0,0,0,11.512-20.569Z"
					transform="translate(-98.161 -129.227)"
					fill="#c60204"
				/>
			</g>
			<g id="Group_1319" data-name="Group 1319">
				<g id="Group_1318" data-name="Group 1318">
					<path
						id="Path_424"
						data-name="Path 424"
						d="M122.9,124.791H96.664a2.942,2.942,0,0,0-2.939,2.939V153.97a2.942,2.942,0,0,0,2.939,2.94H122.9a2.942,2.942,0,0,0,2.939-2.94V127.73A2.942,2.942,0,0,0,122.9,124.791Zm.98,29.179a.981.981,0,0,1-.98.98H106.017l1.732-1.733a12.391,12.391,0,0,1-2.2-.57l-2.3,2.3H96.73l4.728-4.728a12.574,12.574,0,0,1-1.357-1.414l-4.416,4.416v-6.787l2.067-2.067a12.544,12.544,0,0,1-.449-2.321l-1.618,1.618V127.73a.981.981,0,0,1,.98-.98h25.725l-4.518,4.518a12.646,12.646,0,0,1,1.391,1.381l4.623-4.624Z"
						transform="translate(-93.725 -124.791)"
						fill="#c60204"
					/>
				</g>
			</g>
		</g>
	</svg>
);

const ExclamationMark = () => (
	<svg
		data-name="configuration error"
		xmlns="http://www.w3.org/2000/svg"
		width="24"
		height="24"
		viewBox="0 0 37 37"
	>
		<path
			id="Path_463"
			data-name="Path 463"
			d="M18.65,26.05h3.7v3.7h-3.7Zm0-14.8h3.7v11.1h-3.7ZM20.482,2A18.5,18.5,0,1,0,39,20.5,18.491,18.491,0,0,0,20.482,2ZM20.5,35.3A14.8,14.8,0,1,1,35.3,20.5,14.8,14.8,0,0,1,20.5,35.3Z"
			transform="translate(-2 -2)"
			fill="currentColor"
		/>
	</svg>
);

export {
	AlarmIcon,
	DeviceIcon,
	AuditTrailIcon,
	DataAnalysisIcon,
	SupportIcon,
	SettingsIcon,
	HomeIcon,
	AccountIcon,
	NextIcon,
	PrevIcon,
	ErrorIcon,
	ErrorConnectionIcon,
	WarningIcon,
	WarningConnectionIcon,
	DragIndicatorDotsIcon,
	GeneralSettingsIcon,
	CloseIcon,
	CloseIconCircle,
	StatusOfflineIcon,
	DragIcon,
	TargetIcon,
	AssignmentIcon,
	ArrowDownIcon,
	CheckIconCircle,
	CheckIcon,
	RouterIcon,
	NetworkIcon,
	NetworkCheckIcon,
	AnimatedSpinIcon,
	RadiationIcon,
	ResetIcon,
	PencilIcon,
	CheckListIcon,
	CaretDown,
	CalendarInputIcon,
	DisabledIcon,
	EyeIcon,
	ExportDataIcon,
	ResolveIcon,
	RepairIcon,
	ConfiguredIcon,
	ConnectedIcon,
	SupportLogo,
	ExclamationMark,
};
