import React, { ReactNode } from 'react';
import { useRecoilValue } from 'recoil';
import { ConnectedIcon, StatusOfflineIcon } from '../../assets/icons';
import { findDeviceDetailsByIdSelector } from '../../state/devices/selectors/findDeviceDetailsById';
import { DeviceConnectionStatus, Location } from '../../types';
import { getDeviceConnectionStatus } from '../../utils/device';

const getIconCircleProps = ( deviceSize: number ) => {
	if ( deviceSize === undefined ) {
		return { size: 0, offset: 0, radius: 0 };
	}

	let offset = deviceSize / 2;
	let radius = deviceSize / 4;
	let size = radius * 1.2;

	return { size, offset, radius };
};

const getIcon = ( connectionStatus?: {
	status: DeviceConnectionStatus;
	text: string;
	color?: string;
} ) => {
	let styleClass = 'fill-transparent';
	let iconImage: ReactNode | null = null;

	if ( connectionStatus ) {
		const noNetworkConfigured =
			connectionStatus.status === DeviceConnectionStatus.NoNetworkConfigured;
		const isOffline =
			connectionStatus.status === DeviceConnectionStatus.ActivatedDisconnected;
		iconImage = <ConnectedIcon className="text-base-100" />;

		if ( isOffline ) {
			iconImage = <StatusOfflineIcon className="text-base-100" />;
		}

		if ( noNetworkConfigured ) {
			iconImage = null;
		}

		styleClass = noNetworkConfigured
			? 'fill-none'
			: `fill-${ connectionStatus.color }`;
	}

	return { styleClass, iconImage };
};

interface DeviceNetworkStatusIconProps {
	deviceId: string;
	position: Location;
	deviceSize: number;
	isActivated?: boolean;
	hasConnectionProperties?: boolean;
}

export const DeviceNetworkStatusIcon = ( {
	deviceId,
	position,
	deviceSize,
	isActivated,
	hasConnectionProperties,
}: DeviceNetworkStatusIconProps ) => {
	const deviceDetails = useRecoilValue( findDeviceDetailsByIdSelector( deviceId ) );
	const isConnectedMeasurement =
		deviceDetails?.measurementMessage?.isConnected ??
		deviceDetails?.signallingDeviceStatusMessage?.isConnected;

	const connectionStatus = getDeviceConnectionStatus(
		isActivated,
		isConnectedMeasurement,
		hasConnectionProperties
	);
	const iconCircleProps = getIconCircleProps( deviceSize );
	const icon = getIcon( connectionStatus );

	return (
		<svg overflow="visible">
			<circle
				className={ icon.styleClass }
				cx={ position.x - iconCircleProps.offset }
				cy={ position.y - iconCircleProps.offset }
				r={ iconCircleProps.radius }
			/>
			<svg
				width={ iconCircleProps.size }
				height={ iconCircleProps.size }
				fill="white"
				x={ position.x - iconCircleProps.offset - iconCircleProps.size / 2 }
				y={ position.y - iconCircleProps.offset - iconCircleProps.size / 2 }
			>
				{icon.iconImage}
			</svg>
			{connectionStatus?.text ? <title>{connectionStatus.text}</title> : null}
		</svg>
	);
};
