import React from 'react';
import { useTranslation } from 'react-i18next';

export interface IGeneralSettingsSelectInput {
	options: Array<string>;
	optionsLabel: string;
	defaultValue: string;
	name: string;
	register: any;
}

const GeneralSettingsSelectInput: React.FC<IGeneralSettingsSelectInput> = ( {
	options,
	optionsLabel,
	defaultValue,
	name,
	register,
} ) => {
	const { t } = useTranslation();
	return (
		<div className="flex form-control w-5/12 mb-2">
			<label htmlFor={ name } className="label">
				{/* i18next-extract-disable-next-line */}
				<span className="label-select">{optionsLabel}</span>
			</label>
			<select
				id={ name }
				// name={ name }
				className="select select-sm rounded-md w-full font-normal"
				defaultValue={ defaultValue || t( 'select-input-unit', { name } ) }
				{ ...register }
			>
				{!defaultValue && <option disabled>{defaultValue}</option>}
				{options.map( ( value: string ) => (
					<option key={ value }>{value}</option>
				) )}
			</select>
		</div>
	);
};

export default GeneralSettingsSelectInput;
