import { selectorFamily } from 'recoil';
import { getLocatedDevices } from '../../utils/device';
import { placesDefinitionAtom } from './placesDefinition';

export const findLocatedDeviceByIdSelector = selectorFamily( {
	key: 'findLocatedDeviceByIdSelector',
	get:
		( deviceId: string ) => ( { get } ) => {
			const places = get( placesDefinitionAtom );
			const locatedDevices = getLocatedDevices( places );

			return locatedDevices.find( ( device ) => device.deviceId === deviceId );
		},
	cachePolicy_UNSTABLE: {
		// Only store the most recent set of dependencies and their values
		eviction: 'lru',
		maxSize: 64,
	},
} );
