import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMeasurements } from '../hooks/useMeasurements';
import { DeviceConfiguration } from '../types';
import {
	getRegionConfigurationPropertyDisplayValue,
	roundMeasurement,
} from '../utils/device';
import { DefinitionListItem } from './DefinitionListItem';

export interface DataAnalysisRegionsMeasurementsProps {
	deviceId: string;
	configuration?: DeviceConfiguration;
}

const DataAnalysisRegionsMeasurements: React.FC<
DataAnalysisRegionsMeasurementsProps
> = ( { deviceId, configuration } ) => {
	const { t } = useTranslation();
	const { regionsMeasurements } = useMeasurements( deviceId );

	return (
		<div className="flex">
			{regionsMeasurements.map( ( regionMeasurement ) => {
				const regionDefinitionName = regionMeasurement.measurementsData
					?.name as string;

				const regionWarningLevel = getRegionConfigurationPropertyDisplayValue(
					configuration,
					regionDefinitionName,
					'HighAlarmLevels'
				);
				const regionAlarmLevel = getRegionConfigurationPropertyDisplayValue(
					configuration,
					regionDefinitionName,
					'HighHighAlarmLevels'
				);
				const measurementDisplayValue =
					regionMeasurement && regionMeasurement.measurementsData
						? `${ roundMeasurement( regionMeasurement.measurementsData?.value ) } ${
							regionMeasurement.measurementsData.unit
						  }`
						: '-';
				return (
					<div className="mr-4" key={ regionDefinitionName }>
						<h3 className="font-semibold mt-5 mb-2">
							{regionMeasurement.displayName}
						</h3>
						<div className="flex flex-row">
							<dl className="pt-3 border-l border-base-300 pl-2">
								<DefinitionListItem
									term={ `${ t( 'Current readout' ) }:` }
									text={ measurementDisplayValue }
									textStyle="font-bold"
									termStyle="font-light"
								/>

								<DefinitionListItem
									term={ `${ 'Warning level' }:` }
									text={ regionWarningLevel }
									termStyle="font-light"
									textStyle="text-danger-warning"
								/>
								<DefinitionListItem
									term={ `${ t( 'Alarm level' ) }:` }
									text={ regionAlarmLevel }
									termStyle="font-light"
									textStyle="text-error"
								/>
							</dl>
						</div>
					</div>
				);
			} )}
		</div>
	);
};

export default DataAnalysisRegionsMeasurements;
