import { saveAs } from 'file-saver';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { DataAnalysisIcon } from '../../assets/icons';
import useDateRangeFromQuery from '../../hooks/useDateRangeFromQuery';
import useLanguage from '../../hooks/useLanguage';
import api from '../../middleware/api';
import { userHasRoleSelector } from '../../state/auth/selectors/userHasRole';
import { AuditTrailItem, AuditTrailRequest, IPaging } from '../../types';
import { getAuditTimeStamp } from '../../utils/time';
import Button from '../buttons/Button';
import AuditFilters from '../filters/AuditFilters';
import AuditTrailDetails from '../modals/AuditTrailDetails';
import Paging from '../Paging';
import ScrollableTable from '../table/ScrollableTable';
import TableData from '../table/TableData';
import TableHeadItem from '../table/TableHeadItem';
import TableRow from '../table/TableRow';

interface AuditRowProps {
	item: AuditTrailItem;
	onSelectItem: ( item: AuditTrailItem ) => void;
}

const AuditRow: React.FC<AuditRowProps> = ( { item, onSelectItem } ) => {
	const onClickHandler = useCallback( () => {
		onSelectItem( item );
	}, [ onSelectItem, item ] );

	return (
		<TableRow onClick={ onClickHandler }>
			<TableData>{item.operator}</TableData>
			<TableData>{item.message}</TableData>
			<TableData>{getAuditTimeStamp( item.timestamp )}</TableData>
		</TableRow>
	);
};

const AuditTrail: React.FC = () => {
	const { t } = useTranslation();

	const [ auditLog, setAuditLog ] = useState<Array<AuditTrailItem>>( [] );
	const [ isLoadingReportData, setIsLoadingReportData ] =
		useState<boolean>( false );
	const [ currentFilters, setCurrentFilters ] = useState<AuditTrailRequest>();
	const [ auditTrailPagingData, setAuditTrailPagingData ] = useState<IPaging>();
	const [ auditItem, setAuditItem ] = useState<AuditTrailItem | null>( null );

	const { periodFrom: from, periodTo: to } = useDateRangeFromQuery();

	const onChangeAuditLogFilters = useCallback(
		async ( auditFilters: AuditTrailRequest ) => {
			setCurrentFilters( ( data ) => {
				if ( !auditFilters?.page ) {
					return auditFilters;
				}
				return data;
			} );
			try {
				const auditData: { data: Array<AuditTrailItem>; pagingData: IPaging } =
					await api.auditTrail.get( {
						...auditFilters,
					} );
				setAuditLog( auditData.data );
				setAuditTrailPagingData( auditData.pagingData );
			} catch ( _ ) {
				setAuditLog( [] );
			}
		},
		[]
	);

	const handlePrevPageClick = useCallback( () => {
		if ( auditTrailPagingData?.hasPrevPage ) {
			onChangeAuditLogFilters( {
				...currentFilters,
				page: auditTrailPagingData?.currentPage - 1,
			} );
		}
	}, [ currentFilters, auditTrailPagingData, onChangeAuditLogFilters ] );

	const handleNextPageClick = useCallback( () => {
		if ( auditTrailPagingData?.hasNextPage ) {
			onChangeAuditLogFilters( {
				...currentFilters,
				page: auditTrailPagingData?.currentPage + 1,
			} );
		}
	}, [ currentFilters, auditTrailPagingData, onChangeAuditLogFilters ] );

	const onDownloadReport = useCallback( async () => {
		if ( isLoadingReportData ) {
			return;
		}
		try {
			setIsLoadingReportData( true );
			const blob = await api.reporting.audit( {
				...currentFilters,
				from,
				to,
			} );
			setIsLoadingReportData( false );

			saveAs( blob, `${ 'audit-trail' }.pdf` );
		} catch ( e ) {
			setIsLoadingReportData( false );
		}
	}, [ from, to, currentFilters, isLoadingReportData ] );

	const userCanDownloadReports = useRecoilValue(
		userHasRoleSelector( 'SUPERVISOR' )
	);

	const showPaging =
		auditTrailPagingData && auditTrailPagingData.totalCount > 0;

	return (
		<div className="w-full flex flex-col">
			<div className="flex flex-row h-full">
				<AuditFilters
					title={ t( 'Audit trail' ) }
					onChangeFilters={ onChangeAuditLogFilters }
				/>
				<div className="w-px bg-base-301 shadow-left-sided" />
				<div className="flex flex-col flex-1 content-between pt-16 px-9 pb-6">
					<ScrollableTable>
						<thead>
							<tr>
								<TableHeadItem>{t( 'Audit operator' )}</TableHeadItem>
								<TableHeadItem>{t( 'Audit message' )}</TableHeadItem>
								<TableHeadItem>{t( 'Audit timestamp' )}</TableHeadItem>
							</tr>
						</thead>
						<tbody>
							{auditLog.map( ( item ) => (
								<AuditRow item={ item } onSelectItem={ setAuditItem } key={ item.id } />
							) )}
						</tbody>
					</ScrollableTable>
					<div className="flex justify-between pt-8 items-center w-full">
						{showPaging && (
							<Paging
								onPreviousPage={ handlePrevPageClick }
								onNextPage={ handleNextPageClick }
								currentPage={ auditTrailPagingData.currentPage }
								totalPages={ auditTrailPagingData.totalPages }
							/>
						)}
						{userCanDownloadReports && (
							<Button
								text={ `${ t( 'Get audit report' ) }` }
								onClick={ onDownloadReport }
								icon={ <DataAnalysisIcon /> }
								className="w-1/3 mt-2 text-primary-content self-end"
								disabled={ isLoadingReportData }
								loading={ isLoadingReportData }
							/>
						)}
					</div>
				</div>
			</div>
			<AuditTrailDetails auditItem={ auditItem } setAuditTrailId={ setAuditItem } />
		</div>
	);
};

export default AuditTrail;
