import React from 'react';
import {
	Control,
	FieldValues,
	RegisterOptions,
	useController,
} from 'react-hook-form';
import { twMerge } from 'tailwind-merge';
import { applyMacAddressMask } from '../../../utils/forms';

interface AddressFieldProps {
	control: Control<FieldValues, object>;
	name: string;
	rules: Exclude<
	RegisterOptions,
	'valueAsNumber' | 'valueAsDate' | 'setValueAs'
	>;
	label?: string;
	defaultValue?: string;
	disabled?: boolean;
	placeholder?: string;
}

const AddressField = ( {
	control,
	name,
	label,
	defaultValue,
	rules,
	disabled,
	placeholder,
}: AddressFieldProps ) => {
	const {
		field,
		fieldState: { error },
	} = useController( {
		control,
		name,
		defaultValue: defaultValue ?? '',
		rules,
	} );

	const styles = twMerge(
		'input input-md input-bordered disabled:bg-base-300 rounded-md w-full h-9 leading-9 pr-0',
		error && 'border border-danger bg-danger bg-opacity-10'
	);

	const handleOnChange = ( event: React.ChangeEvent<HTMLInputElement> ) => field.onChange( applyMacAddressMask( event.target.value ) );

	return (
		<label
			htmlFor={ `configure-${ label }` }
			className="block mt-2 text-sm flex-1 justify-between items-center"
		>
			{label ? (
				<span className={ `block mb-1 ${ disabled ? 'opacity-40' : '' }` }>
					{label}
				</span>
			) : null}

			<input
				{ ...field }
				type="text"
				className={ styles }
				placeholder={ placeholder }
				disabled={ disabled }
				onChange={ handleOnChange }
			/>
			{!!error && (
				<span className="text-danger text-xs pt-1 block">{error.message}</span>
			)}
		</label>
	);
};

export default AddressField;
