import { selectorFamily } from 'recoil';
import { allDevicesStateAtom } from '../allDevices';

export const findDeviceByIdSelector = selectorFamily( {
	key: 'findDeviceByIdSelector',
	get:
		( searchID: string | undefined ) => ( { get } ) => {
			const devices = get( allDevicesStateAtom );

			if ( searchID ) {
				const foundDeviceById = devices.find(
					( device ) => device.id === searchID
				);
				if ( foundDeviceById ) {
					return foundDeviceById;
				}
			}

			return undefined;
		},
	cachePolicy_UNSTABLE: {
		// Only store the most recent set of dependencies and their values
		eviction: 'lru',
		maxSize: 16,
	},
} );
