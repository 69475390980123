import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { useParams } from 'react-router';
import { saveAs } from 'file-saver';
import DeviceFilters, { IFiltersData } from '../filters/DeviceFilters';

import { activeAlarmsAtom } from '../../state/alarms/activeAlarms';
import { allDevicesStateAtom } from '../../state/devices/allDevices';
import { Alarm, IPaging } from '../../types';
import api, { GetHistoricalAlarmsRequestParams } from '../../middleware/api';
import { AppScreen } from '../../shared/constants';
import Button from '../buttons/Button';
import { DataAnalysisIcon } from '../../assets/icons';
import { sortAlarmsByPriority } from '../../utils/alarms';
import { userHasRoleSelector } from '../../state/auth/selectors/userHasRole';
import ActiveAlarmRow from '../alarmLog/ActiveAlarmRow';
import LoadingSpinner from '../LoadingSpinner';
import useDateRangeFromQuery from '../../hooks/useDateRangeFromQuery';
import HistoricalAlarmsRow from '../alarmLog/HistoricalAlarmRow';
import TableHeadItem from '../table/TableHeadItem';
import NoDataRow from '../table/NoDataRow';
import Paging from '../Paging';
import ScrollableTable from '../table/ScrollableTable';
import { findLocatedDeviceByIdSelector } from '../../state/places/findLocatedDeviceById';

const AlarmLog: React.FC = () => {
	const { t } = useTranslation();
	const { id: deviceId } = useParams<{ id: string }>();

	const { periodFrom, periodTo } = useDateRangeFromQuery();

	const [ historicalAlarmsPagingData, setHistoricalAlarmsPagingData ] =
		useState<IPaging>();
	const [ isLoadingReportData, setIsLoadingReportData ] =
		useState<boolean>( false );
	const [ isLoadingAlarmsData, setIsLoadingAlarmsData ] =
		useState<boolean>( false );

	const activeAlarms = useRecoilValue( activeAlarmsAtom );
	const allDevices = useRecoilValue( allDevicesStateAtom );

	const [ historicalAlarms, setHistoricalAlarms ] = useState<Array<Alarm>>( [] );
	const [ filteredDevicesIds, setFilteredDevicesIds ] = useState<Array<string>>(
		[]
	);
	const [ locationId, setLocationId ] = useState<string>();

	const sortedAlarms = sortAlarmsByPriority( activeAlarms );

	const deviceLocation = useRecoilValue(
		findLocatedDeviceByIdSelector( deviceId )
	);

	const selectedDeviceLocationId = deviceLocation?.locationId;

	const getHistoricalAlarms = useCallback(
		( data?: { page?: number; location?: string } ) => {
			const requestParams: GetHistoricalAlarmsRequestParams = {
				page: data?.page,
				deviceId,
				location: selectedDeviceLocationId
					? selectedDeviceLocationId
					: data?.location,
				periodFrom,
				periodTo,
			};
			setIsLoadingAlarmsData( true );
			api.alarms
				.getHistorical( requestParams )
				.then( ( { alarms, pagingData } ) => {
					if ( deviceId ) {
						setHistoricalAlarms(
							alarms.filter( ( alarm ) => alarm.deviceId === deviceId )
						);
					} else {
						setHistoricalAlarms( alarms );
					}
					setHistoricalAlarmsPagingData( pagingData );
					setIsLoadingAlarmsData( false );
				} )
				.catch( ( error ) => {
					setIsLoadingAlarmsData( false );
					console.warn( {
						msg: 'Failed to load historical alarms',
						error,
					} );
				} );
		},
		[ deviceId, selectedDeviceLocationId, periodFrom, periodTo ]
	);

	useEffect( () => {
		getHistoricalAlarms();
	}, [ getHistoricalAlarms ] );

	const filteredAlarms = sortedAlarms.filter( ( alarm ) => {
		if ( deviceId ) {
			return alarm.deviceId === deviceId;
		}
		return !filteredDevicesIds || filteredDevicesIds.includes( alarm.deviceId );
	} );

	const onFilterOutDevices = useCallback(
		( data?: IFiltersData ) => {
			getHistoricalAlarms( { location: data?.location } );
			setFilteredDevicesIds(
				data?.devices.map( ( device ) => device.deviceId ) || []
			);
			setLocationId( data?.location );
		},
		[ getHistoricalAlarms ]
	);

	const handleNextPageClick = useCallback( () => {
		if ( historicalAlarmsPagingData?.hasNextPage ) {
			getHistoricalAlarms( { page: historicalAlarmsPagingData.currentPage + 1 } );
		}
	}, [ historicalAlarmsPagingData, getHistoricalAlarms ] );

	const handlePrevPageClick = useCallback( () => {
		if ( historicalAlarmsPagingData?.hasPrevPage ) {
			getHistoricalAlarms( { page: historicalAlarmsPagingData.currentPage - 1 } );
		}
	}, [ historicalAlarmsPagingData, getHistoricalAlarms ] );

	const canDownloadReport = !isLoadingReportData;

	const userCanDownloadReports = useRecoilValue(
		userHasRoleSelector( 'SUPERVISOR' )
	);

	const onDownloadReport = useCallback( async () => {
		try {
			setIsLoadingReportData( true );
			if ( canDownloadReport ) {
				const blob = await api.reporting.alarms( {
					periodFrom,
					periodTo,
					location: selectedDeviceLocationId
						? selectedDeviceLocationId
						: locationId,
					deviceId,
				} );
				setIsLoadingReportData( false );

				const reportName =
					allDevices.find( ( d ) => d.id === deviceId )?.name || 'alarms-report';

				saveAs( blob, `${ reportName }.pdf` );
			}
		} catch ( e ) {
			setIsLoadingReportData( false );
		}
	}, [
		canDownloadReport,
		periodFrom,
		periodTo,
		deviceId,
		selectedDeviceLocationId,
		allDevices,
		locationId,
	] );

	const showPaging =
		historicalAlarmsPagingData && historicalAlarmsPagingData.totalCount > 0;

	return (
		<div className="w-full flex flex-col">
			<div className="flex flex-row h-full">
				<DeviceFilters
					title={ t( 'Alarm log' ) }
					pickDates
					onChangeFilters={ onFilterOutDevices }
					routePath={ AppScreen.ALARM_LOG }
					allDevicesAvailable
				/>
				<div className="w-px bg-base-301 shadow-left-sided" />
				{/* Active alarms list */}
				<div className="flex flex-col flex-1 h-full content-between p-6 px-9 gap-4">
					<div className="flex rounded flex-col mt-2 max-h-72 overflow-hidden">
						<h3 className="text-lg font-medium mb-1">{t( 'Active alarms' )}</h3>
						<ScrollableTable>
							<thead>
								<tr>
									<TableHeadItem>{t( 'Alarm type' )}</TableHeadItem>
									<TableHeadItem>{t( 'Device name' )}</TableHeadItem>
									<TableHeadItem>{t( 'Alarm peak value' )}</TableHeadItem>
									<TableHeadItem>{t( 'Alarm start time' )}</TableHeadItem>
									<TableHeadItem>{t( 'Alarm location' )}</TableHeadItem>
								</tr>
							</thead>
							<tbody>
								{filteredAlarms.length ? (
									sortAlarmsByPriority( filteredAlarms ).map( ( alarm ) => (
										<ActiveAlarmRow alarm={ alarm } />
									) )
								) : (
									<NoDataRow>{t( 'No active alarms' )}</NoDataRow>
								)}
							</tbody>
						</ScrollableTable>
					</div>
					{/* Historical alarms list */}
					<div className="flex-1 flex flex-col relative overflow-auto">
						<h3 className="text-lg font-medium">{t( 'Historic alarms' )}</h3>
						{isLoadingAlarmsData ? (
							<LoadingSpinner containerClass="bg-base-100 w-full h-full rounded-md flex-1" />
						) : (
							<ScrollableTable>
								<thead>
									<tr>
										<TableHeadItem>{t( 'Alarm type' )}</TableHeadItem>
										<TableHeadItem>{t( 'Device name' )}</TableHeadItem>
										<TableHeadItem>{t( 'Alarm remark' )}</TableHeadItem>
										<TableHeadItem>{t( 'Alarm resolved at' )}</TableHeadItem>
										<TableHeadItem>{t( 'Alarm location' )}</TableHeadItem>
									</tr>
								</thead>
								<tbody>
									{historicalAlarms.length ? (
										historicalAlarms.map( ( alarm ) => (
											<HistoricalAlarmsRow alarm={ alarm } />
										) )
									) : (
										<NoDataRow>
											{t( 'No historical alarms match the selected filters' )}
										</NoDataRow>
									)}
								</tbody>
							</ScrollableTable>
						)}
						<div className="flex justify-between pt-6 items-center w-full">
							{showPaging && (
								<div className="flex justify-start text-primary mt-2 text-sm font-bold items-center shadow-bsm rounded-md">
									<Paging
										onPreviousPage={ handlePrevPageClick }
										onNextPage={ handleNextPageClick }
										currentPage={ historicalAlarmsPagingData.currentPage }
										totalPages={ historicalAlarmsPagingData.totalPages }
									/>
								</div>
							)}
							{userCanDownloadReports && (
								<Button
									text={ t( 'Get alarms report' ) }
									onClick={ onDownloadReport }
									icon={ <DataAnalysisIcon /> }
									className="w-1/3 mt-2 text-primary-content"
									disabled={ !canDownloadReport }
									loading={ isLoadingReportData }
								/>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AlarmLog;
