/* eslint-disable react/jsx-closing-bracket-location */
import React, { useCallback } from 'react';
import { ArrowLeftIcon } from '@heroicons/react/solid';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import FloorPlanRenderer, {
	placeSelector,
} from '../floorPlan/FloorPlanRenderer';
import { placesDefinitionAtom } from '../../state/places/placesDefinition';
import { PathOfLinksToSelectedPlace } from '../floorPlan/PathOfLinksToSelectedPlace';
import DeviceConfigurationForm from '../configuration/DeviceConfigurationForm';
import { DeviceFamilyType, DeviceTypeEnum } from '../../types';
import { activeAlarmsAtom } from '../../state/alarms/activeAlarms';
import { findDeviceByIdSelector } from '../../state/devices/selectors/findDeviceById';
import { findDeviceDefinition } from '../../state/devices/selectors/findDeviceDefinition';
import { filteredDevicesSelector } from '../../state/devices/selectors/filteredDevices';
import UserRoleNotice from '../UserRoleNotice';
import { useCalculateDeviceConnectionLinePosition } from '../../hooks/useCalculateDeviceConnectionLinePosition';
import { getHighestPriorityAlarm } from '../../utils/alarms';

interface ParamTypes {
	id: string;
}

const DeviceConfiguration: React.FC = () => {
	const params = useParams<ParamTypes>();
	const { t } = useTranslation();
	const history = useHistory();
	const formEndRef = React.createRef<HTMLDivElement>();
	const selectedDeviceId = params.id;
	const placesDefinitionTree = useRecoilValue( placesDefinitionAtom );
	const activeAlarms = useRecoilValue( activeAlarmsAtom );
	const selectedDevice = useRecoilValue(
		findDeviceByIdSelector( selectedDeviceId )
	);
	const deviceDefinitions = useRecoilValue(
		findDeviceDefinition( selectedDevice?.type )
	);
	const signallingDevices = useRecoilValue(
		filteredDevicesSelector( {
			family: DeviceFamilyType.SIGNALING,
			shouldBeActivated: true,
		} )
	);

	const {
		offsetTop,
		offsetLeft,
		offsetRight,
		onDeviceNodeChange,
		onScreenSeparatorNodeChange,
		containerRef,
	} = useCalculateDeviceConnectionLinePosition();

	// TODO: get this with selector
	const placeFromTreeWithSelectedDevice =
		Object.keys( placesDefinitionTree ).length &&
		placeSelector( placesDefinitionTree, undefined, selectedDeviceId );

	const backButtonClickHandler = useCallback( () => {
		history.goBack();
	}, [ history ] );

	const deviceName = selectedDevice?.name ?? '-';
	const hideFloorPlan = selectedDevice?.type === DeviceTypeEnum.VRM_SM;

	const displayFloorPlanRenderer =
		Object.keys( placesDefinitionTree ).length && placeFromTreeWithSelectedDevice;

	const alarmsForCurrentDevice = activeAlarms.filter(
		( alarm ) => alarm.deviceId === selectedDeviceId
	);
	const highestPriorityAlarm = getHighestPriorityAlarm( alarmsForCurrentDevice );
	const connectionLineColorClass = highestPriorityAlarm
		? `border-danger-${ highestPriorityAlarm.alarmSeverity.toLowerCase() } border-opacity-70`
		: 'border-primary-focus';

	return (
		<div
			ref={ containerRef }
			className={ `flex flex-1 ${ hideFloorPlan ? 'flex-col' : '' } relative` }
		>
			{hideFloorPlan && (
				<div className="flex flex-row px-8 pt-8">
					<button className="flex" onClick={ backButtonClickHandler }>
						<ArrowLeftIcon className="h-6 w-6 mr-5 mt-1" />
					</button>
					<h2 className="text-2xl mb-4">
						<b className="uppercase">{deviceName}</b>
						{selectedDevice ? (
							<span> - {t( 'Device Configuration' )}</span>
						) : null}
					</h2>
				</div>
			)}
			{!hideFloorPlan && (
				<div className="flex flex-col w-1/2 pl-8 pt-8 pr-5">
					{/* Header */}
					<div className="flex flex-row">
						<button className="flex" onClick={ backButtonClickHandler }>
							<ArrowLeftIcon className="h-6 w-6 mr-5 mt-1" />
						</button>
						<h2 className="text-2xl mb-6">
							<b className="uppercase">{deviceName}</b>
							{selectedDevice ? (
								<span> - {t( 'Device Configuration' )}</span>
							) : null}
						</h2>
					</div>
					{/* left side */}
					<div className="flex flex-1 w-full">
						{displayFloorPlanRenderer ? (
							<>
								<FloorPlanRenderer
									placesDefinitionTree={ placesDefinitionTree }
									selectedPlaceData={ placeFromTreeWithSelectedDevice }
									selectedDeviceId={ selectedDeviceId }
									activeAlarms={ activeAlarms }
									height="calc(100vh - 280px)"
									selectedDeviceRef={ onDeviceNodeChange }
								/>
								{/* connection line */}
								{offsetLeft !== undefined &&
									offsetRight !== undefined &&
									offsetTop !== undefined && (
									<div
										id="connecting-line"
										className={ `border-t-2 border-dashed fixed ${ connectionLineColorClass }` }
										style={ {
											left: `${ offsetLeft }px`,
											top: `${ offsetTop }px`,
											right: `${ offsetRight }px`,
										} }
									/>
								)}
							</>
						) : (
							<div className="flex bg-base-301 rounded-2xl p-10 mx-auto min-h-full w-full items-center">
								<p className="text-center mx-auto">
									{t( 'Device is not positioned on the floor plan' )}.
								</p>
							</div>
						)}
					</div>
					{placeFromTreeWithSelectedDevice ? (
						<div className="mt-6 text-sm italic">
							<PathOfLinksToSelectedPlace
								currentPlaceID={ placeFromTreeWithSelectedDevice.id }
							/>
						</div>
					) : null}
				</div>
			)}

			{/* Vertical screen separator */}
			{!hideFloorPlan && (
				<div
					ref={ onScreenSeparatorNodeChange }
					className={ `border-l-2 border-dashed absolute left-1/2 top-0 bottom-0 ${ connectionLineColorClass }` }
				/>
			)}

			{/* right side */}
			<div
				className={ `flex flex-col flex-1 overflow-y-auto scrollbar scrollbar-thumb-scrollbar scrollbar-track-scrollbar-default-bg scrollbar-thin ${
					!hideFloorPlan ? 'pl-8 py-10 pr-8' : 'p-8 pt-0'
				}` }
			>
				<UserRoleNotice requiredRole="MAINTENANCE" />
				{deviceDefinitions && selectedDevice ? (
					<DeviceConfigurationForm
						device={ selectedDevice }
						configurationDefinitions={ deviceDefinitions.configuration }
						signallingDevices={ signallingDevices }
						threeCols={ hideFloorPlan }
						formEndRef={ formEndRef }
					/>
				) : null}
			</div>
		</div>
	);
};

export default DeviceConfiguration;
