import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { AnimatedSpinIcon, CloseIcon } from '../../assets/icons';
import api from '../../middleware/api';
import { allDevicesStateAtom } from '../../state/devices/allDevices';
import { deviceDefinitionStateAtom } from '../../state/devices/deviceDefinitions';
import { placesDefinitionAtom } from '../../state/places/placesDefinition';
import { DeviceTypeEnum } from '../../types';
import SettingsLayout from '../SettingsLayout';
import UserRoleNotice from '../UserRoleNotice';
import { userHasRoleSelector } from '../../state/auth/selectors/userHasRole';
import ConfirmationDialog from '../modals/ConfirmationDialog';
import { activeAlarmsAtom } from '../../state/alarms/activeAlarms';
import Button from '../buttons/Button';

interface ManageDevicesProps {}

interface DeviceItemProps {
	id: string;
	name: string;
	type: string;
	hasActiveAlarms: boolean;
}

const DeviceItem: React.FC<DeviceItemProps> = ( {
	name,
	type,
	id,
	hasActiveAlarms = false,
} ) => {
	const { t } = useTranslation();
	const [ isConfirmationDialogOpen, setIsConfirmationDialogOpen ] =
		useState<boolean>( false );
	const [ isLoadingAction, setIsLoadingAction ] = useState<boolean>( false );

	const setAllDevices = useSetRecoilState( allDevicesStateAtom );
	const updatePlacesDefinition = useSetRecoilState( placesDefinitionAtom );

	const toggleConfirmationDialog = useCallback( () => {
		setIsConfirmationDialogOpen( ( isOpen ) => !isOpen );
	}, [] );

	const onDeleteItem = useCallback( async () => {
		/* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
		// Delete from devices
		try {
			setIsLoadingAction( true );

			await api.devices.delete( id );
			const devices = await api.devices.get();
			setAllDevices( devices );

			const updatedPlacesTree = await api.places.get();
			updatePlacesDefinition( updatedPlacesTree );

			setIsLoadingAction( false );
		} catch ( error ) {
			console.log( {
				msg: 'Problem Deleting an item',
				error,
			} );
			setIsLoadingAction( false );
			// TODO - handle failure
		}
	}, [ id, setAllDevices, updatePlacesDefinition ] );

	return (
		<div className="flex items-center relative drop-shadow-md hover:bg-base-300">
			<div className="w-6/12 p-3 py-2 flex-1 border-b border-base-300">
				{name || '-'}
			</div>
			<div className="w-5/12 p-3 py-2 flex-1  border-b border-base-300">
				{type}
			</div>
			<button
				className="flex justify-center p-3 py-2 items-center border-b border-base-300 text-base-800 cursor-pointer"
				onClick={ toggleConfirmationDialog }
				type="button"
			>
				{!isLoadingAction ? (
					<CloseIcon className="w-6 h-6 " />
				) : (
					<AnimatedSpinIcon className="w-6 h-6" />
				)}
			</button>
			<ConfirmationDialog
				title={
					hasActiveAlarms
						? t( 'delete-device-with-alarm-warning', { deviceName: name } )
						: t( 'delete-device-warning', { deviceName: name } )
				}
				toggleModal={ toggleConfirmationDialog }
				onChange={ onDeleteItem }
				onConfirm={ onDeleteItem }
				requiredRoleForConfirmation="MAINTENANCE"
				isOpen={ isConfirmationDialogOpen }
				isLoading={ isLoadingAction }
			/>
		</div>
	);
};

const NoDevices: React.FC<{ text: string }> = ( { text } ) => (
	<div className="flex items-center relative drop-shadow-md">
		<div className="w-6/12 p-3 py-2 flex-1 border-b border-base-300">
			{text}
		</div>
	</div>
);

const clearInput = ( inputText: string ) => {
	return inputText?.replace( / /g, '' ).toLowerCase();
};

const ManageDevices: React.FC<ManageDevicesProps> = () => {
	const history = useHistory();
	const [ name, setName ] = useState<string>( '' );
	const [ type, setType ] = useState<DeviceTypeEnum>();
	const [ loading, setLoading ] = useState( false );
	const [ error, setError ] = useState( false );
	const [ devices, setDevices ] = useRecoilState( allDevicesStateAtom );
	const [ placesTree, setPlacesTree ] = useRecoilState( placesDefinitionAtom );
	const deviceDefinitions = useRecoilValue( deviceDefinitionStateAtom );
	const activeAlarms = useRecoilValue( activeAlarmsAtom );
	const { t } = useTranslation();

	const settingsPath = history.location.pathname
		.split( '/' )
		.filter( ( location ) => location !== 'manage-devices' )
		.join( '/' );

	const onPrevStateHandler = useCallback( () => {
		history.push( `${ settingsPath }/places` );
	}, [ history, settingsPath ] );

	const onNextStepPressHandler = useCallback( () => {
		history.push( `${ settingsPath }/position-devices` );
	}, [ history, settingsPath ] );

	const isWizardMode = settingsPath.includes( 'wizard' );

	const addStackMonitorDeviceToMainArea = useCallback(
		async ( deviceID: string ) => {
			try {
				const placeID = placesTree.children[0].id;
				await api.places.addDevice(
					placeID,
					deviceID,
					// TO DO Remove the third parameter once backend allows adding device without position
					{
						position: {
							x: 0,
							y: 0,
						},
					}
				);
				api.places.get().then( ( res ) => setPlacesTree( res ) );
			} catch ( error: any ) {
				console.log( {
					msg: 'Problem adding a device',
					error,
				} );
				// TODO handle
			}
		},
		[ setPlacesTree, placesTree ]
	);

	const isAddingDevicesEnabled = !!name && !!type;

	const checkUniqueDeviceName = useCallback(
		( name: string, type: DeviceTypeEnum ) => {
			const nameAlreadyInUse = devices.some( ( device ) => {
				return clearInput( device.name ) === clearInput( name );
			} );

			return !nameAlreadyInUse;
		},
		[ devices ]
	);

	const onAddDeviceHandler = useCallback( async () => {
		// Add additional data from the device definition if it is needed
		// const deviceProps = deviceDefinitions.find(
		// 	( d ) => d.type === type,
		// ) || { type }; *
		if ( name && type ) {
			const isDeviceNameUnique = checkUniqueDeviceName( name, type );
			if ( !isDeviceNameUnique ) {
				setError( true );
				return;
			}
			if ( error ) {
				setError( false );
			}
			setLoading( true );
			try {
				const newDeviceData = await api.devices.create( { name, type } );
				// const newDeviceData = await response.json();
				const updatedDevices = await api.devices.get();
				setName( '' );
				if ( newDeviceData.type === DeviceTypeEnum.VRM_SM ) {
					await addStackMonitorDeviceToMainArea( newDeviceData.id );
				}
				setDevices( updatedDevices );
			} catch ( e ) {
				console.log( e );
				// TO DO Handle error
			}
			setLoading( false );
		}
	}, [
		name,
		type,
		checkUniqueDeviceName,
		error,
		setDevices,
		addStackMonitorDeviceToMainArea,
	] );

	const userCanEditSettings = useRecoilValue(
		userHasRoleSelector( 'MAINTENANCE' )
	);

	const inputErrorCssClass = error
		? 'border border-danger bg-danger bg-opacity-10'
		: '';

	return (
		<SettingsLayout isWizard={ isWizardMode } title={ t( 'Manage Devices' ) }>
			<div className="flex flex-col w-full h-full content-between">
				<div className="ml-10 mb-4">
					<UserRoleNotice requiredRole="MAINTENANCE" />
				</div>
				{userCanEditSettings ? (
					<div className="flex items-center m-10 mr-0 mt-0 gap-5">
						<div className="flex form-control w-1/4 flex-1 relative">
							{/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
							<label className="label">
								<span className="label-select">{t( 'Device name' )}</span>
							</label>

							<input
								type="text"
								placeholder={ t( 'Device name' ) }
								className={ `input input-sm rounded-md focus:outline-none outline-none mr-4 w-full ${ inputErrorCssClass }` }
								onChange={ ( e ) => setName( e.target.value ) }
								value={ name }
								onFocus={ () => {
									if ( error ) {
										setError( false );
									}
								} }
							/>

							{error && (
								<small className="text-danger mt-1 absolute -bottom-6">
									{t( 'The device name is not unique!' )}
								</small>
							)}
						</div>

						{/* Data inputs */}
						<div className="flex form-control w-1/2 flex-1">
							{/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
							<label className="label">
								<span className="label-select">{t( 'Device type' )}</span>
							</label>
							<select
								className="select select-sm rounded-md focus:outline-none outline-none mr-4 w-full font-normal"
								onChange={ ( e ) => setType( e.target.value as DeviceTypeEnum ) }
								defaultValue={ t( 'Select device type' ).toString() }
							>
								<option disabled>{t( 'Select device type' )}</option>
								{/* Remove hardcoded "VRM-SM" when API starts returning all of the types */}
								{deviceDefinitions.map( ( def ) => (
									<option key={ def.type }>{def.type}</option>
								) )}
							</select>
						</div>
						<div className="self-end">
							<Button
								className="mx-0"
								text={ t( 'Add' ) }
								onClick={ onAddDeviceHandler }
								disabled={ !isAddingDevicesEnabled }
								loading={ loading }
								isFormButton
							/>
						</div>
					</div>
				) : null}
				<div className="flex items-center mx-10 border-b-2 border-base-300 mb-2">
					<div className="w-6/12 p-2 flex-1 font-bold">{t( 'Device name' )}</div>
					<div className="w-5/12 p-2 flex-1 font-bold">{t( 'Device type' )}</div>
					<div className="w-10 h-1" />
				</div>
				<div className="flex flex-col ml-10 mb-5 flex-1 font-system overflow-auto">
					<div className="bg-primary-content rounded-md overflow-auto scrollbar scrollbar-thumb-scrollbar scrollbar-track-scrollbar-default-bg scrollbar-thin">
						{devices.length ? (
							devices.map( ( item ) => (
								<DeviceItem
									{ ...item }
									key={ item.id }
									hasActiveAlarms={
										!!activeAlarms.find(
											( activeAlarm ) => activeAlarm.deviceId === item.id
										)
									}
								/>
							) )
						) : (
							<NoDevices text={ t( 'No devices in VMS' ) } />
						)}
					</div>
				</div>
				{isWizardMode && (
					<div className="flex w-full justify-between">
						<Button
							text={ `< ${ t( 'Previous page' ) }` }
							onClick={ onPrevStateHandler }
							isFormButton
							className="px-2"
						/>
						<Button
							text={ `${ t( 'Position Devices' ) } >` }
							onClick={ onNextStepPressHandler }
							isFormButton
							className="px-2"
						/>
					</div>
				)}
			</div>
		</SettingsLayout>
	);
};

export default ManageDevices;
