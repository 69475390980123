import React, { ReactNode, useState, useRef } from 'react';
import { useDetectClickOutside } from 'react-detect-click-outside';
import { twMerge } from 'tailwind-merge';

const Select: React.FC<{
	defaultValue?: string;
	placeholder?: string;
	className?: string;
	onChange?: ( value: string ) => void;
	disabled?: boolean;
	options: Array<{ value: string; prettyName: string }>;
}> = ( {
	defaultValue,
	placeholder,
	className,
	onChange,
	disabled,
	options,
} ) => {
	const [ showDropdown, setShowDropdown ] = useState( false );
	const showDropdownHandler = () => setShowDropdown( !showDropdown );
	const selectPlaceholder = placeholder || 'Choose an option';

	const clickOutsideHandler = () => setShowDropdown( false );

	const selectContainerRef = useDetectClickOutside( {
		onTriggered: clickOutsideHandler,
	} );

	const updateSelectedOption = ( option: string ) => {
		onChange && onChange( option );
		setShowDropdown( false );
	};

	const styles = twMerge(
		className,
		showDropdown && 'relative',
		disabled && 'bg-base-200 opacity-70 border-0 cursor-default'
	);

	return (
		<button
			className={ styles }
			ref={ selectContainerRef }
			onClick={ !disabled ? showDropdownHandler : () => {} }
		>
			<div className="text-sm flex font-system h-full items-center rounded-md">
				{selectPlaceholder}
			</div>
			{showDropdown && (
				<div className="absolute text-left w-11/12 top-9 left-0 bg-primary-content border-secondary border text-sm font-system italic rounded overflow-hidden">
					{options.map( ( option ) => {
						return (
							<button
								className="italic py-1 w-full text-left pl-3 focus:bg-selected-click bg-primary-content hover:bg-selected hover:text-primary-content"
								key={ option.value }
								onClick={ () => onChange && updateSelectedOption( option.value ) }
							>
								{option.prettyName}
							</button>
						);
					} )}
				</div>
			)}
		</button>
	);
};

export default Select;
