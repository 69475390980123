import React from 'react';
import { useRecoilValue } from 'recoil';
import { filteredDevicesSelector } from '../../state/devices/selectors/filteredDevices';
import { AlarmSeverityEnum, DeviceTypeEnum } from '../../types';
import { activeAlarmsAtom } from '../../state/alarms/activeAlarms';
import SmallTile from './bottom-indicator/SmallTile';
import MiddleTile from './bottom-indicator/MiddleTile';
import LargeTile from './bottom-indicator/LargeTile';

export interface StackMonitorsBottomIndicatorProps {
	expanded: boolean;
}

const StackMonitorsBottomIndicator: React.FC<
StackMonitorsBottomIndicatorProps
> = ( { expanded } ) => {
	const activeAlarms = useRecoilValue( activeAlarmsAtom );

	const monitors = useRecoilValue(
		filteredDevicesSelector( {
			selectedTypes: [ DeviceTypeEnum.VRM_SM ],
			shouldBePlaced: true,
		} )
	);

	return (
		<div className="flex flex-1 items-center justify-center">
			<div className="absolute flex flex-row items-end justify-center gap-2 bottom-0 flex-wrap">
				{monitors.map( ( monitor ) => {
					const activeAlarmsForCurrentDevice = activeAlarms.filter(
						( alarm ) => alarm.deviceId === monitor.id
					);
					const regionsAlarms = activeAlarmsForCurrentDevice.filter( ( alarm ) => alarm.channelName?.startsWith( 'Region' ) );
					const deviceHasSingleDisconnectedAlarm =
						regionsAlarms.length === 0 &&
						activeAlarmsForCurrentDevice[0]?.alarmSeverity ===
							AlarmSeverityEnum.CONNECTION;

					if ( expanded ) {
						if (
							regionsAlarms.length === 1 ||
							deviceHasSingleDisconnectedAlarm
						) {
							return (
								<MiddleTile
									key={ monitor.id }
									id={ monitor.id }
									name={ monitor.name }
									alarms={ activeAlarmsForCurrentDevice }
								/>
							);
						}

						if ( regionsAlarms.length > 1 ) {
							return (
								<LargeTile
									key={ monitor.id }
									id={ monitor.id }
									name={ monitor.name }
									alarms={ activeAlarmsForCurrentDevice }
								/>
							);
						}
					}
					return (
						<SmallTile
							key={ monitor.id }
							id={ monitor.id }
							name={ monitor.name }
							alarms={ activeAlarmsForCurrentDevice }
						/>
					);
				} )}
			</div>
		</div>
	);
};

export default StackMonitorsBottomIndicator;
