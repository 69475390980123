import { ReactNode } from 'react';
import { Location } from '../../types';

const getStyle = ( deviceSize: number ) => {
	if ( deviceSize === undefined ) {
		return { iconSize: 0, iconOffset: 0, iconRadius: 0 };
	}

	let iconOffset = deviceSize / 2;
	let iconRadius = deviceSize / 4;
	let iconSize = iconRadius * 1.2;

	return { iconSize, iconOffset, iconRadius };
};

interface DeviceStatusIconProps {
	position: Location;
	colorClass: string;
	icon: ReactNode;
	deviceSize: number;
	title?: string;
}

export const DeviceStatusIcon = ( {
	position,
	colorClass,
	icon,
	deviceSize,
	title,
}: DeviceStatusIconProps ) => {
	const style = getStyle( deviceSize );

	return (
		<svg overflow="visible">
			<circle
				className={ colorClass }
				cx={ position.x - style.iconOffset }
				cy={ position.y - style.iconOffset }
				r={ style.iconRadius }
			/>
			<svg
				width={ style.iconSize }
				height={ style.iconSize }
				fill="white"
				x={ position.x - style.iconOffset - style.iconSize / 2 }
				y={ position.y - style.iconOffset - style.iconSize / 2 }
			>
				{icon}
			</svg>
			{title ? <title>{title}</title> : null}
		</svg>
	);
};
