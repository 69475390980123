import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { findLocatedDeviceByIdSelector } from '../../state/places/findLocatedDeviceById';
import { Alarm } from '../../types';
import { getAuditTimeStamp } from '../../utils/time';
import { AlarmListItemIcon } from '../alarms/AlarmListItemIcon';
import TableData from '../table/TableData';
import TableRow from '../table/TableRow';

interface AlarmItemProps {
	alarm: Alarm;
}

const HistoricalAlarmsRow: React.FC<AlarmItemProps> = ( { alarm } ) => {
	const { t } = useTranslation();

	const deviceLocation = useRecoilValue(
		findLocatedDeviceByIdSelector( alarm.deviceId )
	);
	const longLocation = deviceLocation?.locationPath;
	const locationParts = longLocation?.split( '/' ).slice( 1 );
	const shortLocation = locationParts?.length
		? locationParts[locationParts.length - 1]
		: '-';

	const created = getAuditTimeStamp( alarm.created, ', ' );
	const resolved = getAuditTimeStamp( alarm.resolved );
	const resolvedExtended = getAuditTimeStamp( alarm.resolved, ', ' );

	return (
		<TableRow>
			<TableData title={ alarm.alarmSeverity }>
				<div
					className={ `ml-0.5 mr-6 bg-danger-${ alarm.alarmSeverity.toLowerCase() } rounded-full z-10 flex h-7 items-center w-7 justify-center text-base-100` }
				>
					<AlarmListItemIcon type={ alarm.alarmSeverity } />
				</div>
			</TableData>
			<TableData>{alarm.deviceName}</TableData>
			<TableData>{alarm.remark}</TableData>
			<TableData
				title={ `${ t( 'Start time' ) }: ${ created }; ${ t(
					'Resolved at'
				) }: ${ resolvedExtended }` }
			>
				{resolved}
			</TableData>
			<TableData title={ locationParts?.join( ' / ' ) }>{shortLocation}</TableData>
		</TableRow>
	);
};

export default HistoricalAlarmsRow;
