import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
	ConfigurationSection,
	PropertyTypeEnum,
	DeviceRegionsGroup,
	DeviceRegionsGroupPropertyNameEnum,
} from '../../../types';
import NumberField from '../fields/NumberField';
import TextField from '../fields/TextField';
import * as validation from '../validation';
import OnOffField from '../fields/OnOffField';
import { getTranslatedConfigurationProperty } from '../../../utils/translations';

interface RegionsGroupProps {
	groupDefinitions: ConfigurationSection;
	groupConfiguration: Array<DeviceRegionsGroup>;
}

const DEFAULT_REGIONS_NUMBER = 3;

const RegionsGroup = ( {
	groupDefinitions,
	groupConfiguration,
}: RegionsGroupProps ) => {
	const { t } = useTranslation();
	const {
		register,
		control,
		watch,
		getValues,
		clearErrors,
		setValue,
		formState: { errors },
	} = useFormContext();

	const maxRegions = groupDefinitions.maxOcc ?? DEFAULT_REGIONS_NUMBER;

	const activeRegionsDefault = Array.from( Array( maxRegions ) ).map(
		( _, regionIndex ) => {
			let isActive =
				regionIndex === 0 ||
				Boolean(
					groupConfiguration[regionIndex]?.properties?.find(
						( p ) => p.name === DeviceRegionsGroupPropertyNameEnum.Valid
					)?.value
				);
			return {
				index: regionIndex,
				isActive,
			};
		}
	);
	const [ activeRegions, setActiveRegions ] =
		useState<Array<{ index: number; isActive: Boolean }>>( activeRegionsDefault );

	// eslint-disable-next-line consistent-return
	const toggleRegion = ( regionIndex: number ) => {
		// First region (Disabled) - cannot be toggled
		if ( regionIndex === 0 ) return false;
		setValue( `Region-${ regionIndex }`, undefined );
		clearErrors();

		let updatedActiveRegions = activeRegions.map( ( region ) => {
			if ( region.index === regionIndex ) {
				return { ...region, isActive: !region.isActive };
			}
			return region;
		} );

		setActiveRegions( updatedActiveRegions );
	};

	const customEfficiencyDefinition = groupDefinitions.properties.find(
		( definition ) => definition.name === 'CustomEfficiency'
	);

	return (
		<div>
			<h3 className="flex flex-1 font-bold">{t( 'Regions' )}</h3>
			<div className="flex flex-row justify-between">
				{Array.from( Array( maxRegions ).keys() ).map( ( regionIndex ) => {
					const isFirstRegion = regionIndex === 0;
					const isRegionActive = Boolean(
						activeRegions.find( ( region ) => region.index === regionIndex )
							?.isActive
					);

					let customEfficiencyDisabled = !isRegionActive;
					if ( isRegionActive ) {
						const customEfficiencyRateFlagWatch = watch(
							`Region-${ regionIndex }.UseCustomEfficiency.value`
						);
						const currentRegionValues = groupConfiguration[regionIndex];

						const customEfficiencyFlagConfigValue =
							currentRegionValues?.properties.find(
								( property ) => property.name === 'UseCustomEfficiency'
							)?.value;

						customEfficiencyDisabled =
							customEfficiencyRateFlagWatch !== undefined
								? !customEfficiencyRateFlagWatch
								: !customEfficiencyFlagConfigValue;
					}

					return (
						<div
							key={ `nuclide-${ regionIndex }` }
							className={ `mt-4 w-full border p-4 rounded-xl border-base-302 ${
								!isFirstRegion ? 'ml-3' : ''
							} ${ isRegionActive ? 'bg-base-100 bg-opacity-70' : '' }` }
						>
							{/* Toggle region */}
							<label className="flex flex-row justify-between font-bold text-sm pb-2">
								<h4 className="flex-1">
									{t( 'Nuclide' )} {regionIndex + 1}
								</h4>
								<input
									type="checkbox"
									onClick={ () => toggleRegion( regionIndex ) }
									className={ `checkbox checkbox-sm checkbox-primary ml-2 ${
										isFirstRegion ? 'hidden' : ''
									}` }
									defaultChecked={ isFirstRegion || isRegionActive }
									disabled={ isFirstRegion }
									{ ...register( `Region-${ regionIndex }.Valid.value`, {
										value: activeRegions[regionIndex].isActive,
									} ) }
								/>
							</label>
							{groupDefinitions.properties
								.filter( ( prop ) => {
									const excludedDefinitionsNames = [
										'UseCustomEfficiency',
										'CustomEfficiency',
										'Valid',
									];
									const shouldExcludeField = excludedDefinitionsNames.includes(
										prop.name
									);
									return shouldExcludeField ? false : true;
								} )
								.map( ( regionProperty ) => {
									const existingFieldConfig = groupConfiguration[
										regionIndex
									]?.properties.find(
										( prop ) => prop.name === regionProperty.name
									);
									const translatedProperty = getTranslatedConfigurationProperty(
										regionProperty.name
									);
									switch ( regionProperty.type.toString() ) {
										case PropertyTypeEnum.Number:
										case PropertyTypeEnum.Float:
										case PropertyTypeEnum.Double:
										case PropertyTypeEnum.TimeSpan:
										case PropertyTypeEnum.AlarmHigh:
										case PropertyTypeEnum.AlarmHighHigh:
										case PropertyTypeEnum.Uint: {
											return (
												<NumberField
													key={ `${ regionProperty.name }-${ regionIndex }` }
													name={ `Region-${ regionIndex }.${ regionProperty.name }` }
													label={ translatedProperty }
													control={ control }
													id={ `${ regionProperty.name }-${ regionIndex }` }
													placeHolder={ t( 'enter-field-value', {
														name: translatedProperty,
													} ) }
													fieldDefinition={ regionProperty }
													rules={ {
														validate: {
															alarmLevels: () => {
																const highFieldName = `Region-${ regionIndex }.HighAlarmLevels.value`;
																const highHighFieldName = `Region-${ regionIndex }.HighHighAlarmLevels.value`;

																return validation.alarmLevelsDependencies(
																	getValues( [ highFieldName, highHighFieldName ] ),
																	regionProperty.type
																);
															},
															energyRange: () => {
																if (
																	regionProperty.name === 'BeginEnergy' ||
																	regionProperty.name === 'EndEnergy'
																) {
																	const beginEnergy = getValues(
																		`Region-${ regionIndex }.BeginEnergy.value`
																	);
																	const endEnergy = getValues(
																		`Region-${ regionIndex }.EndEnergy.value`
																	);

																	if (
																		typeof beginEnergy === 'number' &&
																		typeof endEnergy === 'number' &&
																		beginEnergy >= endEnergy
																	) {
																		return regionProperty.name === 'BeginEnergy'
																			? t(
																				'error-begin-energy-greater-than-end-energy'
																			  ).toString()
																			: t(
																				'error-end-energy-less-than-begin-energy'
																			  ).toString();
																	}
																}
																return true;
															},
														},
													} }
													defaultValue={ existingFieldConfig?.value }
													defaultUnit={ existingFieldConfig?.unit }
													disabled={ !isRegionActive }
													minValue={ 0 }
													invalidChars={ [ '+', '-', 'e' ] }
													required
												/>
											);
										}
										default: {
											return (
												<TextField
													key={ `${ regionProperty.name }-${ regionIndex }` }
													index={ regionIndex }
													name={ `Region-${ regionIndex }.${ regionProperty.name }.value` }
													control={ control }
													rules={ {
														disabled: !isRegionActive,
														...validation.createRangeRule(
															regionProperty.range[0]
														),
														required: {
															value: true,
															message: t( 'error-field-required', {
																name: translatedProperty,
															} ),
														},
													} }
													label={ translatedProperty }
													placeHolder={ t( 'enter-field-value', {
														name: translatedProperty,
													} ) }
													defaultValue={ existingFieldConfig?.value }
													disabled={ !isRegionActive }
												/>
											);
										}
									}
								} )}

							{/* Extracted fields out of the loop */}
							{customEfficiencyDefinition ? (
								<div>
									<div className="flex flex-row justify-between items-center mt-3">
										{/* <span className="text-sm relative block">
											{ t( 'use-custom-efficiency' ) }
										</span> */}
										<OnOffField
											label={ t( 'use-custom-efficiency' ) }
											register={ register }
											name={ `Region-${ regionIndex }.UseCustomEfficiency.value` }
											disabled={ !isRegionActive }
											defaultValue={
												!!groupConfiguration[regionIndex]?.properties.find(
													( prop ) => prop.name === 'UseCustomEfficiency'
												)?.value
											}
										/>
									</div>
									<NumberField
										name={ `Region-${ regionIndex }.CustomEfficiency` }
										control={ control }
										placeHolder={ t( 'Enter CustomEfficiency' ) }
										fieldDefinition={ customEfficiencyDefinition }
										defaultValue={
											groupConfiguration[regionIndex]?.properties.find(
												( prop ) => prop.name === 'CustomEfficiency'
											)?.value
										}
										defaultUnit={
											groupConfiguration[regionIndex]?.properties.find(
												( prop ) => prop.name === 'CustomEfficiency'
											)?.unit
										}
										disabled={ customEfficiencyDisabled }
										minValue={ 0 }
										invalidChars={ [ '+', '-', 'e' ] }
										required
									/>
								</div>
							) : null}
						</div>
					);
				} )}
			</div>
		</div>
	);
};

export default RegionsGroup;
