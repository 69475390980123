/* ------------------------------------------------------------------------------------------------------------
		* Accept the following params (either via the props or the state):
			-> Places Definition - [svg and the places JSON]
			-> Active Alarms - [to visualize the places and devices with alarms]
			-> Selected Place (optional), defaults to the first child of the "root" node - [zooms the view to it]
			-> Selected Device (optional) - [changes its visualization].
			-> Mode (optional) - {readonly | drag-and-drop | define-places}; default to readonly
		* Do the following actions (either via the props or directly updating the state):
			-> Add or Update Device Location
			-> Navigate to a page
			-> Zoom In/Out (could be merged with the navigation)
		* Update its visualization when:
			-> A new alarm is added
			-> An existing alarm is updated
------------------------------------------------------------------------------------------------------------*/

import React, {
	useCallback,
	useEffect,
	useLayoutEffect,
	useRef,
	useState,
} from 'react';
import { useParams } from 'react-router';
import {
	PlaceDefinition, Alarm, Location, FloorPlanDevice
} from '../../types';
import {
	generateSVGPath,
	getNestedDevicesList,
	getViewBoxFromElement,
	isRounded,
	findPlaceByDeviceId,
	searchPlaceNodeInTree,
	getListWithUpdatedDevicePosition,
} from '../../utils/floorPlan';
import PlaceClipper from './PlaceClipper';
import config from '../../config-loader';
import ReadOnlyFloorPlanLayer from './ReadOnlyFloorPlanLayer';
import DragAndDropFloorPlanLayer from './DragAndDropFloorPlanLayer';
import { DEFAULT_SVG_HEIGHT } from './floorplanConfig';

export enum ModeEnum {
	READONLY = 'readonly',
	DRAG_AND_DROP = 'drag-and-drop',
	// DEFINE_PLACES = 'define-places',
}

interface FloorPlanRendererProps {
	placesDefinitionTree: PlaceDefinition;
	selectedPlaceData: PlaceDefinition;
	mode?: ModeEnum; // default 'readonly'
	activeAlarms?: Alarm[];
	selectedDeviceId?: string;
	handleOnPlaceClickExternally?: ( place: PlaceDefinition ) => void;
	handleOnDeviceClickExternally?: ( deviceId: string ) => void;
	addOrUpdateDeviceLocation?: (
		placeId: string,
		deviceId: string,
		position: Location,
		droppedItemType: string
	) => void;
	removeDeviceLocation?: ( deviceID: string ) => void;
	height?: number | string;
	showDeviceName?: boolean;
	showDeviceConnectionStatus?: boolean;
	showSignallingDevices?: boolean;
	selectedDeviceRef?: any;
}

interface ParamTypes {
	id: string;
}

export const placeSelector = (
	placesTree: PlaceDefinition,
	selectedPlaceID: string = 'root',
	selectedDeviceId?: string
) => {
	if ( placesTree ) {
		if ( selectedDeviceId ) {
			// Returns place node with selected device
			return findPlaceByDeviceId( placesTree, selectedDeviceId, false );
		}
		if ( selectedPlaceID === 'root' && placesTree.isRoot ) {
			// Returns first area in the root when selected place id is root
			return placesTree.children[0];
		}
		// Returns place node with selected place id
		let foundNestedPlaceNode = searchPlaceNodeInTree(
			placesTree,
			'id',
			selectedPlaceID
		);
		if ( foundNestedPlaceNode ) {
			return foundNestedPlaceNode;
		}
	}

	return undefined;
};

type ZoomAnimationRef = SVGAnimateElement & {
	beginElement: () => void;
	addEventListener: (
		type: string,
		callback: EventListenerOrEventListenerObject | null,
		options?: AddEventListenerOptions | boolean
	) => void;
	removeEventListener: (
		type: string,
		callback: EventListenerOrEventListenerObject | null,
		options?: EventListenerOptions | boolean
	) => void;
};

function FloorPlanRenderer( {
	mode,
	placesDefinitionTree,
	selectedPlaceData,
	activeAlarms,
	handleOnPlaceClickExternally,
	handleOnDeviceClickExternally,
	selectedDeviceId,
	showDeviceConnectionStatus,
	addOrUpdateDeviceLocation,
	removeDeviceLocation,
	height,
	showDeviceName,
	showSignallingDevices,
	selectedDeviceRef,
}: FloorPlanRendererProps ) {
	const params = useParams<ParamTypes>();
	const componentMode = mode ? mode : ModeEnum.READONLY;
	const floorPlanRootRef = useRef<SVGSVGElement | null>( null );
	const floorPlanImageRef = useRef<SVGSVGElement | null>( null );
	const clippedPlaceRef = useRef<SVGPathElement | null>( null );
	const zoomAnimationRef = useRef<ZoomAnimationRef>( null );

	const [ devices, setDevices ] = useState<Array<FloorPlanDevice>>( [] );
	const [ rootViewBox, setRootViewBox ] = useState<string>();
	const [ currentlyZoomedViewBox, setCurrentlyZoomedViewBox ] =
		useState<string>();
	const [ editDeviceId, setEditDeviceId ] = useState<string>();
	const updateEditDeviceId = ( id: string | undefined ) => {
		setEditDeviceId( id );
	};

	const getAndSetRootViewBox = useCallback( () => {
		if ( clippedPlaceRef && clippedPlaceRef.current ) {
			// outline padding
			const padding = 4;
			const currentPlaceViewBox = getViewBoxFromElement(
				clippedPlaceRef.current,
				padding
			);

			setRootViewBox( currentPlaceViewBox );
		}
	}, [] );

	useLayoutEffect( () => {
		getAndSetRootViewBox();
	}, [ params, getAndSetRootViewBox ] );

	useEffect( () => {
		if ( selectedPlaceData ) {
			setDevices( getNestedDevicesList( selectedPlaceData ) );
		}
	}, [ selectedPlaceData ] );

	const selectedDeviceIndexInList = devices.findIndex(
		( device ) => device?.deviceId === selectedDeviceId || device?.deviceId === editDeviceId
	);

	// repositioning selected device at the end of the list
	// will show it on top of the other devices on the Floorplan
	useEffect( () => {
		if ( selectedDeviceIndexInList !== -1 ) {
			setDevices( ( devices: Array<FloorPlanDevice> ) => {
				const newPosition = devices.length - 1; // this is the index indicating the end of the list
				const reorderedList = getListWithUpdatedDevicePosition(
					devices,
					selectedDeviceIndexInList,
					newPosition
				);
				return reorderedList;
			} );
		}
	}, [ selectedDeviceIndexInList ] );

	const zoomToElement = useCallback(
		( element: SVGSVGElement | SVGPathElement ) => {
			const padding = 4; // the size of the stroke
			const selectedElementViewBox = getViewBoxFromElement( element, padding );

			let from = rootViewBox ?? '';
			let to = selectedElementViewBox ?? '';

			// -> When user is first time on the screen and zoom to selected place
			if ( !currentlyZoomedViewBox && rootViewBox ) {
				from = rootViewBox;
				to = selectedElementViewBox;
				setCurrentlyZoomedViewBox( selectedElementViewBox );
			}

			// -> User has already zoomed to a place
			if (
				currentlyZoomedViewBox &&
				currentlyZoomedViewBox !== selectedElementViewBox
			) {
				from = currentlyZoomedViewBox;
				to = selectedElementViewBox;
				setCurrentlyZoomedViewBox( selectedElementViewBox );
			}

			// -> Zoom out
			if (
				currentlyZoomedViewBox &&
				selectedElementViewBox === currentlyZoomedViewBox &&
				rootViewBox
			) {
				from = currentlyZoomedViewBox;
				to = rootViewBox;
				setCurrentlyZoomedViewBox( rootViewBox );
			}

			zoomAnimationRef.current?.setAttribute( 'from', from );
			zoomAnimationRef.current?.setAttribute( 'to', to );
			zoomAnimationRef.current?.beginElement();
		},
		[ rootViewBox, currentlyZoomedViewBox ]
	);

	const svgImage =
		placesDefinitionTree.isRoot &&
		placesDefinitionTree.children[0].backgroundType === 'svg'
			? placesDefinitionTree.children[0].background
			: undefined;

	const floorPlanRootOverflow =
		componentMode === ModeEnum.DRAG_AND_DROP &&
		currentlyZoomedViewBox !== undefined
			? 'hidden'
			: 'visible';
	const floorPlanRootViewBox =
		componentMode === ModeEnum.DRAG_AND_DROP && currentlyZoomedViewBox
			? currentlyZoomedViewBox
			: rootViewBox;

	const floorPlanRootHeight =
		height !== undefined ? height : DEFAULT_SVG_HEIGHT;
	const deviceSize = config.deviceSize;

	return (
		<div className="relative self-center w-full">
			{/* Root svg  */}
			<svg
				id="floorPlanRoot"
				xmlns="http://www.w3.org/2000/svg"
				ref={ floorPlanRootRef }
				viewBox={ floorPlanRootViewBox }
				height={ floorPlanRootHeight }
				width="100%"
				overflow={ floorPlanRootOverflow }
				className="mx-auto select-none"
			>
				{/* User defined floor plan image */}
				{svgImage ? (
					<svg
						// eslint-disable-next-line
						dangerouslySetInnerHTML={{ __html: svgImage }}
						id="floorArea"
						ref={ floorPlanImageRef }
						clipPath="url(#clippedPlace)"
						overflow="visible"
					/>
				) : null}

				<PlaceClipper
					ref={ clippedPlaceRef }
					path={ generateSVGPath(
						selectedPlaceData.box,
						isRounded( selectedPlaceData.type )
					) }
				/>

				{/* MODE => Read only */}
				{componentMode === ModeEnum.READONLY && (
					<ReadOnlyFloorPlanLayer
						selectedPlaceData={ selectedPlaceData }
						activeAlarms={ activeAlarms }
						devices={ devices }
						selectedDeviceId={ selectedDeviceId }
						onPlaceClickCallback={ handleOnPlaceClickExternally }
						showSignallingDevices={ showSignallingDevices }
						onDeviceClickCallback={ handleOnDeviceClickExternally }
						deviceSize={ deviceSize }
						showDeviceConnectionStatus={ showDeviceConnectionStatus }
						showDeviceName={ showDeviceName }
						selectedDeviceRef={ selectedDeviceRef }
					/>
				)}

				{/* MODE => Drag & Drop */}
				{componentMode === ModeEnum.DRAG_AND_DROP && (
					<DragAndDropFloorPlanLayer
						selectedPlaceData={ selectedPlaceData }
						devices={ devices }
						selectedDeviceId={ selectedDeviceId }
						onPlaceClickCallback={ handleOnPlaceClickExternally }
						deviceSize={ deviceSize }
						showDeviceName={ showDeviceName }
						zoomToElement={ zoomToElement }
						addOrUpdateDeviceLocation={ addOrUpdateDeviceLocation }
						updateEditDeviceId={ updateEditDeviceId }
						removeDeviceLocation={ removeDeviceLocation }
						editDeviceId={ editDeviceId }
						floorPlanImageRef={ floorPlanImageRef }
						zoomAnimationRef={ zoomAnimationRef }
					/>
				)}
			</svg>
		</div>
	);
}

export default FloorPlanRenderer;
