import { atom } from 'recoil';
import { UserRole } from '../../middleware/auth';

const initialState: UserRole[] = [];

export const userRolesAtom = atom<UserRole[]>( {
	key: 'userRoles',
	// get initial state from local storage to enable user to stay logged in
	default: initialState,
} );
