import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { UserRole } from '../middleware/auth';
import { AppScreen } from '../shared/constants';
import { userHasRoleSelector } from '../state/auth/selectors/userHasRole';
import Alert from './Alert';

export interface UserRoleNoticeProps {
	requiredRole: UserRole;
	className?: string;
	message?: string;
}

const UserRoleNotice: React.FC<UserRoleNoticeProps> = ( {
	requiredRole,
	className,
	message,
} ) => {
	const { t } = useTranslation();

	const userHasRole = useRecoilValue( userHasRoleSelector( requiredRole ) );

	return !userHasRole ? (
		<Alert type="warning">
			<div className={ className }>
				<span className="text-base-content">
					{message ?? t( "You don't have the rights to edit these settings." )}
				</span>
				<Link className="text-primary inline-block" to={ AppScreen.LOGIN }>
					{t( 'change-user-role', { userRole: requiredRole.toLowerCase() } )}
				</Link>
			</div>
		</Alert>
	) : null;
};

export default UserRoleNotice;
