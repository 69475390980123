import React from 'react';
import {
	Control,
	FieldValues,
	RegisterOptions,
	useController,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
import { PropertyDefinition } from '../../../types';
import * as validation from '../validation';

export interface NumberFieldProps {
	control: Control<FieldValues, object>;
	name: string;
	rules?: Exclude<
	RegisterOptions,
	'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'
	>;
	id?: string;
	required?: boolean;
	label?: string;
	placeHolder?: string;
	defaultValue?: number;
	defaultUnit?: string;
	disabled?: boolean;
	fieldDefinition?: PropertyDefinition;
	invalidChars?: Array<'e' | '+' | '-'>;
	minValue?: number;
}

const isValueValidNumber = ( value: string ) => {
	return value !== '' && !Number.isNaN( Number( value ) );
};

const NumberField: React.FC<NumberFieldProps> = ( {
	control,
	name,
	label,
	placeHolder,
	defaultValue,
	rules,
	id,
	defaultUnit,
	disabled,
	required,
	fieldDefinition,
	invalidChars = [],
	minValue,
} ) => {
	const { t } = useTranslation();
	const { field: unitField } = useController( {
		control,
		name: `${ name }.unit`,
		defaultValue: defaultUnit,
	} );

	const {
		field: inputField,
		fieldState: { error },
	} = useController( {
		control,
		name: `${ name }.value`,
		defaultValue:
			defaultValue !== undefined && !Number.isNaN( Number( defaultValue ) )
				? Number( defaultValue )
				: '',
		rules: {
			disabled,
			...( required && {
				required: label
					? t( 'error-field-required', { name: label } ).toString()
					: t( 'Field is required' ).toString(),
			} ),
			...( fieldDefinition?.range &&
				fieldDefinition.range.length > 0 &&
				validation.createRangeRule(
					unitField.value
						? fieldDefinition.range.find(
							( rangeItem ) => rangeItem.unit === unitField.value
						  )
						: fieldDefinition.range[0]
				) ),
			...rules,
		},
	} );

	const handleOnKeyDown = ( event: React.KeyboardEvent<HTMLInputElement> ) => {
		if ( invalidChars.find( ( character ) => character === event.key ) ) {
			event.stopPropagation();
			event.preventDefault();
		}
	};

	const handleOnPaste = ( event: React.ClipboardEvent<HTMLInputElement> ) => {
		const pastedData = event.clipboardData.getData( 'Text' ).toLowerCase();

		if (
			invalidChars.find( ( character ) => pastedData.includes( character.toLowerCase() ) )
		) {
			event.stopPropagation();
			event.preventDefault();
		}
	};

	const handleOnChange = ( event: React.ChangeEvent<HTMLInputElement> ) => {
		const userEnteredValue = event.target.value;

		const processedUserEnteredValue = isValueValidNumber( userEnteredValue )
			? Number( userEnteredValue )
			: '';

		return inputField.onChange( processedUserEnteredValue );
	};

	const unit = fieldDefinition?.unit;
	const rangeOptions = fieldDefinition?.range || [];

	const showUnitsOptions = Boolean( unit && rangeOptions.length > 1 );
	const showUnitText = Boolean( unit && rangeOptions.length <= 1 );

	const inputStyles = twMerge(
		'input input-md input-bordered disabled:bg-base-300 rounded-md w-full h-9 leading-9 pr-0',
		error && 'border border-danger bg-danger bg-opacity-10',
		showUnitText && 'rounded-r-none rounded-l-md '
	);

	const unitTextStyles = twMerge(
		'h-9 flex justify-center items-center text-xs font-bold px-2 border-base-302 border rounded-r-md border-l-0 bg-base-302 bg-opacity-20 ml-0',
		disabled && 'opacity-50'
	);

	let inputId = id ?? `configuration-${ name }`;

	return (
		<label
			htmlFor={ inputId }
			className="block mt-2 text-sm flex-1 justify-between items-center"
		>
			{label ? (
				<span className={ `${ disabled ? 'opacity-40' : '' }` }>{label}</span>
			) : null}
			<div className="flex items-center mt-1 relative">
				<input
					{ ...inputField }
					id={ inputId }
					placeholder={ placeHolder ? placeHolder : `Enter ${ name } value` }
					type="number"
					className={ inputStyles }
					step="any"
					onChange={ handleOnChange }
					disabled={ disabled }
					onKeyDown={ handleOnKeyDown }
					onPaste={ handleOnPaste }
					min={ minValue }
				/>

				{showUnitsOptions ? (
					<select
						{ ...unitField }
						disabled={ disabled }
						className="select select-bordered select-xs rounded disabled:bg-base-300 disabled:opacity-50 h-9 max-w-xs pr-10 ml-2 text-xs bg-opacity-50"
					>
						{rangeOptions.map( ( option ) => (
							<option key={ option.unit }>{option.unit}</option>
						) )}
					</select>
				) : null}

				{showUnitText && <span className={ unitTextStyles }>{unit}</span>}
			</div>
			{!!error && (
				<span className="text-danger text-xs pt-1 block">{error.message}</span>
			)}
		</label>
	);
};

export default NumberField;
