import React from 'react';
import { roundMeasurement } from '../../utils/device';

interface RegionMeasurementProps {
	name: string;
	value?: number;
	unit?: string;
}

const RegionMeasurement: React.FC<RegionMeasurementProps> = ( {
	name,
	value,
	unit,
} ) => {
	return (
		<div className="flex items-center text-left">
			<div className="w-16 inline-block truncate ">{name}</div>
			<div className="flex flex-row flex-wrap items-center font-semibold justify-baseline md:flex-none">
				<span className="inline-block leading-4">
					{value !== undefined ? roundMeasurement( value ) : '-'}
				</span>
				{unit !== undefined ? (
					<span className="pl-1 leading-4">{unit}</span>
				) : null}
			</div>
		</div>
	);
};

export default RegionMeasurement;
