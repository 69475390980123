import { DateTime } from 'luxon';
import React from 'react';
import { useLocation } from 'react-router';

interface IDateRange {
	periodFrom?:string;
	periodTo?:string
}

const useDateRangeFromQuery = (): IDateRange => {
	const search = useLocation().search;
	const from = new URLSearchParams( search ).get( 'from' );
	const to = new URLSearchParams( search ).get( 'to' );

	const periodFrom = from ? DateTime.fromISO( from ).startOf( 'day' ).toISO() : undefined;
	const periodTo = to ? DateTime.fromISO( to ).endOf( 'day' ).toISO() : undefined;


	return { periodFrom, periodTo };
};

export default useDateRangeFromQuery;
