import { useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import api from '../middleware/api';
import {
	CommonDeviceConfigurationGroup,
	CommonPropertyNameEnum,
	ConfigGroupName,
	ConnectionTypeEnum,
	Device,
	DeviceFamilyType,
	DeviceTypeEnum,
} from '../types';
import { ErrorType, getApiErrors } from '../utils/errors';
import { useRefreshDevices } from './useRefreshDevices';

const useConnectDeviceForm = (
	device: Device,
	configuration: CommonDeviceConfigurationGroup,
	defaultValues: FieldValues
) => {
	const { t } = useTranslation();
	const refreshDevices = useRefreshDevices();

	const {
		handleSubmit,
		control,
		formState: { errors, dirtyFields },
	} = useForm( {
		mode: 'onTouched',
		reValidateMode: 'onChange',
		defaultValues,
	} );

	// We don't use the built-in isDirty field from the formState,
	// since it is actually isTouched (set to true on focus->blur)
	// https://github.com/react-hook-form/react-hook-form/issues/3213
	const isDirty = !!Object.keys( dirtyFields ).length;

	const [ testConnectionStatus, setTestConnectionStatus ] = useState<
	'fail' | 'success' | undefined
	>();
	const [ testConnectionMessage, setTestConnectionMessage ] = useState( '' );
	const [ testConnectionLoading, setTestConnectionLoading ] = useState( false );
	const [ saveConfigurationLoading, setSaveConfigurationLoading ] =
		useState( false );
	const [ saveConfigurationStatus, setSaveStatus ] = useState<
	'fail' | 'success' | undefined
	>();
	const [ saveConfigurationMessage, setSaveConfigurationMessage ] = useState( '' );
	const [ responseStatus, setResponseStatus ] = useState<
	'fail' | 'success' | undefined
	>();
	const [ responseMessage, setResponseMessage ] = useState( '' );
	const [ deviceActivationFailed, setDeviceActivationFailed ] = useState( false );

	const resetState = () => {
		if ( deviceActivationFailed ) {
			setDeviceActivationFailed( false );
		}
	};

	const saveConfiguration = async (
		deviceName: string,
		deviceType: DeviceTypeEnum,
		address: string,
		port: number
	) => {
		// 1. Updated config fields
		const updatedDevice = {
			type: deviceType,
			name: deviceName,
			configuration: [
				{
					name: ConfigGroupName.Common,
					properties: [
						{
							name: CommonPropertyNameEnum.Type,
							value: ConnectionTypeEnum.MacAddress,
						},
						{
							name: CommonPropertyNameEnum.Address,
							value: address,
						},
						{
							name: CommonPropertyNameEnum.PortNumber,
							value: port,
						},
					],
				},
			],
		} as Device;

		// 2. Call update api with updated device fields
		return api.devices.update( device.id, updatedDevice );
	};

	const handleTestConnection = async ( {
		port,
		address,
	}: {
		port: { value: number };
		address: { value: string };
	} ) => {
		resetState();
		setTestConnectionLoading( true );
		try {
			await api.devices.checkConnection(
				device.type,
				device.family,
				address.value,
				port.value,
				'MacAddress'
			);

			// throw new Error()
			setTestConnectionStatus( 'success' );
			setResponseStatus( 'success' );
			setTestConnectionMessage( t( 'success-test-connection-message' ) );
			setResponseMessage( t( 'success-test-connection-message' ) );
		} catch ( error ) {
			const errorMessage =
				getApiErrors( error as ErrorType )?.customMessage ||
				t( 'fail-test-connection-message' );
			setTestConnectionStatus( 'fail' );
			setResponseStatus( 'fail' );
			setResponseMessage( errorMessage );
			setTestConnectionMessage( errorMessage );
		}
		setTestConnectionLoading( false );
	};

	const handleSave = async ( {
		port,
		address,
	}: {
		port: { value: number };
		address: { value: string };
	} ) => {
		resetState();
		setSaveConfigurationLoading( true );
		if ( Object.keys( errors ).length > 0 || !port || !address || !configuration ) return;

		try {
			// 1. Call save api
			await saveConfiguration(
				device.name,
				device.type,
				address.value,
				port.value
			);
			// 2. active if signalling device
			if ( device.family === DeviceFamilyType.SIGNALING ) {
				try {
					await api.devices.activate( device.id );
				} catch ( error ) {
					setDeviceActivationFailed( true );
				}
			}

			refreshDevices();

			setResponseStatus( 'success' );
			setSaveStatus( 'success' );
			setResponseMessage( t( 'Successfully updated the configuration' ) );
			setSaveConfigurationMessage( t( 'Successfully updated the configuration' ) );
		} catch ( error ) {
			setResponseStatus( 'fail' );
			setSaveStatus( 'fail' );
			const errorMessage =
				getApiErrors( error as ErrorType )?.customMessage ||
				t( 'Could not update configuration' );
			setResponseMessage( errorMessage );
			setSaveConfigurationMessage( errorMessage );
		}
		setSaveConfigurationLoading( false );
	};

	const onTestConnection = handleSubmit( handleTestConnection );
	const onSave = handleSubmit( handleSave );

	return {
		onTestConnection,
		onSave,
		control,
		isDirty,
		testConnectionLoading,
		saveConfigurationLoading,
		testConnectionStatus,
		testConnectionMessage,
		saveConfigurationStatus,
		saveConfigurationMessage,
		responseStatus,
		responseMessage,
		deviceActivationFailed,
		errors,
	};
};

export { useConnectDeviceForm };
