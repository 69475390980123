import { selectorFamily } from 'recoil';
import { DeviceTypeEnum, DeviceFamilyType } from '../../../types';
import { checkDeviceAddedInPlaceDefinition } from '../../../utils/device';
import { allDevicesStateAtom } from '../allDevices';
import { placesDefinitionAtom } from '../../places/placesDefinition';
import { NonEmptyArray, RequireAtLeastOne } from '../../../utils/types';

// TODO: Add support for include all but..(exclude filter)
type Filters = {
	selectedTypes: NonEmptyArray<DeviceTypeEnum>;
	family: DeviceFamilyType;
	shouldBePlaced: boolean; // will return all devices which ids are present in PlacesDefinition (default - FALSE)
	shouldBeActivated: boolean;
};

export const filteredDevicesSelector = selectorFamily( {
	key: 'filteredDevicesSelector',
	get:
		( filters: RequireAtLeastOne<Filters> ) => ( { get } ) => {
			const devices = get( allDevicesStateAtom );
			const places = get( placesDefinitionAtom );

			return devices.filter( ( device ) => {
				// Push all checks results as flags (Boolean) inside this array
				// At the end we check if all conditions are met and decide whether to include the device
				const conditionsChecksList: Boolean[] = [];

				if (
					'selectedTypes' in filters &&
					filters.selectedTypes &&
					filters.selectedTypes.length
				) {
					const conditionCheckResult = filters.selectedTypes.includes(
						device.type
					);
					conditionsChecksList.push( conditionCheckResult );
				}

				if ( 'shouldBePlaced' in filters && Object.keys( places ).length ) {
					const isDeviceInPlaces = checkDeviceAddedInPlaceDefinition(
						places,
						device.id
					);
					const conditionCheckResult =
						isDeviceInPlaces === filters.shouldBePlaced;
					conditionsChecksList.push( conditionCheckResult );
				}

				if ( 'family' in filters ) {
					const isDeviceFamilyTypeMatching = filters.family === device.family;
					conditionsChecksList.push( isDeviceFamilyTypeMatching );
				}

				if ( 'shouldBeActivated' in filters ) {
					const isActivatedFilterSatisfied =
						device.isActivated === filters.shouldBeActivated;
					conditionsChecksList.push( isActivatedFilterSatisfied );
				}

				// If all conditions are met (true / return device) else do not return the device
				const deviceMeetsFilterConditions =
					!conditionsChecksList.includes( false );
				return deviceMeetsFilterConditions;
			} );
		},
	cachePolicy_UNSTABLE: {
		// Only store the most recent set of dependencies and their values
		eviction: 'lru',
		maxSize: 16,
	},
} );
