import React from 'react';
import { ConnectDragSource } from 'react-dnd';

import { DragIcon, TargetIcon } from '../../assets/icons';
import * as floorPlanConfig from './floorplanConfig';

interface DeviceDragLayerProps {
	dragRef: ConnectDragSource;
	deviceX: number;
	deviceY: number;
	deviceSize: number;
	isDragging: boolean;
}

export const DeviceDragLayer = ( {
	deviceX,
	deviceY,
	deviceSize,
	isDragging,
	dragRef,
}: DeviceDragLayerProps ) => {
	return (
		<svg
			x={ deviceX - deviceSize / 2 }
			y={ deviceY - deviceSize / 2 }
			overflow="visible"
		>
			<svg
				x={ ( deviceSize - floorPlanConfig.DRAG_ICON_SIZE ) / 2 }
				y={ ( deviceSize - floorPlanConfig.DRAG_ICON_SIZE ) / 2 }
				fill="white"
			>
				{!isDragging ? (
					<DragIcon
						className="fill-base-100"
						size={ floorPlanConfig.DRAG_ICON_SIZE }
					/>
				) : (
					<TargetIcon
						className="fill-base-100"
						size={ floorPlanConfig.DRAG_ICON_SIZE }
					/>
				)}
			</svg>

			{/* Empty div to attach dragRef */}
			<foreignObject width={ deviceSize } height={ deviceSize }>
				<div
					ref={ dragRef }
					style={ {
						height: deviceSize,
						width: deviceSize,
					} }
				/>
			</foreignObject>
		</svg>
	);
};
