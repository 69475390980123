import { atom } from 'recoil';
import { GeneralSettingsUnitsDefinitions } from '../../shared/constants';

/* Interfaces */

/* General units definitions state */
export const generalSettingsDefinitionStateAtom = atom<{ [key in GeneralSettingsUnitsDefinitions]?: Array<string> }>( {
	key: 'generalSettingsDefinitionState',
	default: {},
} );
