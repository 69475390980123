import React from 'react';
import { AlarmSeverityEnum } from '../../types';
import {
	ErrorConnectionIcon,
	ErrorIcon,
	WarningConnectionIcon,
	WarningIcon,
	StatusOfflineIcon,
} from '../../assets/icons';

export const AlarmListItemIcon = ( { type }: { type: AlarmSeverityEnum } ) => (
	<>
		{type === AlarmSeverityEnum.ERROR ? <ErrorIcon /> : null}
		{type === AlarmSeverityEnum.ERROR_AND_CONNECTION ? (
			<ErrorConnectionIcon />
		) : null}
		{type === AlarmSeverityEnum.WARNING ? <WarningIcon /> : null}
		{type === AlarmSeverityEnum.CONNECTION ? <StatusOfflineIcon /> : null}
		{type === AlarmSeverityEnum.WARNING_AND_CONNECTION ? (
			<WarningConnectionIcon />
		) : null}{' '}
	</>
);
