import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import Button from '../buttons/Button';
import Logo from '../Logo';
import { AccountIcon, CaretDown } from '../../assets/icons';
import { AppScreen } from '../../shared/constants';
import { useAuthActions } from '../../hooks/useAuth';

const Login: React.FC = () => {
	const history = useHistory();
	const { t } = useTranslation();
	const { login } = useAuthActions();

	const [ username, setUsername ] = useState( '' );
	const [ password, setPassword ] = useState( '' );
	const [ hasError, setHasError ] = useState( false );
	const [ loading, setLoading ] = useState( false );

	const validateForm = useCallback( () => {
		return username.length === 0 || password.length === 0;
	}, [ username.length, password.length ] );

	const onLoginClick = useCallback( async () => {
		const hasError = validateForm();
		setHasError( hasError );
		if ( !hasError ) {
			setLoading( true );
			login( { username, password } ).catch( ( err ) => {
				setHasError( true );
				setLoading( false );
			} );
		}
	}, [ setHasError, validateForm, login, username, password ] );

	useEffect( () => {
		const listener = ( event: KeyboardEvent ) => {
			if ( event.code === 'Enter' || event.code === 'NumpadEnter' ) {
				event.preventDefault();
				onLoginClick();
			}
		};
		document.addEventListener( 'keydown', listener );
		return () => {
			document.removeEventListener( 'keydown', listener );
		};
	}, [ onLoginClick ] );

	const onCancelClick = useCallback( () => {
		// action is 'POP' on initial load
		history.action !== 'POP' ? history.goBack() : history.push( AppScreen.HOME );
	}, [ history ] );

	return (
		<div className="flex align-center justify-center flex-1">
			<div className="mx-auto bg-primary-focus my-auto rounded-xl px-9 py-8 text-primary-content w-login-form relative">
				<div className="flex flex-row justify-between items-start">
					<h2 className="font-bold text-5xl">VMS</h2>
					<Logo className="w-28 h-auto" />
				</div>

				<div className="mt-8">
					<h4 className="border-b border-base-100 border-opacity-30 text-lg pb-2">
						{t( 'Login name' )}
					</h4>
					<div className="flex flex-row items-center mt-3">
						<input
							value={ username }
							onChange={ ( event ) => {
								setUsername( event?.target.value );
							} }
							type="text"
							placeholder={ t( 'Enter your name' ) }
							className="input input-md h-9 input-bordered mr-2 rounded-md w-full text-base-content"
						/>
					</div>
				</div>
				<div className="mt-5">
					<h4 className="border-b border-base-100 border-opacity-30 text-lg pb-2">
						{t( 'Password' )}
					</h4>
					<input
						value={ password }
						onChange={ ( event ) => {
							setPassword( event.target.value );
						} }
						type="password"
						placeholder={ t( 'Enter your password' ) }
						className="input input-md h-9 input-bordered mr-2 rounded-md mt-3 text-base-content"
					/>
				</div>
				<div
					className={ `mt-6 h-8 leading-8 text-center rounded ${
						hasError ? 'bg-danger-error animate-fade-in-down' : ''
					}` }
				>
					{hasError && (
						<span className="text-base-301 text-xs">
							{t( 'Your username or password are incorrect' )}
						</span>
					)}
				</div>
				<div className="flex flex-row items-center justify-end mt-12">
					<Button
						onClick={ onCancelClick }
						text={ t( 'Cancel' ) }
						className="mr-2 w-auto bg-neutral shadow-dark px-4 text-xs h-9 leading-6"
					/>
					<Button
						onClick={ onLoginClick }
						text={ t( 'Login' ) }
						icon={ <AccountIcon /> }
						className="bg-primary w-52 shadow-dark text-xs h-9 leading-6"
						loading={ loading }
						disabled={ loading }
					/>
				</div>
			</div>
		</div>
	);
};

export default Login;
