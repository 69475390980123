import { useRecoilValue } from 'recoil';
import { ReactNode, useCallback } from 'react';
import { useDrag } from 'react-dnd';
import { Alarm, DevicePosition, AlarmSeverityEnum } from '../../types';
import { getAlarmIcon } from './alarm_styles';
import { AssignmentIcon } from '../../assets/icons';
import { RemoveDeviceButton } from './RemoveDeviceButton';
import { DragItemType } from './dragItemsTypes';
import { findDeviceByIdSelector } from '../../state/devices/selectors/findDeviceById';
import { DeviceStatusIcon } from './DeviceStatusIcon';
import { DeviceBox } from './DeviceBox';
import { DeviceDragLayer } from './DeviceDragLayer';
import { DeviceName } from './DeviceName';
import FloorPlanDeviceMeasurement from './FloorPlanDeviceMeasurement';
import { DeviceNetworkStatusIcon } from './DeviceNetworkStatusIcon';

interface FloorPlanDeviceItemType extends DevicePosition {
	name?: string;
}

type DeviceProps = {
	device: FloorPlanDeviceItemType;
	size: number;
	selected?: boolean;
	activeAlarm?: Alarm;
	editMode?: boolean;
	showConnectionStatus?: boolean;
	isDirectAncestor?: boolean;
	showDeviceTitle?: boolean;
	showDeviceName?: boolean;
	selectedDeviceRef?: any;
	hasConnectionProperties?: boolean;
	isActivated?: boolean;
	onClick: () => void;
	onRemoveButtonClick?: () => void;
};

const alarmSeverityColorClassMap: Record<
AlarmSeverityEnum,
{ box: string; icon: string }
> = {
	[AlarmSeverityEnum.ERROR]: {
		box: 'fill-error opacity-60',
		icon: 'fill-error',
	},
	[AlarmSeverityEnum.WARNING]: {
		box: 'fill-warning opacity-60',
		icon: 'fill-warning',
	},
	[AlarmSeverityEnum.CONNECTION]: {
		box: 'fill-info opacity-60',
		icon: 'fill-info',
	},
	[AlarmSeverityEnum.ERROR_AND_CONNECTION]: { box: '', icon: '' },
	[AlarmSeverityEnum.WARNING_AND_CONNECTION]: { box: '', icon: '' },
};

const getDeviceStyles = (
	activeAlarm?: Alarm,
	selected?: boolean,
	editMode?: boolean,
	showConnectionStatus?: boolean,
	isDirectAncestor?: boolean
) => {
	let deviceBoxClass = 'fill-base-600';
	let deviceStatusIconClass = 'fill-transparent';
	let deviceStatusIcon: ReactNode | undefined = null;

	if ( activeAlarm && isDirectAncestor ) {
		deviceStatusIcon = getAlarmIcon( activeAlarm.alarmSeverity );
		const alarmStyles = alarmSeverityColorClassMap[activeAlarm.alarmSeverity];
		deviceBoxClass = alarmStyles.box;
		deviceStatusIconClass = alarmStyles.icon;
	} else if ( selected && !showConnectionStatus ) {
		deviceBoxClass = 'fill-selected opacity-60';
		deviceStatusIconClass = 'fill-selected';
		deviceStatusIcon = <AssignmentIcon />;
	} else if ( editMode ) {
		deviceBoxClass = 'fill-primary opacity-60 stroke-2';
	} else if ( showConnectionStatus ) {
		if ( selected ) {
			deviceBoxClass = 'fill-selected opacity-60';
		}
	}

	return { deviceBoxClass, deviceStatusIconClass, deviceStatusIcon };
};

function Device( {
	device,
	size,
	onClick,
	selected,
	activeAlarm,
	editMode,
	onRemoveButtonClick,
	showConnectionStatus,
	isDirectAncestor,
	showDeviceName,
	selectedDeviceRef,
	hasConnectionProperties,
	isActivated,
}: DeviceProps ) {
	const [ { isDragging }, dragRef ] = useDrag( () => ( {
		type: DragItemType.POSITIONED_DEVICE,
		item: device,
		collect: ( monitor ) => ( {
			isDragging: monitor.isDragging(),
		} ),
	} ) );

	const { position, deviceId } = device;
	const deviceData = useRecoilValue( findDeviceByIdSelector( device.deviceId ) );

	const handleOnRemoveButtonPress = useCallback( () => {
		if ( onRemoveButtonClick && typeof onRemoveButtonClick === 'function' ) {
			onRemoveButtonClick();
		}
	}, [ onRemoveButtonClick ] );

	const styles = getDeviceStyles(
		activeAlarm,
		selected,
		editMode,
		showConnectionStatus,
		isDirectAncestor
	);

	return (
		<svg
			onClick={ onClick }
			className="cursor-pointer"
			overflow="visible"
			width={ size }
			height={ size }
		>
			<DeviceBox
				selectedDeviceRef={ selected ? selectedDeviceRef : null }
				id={ `${ selected && 'selected-device' }` }
				positionX={ position.x }
				positionY={ position.y }
				className={ `${ styles.deviceBoxClass } ` }
				size={ size }
			>
				{isDirectAncestor ? (
					<FloorPlanDeviceMeasurement
						deviceId={ deviceId }
						positionX={ position.x }
						positionY={ position.y }
					/>
				) : null}
			</DeviceBox>

			{selected || ( activeAlarm && isDirectAncestor ) ? (
				<DeviceStatusIcon
					position={ device.position }
					colorClass={ styles.deviceStatusIconClass }
					icon={ styles.deviceStatusIcon }
					deviceSize={ size }
				/>
			) : null}

			{showConnectionStatus ? (
				<DeviceNetworkStatusIcon
					deviceId={ deviceId }
					isActivated={ isActivated }
					hasConnectionProperties={ hasConnectionProperties }
					deviceSize={ size }
					position={ device.position }
				/>
			) : null}

			{editMode ? (
				<svg overflow="visible">
					<DeviceDragLayer
						deviceX={ position.x }
						deviceY={ position.y }
						deviceSize={ size }
						isDragging={ isDragging }
						dragRef={ dragRef }
					/>
					<RemoveDeviceButton
						x={ position.x + size / 2 }
						y={ position.y - size / 2 }
						onClick={ handleOnRemoveButtonPress }
					/>
				</svg>
			) : null}

			{showDeviceName && deviceData?.name ? (
				<DeviceName
					name={ deviceData.name }
					deviceX={ position.x }
					deviceY={ position.y }
					deviceSize={ size }
				/>
			) : null}
		</svg>
	);
}

export default Device;
