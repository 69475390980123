import { atom } from 'recoil';
import auth from '../../middleware/auth';

const initialState = auth.isLoggedIn;

export const isLoggedInState = atom<boolean>( {
	key: 'isLoggedIn',
	// get initial state from local storage to enable user to stay logged in
	default: initialState,
} );
