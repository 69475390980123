import React from 'react';
import { Link } from 'react-router-dom';
import { AppScreen } from '../../shared/constants';

interface PathOfLinksToSelectedPlaceProps {
	currentPlaceID: string;
}

export const getParentPlacesIDs = ( placeID?: string ): string[] => {
	if ( !placeID ) {
		return [];
	}

	// area1.zone1.room2 -> ['area1', 'zone1', 'room2']
	const idsArray = placeID.split( '.' );
	let placesIDs: string[] = [];

	for ( let index = 0; index < idsArray.length; index++ ) {
		const currentPlaceID =
			index === 0 ? idsArray.join( '.' ) : idsArray.slice( 0, -index ).join( '.' );
		placesIDs.unshift( currentPlaceID );
	}

	return placesIDs;
};

export const PathOfLinksToSelectedPlace = ( {
	currentPlaceID,
}: PathOfLinksToSelectedPlaceProps ) => {
	const placesIDs: string[] = getParentPlacesIDs( currentPlaceID );

	return (
		<div className="flex-1 mb-8 text-center">
			{placesIDs.map( ( placeId, index ) => {
				const [ linkName ] = placeId.split( '.' ).reverse();
				const isLastLink = index === placesIDs.length - 1;

				return isLastLink ? (
					<h2
						key={ placeId }
						className={ `inline-block capitalize ${
							index !== 0 ? 'font-bold' : ''
						}` }
					>
						{linkName}
					</h2>
				) : (
					<Link key={ placeId } replace to={ `${ AppScreen.PLACES }/${ placeId }` }>
						<h2 className="font-extralight inline-block capitalize hover:underline">
							{linkName}
						</h2>
						<span className="text-xs mx-3 relative  text-gray-500 font-normal font">
							&#12297;
						</span>
					</Link>
				);
			} )}
		</div>
	);
};
