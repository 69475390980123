import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
import { AppScreen } from '../../../shared/constants';
import { findDeviceDetailsByIdSelector } from '../../../state/devices/selectors/findDeviceDetailsById';
import {
	getRegionConfigurationPropertyDisplayValue,
	roundMeasurement,
} from '../../../utils/device';
import { Alarm, AlarmSeverityEnum } from '../../../types';
import AlarmSummaryInfo from '../AlarmSummaryInfo';
import { getHighestPriorityAlarm } from '../../../utils/alarms';
import { findDeviceByIdSelector } from '../../../state/devices/selectors/findDeviceById';
import { TileIcon } from '../TileIcon';
import { TileHandle } from '../TileHandle';
import {
	getAlarmCurrentValueText,
	getContainerStyleByAlarmSeverity,
} from '../helpers';

export interface MiddleTileProps {
	id: string;
	name: string;
	alarms: Array<Alarm>;
}

const MiddleTile: React.FC<MiddleTileProps> = ( { id, name, alarms } ) => {
	const { t } = useTranslation();
	const monitorDetails = useRecoilValue( findDeviceDetailsByIdSelector( id ) );
	const device = useRecoilValue( findDeviceByIdSelector( id ) );
	const [ isFullyExpanded, setIsFullyExpanded ] = useState( false );

	const highestPriorityAlarm = getHighestPriorityAlarm( alarms );
	const regionsAlarms = alarms.filter( ( alarm ) => alarm.channelName?.startsWith( 'Region' ) );
	const alarmSeverity = highestPriorityAlarm?.alarmSeverity;
	const deviceHasSingleDisconnectedAlarm =
		regionsAlarms.length === 0 &&
		alarmSeverity === AlarmSeverityEnum.CONNECTION;

	const onTileHandleClick = () => {
		setIsFullyExpanded( !isFullyExpanded );
	};

	const renderExpandableAlarmSummaryInfo = () => {
		if ( isFullyExpanded ) {
			const regionName = highestPriorityAlarm?.channelName
				? getRegionConfigurationPropertyDisplayValue(
					device?.configuration,
					highestPriorityAlarm.channelName,
					'Name'
				  )
				: '-';

			const currentValue = getAlarmCurrentValueText(
				highestPriorityAlarm?.channelName,
				highestPriorityAlarm,
				monitorDetails?.measurementMessage
			);

			return (
				<AlarmSummaryInfo
					title={
						!deviceHasSingleDisconnectedAlarm
							? regionName
							: t( 'Device is disconnected' )
					}
					currentValue={
						!deviceHasSingleDisconnectedAlarm ? currentValue : undefined
					}
					peakValue={
						!deviceHasSingleDisconnectedAlarm
							? highestPriorityAlarm?.peakValue
							: undefined
					}
					unit={
						!deviceHasSingleDisconnectedAlarm
							? highestPriorityAlarm?.unit
							: undefined
					}
					startTime={ highestPriorityAlarm?.created }
				/>
			);
		}

		return (
			<div
				className={ `text-sm italic  ${
					!deviceHasSingleDisconnectedAlarm ? 'font-bold' : ''
				}` }
			>
				{deviceHasSingleDisconnectedAlarm ? (
					<p>{t( 'Device is disconnected' )}</p>
				) : (
					<p>
						{highestPriorityAlarm?.peakValue !== undefined
							? roundMeasurement( highestPriorityAlarm?.peakValue )
							: '-'}
						{highestPriorityAlarm?.unit ?? ''}
					</p>
				)}
			</div>
		);
	};

	const containerStyles = twMerge(
		'relative flex border-base-700 px-2 rounded-t-2.5xl text-primary-content bottom-0 -mb-0.5',
		isFullyExpanded
			? 'h-stack-m-large min-w-stack-m-large'
			: 'h-stack-m-middle min-w-stack-m-middle',
		getContainerStyleByAlarmSeverity( alarmSeverity ),
		'transition-height ease-out-cubic-bezier-1 duration-200 transform-gpu'
	);

	const shouldUseRadiationIcon = alarmSeverity === AlarmSeverityEnum.ERROR;

	return (
		<div className={ containerStyles }>
			<div className="flex flex-col flex-1">
				<TileHandle onClick={ onTileHandleClick } />

				<Link to={ `${ AppScreen.STACK_MONITOR }/${ id }` }>
					{highestPriorityAlarm ? (
						<TileIcon
							alarmSeverityType={ highestPriorityAlarm?.alarmSeverity }
							showRadiationIcon={ shouldUseRadiationIcon }
						/>
					) : null}

					<div className="px-2">
						<h3 className="font-semibold text-xl cursor-pointer text-left">
							{name}
						</h3>
						<div className="flex flex-col flex-1">
							{!isFullyExpanded && (
								<div className="w-32 h-2 border-t border-base-100 border-opacity-30 mt-2" />
							)}

							{renderExpandableAlarmSummaryInfo()}
						</div>
					</div>
				</Link>
			</div>
		</div>
	);
};

export default MiddleTile;
