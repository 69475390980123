import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ResolveIcon } from '../../assets/icons';
import { Alarm, AlarmSeverityEnum, DeviceConfiguration } from '../../types';
import { AlarmListItemIcon } from '../alarms/AlarmListItemIcon';
import Button from '../buttons/Button';
import ResolveAlarm from '../modals/ResolveAlarm';

export interface StackMonitorAlarmWrapperProps {
	resolveDisabled?: boolean;
	alarms: Array<Alarm>;
	configuration?: DeviceConfiguration;
}

const StackMonitorAlarmWrapper: React.FC<StackMonitorAlarmWrapperProps> = ( {
	alarms,
	resolveDisabled = false,
	children,
	configuration,
} ) => {
	const { t } = useTranslation();

	const [ isResolveModalOpen, setIsResolveModalOpen ] = useState( false );

	const handleOpenResolveModal = () => {
		setIsResolveModalOpen( true );
	};

	const handleCloseResolveModal = () => {
		setIsResolveModalOpen( false );
	};

	const highestPriorityAlarm = alarms[0];
	if ( !highestPriorityAlarm ) {
		return <div className="ml-6">{children}</div>;
	}

	const title =
		highestPriorityAlarm.alarmSeverity === AlarmSeverityEnum.CONNECTION
			? t( 'Device is disconnected' )
			: t( 'High Measurement Detected' );

	return (
		<div
			className={ `bg-danger-${ highestPriorityAlarm.alarmSeverity.toLowerCase() } pt-4 pb-8 rounded-2xl text-primary-content px-6 relative` }
		>
			<div className="flex flex-row items-center justify-between mb-5">
				<h3 className="text-2xl font-medium mb-2 mr-8">{title}</h3>
				<div
					className={ `bg-base-100 text-danger-${ highestPriorityAlarm.alarmSeverity.toLocaleLowerCase() } flex items-center w-8 h-8 rounded-full justify-center absolute top-4 right-4` }
				>
					<AlarmListItemIcon type={ highestPriorityAlarm.alarmSeverity } />
				</div>
			</div>
			{children}

			{/* Buttons */}
			<div className="flex justify-end mt-6">
				<Button
					text={ t( 'Solve & Report' ) }
					onClick={ handleOpenResolveModal }
					icon={ <ResolveIcon /> }
					className={ `bg-primary min-w-56 w-auto ${
						resolveDisabled ? 'opacity-40 cursor-default' : ''
					}` }
				/>
				{isResolveModalOpen ? (
					<ResolveAlarm
						alarms={ alarms }
						resolveDisabled={ resolveDisabled }
						configuration={ configuration }
						closeModal={ handleCloseResolveModal }
					/>
				) : null}
			</div>
		</div>
	);
};

export default StackMonitorAlarmWrapper;
