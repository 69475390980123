import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import ReactTooltip from 'react-tooltip';
import { AppScreen } from '../../shared/constants';
import Button from '../buttons/Button';
import DevicesGrid from '../DevicesGrid';
import SettingsLayout from '../SettingsLayout';
import UserRoleNotice from '../UserRoleNotice';

const ConfigureDevices: React.FC = () => {
	const { t } = useTranslation();

	const history = useHistory();
	const settingsPath = history.location.pathname
		.split( '/' )
		.filter( ( location ) => location !== 'position-devices' )
		.join( '/' );

	const isWizardMode = settingsPath.includes( 'wizard' );

	const navigateToDeviceDetails = useCallback(
		( deviceId: string ) => {
			history.push( `/device/configuration/${ deviceId }` );
		},
		[ history ]
	);

	const onPrevButtonPress = useCallback( () => {
		history.push(
			isWizardMode
				? AppScreen.WIZARD_CONNECT_DEVICES
				: AppScreen.CONNECT_DEVICES
		);
	}, [ history, isWizardMode ] );

	const finishButtonClickHandler = useCallback( () => {
		history.push( AppScreen.HOME );
	}, [ history ] );

	return (
		<SettingsLayout isWizard={ isWizardMode } title={ t( 'Configure Devices' ) }>
			<div
				className={ `flex flex-col w-full h-full content-between flex-1 overflow-hidden ${
					isWizardMode ? 'pl-10' : 'pl-8'
				}` }
			>
				<div className="mb-2">
					<UserRoleNotice requiredRole="MAINTENANCE" />
				</div>
				<DevicesGrid onItemClick={ navigateToDeviceDetails } />
			</div>
			{isWizardMode && (
				<div className="flex justify-between pl-4 pt-8">
					<Button
						text={ `< ${ t( 'Previous page' ) }` }
						onClick={ onPrevButtonPress }
						isFormButton
						className="px-2"
					/>
					<Button
						text={ t( 'Finish' ) }
						className="m-0 min-w-36"
						onClick={ finishButtonClickHandler }
						isFormButton
					/>
				</div>
			)}
			<ReactTooltip id="checked-tooltip" className="tooltip" />
			<ReactTooltip id="path-tooltip" className="tooltip" />
		</SettingsLayout>
	);
};

export default ConfigureDevices;
