import React from 'react';
import * as floorPlanConfig from './floorplanConfig';
import { truncateText } from '../../utils/strings';

export interface DeviceNameProps {
	name: string;
	deviceX: number;
	deviceY: number;
	deviceSize: number;
}

export const DeviceName = ( {
	name,
	deviceX,
	deviceY,
	deviceSize,
}: DeviceNameProps ) => {
	return (
		<svg overflow="visible">
			<rect
				className="fill-base-302"
				x={ deviceX - floorPlanConfig.DEVICE_NAME_BOX_WIDTH / 2 }
				y={
					deviceY + deviceSize / 2 + floorPlanConfig.DEVICE_NAME_BOX_TOP_MARGIN
				}
				width={ floorPlanConfig.DEVICE_NAME_BOX_WIDTH }
				height={ floorPlanConfig.DEVICE_NAME_BOX_HEIGHT }
				rx={ floorPlanConfig.DEVICE_RADIUS }
				ry={ floorPlanConfig.DEVICE_RADIUS }
			/>
			<text
				fontSize={ floorPlanConfig.DEVICE_NAME_TEXT_SIZE }
				className="font-bold fill-base-600"
				dominantBaseline="middle"
				textAnchor="middle"
				x={ deviceX - deviceSize / 2 }
				y={
					deviceY +
					deviceSize / 2 +
					floorPlanConfig.DEVICE_NAME_BOX_HEIGHT / 2 +
					floorPlanConfig.DEVICE_NAME_BOX_TOP_MARGIN
				}
			>
				<tspan x={ deviceX }>{truncateText( name, 9 ) ?? '-'}</tspan>
			</text>
			<title>{name}</title>
		</svg>
	);
};
