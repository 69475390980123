import React from 'react';
import { twMerge } from 'tailwind-merge';

export interface ReadOnlyFieldProps {
	label: string;
	defaultValue: string;
}

const ReadOnlyField: React.FC<ReadOnlyFieldProps> = ( {
	label,
	defaultValue,
} ) => {
	const styles = twMerge(
		'input input-md input-bordered rounded-md w-full h-9 leading-9 border-transparent focus:border-transparent focus:ring-0 bg-base-300 cursor-not-allowed text-neutral'
	);

	return (
		<label className="block mt-2 text-sm">
			<span>{label}</span>
			<div className="flex items-center mt-1 relative">
				<input
					readOnly
					type="text"
					className={ styles }
					defaultValue={ defaultValue }
				/>
			</div>
		</label>
	);
};

export default ReadOnlyField;
