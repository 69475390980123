import React, { useCallback, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { allDevicesStateAtom } from '../../state/devices/allDevices';
import {
	DeviceTypeEnum,
	FloorPlanDevice,
	Location,
	PlaceDefinition,
} from '../../types';
import {
	generateSVGPath,
	getNestedPlacesList,
	isRounded,
	screenToSVGPosition,
} from '../../utils/floorPlan';
import Device from './Device';
import Place, { DroppedDeviceType } from './Place';

type ZoomAnimationRef = SVGAnimateElement & {
	beginElement: () => void;
	addEventListener: (
		type: string,
		callback: EventListenerOrEventListenerObject | null,
		options?: AddEventListenerOptions | boolean
	) => void;
	removeEventListener: (
		type: string,
		callback: EventListenerOrEventListenerObject | null,
		options?: EventListenerOptions | boolean
	) => void;
};

interface DragAndDropFloorPlanLayerProps {
	selectedPlaceData: PlaceDefinition;
	devices: Array<FloorPlanDevice>;
	deviceSize: number;
	zoomAnimationRef: React.RefObject<ZoomAnimationRef>;
	floorPlanImageRef: React.MutableRefObject<SVGSVGElement | null>;
	selectedDeviceId?: string;
	showDeviceName?: boolean;
	editDeviceId?: string;
	zoomToElement: ( element: SVGSVGElement | SVGPathElement ) => void;
	removeDeviceLocation?: ( deviceID: string ) => void;
	updateEditDeviceId: ( id?: string ) => void;
	onPlaceClickCallback?: ( place: PlaceDefinition ) => void;
	addOrUpdateDeviceLocation?: (
		placeId: string,
		deviceId: string,
		position: Location,
		droppedItemType: string
	) => void;
}

const DragAndDropFloorPlanLayer: React.FC<DragAndDropFloorPlanLayerProps> = ( {
	selectedPlaceData,
	onPlaceClickCallback,
	devices,
	selectedDeviceId,
	deviceSize,
	showDeviceName,
	zoomToElement,
	addOrUpdateDeviceLocation,
	updateEditDeviceId,
	removeDeviceLocation,
	editDeviceId,
	zoomAnimationRef,
	floorPlanImageRef,
} ) => {
	const existingDevices = useRecoilValue( allDevicesStateAtom );
	const [ isZooming, setIsZooming ] = useState<boolean>( false );

	// Update `isZooming` to state when animation fired
	// This helps preventing interruption while animation in progress and the user clicks on a place repeatedly
	// which causes crazy jumping
	const handleStartZooming = useCallback( () => {
		setIsZooming( true );
	}, [] );
	const handleStopZooming = useCallback( () => {
		setIsZooming( false );
	}, [] );

	useEffect( () => {
		let animationElement = zoomAnimationRef?.current;
		animationElement?.addEventListener( 'beginEvent', handleStartZooming );
		animationElement?.addEventListener( 'endEvent', handleStopZooming );

		return () => {
			animationElement?.removeEventListener( 'beginEvent', handleStartZooming );
			animationElement?.removeEventListener( 'endEvent', handleStopZooming );
		};
	}, [ handleStartZooming, handleStopZooming, zoomAnimationRef ] );

	const handlePlaceClick = useCallback(
		( element: SVGSVGElement | SVGPathElement, place: PlaceDefinition ) => {
			if ( !isZooming ) {
				zoomToElement( element );
			}

			if ( onPlaceClickCallback && typeof onPlaceClickCallback === 'function' ) {
				onPlaceClickCallback( place );
			}
		},
		[ onPlaceClickCallback, isZooming, zoomToElement ]
	);

	const handleDeviceDrop = useCallback(
		async ( droppedDevice: DroppedDeviceType ) => {
			if ( !floorPlanImageRef?.current || droppedDevice.position === null ) {
				return;
			}

			const {
				id: deviceId,
				placeId,
				position,
				droppedItemType,
			} = droppedDevice;

			const devicePositionOnFloorPlan = screenToSVGPosition(
				floorPlanImageRef?.current,
				position
			);

			if ( addOrUpdateDeviceLocation ) {
				addOrUpdateDeviceLocation(
					placeId,
					deviceId,
					{
						x: Math.round( devicePositionOnFloorPlan.x ),
						y: Math.round( devicePositionOnFloorPlan.y ),
					},
					droppedItemType
				);
			}
		},
		[ addOrUpdateDeviceLocation, floorPlanImageRef ]
	);

	const handleRemoveDevice = useCallback(
		async ( deviceId: string ) => {
			if ( removeDeviceLocation ) {
				removeDeviceLocation( deviceId );
			}
			updateEditDeviceId( undefined );
		},
		[ removeDeviceLocation, updateEditDeviceId ]
	);

	const handleDeviceClick = useCallback(
		( deviceId: string ) => {
			if ( deviceId === editDeviceId ) {
				updateEditDeviceId( undefined );
			} else {
				updateEditDeviceId( deviceId );
			}
		},
		[ editDeviceId, updateEditDeviceId ]
	);

	const getDeviceById = useCallback(
		( deviceId: string ) => {
			return existingDevices.find( ( device ) => device.id === deviceId );
		},
		[ existingDevices ]
	);

	return (
		<>
			{/*
				- HIDDEN DEVICE ELEMENT
				- used to calculate the size of the draggable device preview element
			*/}
			<svg
				overflow="visible"
				width={ deviceSize }
				height={ deviceSize }
				id="floor-plan-device-hidden"
				className="opacity-0"
			>
				<rect width={ deviceSize } height={ deviceSize } />
			</svg>

			{/* Zoom */}
			<animate
				ref={ zoomAnimationRef }
				attributeName="viewBox"
				begin="indefinite"
				dur="0.5s"
				fill="freeze"
				restart="whenNotActive"
			/>

			{/* Places */}
			{getNestedPlacesList( selectedPlaceData ).map( ( place: PlaceDefinition ) => (
				<Place
					key={ place.id }
					placeId={ place.id }
					type={ place.type }
					pathCoords={ generateSVGPath( place.box, isRounded( place.type ) ) }
					onPlaceClick={ ( element ) => {
						handlePlaceClick( element, place );
					} }
					onDrop={ handleDeviceDrop }
					withOutline
				/>
			) )}

			{/* Devices */}
			{devices.map( ( device ) => {
				if (
					!device ||
					getDeviceById( device.deviceId )?.type === DeviceTypeEnum.VRM_SM
				) {
					return null;
				}

				return (
					<Device
						key={ device.deviceId }
						device={ device }
						onClick={ () => {
							handleDeviceClick( device.deviceId );
						} }
						size={ deviceSize }
						editMode={ editDeviceId === device.deviceId }
						onRemoveButtonClick={ () => {
							handleRemoveDevice( device.deviceId );
						} }
						selected={ device.deviceId === selectedDeviceId }
						showDeviceName={ showDeviceName }
					/>
				);
			} )}
		</>
	);
};

export default DragAndDropFloorPlanLayer;
