import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SupportLogo } from '../../assets/icons';
import { ReactComponent as LogoImage } from '../../assets/logo.svg';
import config from '../../config-loader';
import api from '../../middleware/api';
import { LicenseData } from '../../types';
import { getPrettyTimeStamp } from '../../utils/time';

export interface SupportProps {}

const Support: React.FC<SupportProps> = () => {
	const { t } = useTranslation();
	const [ licenseData, setLicenseData ] = useState<LicenseData | undefined>();

	const mail = config.supportEmail ?? 'customersupport@comecer.com';
	const phone = config.supportPhone ?? '+3126458795523';

	useEffect( () => {
		api.license
			.get()
			.then( ( data ) => {
				setLicenseData( data );
			} )
			.catch( () => setLicenseData( undefined ) );
	}, [] );

	const expirationDate = licenseData
		? getPrettyTimeStamp( DateTime.fromISO( licenseData?.expirationDate ).toISO() )
		: undefined;

	return (
		<div className="w-3/4 bg-base-300 rounded-lg h-3/4 m-auto flex flex-col">
			<h1 className="flex-1 flex items-center justify-end mr-24">
				<SupportLogo />
			</h1>
			<div className="m-0 bg-primary h-2/3 mr-24 rounded-lg p-16 pb-0 pt-12 text-base-301 font-light rounded-br-none rounded-tl-none flex flex-col">
				<div className="flex flex-col gap-2 flex-1">
					<h2 className="text-xl font-bold">{t( 'Comecer helpdesk' )}</h2>
					<div className="divider bg-secondary rounded-md h-divider opacity-25 m-0 w-1/2" />
					<div className="flex flex-col h-full justify-center gap-2">
						<p className="font-system opacity-75 text-md">{t( 'For direct assistance please contact' )}:</p>
						<p>
							<a className="underline text-sm" href={ `mailto:${ mail }` }>
								{mail}
							</a>
						</p>
						<p>
							<a className="underline text-sm" href={ `tel:${ phone }` }>
								{phone}
							</a>
						</p>
					</div>

				</div>
				{/* <div className="flex flex-col">
					<h2 className="text-xl font-bold">{t( 'License data' )}:</h2>
					{licenseData?.licenseFound || licenseData?.isExpired ? (
						<div className="flex text-sm flex-col">
							{licenseData?.isExpired && <div>{t( 'Expired!' )}</div>}
							<div>{`${ t( 'Expiration date' ) }: ${ expirationDate }`}</div>
						</div>
					) : (
						<div>{t( 'No license found' )}</div>
					)}
				</div> */}
			</div>
		</div>
	);
};

export default Support;
