import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { twMerge } from 'tailwind-merge';
import { useIsMounted } from '../hooks/useIsMounted';
import usePrevious from '../hooks/usePrevious';
import { useRefreshDeviceDetails } from '../hooks/useRefreshDeviceDetails';
import { findDeviceDetailsByIdSelector } from '../state/devices/selectors/findDeviceDetailsById';
import { DeviceTypeEnum, GraphicsUrls } from '../types';

interface GrafanaChartProps {
	deviceId: string;
	totalAmountAlarmsForDevice: number;
	chartUrlType: keyof GraphicsUrls;
	selectedPeriod?: string;
	deviceType?: DeviceTypeEnum;
	isMeasuringDevice?: boolean;
	showSpectrumGraph?: boolean;
	chartStyle?: string;
	onLoad?: () => void;
}

const GrafanaChart = ( {
	deviceId,
	totalAmountAlarmsForDevice,
	isMeasuringDevice,
	chartUrlType,
	selectedPeriod,
	deviceType,
	onLoad,
	showSpectrumGraph,
	chartStyle,
}: GrafanaChartProps ) => {
	const deviceDetails = useRecoilValue( findDeviceDetailsByIdSelector( deviceId ) );
	const isMounted = useIsMounted();
	const { t } = useTranslation();
	const previousDeviceAlarmsLength = usePrevious<number>(
		totalAmountAlarmsForDevice
	);

	// Sometimes the graphic service is slow, so the Grafana chart might not be available even after activating a device
	// In these cases, we force-re-fetch the device details (that include the grafana charts) when the user opens the details
	// of a device without graphicUrls
	// We do it only once, to avoid a request loop when the grafana charting service is not working (for whatever reason)
	const [ refreshedGrafanaChartUrls, setRefreshedGrafanaChartUrls ] =
		useState( false );

	const refreshCurrentDeviceDetails = useRefreshDeviceDetails();

	useEffect( () => {
		// If the device is without chart urls and we haven't refreshed them yet, we do the refresh.
		if (
			!refreshedGrafanaChartUrls &&
			deviceDetails &&
			deviceDetails.graphicsUrls !== undefined &&
			!deviceDetails.graphicsUrls[chartUrlType]
		) {
			if ( isMounted() ) {
				setRefreshedGrafanaChartUrls( true );
			}
			refreshCurrentDeviceDetails( deviceId );
		}
	}, [
		deviceDetails,
		deviceId,
		refreshCurrentDeviceDetails,
		refreshedGrafanaChartUrls,
		chartUrlType,
		isMounted,
	] );

	useEffect( () => {
		let refreshDeviceDetailsTimeout: ReturnType<typeof setTimeout>;
		// Wait for two seconds and refresh the grafana charts upon new alarm.
		if (
			previousDeviceAlarmsLength !== undefined &&
			previousDeviceAlarmsLength < totalAmountAlarmsForDevice
		) {
			refreshDeviceDetailsTimeout = setTimeout( () => {
				refreshCurrentDeviceDetails( deviceId );
			}, 2000 );
		}

		return () => {
			clearTimeout( refreshDeviceDetailsTimeout );
		};
	}, [
		totalAmountAlarmsForDevice,
		deviceId,
		refreshCurrentDeviceDetails,
		previousDeviceAlarmsLength,
	] );

	// Requires allowing third-party cookies
	let graphicsUrl: string | undefined;
	let spectrumGraphUrl: string | undefined;

	const chartUrlRoot =
		deviceDetails?.graphicsUrls && deviceDetails.graphicsUrls[chartUrlType]
			? deviceDetails.graphicsUrls[chartUrlType]
			: undefined;

	if ( chartUrlRoot ) {
		if ( selectedPeriod ) {
			graphicsUrl = `${ chartUrlRoot }&${ selectedPeriod }`;
		} else {
			graphicsUrl = chartUrlRoot;
		}
	}

	if ( showSpectrumGraph && deviceDetails?.graphicsUrls?.Spectrum ) {
		spectrumGraphUrl = deviceDetails.graphicsUrls.Spectrum;
	}

	const handleOnChartLoad = () => {
		if ( onLoad && typeof onLoad === 'function' ) {
			onLoad();
		}
	};

	const chartStyleClass = twMerge(
		'w-full h-full rounded-2.5xl bg-primary-content p-4',
		chartStyle
	);

	return (
		<>
			{graphicsUrl ? (
				<iframe
					key={ graphicsUrl }
					src={ graphicsUrl }
					frameBorder="0"
					title="device-details-charts"
					className={ chartStyleClass }
					onLoad={ handleOnChartLoad }
				/>
			) : null}

			{spectrumGraphUrl && showSpectrumGraph ? (
				<iframe
					key={ spectrumGraphUrl }
					src={ spectrumGraphUrl }
					frameBorder="0"
					title="device-details-charts"
					className="w-full flex-1 bg-primary-content rounded-2.5xl p-4"
				/>
			) : null}
			{!graphicsUrl && showSpectrumGraph && !spectrumGraphUrl ? (
				<div className="flex bg-base-301 rounded-2xl mx-auto h-full w-full items-center">
					<p className="text-center mx-auto">
						{isMeasuringDevice
							? t( 'There is no chart for this device at the moment' )
							: `${ t( 'Signaling Device' ) }: ${ deviceType }`}
						.
					</p>
				</div>
			) : null}
		</>
	);
};

export default GrafanaChart;
