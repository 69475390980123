import { selectorFamily } from 'recoil';
import { UserRole } from '../../../middleware/auth';
import { userRolesAtom } from '../userRoles';

export const userHasRoleSelector = selectorFamily( {
	key: 'userHasRoleSelector',
	get:
		( requiredRole: UserRole ) => ( { get } ) => {
			const userRoles = get( userRolesAtom );

			const userHasRole =
				userRoles.findIndex( ( role ) => role === requiredRole ) !== -1;

			return userHasRole;
		},
	cachePolicy_UNSTABLE: {
		// Only store the most recent set of dependencies and their values
		eviction: 'lru',
		maxSize: 16,
	},
} );
