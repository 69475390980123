export const DEVICE_RADIUS = 3;
export const DEVICE_TEXT_SIZE = 8;
export const DEVICE_TEXT_COLOR = 'white';
export const DEVICE_COLOR = '#869092';
export const SELECTED_DEVICE_COLOR = 'rgba(131,131,203,.5)';
export const SELECTED_DEVICE_ICON_COLOR = '#8383cb';
export const DEVICE_NAME_BOX_WIDTH = 60;
export const DEVICE_NAME_BOX_HEIGHT = 12;
export const DEVICE_NAME_TEXT_SIZE = DEVICE_NAME_BOX_HEIGHT * 0.74;
export const DEVICE_NAME_BOX_TOP_MARGIN = 3;
export const DRAG_ICON_SIZE = 20;

const floorPlanHeadingHeight = 60;
const floorPlanYSpacing = 140;
const wrapperContainerYMargin = 32;
export const DEFAULT_SVG_HEIGHT = `calc(100vh - ${
	floorPlanHeadingHeight + wrapperContainerYMargin + floorPlanYSpacing
}px`;
