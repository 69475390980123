import React from 'react';
import { FieldValues, UseFormRegister } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';

interface GeneralSettingsTextInputProps {
	register: UseFormRegister<FieldValues>;
	name: string;
	label: string;
	defaultValue: string;
	disabled?: boolean;
}

const GeneralSettingsTextInput: React.FC<GeneralSettingsTextInputProps> = ( {
	name,
	label,
	defaultValue,
	register,
	disabled,
} ) => {
	const styles = twMerge(
		'input input-md bg-base-100 rounded-md w-full h-9 leading-9',
		disabled && 'bg-base-300 cursor-not-allowed'
	);

	return (
		<div className="flex form-control w-5/12 mb-2">
			<label className="label">{label}</label>
			<div>
				<input
					{ ...register( name ) }
					type="text"
					className={ styles }
					defaultValue={ defaultValue }
					readOnly={ disabled }
				/>
			</div>
		</div>
	);
};

export default GeneralSettingsTextInput;
