import React, { useCallback, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import FloorPlanRenderer, {
	ModeEnum,
	placeSelector,
} from '../floorPlan/FloorPlanRenderer';
import { PlaceDefinition, Location, DeviceTypeEnum } from '../../types';
import { placesDefinitionAtom } from '../../state/places/placesDefinition';
import { allDevicesStateAtom } from '../../state/devices/allDevices';
import { AppScreen } from '../../shared/constants';
import api from '../../middleware/api';
import { filteredDevicesSelector } from '../../state/devices/selectors/filteredDevices';
import { DragItemType } from '../floorPlan/dragItemsTypes';
import { userHasRoleSelector } from '../../state/auth/selectors/userHasRole';
import Button from '../buttons/Button';
import SettingsLayout from '../SettingsLayout';
import { SettingsDraggableDevicesContainer } from '../SettingsDraggableDevicesContainer';

export default function PositionDevices() {
	const history = useHistory();
	const { t } = useTranslation();
	const [ placesDefinitionTree, setPlacesDefinitionTree ] =
		useRecoilState( placesDefinitionAtom );
	const listOfAllDevices = useRecoilValue( allDevicesStateAtom );
	const [ loadingDevices, setLoadingDevices ] = useState( false );
	const unplacedDevicesList = useRecoilValue(
		filteredDevicesSelector( {
			selectedTypes: [
				DeviceTypeEnum.VRM_NP,
				DeviceTypeEnum.VMS_LA,
				DeviceTypeEnum.VMS_RB,
				DeviceTypeEnum.VRM_303,
				DeviceTypeEnum.GENERIC,
				DeviceTypeEnum.VRM_SM_GP,
			],
			shouldBePlaced: false,
		} )
	);

	const [ zoomedPlace, setZoomedPlace ] = useState<PlaceDefinition>();
	const selectedPlaceFromTree =
		Object.keys( placesDefinitionTree ).length &&
		placeSelector( placesDefinitionTree, 'root', undefined );

	let zoomedPlaceTitle;
	if ( zoomedPlace ) {
		zoomedPlaceTitle = zoomedPlace.name;
	} else if ( selectedPlaceFromTree ) {
		zoomedPlaceTitle = selectedPlaceFromTree.name;
	}

	const settingsPath = history.location.pathname
		.split( '/' )
		.filter( ( location ) => location !== 'position-devices' )
		.join( '/' );

	const isWizardMode = settingsPath.includes( 'wizard' );
	const onConnectDevicesClick = useCallback( () => {
		// Navigate to devices screen for now. Should be changed once ConnectDevice screen is ready.
		history.push(
			isWizardMode
				? `${ AppScreen.WIZARD_CONNECT_DEVICES }`
				: AppScreen.CONNECT_DEVICES
		);
	}, [ history, isWizardMode ] );

	const onPrevStateHandler = useCallback( () => {
		history.push( `${ settingsPath }/manage-devices` );
	}, [ history, settingsPath ] );

	const fetchAndUpdatePlaces = useCallback( () => {
		api.places.get().then( ( placesTree ) => {
			setPlacesDefinitionTree( placesTree );
		} );
	}, [ setPlacesDefinitionTree ] );

	const handleAddOrUpdateDeviceLocation = useCallback(
		async (
			placeId: string,
			deviceId: string,
			position: Location,
			droppedItemType: string
		) => {
			if ( droppedItemType ) {
				try {
					if ( droppedItemType === DragItemType.NOT_POSITIONED_DEVICE ) {
						await api.places.addDevice( placeId, deviceId, {
							position,
						} );
					} else {
						await api.places.updateDevice( placeId, deviceId, {
							deviceId,
							position,
						} );
					}

					fetchAndUpdatePlaces();
				} catch ( error: any ) {
					// TODO handle
					console.log( error );
				}
			}
		},
		[ fetchAndUpdatePlaces ]
	);

	const handleRemoveDeviceLocation = useCallback(
		async ( deviceId: string ) => {
			setLoadingDevices( true );

			await api.places.removeDevice( deviceId );
			// TODO: Delete device configuration ("ipAddress", "port")
			fetchAndUpdatePlaces();
			setLoadingDevices( false );
		},
		[ fetchAndUpdatePlaces ]
	);

	const userCanEditPositions = useRecoilValue(
		userHasRoleSelector( 'MAINTENANCE' )
	);

	const handleOnPlaceClick = useCallback( ( place: PlaceDefinition ) => {
		setZoomedPlace( place );
	}, [] );

	return (
		<div className="flex flex-col flex-1">
			<SettingsLayout
				isWizard={ isWizardMode }
				title={ t( 'Position Devices' ) }
				leftContainerSlot={ (
					<SettingsDraggableDevicesContainer
						devices={ unplacedDevicesList }
						loading={ loadingDevices }
						isWizardMode={ isWizardMode }
					/>
				) }
				customContentClass="py-0 pt-8"
			>
				<div className="flex overflow-hidden flex-1 flex-col w-full content-between">
					<div className="h-full flex flex-col flex-1">
						<div className="flex flex-row gap-8 h-full">
							{/* Floor plan */}
							{Object.keys( placesDefinitionTree ).length &&
							selectedPlaceFromTree ? (
								<div className="flex flex-1 flex-col justify-baseline">
										{zoomedPlaceTitle ? (
										<h2 className="text-xl mb-4 text-center pl-10">
												{zoomedPlaceTitle}
											</h2>
										) : null}
										<FloorPlanRenderer
										placesDefinitionTree={ placesDefinitionTree }
										selectedPlaceData={ selectedPlaceFromTree }
										mode={
												userCanEditPositions
													? ModeEnum.DRAG_AND_DROP
													: ModeEnum.READONLY
											}
										handleOnPlaceClickExternally={ handleOnPlaceClick }
										removeDeviceLocation={ handleRemoveDeviceLocation }
										addOrUpdateDeviceLocation={ handleAddOrUpdateDeviceLocation }
										showDeviceName
										showSignallingDevices
									/>
									</div>
								) : null}
						</div>
					</div>
				</div>
			</SettingsLayout>
			<div
				className={ `flex w-full mt-2 ${
					isWizardMode ? 'justify-between' : 'justify-end'
				}` }
			>
				{isWizardMode && (
					<>
						<Button
							className="px-2"
							text={ `< ${ t( 'Previous page' ) }` }
							onClick={ onPrevStateHandler }
							isFormButton
						/>
						<Button
							className="px-2"
							text={ `${ t( 'Connect Devices' ) } >` }
							onClick={ onConnectDevicesClick }
							disabled={ unplacedDevicesList.length === listOfAllDevices.length }
							isFormButton
						/>
					</>
				)}
			</div>
		</div>
	);
}
