import React from 'react';

const NoDataRow: React.FC = ( { children } ) => {
	return (
		<tr className="p-3 py-2.5 text-neutral text-center">
			<td className="bg-primary-content" colSpan={ 5 }>
				{children}
			</td>
		</tr>
	);
};

export default NoDataRow;
