import { atom } from 'recoil';

/* Interfaces */

interface IUnits {
	[key: string]: string;
}

/* General units definitions state */
export const instanceUnitsStateAtom = atom<IUnits>( {
	key: 'instanceUnits',
	default: {},
} );
