import { atom } from 'recoil';
import { DeviceDetailsResponse } from '../../middleware/api';
import { IMeasuringDeviceDetails } from '../../types';

export const devicesDetailsAtom = atom<DeviceDetailsResponse>( {
	key: 'devicesDetails',
	default: {
		signallingDeviceDetails: [],
		measuringDeviceDetails: [],
	},
} );
