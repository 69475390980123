import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Device } from '../../types';
import CustomDeviceListItemDragPreview from './CustomDeviceListItemDragPreview';
import DraggableDeviceListItem from './DraggableDeviceListItem';

interface Props {
	listItems: Device[];
}

export default function DraggableDevicesList( {
	listItems,
}: Props ): ReactElement {
	const { t } = useTranslation();
	return (
		<div>
			{listItems.length > 0 ? (
				listItems.map( ( device ) => (
					<DraggableDeviceListItem key={ device.id } device={ device } />
				) )
			) : (
				<span className="text-sm">{t( 'position-device-list-empty' )}</span>
			)}

			{/* The element which will be shown when dragging an item from the list */}
			<CustomDeviceListItemDragPreview />
		</div>
	);
}
