import React, {
	useCallback, useEffect, useMemo, useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import { useHistory } from 'react-router';
import { twMerge } from 'tailwind-merge';
import { CalendarInputIcon } from '../../assets/icons';
import useDateRangeFromQuery from '../../hooks/useDateRangeFromQuery';
import useLanguage from '../../hooks/useLanguage';
import { applyDateInputMask, getIsoDateFromLocaleString, getLocalizedValueFromIso } from '../../utils/time';
import { queryBuilder } from '../../utils/strings';

export interface DateRangeInputProps
	extends React.DetailedHTMLProps<
	React.InputHTMLAttributes<HTMLInputElement>,
	HTMLInputElement
	> {
	dateRangeType: 'from' | 'to';
	onControlClick?: () => void;
	iconColor?: string;
	onEnterInputValue?: () => void;
}

const DateRangeInput: React.FC<DateRangeInputProps> = ( {
	className,
	iconColor = 'text-selected',
	dateRangeType,
	onControlClick,
	onEnterInputValue = () => {},
	...rest
} ) => {
	const history = useHistory();
	const { periodFrom, periodTo } = useDateRangeFromQuery();
	const language = useLanguage();
	const [ typedValue, setTypedValue ] = useState<string>( '' );
	const [ focused, setFocused ] = useState<boolean>( false );

	const dateRange = useMemo(
		() => ( {
			from: periodFrom
				? DateTime.fromISO( periodFrom ).setLocale( language )
				: undefined,
			to: periodTo ? DateTime.fromISO( periodTo ).setLocale( language ) : undefined,
		} ),
		[ periodFrom, periodTo, language ]
	);

	const { t } = useTranslation();
	const inputValue =
		dateRange && dateRange[dateRangeType]?.toFormat( 'dd LLL, yyyy' );

	const onChangeInput = useCallback(
		( e ) => {
			const newValue = DateTime.fromISO(
				getIsoDateFromLocaleString( e.target.value )
			).toISODate();
			setTypedValue( ( value ) => {
				if ( value === t( 'Invalid Date' ) ) {
					return '';
				}
				return e.target.value === '' || newValue || value.length < 10
					? applyDateInputMask( e.target.value )
					: t( 'Invalid Date' );
			} );
		},
		[ t ]
	);

	const setDateRange = useCallback(
		( newDate: string ) => {
			const isoDate = getIsoDateFromLocaleString( newDate );
			const dateRangeValues =
				dateRangeType === 'from'
					? {
						from: isoDate,
						to: periodTo ? DateTime.fromISO( periodTo ).toISODate() : '',
					  }
					: {
						to: isoDate,
						from: periodFrom ? DateTime.fromISO( periodFrom ).toISODate() : '',
					  };
			const query = queryBuilder( dateRangeValues );
			const location = history.location;
			history.push( `${ location.pathname }?${ query }` );
		},
		[ periodFrom, periodTo, dateRangeType, history ]
	);

	const onEnterValue = useCallback(
		( e: React.KeyboardEvent<HTMLInputElement> ) => {
			if ( e.code === 'Backspace' ) {
				setDateRange( '' );
			}

			const isValidEnterKey =
				e.code === 'Enter' || e.code === 'Tab' || e.code === 'NumpadEnter';
			const newDate = DateTime.fromISO(
				getIsoDateFromLocaleString( typedValue )
			).toISODate();

			if ( typedValue?.length === 10 && isValidEnterKey && newDate ) {
				setDateRange( typedValue );
			}
		},
		[ setDateRange, typedValue ]
	);

	const onFocus = useCallback(
		( e ) => {
			setFocused( true );
			e.preventDefault();
			setDateRange( '' );
			setTimeout( () => {
				e.target.setSelectionRange( 0, 20 );
			}, 0 );
		},
		[ setDateRange ]
	);

	const onBlur = useCallback( () => {
		setFocused( false );
		const newDate = DateTime.fromISO(
			getIsoDateFromLocaleString( typedValue )
		).toISODate();
		if ( typedValue?.length === 10 && newDate ) {
			setDateRange( typedValue );
		}
	}, [ typedValue, setDateRange ] );

	useEffect( () => {
		const startDate =
			periodFrom && DateTime.fromISO( periodFrom ).startOf( 'day' ).toMillis();
		const endDate =
			periodTo && DateTime.fromISO( periodTo ).endOf( 'day' ).toMillis();

		if ( startDate && endDate && dateRangeType === 'to' && endDate < startDate ) {
			setDateRange( '' );
		}

		if ( !periodFrom && !periodTo && !focused ) {
			setTypedValue( '' );
		}
	}, [ periodFrom, setDateRange, dateRangeType, periodTo, focused ] );

	useEffect( () => {
		if ( dateRangeType === 'from' ) {
			const localizedFrom =
				periodFrom && DateTime.fromISO( periodFrom ).toFormat( 'dd-MM-yyyy' );
			localizedFrom && setTypedValue( localizedFrom );
		} else {
			const localizedTo =
				periodTo && DateTime.fromISO( periodTo ).toFormat( 'dd-MM-yyyy' );
			setTypedValue( ( value ) => {
				const startDate =
					periodFrom && DateTime.fromISO( periodFrom ).startOf( 'day' ).toMillis();
				const isoValue = DateTime.fromJSDate( new Date( value ) ).toISODate();
				const endDate = DateTime.fromJSDate(
					new Date( getLocalizedValueFromIso( isoValue ) )
				)
					.endOf( 'day' )
					.toMillis();
				if ( startDate && endDate && endDate < startDate ) {
					return '';
				}
				return localizedTo || value;
			} );
		}
	}, [ dateRangeType, setTypedValue, periodFrom, periodTo, setDateRange ] );

	const inputClassName = twMerge(
		'w-full px-2 py-1 outline-none text-sm  max-w-xs input input-sm input-bordered capitalize bg-transparent border-none',
		className
	);

	return (
		<div className="relative flex bg-primary-content rounded-md shadow-xs">
			<input
				type="text"
				className={ inputClassName }
				value={ inputValue || typedValue }
				placeholder={ `${ dateRangeType === 'from' ? t( 'from' ) : t( 'to' ) }...` }
				{ ...rest }
				onChange={ onChangeInput }
				onKeyDown={ onEnterValue }
				onFocus={ onFocus }
				onBlur={ onBlur }
			/>
			<button
				className="p-0 m-0 flex items-center h-full w-10 absolute right-0"
				onClick={ onControlClick }
			>
				<i className={ `absolute right-2 ${ iconColor }` }>
					<CalendarInputIcon />
				</i>
			</button>
		</div>
	);
};

export default DateRangeInput;
