import React from 'react';
import { twMerge } from 'tailwind-merge';

import {
	CheckIcon,
	CloseIcon,
	ExclamationMark,
	WarningIcon,
} from '../assets/icons';

interface AlertProps {
	type: 'success' | 'fail' | 'warning' | 'connectionFail';
	message?: string;
	className?: string;
	hideIcon?: boolean;
}
type SkinType = Record<
'success' | 'fail' | 'warning' | 'connectionFail',
{ color: string; icon: JSX.Element }
>;

const skins: SkinType = {
	fail: {
		color: 'danger-error',
		icon: <CloseIcon />,
	},
	success: {
		color: 'success',
		icon: <CheckIcon />,
	},
	warning: {
		color: 'danger-warning',
		icon: <WarningIcon />,
	},
	connectionFail: {
		color: 'danger-error',
		icon: <ExclamationMark />,
	},
};

const Alert: React.FC<AlertProps> = ( {
	type,
	message,
	className,
	hideIcon,
	children,
} ) => {
	const skin = skins[type] ?? skins.fail;
	const style = twMerge(
		`animate-fade-in-down flex flex-row items-center bg-${ skin.color } rounded bg-opacity-10 border border-${ skin.color } p-2`,
		type === 'success' ? 'border-opacity-70' : 'border-opacity-30',
		className && className
	);
	return (
		<div className={ style }>
			{!hideIcon ? (
				<div
					className={ `flex items-center justify-center min-h-7 min-w-7 rounded-full bg-${
						skin.color
					} text-${ skin.color } ${
						type === 'connectionFail'
							? 'bg-opacity-0 opacity-75'
							: 'bg-opacity-30'
					}` }
				>
					{skin.icon}
				</div>
			) : null}
			<div className="text-left text-sm font-light pl-4 pr-2">
				{message}
				{children}
			</div>
		</div>
	);
};

export default Alert;
