import React from 'react';
import { Route, RouteProps } from 'react-router';
import { useRecoilValue } from 'recoil';
import { isLoggedInState } from '../state/auth/isLoggedIn';
import Unauthorized from './screens/Unauthorized';

interface PrivateRouteProps extends RouteProps {}

const PrivateRoute: React.FC<PrivateRouteProps> = ( { ...rest } ) => {
	const isLoggedInUser = useRecoilValue( isLoggedInState );

	return (
		<Route { ...rest }>{isLoggedInUser ? rest.children : <Unauthorized />}</Route>
	);
};

export default PrivateRoute;
