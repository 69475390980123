import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { RecoilRoot } from 'recoil';
import LoadedApp from './LoadedApp';

const App: React.FC = () => (
	<RecoilRoot>
		<DndProvider backend={ HTML5Backend }>
			<LoadedApp />
		</DndProvider>
	</RecoilRoot>
);

export default App;
