import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useRecoilValue } from 'recoil';
import { AppScreen } from '../../shared/constants';
import { placesDefinitionAtom } from '../../state/places/placesDefinition';
import { DeviceTypeEnum } from '../../types';
import FloorPlanRenderer, {
	placeSelector,
} from '../floorPlan/FloorPlanRenderer';
import StackMonitorDeviceItem from '../StackMonitorDeviceItem';
import { filteredDevicesSelector } from '../../state/devices/selectors/filteredDevices';
import { findDeviceByIdSelector } from '../../state/devices/selectors/findDeviceById';
import { userHasRoleSelector } from '../../state/auth/selectors/userHasRole';
import Button from '../buttons/Button';
import SettingsLayout from '../SettingsLayout';
import { SettingsConnectFormContainer } from '../SettingsConnectFormContainer';
import { checkDeviceHasConnectionProperties } from '../../utils/device';

export default function ConnectDevices() {
	const history = useHistory();
	const { t } = useTranslation();
	const placesDefinitionTree = useRecoilValue( placesDefinitionAtom );
	const monitors = useRecoilValue(
		filteredDevicesSelector( { selectedTypes: [ DeviceTypeEnum.VRM_SM ] } )
	);
	const [ selectedDeviceId, setSelectedDeviceId ] = useState<string>();
	const selectedDeviceData = useRecoilValue(
		findDeviceByIdSelector( selectedDeviceId )
	);

	// Set selected place to root
	const selectedPlaceFromTree =
		Object.keys( placesDefinitionTree ).length &&
		placeSelector( placesDefinitionTree, 'root', undefined );

	const settingsPath = history.location.pathname
		.split( '/' )
		.filter( ( location ) => location !== 'position-devices' )
		.join( '/' );

	const isWizardMode = settingsPath.includes( 'wizard' );
	const onNextButtonPress = useCallback( () => {
		history.push(
			isWizardMode ? AppScreen.WIZARD_DEVICES : AppScreen.SETTINGS_DEVICES
		);
	}, [ history, isWizardMode ] );

	const onPrevButtonPress = useCallback( () => {
		history.push(
			isWizardMode
				? AppScreen.WIZARD_POSITION_DEVICES
				: AppScreen.POSITION_DEVICES
		);
	}, [ history, isWizardMode ] );

	const onDeviceClick = ( clickedDeviceId: string ) => {
		setSelectedDeviceId( clickedDeviceId );
	};

	const userCanEditSettings = useRecoilValue(
		userHasRoleSelector( 'MAINTENANCE' )
	);

	const onStackMonitorButtonClick = ( clickedDeviceId: string ) => {
		if ( userCanEditSettings ) {
			onDeviceClick( clickedDeviceId );
		} else {
			history.push( `${ AppScreen.DEVICE_DETAILS }/${ clickedDeviceId }` );
		}
	};

	return (
		<div className="flex flex-col flex-1">
			<SettingsLayout
				isWizard={ isWizardMode }
				title={ t( 'Connect Devices' ) }
				leftContainerSlot={
					<SettingsConnectFormContainer device={ selectedDeviceData } />
				}
				customContentClass="py-0 pt-8"
			>
				<div className="flex overflow-hidden flex-1 flex-col w-full content-between">
					<div className="h-full overflow-hidden flex flex-col flex-1">
						<div className="flex flex-1 flex-row gap-8 h-full">
							{/* Floor plan */}
							{Object.keys( placesDefinitionTree ).length &&
								selectedPlaceFromTree && (
								<div className="flex flex-1 flex-col justify-baseline">
									<h2 className="text-xl mb-4 text-center pl-10">
										{selectedPlaceFromTree.name}
									</h2>
									<FloorPlanRenderer
										placesDefinitionTree={ placesDefinitionTree }
										selectedPlaceData={ selectedPlaceFromTree }
										selectedDeviceId={ selectedDeviceId }
										handleOnDeviceClickExternally={
											userCanEditSettings
												? ( clickedDeviceId: string ) => {
													onDeviceClick( clickedDeviceId );
													  }
												: undefined
										}
										handleOnPlaceClickExternally={ () => {
											return false;
										} }
										showDeviceConnectionStatus
										showDeviceName
										showSignallingDevices
									/>
									{monitors.length && (
										<div className="flex flex-row flex-wrap gap-2 items-center justify-center mt-4">
											<h3 className="mr-2">
												{`${ t( 'Stack monitor devices' ) }:`}
											</h3>
											{monitors.map( ( monitor ) => {
												const isSelected = selectedDeviceId === monitor.id;
												const isActivated = monitor.isActivated;
												const hasConnectionProperties =
														checkDeviceHasConnectionProperties(
															monitor.configuration
														);

												return (
													<StackMonitorDeviceItem
														key={ monitor.id }
														id={ monitor.id }
														name={ monitor.name }
														isSelected={ isSelected }
														onClick={ onStackMonitorButtonClick }
														isActivated={ isActivated }
														hasConnectionProperties={ hasConnectionProperties }
													/>
												);
											} )}
										</div>
									)}
								</div>
							)}
						</div>
					</div>
				</div>
			</SettingsLayout>
			<div
				className={ `flex w-full mt-2 ${
					isWizardMode ? 'justify-between' : 'justify-end'
				}` }
			>
				{isWizardMode && (
					<>
						<Button
							className="px-2"
							text={ `< ${ t( 'Previous page' ) }` }
							onClick={ onPrevButtonPress }
							isFormButton
						/>
						<Button
							className="px-2"
							text={ `${ t( 'Configure Devices' ) } >` }
							onClick={ onNextButtonPress }
							disabled={ false }
							isFormButton
						/>
					</>
				)}
			</div>
		</div>
	);
}
