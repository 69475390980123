import React from 'react';
import { DateTime } from 'luxon';
import ReactTooltip from 'react-tooltip';
import { Alarm, AlarmSeverityEnum } from '../../types';
import { AlarmListItemIcon } from './AlarmListItemIcon';
import { roundMeasurement } from '../../utils/device';
import useLanguage from '../../hooks/useLanguage';
import i18n from '../../i18n';

interface AlarmListItemProps {
	type: AlarmSeverityEnum;
	deviceName: string;
	peakValue: number;
	unit: string;
	created: string;
	resolvedAt?: string;
	isResolved?: boolean;
	secondAlarm?: Alarm;
}

const getTimeFromDate = ( isoDate: string, language: string ) => DateTime.fromISO( isoDate )
	.setLocale( language )
	.toLocaleString( DateTime.TIME_SIMPLE );

const getAlarmReasonText = (
	type: AlarmSeverityEnum,
	peakValue: number,
	unit: string
) => {
	const formattedPeakValue = roundMeasurement( peakValue );
	switch ( type ) {
		case AlarmSeverityEnum.ERROR:
			return `${ formattedPeakValue } ${ unit }`;
		case AlarmSeverityEnum.ERROR_AND_CONNECTION:
			return `${ formattedPeakValue } ${ unit }`;
		case AlarmSeverityEnum.WARNING:
			return `${ formattedPeakValue } ${ unit }`;
		case AlarmSeverityEnum.CONNECTION:
			return i18n.t( 'Not connected' );
		case AlarmSeverityEnum.WARNING_AND_CONNECTION:
			return i18n.t( 'Switched off' );
		default:
			break;
	}

	return '';
};

export const AlarmListItem = ( {
	type,
	deviceName,
	peakValue,
	unit,
	isResolved,
	created,
	resolvedAt,
	secondAlarm,
}: AlarmListItemProps ) => {
	const language = useLanguage();

	let alarmColorClass = '';

	switch ( type ) {
		case AlarmSeverityEnum.CONNECTION: {
			alarmColorClass = 'bg-danger-disconnected';
			break;
		}
		case AlarmSeverityEnum.WARNING: {
			alarmColorClass = 'bg-warning';
			break;
		}
		case AlarmSeverityEnum.ERROR: {
			alarmColorClass = 'bg-error';
			break;
		}
		case AlarmSeverityEnum.WARNING_AND_CONNECTION: {
			alarmColorClass = 'bg-danger-warning_and_connection';
			break;
		}
		case AlarmSeverityEnum.ERROR_AND_CONNECTION: {
			alarmColorClass = 'bg-error';
			break;
		}
		default: {
			alarmColorClass = 'bg-base-700';
		}
	}

	return (
		<>
			{!isResolved ? (
				<div
					className={ `rounded-r-md cursor-pointer  ${ alarmColorClass } text-primary-content` }
				>
					<div className="flex py-2 pl-6 pr-2 rounded-r-md mb-0.5 gap-1 items-center">
						<div
							className="ml-2 flex-1 overflow-hidden"
							data-for="device-name-tooltip"
							data-tip={ deviceName }
						>
							<h3 className="text-base w-full whitespace-nowrap truncate">
								{deviceName}
							</h3>
							<h4 className="text-sm text-gray-500">
								{getAlarmReasonText( type, peakValue, unit )}
							</h4>
						</div>
						<div className="self-center rounded-md flex items-center rounded-l-full mr-2 py-2 pr-2 relative w-29 justify-between">
							<div
								className={ `m-0.5 ${ alarmColorClass } rounded-full z-20 flex h-8 items-center w-8 justify-center` }
							>
								<div>
									<AlarmListItemIcon type={ type } />
								</div>
							</div>
							{secondAlarm && (
								<div
									className={ `m-0.5 bg-danger-${ secondAlarm.alarmSeverity.toLowerCase() } rounded-full flex h-8 items-center w-8 justify-center absolute z-10 left-4` }
								>
									<div>
										<AlarmListItemIcon type={ secondAlarm.alarmSeverity } />
									</div>
								</div>
							)}
							<div className="w-full filter brightness-65 self-end absolute flex justify-end rounded-l-full h-9 overflow-hidden">
								<div
									className={ `w-full ${ alarmColorClass } overflow-hidden rounded bordered` }
								/>
							</div>
							<h5 className="text-sm filter brightness-200">
								{getTimeFromDate( created, language )}
							</h5>
						</div>
					</div>
				</div>
			) : (
				<div className="flex flex-col overflow-auto pt-2 opacity-80">
					<div className="flex justify-between border-t border-base-700 pt-2 gap-1">
						<div
							className="ml-2 self-center overflow-hidden"
							data-for="device-name-tooltip"
							data-tip={ deviceName }
						>
							<h3 className="text-base w-full truncate">{deviceName}</h3>
							<h4 className="text-base text-gray-500">
								{getAlarmReasonText( type, peakValue, unit )}
							</h4>
						</div>
						<div className="self-center px-4 py-1 bg-base-200 rounded-md">
							<h5 className="text-sm text-gray-500 ml-auto mr-1 font-bold">
								{isResolved && resolvedAt
									? `${ getTimeFromDate( resolvedAt, language ) }`
									: ''}
							</h5>
						</div>
					</div>
				</div>
			)}{' '}
		</>
	);
};
