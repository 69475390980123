import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
	ConfigurationSection,
	DeviceConfigurationGroup as DeviceConfigurationGroupType,
	PropertyTypeEnum,
} from '../../../types';
import NumberField from '../fields/NumberField';
import TextField from '../fields/TextField';
import * as validation from '../validation';
import PasswordField from '../fields/PasswordField';
import OnOffField from '../fields/OnOffField';
import { getTranslatedConfigurationProperty } from '../../../utils/translations';

interface DeviceConfigurationGroupProps {
	groupDefinitions: ConfigurationSection;
	groupConfiguration?: DeviceConfigurationGroupType;
}

const DeviceConfigurationGroup = ( {
	groupDefinitions,
	groupConfiguration,
}: DeviceConfigurationGroupProps ) => {
	const { t } = useTranslation();
	const {
		register,
		control,
		watch,
		getValues,
		formState: { errors },
	} = useFormContext();

	const customFlowRateDefinition = groupDefinitions.properties.find(
		( definition ) => definition.name === 'CustomFlowRate'
	);
	const customFlowRate = groupConfiguration?.properties.find(
		( prop ) => prop.name === 'CustomFlowRate'
	);
	const customFlowRateFlag = groupConfiguration?.properties.find(
		( prop ) => prop.name === 'UseCustomFlowRate'
	);
	const customFlowRateFlagWatch = watch(
		'DeviceConfiguration.UseCustomFlowRate.value'
	);
	const isCustomFlowRateDisabled =
		customFlowRateFlagWatch !== undefined
			? !customFlowRateFlagWatch
			: !customFlowRateFlag?.value;

	return (
		<div>
			<div>
				<h3 className="font-bold">{t( 'Device Configuration' )}</h3>

				<div>
					{groupDefinitions?.properties
						.filter( ( prop ) => {
							const excludeField = [
								'SignallingDevices',
								'CustomFlowRate',
								'UseCustomFlowRate',
							];
							const shouldExcludeField = excludeField.includes( prop.name );
							return shouldExcludeField ? false : true;
						} )
						.map( ( configurationProperty ) => {
							const existingFieldConfig = groupConfiguration?.properties.find(
								( prop ) => prop.name === configurationProperty.name
							);

							const translatedPropertyName = getTranslatedConfigurationProperty(
								configurationProperty.name
							);

							switch ( configurationProperty.type.toString() ) {
								case PropertyTypeEnum.Password: {
									const fieldLength = configurationProperty.length;

									return (
										<PasswordField
											key={ configurationProperty.name }
											label={ translatedPropertyName }
											control={ control }
											name={ `DeviceConfiguration.${ configurationProperty.name }` }
											placeHolder={ t( 'Enter password' ) }
											fieldDefinition={ configurationProperty }
											maxLength={ fieldLength?.end }
											defaultValue={ existingFieldConfig?.value }
											required
										/>
									);
								}
								case PropertyTypeEnum.Number:
								case PropertyTypeEnum.Float:
								case PropertyTypeEnum.Double:
								case PropertyTypeEnum.TimeSpan:
								case PropertyTypeEnum.AlarmHigh:
								case PropertyTypeEnum.AlarmHighHigh: {
									return (
										<NumberField
											key={ configurationProperty.name }
											name={ `DeviceConfiguration.${ configurationProperty.name }` }
											label={ translatedPropertyName }
											placeHolder={ t( 'enter-field-value', {
												name: translatedPropertyName,
											} ) }
											fieldDefinition={ configurationProperty }
											control={ control }
											defaultValue={ existingFieldConfig?.value }
											defaultUnit={ existingFieldConfig?.unit }
											rules={ {
												validate: () => {
													const highFieldName =
														'DeviceConfiguration.High.value';
													const highHighFieldName =
														'DeviceConfiguration.HighHigh.value';

													return validation.alarmLevelsDependencies(
														getValues( [ highFieldName, highHighFieldName ] ),
														configurationProperty.type
													);
												},
											} }
											minValue={ 0 }
											invalidChars={ [ '+', '-', 'e' ] }
											required
										/>
									);
								}
								default: {
									return (
										<TextField
											key={ configurationProperty.name }
											control={ control }
											name={ `DeviceConfiguration.${ configurationProperty.name }.value` }
											rules={ {
												...validation.createRangeRule(
													configurationProperty.range[0]
												),
												required: t( 'error-field-required', {
													name: translatedPropertyName,
												} ).toString(),
											} }
											label={ translatedPropertyName }
											placeHolder={ t( 'enter-field-value', {
												name: translatedPropertyName,
											} ) }
											defaultValue={ existingFieldConfig?.value }
										/>
									);
								}
							}
						} )}
				</div>

				{customFlowRateDefinition ? (
					<div>
						<div className="flex flex-row justify-between items-center mt-3">
							{/* Toggle CustomFlowRate field */}
							<OnOffField
								label={ t( 'custom-flow-rate-field' ) }
								register={ register }
								name="DeviceConfiguration.UseCustomFlowRate.value"
								defaultValue={ customFlowRateFlag?.value }
							/>
						</div>

						<NumberField
							name="DeviceConfiguration.CustomFlowRate"
							placeHolder={ t( 'Enter custom flow rate' ) }
							defaultValue={ customFlowRate?.value }
							control={ control }
							fieldDefinition={ customFlowRateDefinition }
							disabled={ isCustomFlowRateDisabled }
							required
							minValue={ 0 }
							invalidChars={ [ '+', '-', 'e' ] }
						/>
					</div>
				) : null}
				{/* End custom flow rate */}
			</div>
		</div>
	);
};

export default DeviceConfigurationGroup;
