import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
	CommonPropertyNameEnum,
	ConfigurationSection,
	CommonDeviceConfigurationGroup,
	DeviceTypeEnum,
} from '../../../types';
import NumberField from '../fields/NumberField';
import ReadOnlyField from '../fields/ReadOnlyField';
import TextField from '../fields/TextField';

interface CommonGroupProps {
	deviceName: string;
	deviceType: DeviceTypeEnum;
	groupDefinitions: ConfigurationSection;
	groupConfiguration?: CommonDeviceConfigurationGroup;
}

const CommonGroup = ( {
	groupDefinitions,
	groupConfiguration,
	deviceName,
	deviceType,
}: CommonGroupProps ) => {
	const { t } = useTranslation();

	const { control } = useFormContext();

	const pollingIntervalDefinition = groupDefinitions.properties.find(
		( definition ) => definition.name === CommonPropertyNameEnum.PollingInterval
	);
	const pollingIntervalExistingConfig = groupConfiguration?.properties.find(
		( property ) => property.name === CommonPropertyNameEnum.PollingInterval
	);

	return (
		<div>
			<h3 className="font-bold">{t( 'Common' )}</h3>
			<div>
				{/* Device name */}
				<TextField
					control={ control }
					name="deviceName"
					label={ t( 'Device name' ) }
					placeHolder={ t( 'enter-field-value', {
						name: t( 'Device name' ),
					} ) }
					rules={ {
						required: t( 'error-field-required', {
							name: t( 'Device name' ),
						} ).toString(),
					} }
					defaultValue={ deviceName }
				/>

				{/* Device type */}
				<ReadOnlyField label={ t( 'Device type' ) } defaultValue={ deviceType } />
			</div>

			<div className="mt-4">
				<h3 className="font-bold">{t( 'Network Connection' )}</h3>
				<div>
					{/* Polling interval */}
					{pollingIntervalDefinition ? (
						<NumberField
							name={ `Common.${ CommonPropertyNameEnum.PollingInterval }` }
							control={ control }
							fieldDefinition={ pollingIntervalDefinition }
							label={ t( 'Polling interval' ) }
							placeHolder={ t( 'Enter polling interval' ) }
							defaultValue={ pollingIntervalExistingConfig?.value as number }
							defaultUnit={ pollingIntervalExistingConfig?.unit }
							minValue={ 0 }
							invalidChars={ [ '+', '-', 'e' ] }
							required
						/>
					) : null}
				</div>
			</div>
		</div>
	);
};

export default CommonGroup;
