import React from 'react';

interface TileHandleProps {
	onClick: () => void;
}

export const TileHandle = ( { onClick }: TileHandleProps ) => {
	return (
		<button
			className="flex flex-col items-center justify-baseline pt-1.5 pb-3"
			onClick={ onClick }
		>
			<span className="w-4 bg-base-100 block rounded-full h-0.5" />
		</button>
	);
};
