import { DateTime } from 'luxon';
import i18n from '../i18n';
import { TimeOptionType } from './grafana';

export const getNextMonthStartDate = ( date: Date ) => {
	return DateTime.fromJSDate( date ).plus( { months: 1 } ).toJSDate();
};

export const getAuditTimeStamp = (
	timeStamp?: string,
	dateTimeDivider = ' | '
) => {
	if ( !timeStamp ) {
		return '-';
	}

	const timeStampDate = DateTime.fromISO( timeStamp ).setLocale( i18n.language );
	const dateString = timeStampDate.toFormat( 'dd-MMM-yyyy' );
	const timeString = timeStampDate.toLocaleString( DateTime.TIME_SIMPLE );

	return `${ timeString }${ dateTimeDivider }${ dateString }`;
};

export const getPrettyTimeStamp = ( timeStamp?: string ): string => {
	if ( !timeStamp ) {
		return '-';
	}

	const todayMillis = DateTime.fromJSDate( new Date() ).startOf( 'day' ).toMillis();
	const yesterdayMillis = DateTime.fromJSDate( new Date() )
		.minus( { day: 1 } )
		.startOf( 'day' )
		.toMillis();
	const todayYearNumber = DateTime.fromJSDate( new Date() ).year;

	const timeStampDate = DateTime.fromISO( timeStamp ).setLocale( i18n.language );
	const timeStampDayMillis = timeStampDate.startOf( 'day' ).toMillis();
	const timeStampYearNumber = timeStampDate.year;

	if ( timeStampYearNumber !== todayYearNumber ) {
		return timeStampDate.toFormat( 'dd-MMM-yyyy' );
	}

	if ( todayMillis === timeStampDayMillis ) {
		return `${ timeStampDate.toLocaleString( DateTime.TIME_SIMPLE ) }, ${ i18n.t(
			'Today'
		) }`;
	}

	if ( yesterdayMillis === timeStampDayMillis ) {
		return `${ timeStampDate.toLocaleString( DateTime.TIME_SIMPLE ) }, ${ i18n.t(
			'Yesterday'
		) }`;
	}

	return `${ timeStampDate.toLocaleString(
		DateTime.TIME_SIMPLE
	) },  ${ timeStampDate.toFormat( 'dd-LLL' ) }`;
};

export const today = new Date();

export const getSelectedRangeData = (
	opt: TimeOptionType = 'hour',
	customRange: { from?: string; to?: string }
) => {
	const now = DateTime.fromMillis( Date.now() );
	const nowISO = now.toISO();
	switch ( opt ) {
		case 'hour':
			return { from: now.minus( { hour: 1 } ).toISO(), to: nowISO };
		case 'day':
			return { from: now.minus( { day: 1 } ).toISO(), to: nowISO };
		case 'week':
			return { from: now.minus( { week: 1 } ).toISO(), to: nowISO };
		case 'month':
			return { from: now.minus( { month: 1 } ).toISO(), to: nowISO };
		case 'year':
			return { from: now.minus( { year: 1 } ).toISO(), to: nowISO };
		default:
			return {
				from: customRange.from,
				to: customRange.to,
			};
	}
};

export const applyDateInputMask = ( date: string ) => {
	const DATE_WITH_MASK = 10;
	const setCharacters = new Set( [
		'0',
		'1',
		'2',
		'3',
		'4',
		'5',
		'6',
		'7',
		'8',
		'9',
	] );
	let res = '';
	let idxAtMask = -1;
	for ( let idx = 0; idx < date.length; idx++ ) {
		let currChar = date.charAt( idx );
		if ( setCharacters.has( currChar ) ) {
			if ( idxAtMask === 1 && idx <= 6 ) {
				res += '-';
				idxAtMask = -1;
			}
			res += currChar;
			idxAtMask++;
		}
	}

	if ( res.length <= DATE_WITH_MASK ) {
		return res;
	}
	return res.substring( 0, DATE_WITH_MASK );
};

export const getLocalizedValueFromIso = ( isoDate: string ) => {
	if ( isoDate ) {
		const dateValues = isoDate.split( '-' );
		const year = dateValues[0];
		const month = dateValues[2];
		const day = dateValues[1];

		// Same for EN and IT
		return [ year, month, day ].join( '-' );
	}

	return '';
};

export const getIsoDateFromLocaleString = ( isoDate: string ) => {
	if ( isoDate ) {
		const dateValues = isoDate.split( '-' );
		const year = dateValues[2];
		const month = dateValues[1];
		const day = dateValues[0];

		// Same for EN and IT
		return [ year, month, day ].join( '-' );
	}

	return '';
};
