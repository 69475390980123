import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { allDevicesStateAtom } from '../state/devices/allDevices';
import DeviceItemRow from './devicesList/DeviceItemRow';
import ScrollableTable from './table/ScrollableTable';
import TableHeadItem from './table/TableHeadItem';

export interface DevicesGridProps {
	onItemClick: ( deviceId: string ) => void;
}

const DevicesGrid: React.FC<DevicesGridProps> = ( { onItemClick } ) => {
	const devices = useRecoilValue( allDevicesStateAtom );
	const { t } = useTranslation();

	return (
		<ScrollableTable>
			<thead>
				<tr>
					<TableHeadItem>{t( 'Device name' )}</TableHeadItem>
					<TableHeadItem>{t( 'Device type' )}</TableHeadItem>
					<TableHeadItem>{t( 'Current measurement' )}</TableHeadItem>
					<TableHeadItem>{t( 'Location' )}</TableHeadItem>
					<TableHeadItem>{t( 'Last seen' )}</TableHeadItem>
					<TableHeadItem>{t( 'Status' )}</TableHeadItem>
				</tr>
			</thead>
			<tbody>
				{devices.map( ( device ) => (
					<DeviceItemRow
						key={ device.id }
						device={ device }
						onClick={ onItemClick }
					/>
				) )}
			</tbody>
		</ScrollableTable>
	);
};

export default DevicesGrid;
