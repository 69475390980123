import React from 'react';
import { FieldValues, RegisterOptions, UseFormRegister } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';

export interface OnOffFieldProps {
	name: string;
	register: UseFormRegister<FieldValues>;
	label?: string;
	errorText?: string;
	disabled?: boolean;
	defaultValue?: boolean;
	className?: string;
}

const OnOffField: React.FC<OnOffFieldProps> = ( {
	register,
	name,
	label,
	errorText,
	disabled,
	defaultValue,
	className,
} ) => {
	const containerClass = twMerge(
		'block text-sm flex-1 flex',
		className && className
	);

	return (
		<label className={ containerClass }>
			{label ? (
				<span className={ `${ disabled ? 'opacity-40' : '' } flex-1` }>
					{label}
				</span>
			) : null}
			<div className="flex items-center">
				<input
					{ ...register( name ) }
					type="checkbox"
					className="checkbox checkbox-sm checkbox-primary"
					disabled={ disabled }
					defaultChecked={ defaultValue }
				/>
			</div>
			{!!errorText && (
				<span className="text-danger text-xs pt-2 block">{errorText}</span>
			)}
		</label>
	);
};

export default OnOffField;
