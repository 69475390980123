import React, {
	createRef,
	useCallback,
	useEffect,
	useLayoutEffect,
	useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { ResetIcon } from '../../assets/icons';
import useDateRangeFromQuery from '../../hooks/useDateRangeFromQuery';
import { auditFiltersContainerMaxHeight } from '../../shared/constants';
import { AuditTrailRequest } from '../../types';
import Button from '../buttons/Button';
import DateRangeInput from '../calendar/DateRangeInput';
import FiltersCalendar from '../calendar/FiltersCalendar';
// import useAutoClose from '../../hooks/useAutoClose';

export interface AuditFiltersProps {
	title: string;
	onChangeFilters: ( auditFilters: AuditTrailRequest ) => void;
}

const emptyFilters: AuditTrailRequest = {};

const AuditFilters: React.FC<AuditFiltersProps> = ( {
	title,
	onChangeFilters,
} ) => {
	const { t } = useTranslation();
	const history = useHistory();
	const componentContainerRef = createRef<HTMLDivElement>();

	const { periodFrom: from, periodTo: to } = useDateRangeFromQuery();

	const [ filters, setFilters ] = useState<AuditTrailRequest>( emptyFilters );
	const [ isCalendarOpen, setIsCalendarOpen ] = useState<boolean>( false );
	const [ isStartDateActive, setIsStartDateActive ] = useState<boolean>( false );
	const [ containerHeight, setContainerHeight ] = useState<number>();

	// useAutoClose( { setIsOpen: setIsCalendarOpen, element: calendarRef } );
	const calendarWithBottomOffset =
		containerHeight && containerHeight < auditFiltersContainerMaxHeight;

	useLayoutEffect( () => {
		setContainerHeight( componentContainerRef.current?.scrollHeight );
	}, [ setContainerHeight, componentContainerRef ] );

	const onOpenCalendarWithStartDate = useCallback( () => {
		setIsCalendarOpen( false );
		setTimeout( () => {
			setIsCalendarOpen( true );
		}, 0 );
		setIsStartDateActive( true );
	}, [] );

	const resetUrl = useCallback( () => {
		history.push( history.location.pathname );
	}, [ history ] );

	const onOpenCalendarWithEndDate = useCallback( () => {
		setIsCalendarOpen( false );
		setTimeout( () => {
			setIsCalendarOpen( true );
		}, 0 );

		setIsStartDateActive( false );
	}, [] );

	const onChange = useCallback(
		async ( e ) => {
			try {
				const key: keyof AuditTrailRequest = e.target.name;
				const requestData = {
					...filters,
					[key]: e.target.value,
				};
				setFilters( requestData );
				onChangeFilters( requestData );
			} catch ( _ ) {
				//
			}
		},
		[ filters, onChangeFilters ]
	);

	const onResetFilters = useCallback( () => {
		setFilters( {} );
		onChangeFilters( {} );
		resetUrl();
	}, [ onChangeFilters, resetUrl ] );

	useEffect( () => {
		onChangeFilters( {} );
	}, [ onChangeFilters ] );

	useEffect( () => {
		if ( from || to ) {
			const requestData: AuditTrailRequest = {
				...filters,
				from,
				to,
			};
			onChangeFilters( requestData );
		}
	}, [ onChangeFilters, from, to, filters ] );

	return (
		<div
			className="w-80 p-4 pl-10 pr-6 flex flex-col gap-3"
			ref={ componentContainerRef }
		>
			<h1 className="text-gray-700 text-3xl font-medium font-system mb-5">
				{title}
			</h1>
			<div className="text-md font-bold">{t( 'Selection filters' )}</div>
			<div className="border-b border-base-302" />
			<input
				className="w-full max-w-xs input input-sm input-bordered rounded-md font-normal"
				placeholder={ t( 'Operator placeholder' ) }
				name="operator"
				onChange={ onChange }
				value={ filters.operator || '' }
			/>
			<input
				className="w-full max-w-xs input input-sm input-bordered rounded-md font-normal"
				placeholder={ t( 'Message placeholder' ) }
				name="message"
				onChange={ onChange }
				value={ filters.message || '' }
			/>
			{!calendarWithBottomOffset && (
				<div className="relative h-0 p-0">
					<FiltersCalendar
						className="absolute top-28 z-30 bg-base-100 shadow-dark w-calendar overflow-visible"
						isOpen={ isCalendarOpen }
						setIsOpen={ setIsCalendarOpen }
						firstDayInputIsActive={ isStartDateActive }
					/>
				</div>
			)}
			<div className="text-md font-bold mt-4">{t( 'Selection period' )}</div>
			<div className="border-b border-base-302" />
			<DateRangeInput
				className="w-full max-w-xs input input-sm input-bordered rounded-md font-normal capitalize"
				iconColor="text-base-600"
				dateRangeType="from"
				onControlClick={ onOpenCalendarWithStartDate }
				onChange={ onChange }
			/>
			<DateRangeInput
				className="w-full max-w-xs input input-sm input-bordered rounded-md font-normal capitalize"
				iconColor="text-base-600"
				dateRangeType="to"
				onControlClick={ onOpenCalendarWithEndDate }
				onChange={ onChange }
			/>
			<Button
				text={ t( 'Reset filters' ) }
				onClick={ onResetFilters }
				icon={ <ResetIcon /> }
				className="bg-base-300 w-full border-base-700 text-sm text-base-900 font-thin my-2"
			/>
			{isCalendarOpen && calendarWithBottomOffset && (
				<FiltersCalendar
					className="absolute top-filters-calendar z-30 bg-base-100 shadow-dark"
					isOpen={ isCalendarOpen }
					setIsOpen={ setIsCalendarOpen }
					firstDayInputIsActive={ isStartDateActive }
				/>
			)}
		</div>
	);
};

export default AuditFilters;
