import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { AppScreen } from '../../shared/constants';
import Button from '../buttons/Button';

const Unauthorized: React.FC = () => {
	const { t } = useTranslation();
	const history = useHistory();

	const redirectToLogin = useCallback( () => {
		history.push( AppScreen.LOGIN );
	}, [ history ] );

	return (
		<div className="flex h-screen justify-center items-center bg-primary">
			<div className="flex flex-col items-center text-secondary gap-4 w-96">
				<div className=" text-8xl font-bold">401</div>
				<div className="w-2/3">
					{t( 'You are not authorized to see this page' )}
				</div>
				<div className="flex w-full gap-2 justify-center">
					<div className="flex w-1/5">
						{' '}
						<Button
							text={ t( 'Login' ) }
							className="bg-secondary text-primary rounded-sm shadow-none"
							onClick={ redirectToLogin }
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Unauthorized;
