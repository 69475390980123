import jwt_decode from 'jwt-decode';

const SAVED_TOKENS_KEY = 'SAVED_TOKENS_KEY';
const SAVED_ROLES_KEY = 'SAVED_ROLES_KEY';

// There is a limit to the setTimeout delay.
// When exceeded, the setTimeout fires immediately.
const MAX_TIMEOUT_DELAY = 2 ** 31 - 1;

export interface UserTokens {
	accessToken: string;
	refreshToken: string;
}

type Tokens = UserTokens | null;

export type UserRole = 'SUPERVISOR' | 'MAINTENANCE';

export interface Auth {
	isLoggedIn: boolean;
	tokens: Tokens;
	roles: UserRole[];
	reloadTokens: () => void;
	updateTokens: ( newTokens: UserTokens, roles?: UserRole[] ) => void;
	clearTokens: () => void;
	onIsLoggedInChange: (
		cb: ( isLoggedIn: boolean, roles: UserRole[] ) => void
	) => void;
	scheduleAutoClean: ( refreshToken: string ) => void;
}

export interface DecodedAuthToken {
	aud: string;
	exp: number;
	iss: string;
	nonce: string;
	session_exp: string;
}

let isLoggedInCallback: Array<
( isLoggedIn: boolean, roles: UserRole[] ) => void
> = [];

const triggerIsLoggedInChange = ( isLoggedIn: boolean, roles: UserRole[] ) => {
	isLoggedInCallback.forEach( ( cb ) => {
		cb( isLoggedIn, roles );
	} );
};

let autoClearTimeoutId: NodeJS.Timeout | null = null;

const auth: Auth = {
	isLoggedIn: false,
	roles: [],
	tokens: null,
	scheduleAutoClean: ( refreshToken: string ) => {
		if ( autoClearTimeoutId ) {
			clearTimeout( autoClearTimeoutId );
		}

		const decodedRefreshToken: DecodedAuthToken = jwt_decode( refreshToken );
		const sessionExpiryTime =
			parseInt( decodedRefreshToken.session_exp, 10 ) * 1000;

		const now = Date.now();
		const waitTime = sessionExpiryTime - now;

		if ( waitTime < 0 ) {
			auth.clearTokens();
		} else if ( waitTime < MAX_TIMEOUT_DELAY ) {
			// We set the timeout only when the wait is lower than the maximum allowed
			autoClearTimeoutId = setTimeout( auth.clearTokens, waitTime );
		}
	},
	reloadTokens: () => {
		const savedTokens = localStorage.getItem( SAVED_TOKENS_KEY );
		const savedRoles = localStorage.getItem( SAVED_ROLES_KEY );

		if ( savedTokens && savedRoles && !auth.tokens ) {
			auth.tokens = JSON.parse( savedTokens );
			auth.roles = JSON.parse( savedRoles );
			auth.isLoggedIn = true;

			if ( auth.tokens ) {
				auth.scheduleAutoClean( auth.tokens.refreshToken );
			}
		}

		triggerIsLoggedInChange( auth.isLoggedIn, auth.roles );
	},
	updateTokens: ( newTokens, newRoles ) => {
		localStorage.setItem( SAVED_TOKENS_KEY, JSON.stringify( newTokens ) );
		auth.tokens = newTokens;
		auth.isLoggedIn = true;
		if ( newRoles ) {
			localStorage.setItem( SAVED_ROLES_KEY, JSON.stringify( newRoles ) );
			auth.roles = newRoles;
			triggerIsLoggedInChange( auth.isLoggedIn, newRoles );
		} else {
			triggerIsLoggedInChange( auth.isLoggedIn, auth.roles );
		}

		auth.scheduleAutoClean( newTokens.refreshToken );
	},
	clearTokens: () => {
		localStorage.removeItem( SAVED_TOKENS_KEY );
		auth.tokens = null;
		auth.isLoggedIn = false;

		triggerIsLoggedInChange( auth.isLoggedIn, [] );
		autoClearTimeoutId = null;
	},
	onIsLoggedInChange: ( callback ) => {
		isLoggedInCallback.push( callback );

		// We always execute the callback in order to notify about the current state.
		callback( auth.isLoggedIn, auth.roles );
	},
};

auth.reloadTokens();

export default auth;
