import { selectorFamily } from 'recoil';
import { devicesDetailsAtom } from '../devicesDetails';

export const findDeviceDetailsByIdSelector = selectorFamily( {
	key: 'findDeviceDetailsByIdSelector',
	get:
		( searchId: string | undefined ) => ( { get } ) => {
			const devices = get( devicesDetailsAtom );

			if ( searchId ) {
				const foundMeasuringDeviceById = devices.measuringDeviceDetails.find(
					( device ) => device.deviceId === searchId
				);
				if ( foundMeasuringDeviceById ) {
					return foundMeasuringDeviceById;
				}

				const foundSignalingById = devices.signallingDeviceDetails.find(
					( device ) => device.deviceId === searchId
				);
				if ( foundSignalingById ) {
					return foundSignalingById;
				}
			}

			return null;
		},
	cachePolicy_UNSTABLE: {
		// Only store the most recent set of dependencies and their values
		eviction: 'lru',
		maxSize: 16,
	},
} );
