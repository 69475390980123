import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { ResolveIcon } from '../../assets/icons';
import { findDeviceDetailsByIdSelector } from '../../state/devices/selectors/findDeviceDetailsById';
import { Alarm, AlarmSeverityEnum } from '../../types';
import { roundMeasurement } from '../../utils/device';
import { getPrettyTimeStamp } from '../../utils/time';
import Button from '../buttons/Button';
import ResolveAlarm from '../modals/ResolveAlarm';
import { AlarmListItemIcon } from './AlarmListItemIcon';

interface DeviceDetailsAlarmCardProps {
	deviceId: string;
	selectedDeviceAlarm: Alarm;
	deviceName: string;
	secondDeviceAlarm?: Alarm;
	resolveDisabled?: boolean;
}
interface CardAlarm {
	'Current value': string;
	'Peak value': string;
	'Start time': string;
}

const DeviceDetailsAlarmCard: React.FC<DeviceDetailsAlarmCardProps> = ( {
	deviceId,
	selectedDeviceAlarm,
	deviceName,
	resolveDisabled = false,
} ) => {
	const { t } = useTranslation();
	const deviceDetails = useRecoilValue( findDeviceDetailsByIdSelector( deviceId ) );

	const [ isResolveModalOpen, setIsResolveModalOpen ] = useState( false );

	const handleOpenResolveModal = () => {
		setIsResolveModalOpen( true );
	};

	const handleCloseResolveModal = () => {
		setIsResolveModalOpen( false );
	};

	const title =
		selectedDeviceAlarm.alarmSeverity === AlarmSeverityEnum.CONNECTION
			? t( 'Device is disconnected' )
			: t( 'High Measurement Detected' );

	// We always get the first measurement, since the normal devices have only 1 channel (for now).
	const measurement = deviceDetails?.measurementMessage?.measurements[0];
	const measurementDisplayValue = measurement
		? `${ roundMeasurement( measurement.value ) } ${ measurement.unit }`
		: '-';

	const isConnected =
		deviceDetails?.measurementMessage?.isConnected ??
		deviceDetails?.signallingDeviceStatusMessage?.isConnected;

	const alarmData: CardAlarm = {
		'Current value': measurementDisplayValue,
		'Peak value': `${ roundMeasurement( selectedDeviceAlarm.peakValue ) } ${
			selectedDeviceAlarm.unit
		}`,
		'Start time': getPrettyTimeStamp( selectedDeviceAlarm.created ),
	};

	const getAlarmCardPrettyOptionText = ( key: keyof CardAlarm ) => {
		switch ( key ) {
			case 'Current value':
				return t( 'Current value' );
			case 'Peak value':
				return t( 'Peak value' );
			case 'Start time':
				return t( 'Start time' );
			default:
				return t( 'Current value' );
		}
	};

	return (
		<div
			className={ `bg-danger-${ selectedDeviceAlarm.alarmSeverity.toLowerCase() } p-4 rounded-2.5xl text-base-100 text-sm` }
		>
			<div className="flex gap-2 relative">
				<h2 className="font-semibold text-xl flex-1 mr-8">{title}</h2>
				<div
					className={ `bg-base-100 text-danger-${ selectedDeviceAlarm.alarmSeverity.toLowerCase() } flex items-center w-8 h-8 rounded-full justify-center absolute z-10 right-0 top-0` }
				>
					<AlarmListItemIcon type={ selectedDeviceAlarm.alarmSeverity } />
				</div>
			</div>
			<h3 className="font-bold my-2 text-md">{deviceName}</h3>
			<div>
				{Object.keys( alarmData )
					.filter( ( k ) => {
						if (
							selectedDeviceAlarm.alarmSeverity === AlarmSeverityEnum.CONNECTION
						) {
							return k !== 'Peak value';
						}
						return true;
					} )
					.map( ( key ) => (
						<div className="flex gap-2 2-full" key={ key }>
							<div className="w-1/4">
								{`${
									key === 'Current value' && !isConnected
										? t( 'Last value' )
										: getAlarmCardPrettyOptionText( key as keyof CardAlarm )
								}:`}
							</div>
							<div
								className={ `flex-1 ${
									key.toLowerCase().includes( 'value' ) ? 'font-bold' : ''
								}` }
							>
								{alarmData[key as keyof CardAlarm]}
							</div>
						</div>
					) )}
				{selectedDeviceAlarm.alarmSeverity === AlarmSeverityEnum.CONNECTION && (
					<div className="flex gap-2 2-full">
						<div className="w-1/4">{`${ t( 'Current status' ) }:`}</div>
						<div>{isConnected ? t( 'Connected' ) : t( 'Disconnected' )}</div>
					</div>
				)}
			</div>
			<div className="w-full flex justify-end mt-2">
				<div className="flex  gap-2 w-3/4">
					<Button
						text={ t( 'Solve & Report' ) }
						onClick={ handleOpenResolveModal }
						icon={ <ResolveIcon /> }
						className={ `bg-primary min-w-56 w-auto shadow-bsm ${
							resolveDisabled ? 'opacity-40 cursor-default' : ''
						}` }
					/>
					{isResolveModalOpen ? (
						<ResolveAlarm
							alarms={ [ selectedDeviceAlarm ] }
							resolveDisabled={ resolveDisabled }
							closeModal={ handleCloseResolveModal }
						/>
					) : null}
				</div>
			</div>
		</div>
	);
};

export default DeviceDetailsAlarmCard;
