/*
Example API error
---------------
{
  detail: "Please refer to the errors property for additional details."
  errors: {AlarmMonitoring: ["Alarm not found"]}
  instance: "/api/v1/alarms/38cc8707-c9e3-4baa-83bf-88de57a2ffdd/resolve"
  status: 100202
  title: "One or more validation errors occurred."
}
*/

import i18n from 'i18next';
import { ErrorCode } from './errorCodes';

const generateErrorMessageBasedOnErrorCode = ( code: ErrorCode ) => {
	switch ( code ) {
		case ErrorCode.ConnectionInUse: {
			return i18n.t( 'There is an already existing connection for this device' );
		}

		case ErrorCode.WrongAddressOrPort: {
			return i18n.t( 'Invalid address or port number' );
		}

		case ErrorCode.ConnectionFailed: {
			return i18n.t( 'Connection failed' );
		}

		// Alarms
		case ErrorCode.AlarmsMonitoringMeasurementsOutOfBounds: {
			return i18n.t( 'The device is still in alarm' );
		}
		case ErrorCode.AlarmsMonitoringDeviceStillDisconnected: {
			return i18n.t( 'The device is still disconnected' );
		}

		case ErrorCode.AddressNotResolved: {
			return i18n.t( 'Address is not resolved' );
		}

		case ErrorCode.ApplicationError: {
			return i18n.t( 'Application Error' );
		}
		case ErrorCode.WrongInputData: {
			return i18n.t( 'Wrong input data' );
		}
		case ErrorCode.WrongInputDeviceConfiguration: {
			return i18n.t( 'Wrong input for device configuration' );
		}
		case ErrorCode.PlaceNotFound: {
			return i18n.t( 'Place not found' );
		}
		case ErrorCode.DevicePositionNotFound: {
			return i18n.t( 'Device position not found' );
		}
		case ErrorCode.DeviceDefinitionNotFound: {
			return i18n.t( 'Device definition not found' );
		}
		case ErrorCode.DeviceMessageNotFound: {
			return i18n.t( 'Device message not found' );
		}
		case ErrorCode.DeviceConfigurationNotFound: {
			return i18n.t( 'Device configuration not found' );
		}
		case ErrorCode.PlaceAlreadyExists: {
			return i18n.t( 'Problem' );
		}
		case ErrorCode.DeviceAlreadyExists: {
			return i18n.t( 'Device already exists' );
		}
		case ErrorCode.InvalidUsernameAndPassword: {
			return i18n.t( 'Invalid username or password' );
		}
		case ErrorCode.UserSessionExpired: {
			return i18n.t( 'User session expired' );
		}
		case ErrorCode.InvalidSession: {
			return i18n.t( 'Invalid session' );
		}
		case ErrorCode.AuthNoRights: {
			return i18n.t( 'Auth no rights' );
		}
		case ErrorCode.AuthFailed: {
			return i18n.t( 'Auth failed' );
		}
		case ErrorCode.InvalidJwtToken: {
			return i18n.t( 'Invalid JWT token' );
		}
		case ErrorCode.ExternalError: {
			return i18n.t( 'External error' );
		}
		case ErrorCode.ExternalRequestHandledUnsuccesfully: {
			return i18n.t( 'External request handled unsuccessfully' );
		}
		case ErrorCode.DeviceConfigurationNone: {
			return i18n.t( 'Device configuration none' );
		}
		case ErrorCode.DeviceConfigurationConnectionAlreadyInUse: {
			return i18n.t( 'Device configuration connection already in use' );
		}
		case ErrorCode.DeviceConfigurationDeviceDoesNotExist: {
			return i18n.t( 'Device configuration device does not exist' );
		}
		case ErrorCode.DeviceConfigurationInvalidConfiguration: {
			return i18n.t( 'Device configuration invalid configuration' );
		}
		case ErrorCode.DeviceConfigurationUnexpectedError: {
			return i18n.t( 'Device configuration unexpected error' );
		}
		case ErrorCode.DeviceConfigurationIdentifierAlreadyInUse: {
			return i18n.t( 'Device configuration identifier already in use' );
		}
		case ErrorCode.AlarmsMonitoringNone: {
			return i18n.t( 'Alarms monitoring none' );
		}
		case ErrorCode.AlarmsMonitoringAlarmNotFound: {
			return i18n.t( 'Alarms monitoring alarm not found' );
		}
		case ErrorCode.AlarmsMonitoringMeasurementsUnexpectedError: {
			return i18n.t( 'Alarms monitoring measurements unexpected error' );
		}
		case ErrorCode.DeviceTypeNotSupported: {
			return i18n.t( 'Device type not supported' );
		}
		case ErrorCode.DeviceCheckFailed: {
			return i18n.t( 'Device check failed' );
		}
		case ErrorCode.InternalError: {
			return i18n.t( 'Internal Error' );
		}

		default:
			return i18n.t( 'something-went-wrong-with-code', { code } );
	}
};

export interface ErrorType {
	errors: { [key: string]: Array<string> };
	code: number;
	message: string;
}

export const getApiErrors = ( response: ErrorType ) => {
	let message = '';
	let customMessage = '';

	if ( response?.errors && Object.keys( response?.errors ).length ) {
		const errorDomainNames = Object.keys( response.errors ); // i.e.[ "AlarmMonitoring", "TryConnectDevice", "Authentication" ]
		const firstErrorDomainName = errorDomainNames[0];
		const errorMessage = firstErrorDomainName
			? ( response.errors as any )[firstErrorDomainName]
			: '';
		message = `${ errorMessage } (Error Code : ${
			response?.code ? response.code : '-'
		})`;
	} else if ( response.message ) {
		message = response.message;
	}

	if ( response?.code ) {
		customMessage = generateErrorMessageBasedOnErrorCode( response.code );
	}

	return { message, customMessage };
};
